import { all } from 'redux-saga/effects';
import loginSaga from './user-authentication';
import notificationSaga from './notification';
import localeSaga from './locale';
import setting from './settings';
import userProfile from './user-profile';
import memberInfo from './member-info';
import event from './event';
import committeeSaga from './committee';
import campaign from './campaign';
import payment from './payment';

const sagas = function* sagas() {
  yield all([
    loginSaga(),
    notificationSaga(),
    localeSaga(),
    setting(),
    userProfile(),
    memberInfo(),
    event(),
    committeeSaga(),
    campaign(),
    payment(),
  ]);
};

export default sagas;
