import { createAction } from 'redux-actions';

export const SEARCH_MEMBERS = 'SEARCH_MEMBERS';
export const searchMembers = createAction(SEARCH_MEMBERS);

export const SEARCH_MEMBERS_REQUESTED = 'SEARCH_MEMBERS_REQUESTED';
export const searchMembersRequested = createAction(SEARCH_MEMBERS_REQUESTED);

export const SEARCH_MEMBERS_SUCCESS = 'SEARCH_MEMBERS_SUCCESS';
export const searchMembersSuccess = createAction(SEARCH_MEMBERS_SUCCESS);

export const SEARCH_MEMBERS_FAILURE = 'SEARCH_MEMBERS_FAILURE';
export const searchMembersFailure = createAction(SEARCH_MEMBERS_FAILURE);

export const ADD_MEMBER = 'ADD_MEMBER';
export const addMember = createAction(ADD_MEMBER);

export const UPDATE_SELECTED_MEMBER = 'UPDATE_SELECTED_MEMBER';
export const updateSelectedMember = createAction(UPDATE_SELECTED_MEMBER);

export const DELETE_MEMBER = 'DELETE_MEMBER';
export const deleteCommiteeMember = createAction(DELETE_MEMBER);

export const ADD_COMMITTEE_REQUESTED = 'ADD_COMMITTEE_REQUESTED';
export const addCommitteeRequested = createAction(ADD_COMMITTEE_REQUESTED);

export const ADD_COMMITTEE = 'ADD_COMMITTEE';
export const addCommittee = createAction(ADD_COMMITTEE);

export const ADD_COMMITTEE_SUCCESS = 'ADD_COMMITTEE_SUCCESS';
export const addCommitteeSuccess = createAction(ADD_COMMITTEE_SUCCESS);

export const ADD_COMMITTEE_FAILURE = 'ADD_COMMITTEE_FAILURE';
export const addCommitteeFailure = createAction(ADD_COMMITTEE_FAILURE);

export const FETCH_COMMITTEES_REQUESTED = 'FETCH_COMMITTEES_REQUESTED';
export const fetchCommitteesRequested = createAction(FETCH_COMMITTEES_REQUESTED);

export const FETCH_COMMITTEES = 'FETCH_COMMITTEES';
export const fetchCommittees = createAction(FETCH_COMMITTEES);

export const FETCH_COMMITTEES_SUCCESS = 'FETCH_COMMITTEES_SUCCESS';
export const fetchCommitteesSuccess = createAction(FETCH_COMMITTEES_SUCCESS);

export const FETCH_COMMITTEES_FAILURE = 'FETCH_COMMITTEES_FAILURE';
export const fetchCommitteesFailure = createAction(FETCH_COMMITTEES_FAILURE);

// ation to fetch committee by members start

export const FETCH_COMMITTEES_BY_MEMBER_REQUESTED = 'FETCH_COMMITTEES_BY_MEMBER_REQUESTED';
export const fetchCommitteesByMemberRequested = createAction(
  FETCH_COMMITTEES_BY_MEMBER_REQUESTED,
);

export const FETCH_COMMITTEES_BY_MEMBER = 'FETCH_COMMITTEES_BY_MEMBER';
export const fetchCommitteesByMember = createAction(FETCH_COMMITTEES_BY_MEMBER);

export const FETCH_COMMITTEES_BY_MEMBER_SUCCESS = 'FETCH_COMMITTEES_BY_MEMBER_SUCCESS';
export const fetchCommitteesByMemberSuccess = createAction(
  FETCH_COMMITTEES_BY_MEMBER_SUCCESS,
);

export const FETCH_COMMITTEES_BY_MEMBER_FAILURE = 'FETCH_COMMITTEES_BY_MEMBER_FAILURE';
export const fetchCommitteesByMemberFailure = createAction(
  FETCH_COMMITTEES_BY_MEMBER_FAILURE,
);

// action to fetch committee by members end
export const DELETE_COMMITTEE = 'DELETE_COMMITTEE';
export const deleteCommittee = createAction(DELETE_COMMITTEE);

export const DELETE_COMMITTEE_SUCCESS = 'DELETE_COMMITTEE_SUCCESS';
export const deleteCommitteeSuccess = createAction(DELETE_COMMITTEE_SUCCESS);

export const DELETE_COMMITTEE_FAILURE = 'DELETE_COMMITTEE_FAILURE';
export const deleteCommitteeFailure = createAction(DELETE_COMMITTEE_FAILURE);

export const GET_COMMITTEE_REQUESTED = 'GET_COMMITTEE_REQUESTED';
export const getCommitteeRequested = createAction(GET_COMMITTEE_REQUESTED);

export const GET_COMMITTEE = 'GET_COMMITTEE';
export const getCommittee = createAction(GET_COMMITTEE);

export const GET_COMMITTEE_SUCCESS = 'GET_COMMITTEE_SUCCESS';
export const getCommitteeSuccess = createAction(GET_COMMITTEE_SUCCESS);

export const GET_COMMITTEE_FAILURE = 'GET_COMMITTEE_FAILURE';
export const getCommitteeFailure = createAction(GET_COMMITTEE_FAILURE);

export const EDIT_COMMITTEE_FIELDS_STATE = 'EDIT_COMMITTEE_FIELDS_STATE';
export const editCommitteeFieldsState = createAction(EDIT_COMMITTEE_FIELDS_STATE);

export const EDIT_COMMITTEE_UPDATE_SELECTED_MEMBER = 'EDIT_COMMITTEE_UPDATE_SELECTED_MEMBER';
export const updateSelectedMemberInEditCommittee = createAction(
  EDIT_COMMITTEE_UPDATE_SELECTED_MEMBER,
);

export const EDIT_COMMITTEE_UPDATE_ADDED_MEMBERS = 'EDIT_COMMITTEE_UPDATE_ADDED_MEMBERS';
export const editCommitteeUpdateAddedMembers = createAction(
  EDIT_COMMITTEE_UPDATE_ADDED_MEMBERS,
);

export const EDIT_COMMITTEE_UPDATE_DELETED_MEMBERS = 'EDIT_COMMITTEE_UPDATE_DELETED_MEMBERS';
export const editCommitteeUpdateDeletedMembers = createAction(
  EDIT_COMMITTEE_UPDATE_DELETED_MEMBERS,
);

export const EDIT_COMMITTEE_REQUESTED = 'EDIT_COMMITTEE_REQUESTED';
export const editCommitteeRequested = createAction(EDIT_COMMITTEE_REQUESTED);

export const EDIT_COMMITTEE = 'EDIT_COMMITTEE';
export const editCommittee = createAction(EDIT_COMMITTEE);

export const EDIT_COMMITTEE_SUCCESS = 'EDIT_COMMITTEE_SUCCESS';
export const editCommitteeSuccess = createAction(EDIT_COMMITTEE_SUCCESS);

export const EDIT_COMMITTEE_FAILURE = 'EDIT_COMMITTEE_FAILURE';
export const editCommitteeFailure = createAction(EDIT_COMMITTEE_FAILURE);

export const TOGGLE_SEARCH_LIST = 'TOGGLE_SEARCH_LIST';
export const toggleSearchList = createAction(TOGGLE_SEARCH_LIST);
