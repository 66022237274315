import React from 'react';
import './MemberProfile.scss';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { func, shape, string } from 'prop-types';
import { TopHeader } from 'components';
// import { workInfo, addressInfo } from 'actions/member-view-action';
import { memberListView, checkUserUsage } from 'actions/profile-info-action';
import ProfileMain from '../ProfileMain/index';
import ProfileInfo from '../ProfileInfo';
import ProfileActivity from '../ProfileActivity';
import ProfilePayment from '../ProfilePayment';
import ProfileCommittees from '../ProfileCommittees';
import ProfileEvents from '../ProfileEvents';
import ProfileEmail from '../ProfileEmail';

class MemberProfile extends React.Component {
  static propTypes = {
    // addressInfo: func.isRequired,
    memberListView: func.isRequired,
    checkUserUsage: func.isRequired,
    paymentForm: shape({
      email: string,
      name: string,
    }),
    /* workInfo: func.isRequired, */
  };

  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
    };

    this.tabIndexes = {
      info: 0,
      activity: 1,
      payments: 2,
      committees: 3,
      events: 4,
      email: 5,
    };
  }

  componentDidMount() {
    // const url = new URL(window.location.href);
    const userId = this.props.match.params.memberId;

    const currentTab = this.props.match.params.tab ? this.props.match.params.tab : 'info';
    const currentTabIndex = this.tabIndexes[currentTab] ? this.tabIndexes[currentTab] : 0;

    this.setState({
      tabIndex: currentTabIndex,
    });

    if (userId) {
      this.props.checkUserUsage();
      this.props.memberListView(userId);
      // this.props.workInfo(userId);
      // this.props.addressInfo(userId);
    }
  }

  onTabSelect = (tabIndex, allowPayments) => {
    let tabSlug = Object.keys(this.tabIndexes).find((key) => this.tabIndexes[key] === tabIndex);

    if (!allowPayments && tabSlug === 'payments') {
      tabSlug = Object.keys(this.tabIndexes).find((key) => this.tabIndexes[key] === (tabIndex + 1));
    }

    const userId = this.props.match.params.memberId;
    const pathName = tabSlug === 'info' ? `/member/${userId}` : `/member/${userId}/${tabSlug}`;

    this.setState({ tabIndex });

    this.props.history.push({
      pathname: pathName,
    });
  };

  render() {
    const { member, owner, stateData } = this.props;

    if (!member.memberView.data) {
      return null;
    }

    const allowPayments = this.props.usage && this.props.usage.acceptPayment && (owner && owner.paymentDetails);

    return (
      <div className="App-Memership">
        <div className="container">
          <div className="Header-top">
            <TopHeader />
          </div>
          <ProfileMain userData={member.memberView.data} userDataInfo={stateData} />
          <div className="tab_bar">
            <Tabs selectedIndex={this.state.tabIndex} onSelect={(i) => this.onTabSelect(i, allowPayments)}>
              <TabList>
                <Tab>Info</Tab>
                <Tab>Activity</Tab>
                { allowPayments ? <Tab>Payments</Tab> : null }
                <Tab>Committees</Tab>
                <Tab>Events</Tab>
                <Tab>Email</Tab>

              </TabList>

              <TabPanel>
                <ProfileInfo userData={member.memberView.data} />
              </TabPanel>
              <TabPanel>
                <ProfileActivity />
              </TabPanel>
              {
                allowPayments ? <TabPanel><ProfilePayment userData={member.memberView.data} /></TabPanel> : null
              }
              <TabPanel>
                <ProfileCommittees />
              </TabPanel>
              <TabPanel>
                <ProfileEvents />
              </TabPanel>
              <TabPanel>
                <ProfileEmail />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  member: state.userProfile,
  usage: state.userProfile.usage,
  owner: state.settings && state.settings.userDetails ? state.settings.userDetails : null,
  stateData: state,
});

export default connect(mapStateToProps, {
  checkUserUsage,
  // addressInfo,
  memberListView,
  /* workInfo, */
})(MemberProfile);
