import QRCode from 'qrcode';
import { Component } from 'react';

class ReactQR extends Component {
  state = {
    qrURL: '',
  }

  async componentDidMount() {
    try {
      if (this.props.value) {
        const url = await QRCode.toDataURL(this.props.value);

        this.setState({ qrURL: url });
      }
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const { title, value, enableDownload, fileName } = this.props;

    const downloadBtn = {
      boxShadow: 'none',
      margin: '15px 0',
      color: '#000',
      padding: '10px',
      fontSize: '17px',
    };

    if (this.state.qrURL) {
      return (
        <div style={{ textAlign: 'center' }}>
            <h3>{title}</h3>
            <div>
              <div>
                <img src={this.state.qrURL} style={{ border: '10px solid #2c2a2a' }}/>
              </div>
              {enableDownload ? <div><a className="btn btn-default" download={fileName} href={this.state.qrURL} style={downloadBtn}>Download</a></div> : ''}
              <div>
                <a className="btn btn-warning m-0" href={`${value}`} style={downloadBtn}>Update Member Profile</a>
              </div>
            </div>
          </div>
      );
    }

    return (<div>QR code: {this.state.qrURL}</div>);
  }
}
export default ReactQR;
