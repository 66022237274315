import React, { Component } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { logOut } from 'actions/user-authentication-action-types';

class Logout extends Component {
  static propTypes = { logOut: func.isRequired };

  componentDidMount() {
    this.props.logOut();
  }

  render() {
    return <h1>Logout.</h1>;
  }
}
export default connect(null, { logOut })(Logout);
