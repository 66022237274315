import { combineReducers } from 'redux';
import login from './userAuthentication';
import notification from './notification';
import settings from './settings';
import locale from './locale';
import userProfile from './profileInfo';
import memberInfo from './memberInfo';
import event from './event';
import committee from './committee';
import campaign from './campaign';
import payment from './payment';

const reducers = combineReducers({
  campaign,
  committee,
  event,
  locale,
  login,
  memberInfo,
  notification,
  settings,
  userProfile,
  payment,
});

export default reducers;
