import React, { Component, Fragment } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { Link, FormattedMessage } from 'components';
import {
  loginAuthorization,
  loginFieldsState,
  showLanguageDropdown,
} from 'actions/user-authentication-action-types';
import User from 'utils/user';
import { selectLocale } from 'actions/locale-action-types';
import logo from 'assets/images/LogoIcon.png';

const Loader = require('react-loader');

const { validateEmail } = new User();

// Login Component
class Login extends Component {
  static propTypes = {
    lang: string.isRequired,
    logOutStatus: string,
    loginAuthorization: func.isRequired,
    loginAuthorizationError: string,
    loginFieldsDetails: shape({
      email: string,
      name: string,
    }),
    loginFieldsState: func.isRequired,
    selectLocale: func.isRequired,
  };

  state = {
    error1: 1,
    errors: {},
    loaded: true,
    type: 'password',
  };

  componentDidMount() {
    // document.body.addEventListener('click', this.handleBodyClick);
    const { loginFieldsDetails } = this.props;

    if (loginFieldsDetails.email || loginFieldsDetails.password) {
      this.props.loginFieldsState(
        update(loginFieldsDetails, {
          $merge: {
            email: '',
            password: '',
          },
        }),
      );
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    const { loginFieldsDetails } = this.props;

    const updatedLoginFieldsDetails = {
      ...loginFieldsDetails,
      [name]: value,
    };

    if (name === 'language') {
      this.props.selectLocale(value);
    }

    this.props.loginFieldsState(updatedLoginFieldsDetails);
  };

  validateFields = () => {
    const {
      loginFieldsDetails: { email, password },
    } = this.props;

    const errors = {};

    let isValid = true;

    if (!email) {
      isValid = false;
      errors.userEmail = 'emptyEmailValidation';
    } else if (!validateEmail(email)) {
      isValid = false;
      errors.userEmail = 'emailValidation';
    }
    if (!password) {
      isValid = false;
      errors.userPassword = 'emptyPassword';
    }

    this.setState({ errors });

    return isValid;
  };

  handleKeyPress = (event) => {
    User.spaceNotAllowed(event);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const isValid = this.validateFields();

    if (isValid) {
      const { loginFieldsDetails } = this.props;

      this.setState({ error1: 1 });
      this.setState({ loaded: false });
      // console.log('loginFieldsDetails +++>>>', this.props.loginFieldsDetails);
      this.setState({ errors: '' });
      this.props.loginAuthorization({ ...loginFieldsDetails });

      this.props.loginFieldsState(
        update(loginFieldsDetails, { $merge: { password: '' } }),
      );
    }
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  // showMenu = () => {
  //   this.props.showLanguageDropdown(true);
  //   this.setState({ shouldShowLanguageMenu: !this.state.shouldShowLanguageMenu });
  // };
  handleClick = () => this.setState(({ type }) => ({
    type: type === 'text' ? 'password' : 'text',
  }));

  selectedFlag = (data) => {
    const { loginFieldsDetails } = this.props;

    const updatedLoginFieldsDetails = {
      ...loginFieldsDetails,
    };

    this.props.selectLocale(data.value);

    this.props.loginFieldsState(updatedLoginFieldsDetails);
  };

  func() {
    this.setState({ loaded: true });
    this.setState({ error1: 2 });
  }

  render() {
    const {
      loginFieldsDetails: { email, password },
      loginAuthorizationError,
    } = this.props;
    const {
      errors: { userEmail, userPassword },
    } = this.state;

    if (loginAuthorizationError && this.state.error1 === 1) {
      this.func();
    }

    return (
      <Fragment>
        <div className="container">
          <div className="panel onboarding-bx">
            <img alt="logo" className="logo-sm" src={logo} />
            <h2>LOGIN</h2>
            {/* <form action="/SettingStepProfile"> */}
            <Loader loaded={this.state.loaded}>
              {/* <div className="alert alert-warning">System login is temporarily offline while we perform some planned maintenance to improve things for you.  Everything should be back online by 12pm today.</div> */}
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    autoFocus
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    type="text"
                    value={email || ''}
                    onBlur={this.handleBlurChange}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  {!!userEmail && this.showError(userEmail)}
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    name="password"
                    name="password"
                    placeholder="Password"
                    type={this.state.type}
                    value={password || ''}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <span className="password_show" onClick={this.handleClick}>
                    {this.state.type === 'text' ? 'Hide' : 'Show'}
                  </span>
                  {!!userPassword && this.showError(userPassword)}
                </div>
                <div className="form-group text-right">
                  <Link className="link" href="/forgotpassword">
                    Forgot Password
                  </Link>
                </div>
                <div className="form-group">
                  <input
                    className="btn btn-primary full"
                    type="submit"
                    value="Login"
                  />
                </div>
                <div className="form-group text-center">
                  <Link className="link" href="/signup">
                    Sign Up
                  </Link>
                </div>
              </form>
            </Loader>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  login: { loginFieldsDetails, logOutStatus, loginAuthorizationError },
  locale: { lang },
}) => ({
  lang,
  logOutStatus,
  loginAuthorizationError,
  loginFieldsDetails,
});

export default connect(mapStateToProps, {
  loginAuthorization,
  loginFieldsState,
  selectLocale,
  showLanguageDropdown,
})(Login);
