/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import logo from 'assets/images/LogoIcon.png';
import Userimg from 'assets/images/Userimg.png';
import { globalSearch } from 'actions/user-authentication-action-types';
import { getUserDetails } from 'actions/settings-step-action';
import SubscriptionNotice from './SubscriptionNotice';

const Loader = require('react-loader');

class TopHeader extends React.Component {
  state = {
    searchKeyword: '',
    showSearchList: false,
  };

  componentDidMount() {
    this.props.getUserDetails();
  }

  getMembers = (event) => {
    event.preventDefault();

    let keyword = event.target.value.trim();

    const checkHtmlTags = /(<([^>]+)>)/gi.test(keyword);

    if (keyword && !checkHtmlTags) {
      this.props.globalSearch({
        keyword,
      });
    }

    if (checkHtmlTags) {
      keyword = '';
    }

    this.setState({
      searchKeyword: keyword,
      showSearchList: true,
    });
  };

  clearSearch = () => {
    this.setState({
      searchKeyword: '',
      showSearchList: false,
    });

    this.refs.keyword.value = '';
  };

  render() {
    const {
      apiErrors,
      isLoading,
      membersList,
      globalSearchStatus,
      userDetails,
      hideNotice,
    } = this.props;

    const { searchKeyword, showSearchList } = this.state;

    {
      apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: false, // 1500,
        });
    }

    let optionList;

    // console.log('showSearchList: ', showSearchList);
    // console.log('searchKeyword: ', searchKeyword);
    // console.log('globalSearchStatus: ', globalSearchStatus);

    if (showSearchList && searchKeyword && globalSearchStatus === 'success') {
      if (membersList.length) {
        optionList = (
          <ul className="global-search-ul">
            {membersList.map((eachMember) => (
              <li className="global-search-li" key={eachMember.id}>
                <a href={`/member/${eachMember.id}`}>
                  <span className="capitalize">
                    {eachMember.firstName} {eachMember.lastName}
                  </span>
                </a>
              </li>
            ))}
          </ul>
        );
      } else {
        optionList = (
          <div className="no-options">
            <em>No record found</em>
          </div>
        );
      }
    }

    return (
      <React.Fragment>
        <div className="App-header">
          <div className="App-logo">
            <Link className="" to="/">
              <img alt="logo" className="logoinner" src={logo.replace('./', '/')} />
            </Link>
          </div>

          {isLoading && <Loader loaded={!isLoading} />}

          <div className="App-searchbar">
            <div className="search">
              <input
                className="searchTerm"
                placeholder="Type here..."
                ref="keyword"
                type="text"
                onKeyUp={(event) => this.getMembers(event)}
              />
              {searchKeyword && (
                <button
                  className="searchButton"
                  id="clear"
                  onClick={(e) => this.clearSearch(e)}
                >
                  Reset
                </button>
              )}
              {/* <button type="submit" className="searchButton">
                Search
              </button> */}
            </div>
            {optionList}
          </div>
          <div className="App-account">
            <Link to="/">
              <p>
                <span>{userDetails.firstName ? userDetails.firstName : ''}</span>{' '}
                &nbsp;
                <span>{userDetails.lastName ? userDetails.lastName : ''}</span>
              </p>

              <div className="user-avatar">
                <img
                  alt="userimg"
                  src={userDetails.profileImg ? userDetails.profileImg : Userimg.replace('./', '/')}
                />
              </div>
            </Link>
            <div className="setting">
              <Link className="" to="/settings">
                <i className="fa fa-cog"></i>
              </Link>
            </div>
          </div>
        </div>
        { hideNotice ? null : <SubscriptionNotice /> }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  login: { membersList, globalSearchError, isLoading, globalSearchStatus },
  settings,
}) => ({
  membersList,
  apiErrors: globalSearchError,
  isLoading,
  globalSearchStatus,
  userDetails: settings.userDetails,
});

export default connect(mapStateToProps, {
  globalSearch,
  push,
  getUserDetails,
})(TopHeader);
