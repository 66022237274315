/* eslint-disable no-restricted-globals */
/* eslint-disable max-lines */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state */
import React from 'react';
import './CreateEvent.scss';
import leftarrow from 'assets/images/leftarrow.png';
import {
  checkUserUsage,
  filterDataList,
  filterList,
  filterDataListing,
  indivisualUser,
} from 'actions/profile-info-action';
import { func } from 'prop-types';
import {
  addEvent,
  editEvent,
  inviteMemberList,
  filterEventList,
  deleteMemberEvent,
  updateMemberEvent,
  eventInvitedDeleteUser,
} from 'actions/event-action';
import { TopHeader, FormattedMessage, UpgradeSubscription } from 'components';

import User from 'utils/user';
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-places-autocomplete';
/* import DatePicker from 'react-datepicker'; */
import { connect } from 'react-redux';
import moment from 'moment';
import {
  addCampaignStepOne,
} from 'actions/campaign';

const { authUser } = new User();

let self;

class CreateEvent extends React.Component {
  static propTypes = {
    filterDataList: func.isRequired,
    filterList: func.isRequired,
    filterDataListing: func.isRequired,
    checkUserUsage: func.isRequired,
    addEvent: func.isRequired,
    editEvent: func.isRequired,
    indivisualUser: func.isRequired,
    inviteMemberList: func.isRequired,
    filterEventList: func.isRequired,
    deleteMemberEvent: func.isRequired,
    updateMemberEvent: func.isRequired,
    eventInvitedDeleteUser: func.isRequired,
  };

  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    errors: {},
    startDate: null,
    hour: '',
    minute: '',
    timeFormat: '',
    timeZone: '',
    title: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    description: '',
    member: [],
    eventId: '',
    organisationName: '',
    emailTitle: '',
    body: '',
    selectedFilter: [],
    selected_column: [],
    selected_column_value: [],
    optionList: false,
    emailIndividuals: false,
    checked: false,
    optionListTrue: false,
    Opted: false,
  };

  componentDidMount() {
    this.props.checkUserUsage();

    // console.log(this.props.match);

    document.addEventListener('mousedown', this.handleClickOutside);
    let userDataValue = localStorage.getItem('userDataValue');

    userDataValue = userDataValue ? JSON.parse(userDataValue) : null;

    let organisationName1 = authUser().user.Organisation.name === undefined
      ? null
      : authUser().user.Organisation.name;

    organisationName1 = !organisationName1 && userDataValue ? userDataValue.Organisation.name : organisationName1;

    this.setState({ organisationName: organisationName1 });
    if (this.props.location.state) {
      const { fromNotifications } = this.props.location.state;

      this.state.eventId = fromNotifications.id;
      this.state.startDate = fromNotifications && fromNotifications.date
        ? moment(fromNotifications.date).format('MM/DD/YYYY')
        : null;
      this.state.hour = fromNotifications.hour;
      this.state.minute = fromNotifications.minute;
      this.state.timeZone = fromNotifications.timeZone;
      this.state.timeFormat = fromNotifications.timeFormat;
      this.state.title = fromNotifications.title;
      this.state.address1 = fromNotifications.address1;
      this.state.address2 = fromNotifications.address2;
      this.state.city = fromNotifications.city;
      this.state.state = fromNotifications.state;
      this.state.zipCode = fromNotifications.zipCode;
      this.state.description = fromNotifications.description;
      const eventsValue = {
        id: fromNotifications.id,
        offSet: 0,
      };

      this.props.inviteMemberList(eventsValue);
    }
    if (this.props.data1.userProfile.memberList.length !== 0) {
      this.setState({ member: this.props.data1.userProfile.memberList });
    }
    if (this.props.data1.event.memberListing.length !== 0) {
      this.setState({ member: this.props.data1.event.memberListing });
    }

    console.log('this.props.data1: ', this.props.data1.event.memberList);
    this.props.filterDataListing();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleOptChange = (event) => {
    this.setState({
      Opted: event.target.checked,
    });
  };

  individualsEmail = (event) => {
    if (event.target.checked) {
      this.setState({ emailIndividuals: true });
    } else {
      this.setState({ emailIndividuals: false });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ optionList: false });
    }
  }

  /* slectedFilter() {} */

  handleChangeDate = (event) => {
    // this.setState({ startDate: date ? date.format('MM/DD/YYYY') : null });
    this.setState({ startDate: event.target.value });
  }

  createSelectItems(data) {
    const items = [];

    for (let i = 0; i <= data; i++) {
      if (i < 10) {
        // console.log("+++",i);
        i = `0${i}`;
      }
      items.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
      // here I will be creating my options dynamically based on
      // what props are currently passed to the parent component
    }

    return items;
  }

  handleLoginKeyUp = (event, data) => {
    if (event.target.value.length !== 0) {
      this.props.indivisualUser({
        key: data,
        value: event.target.value,
        type: 'text',
      });
    }
  };

  onDropdownSelected = (e) => {
    const { name, value } = e.target;

    console.log('THE VAL', name, value);

    this.setState({ [name]: value });
    // here you will see the current selected value of the select input
  }

  handleChangeAddress = (address1) => {
    this.setState({ address1 });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  validateHome = () => {
    const errors = {};

    let isValid = true;

    if (!this.state.title) {
      isValid = false;
      errors.titleError = 'requiredField';
    } else if (this.state.title.length < 2) {
      isValid = false;
      errors.titleError = 'invalidField';
    }

    if (!this.state.minute) {
      isValid = false;
      errors.minuteError = 'requiredField';
    }

    if (!this.state.hour) {
      isValid = false;
      errors.hourError = 'requiredField';
    }

    if (this.state.emailIndividuals && this.state.body) {
      const emailBody = this.state.body.trim();

      const checkHtmlTags = /(<([^>]+)>)/gi.test(emailBody);

      if (checkHtmlTags) {
        errors.htmlTags = 'htmlTagsError';
        isValid = false;
      }
    }

    this.setState({ errors });

    return isValid;
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  saveAndContinue = (event) => {
    event.preventDefault();
    const valid = this.validateHome();

    if (valid) {
      const options = {
        date: this.state.startDate ? this.state.startDate : null,
        hour: this.state.hour,
        minute: this.state.minute,
        timeFormat: this.state.timeFormat,
        timeZone: this.state.timeZone,
        title: this.state.title,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zipCode: this.state.zipCode,
        description: this.state.description,
        member: this.props.data1.event.memberList,
        peopleInvited: this.props.data1.event.memberList.length,
        subject: `${this.state.organisationName} 'to' ${this.state.title}`,
        body: this.state.body,
        emailIndividuals: this.state.emailIndividuals,
      };

      if (this.state.eventId) {
        options.id = this.state.eventId;
        this.props.editEvent(options);
      } else {
        this.props.addEvent(options);
        if (this.state.Opted) {
          // this.props.data1.event.memberList
          const memberIds = [];

          let i;

          for (i = 0; i < this.props.data1.event.memberList.length; i++) {
            // text += this.props.data1.event.memberList[i] + "<br>";
            // (this.props.data1.event.memberList[i].id)
            const memberIdval = this.props.data1.event.memberList[i].id;

            const memberIdvalObj = { memberId: memberIdval, filterName: '' };

            memberIds.push(memberIdvalObj);
          }

          const formData = {
            campaignName: this.state.title,
            campaignId: '',
            // filterIds: this.state.selectedFilterIds,
            filterIds: [],
            filterNames: '',
            members: memberIds,
          };

          console.log('addCampaignStepOne');
          this.props.addCampaignStepOne(formData);
        }
      }
    }
  };

  onDropdownSelected12 = () => {
    this.setState({ optionList: !this.state.optionList });
    for (let i = 0, len = this.props.data1.event.memberList.length; i < len; i++) {
      for (
        let j = 0, leng = this.props.data1.userProfile.filterListing.data.length;
        j < leng;
        j++
      ) {
        if (
          this.props.data1.event.memberList[i].filterList
          === this.props.data1.userProfile.filterListing.data[j].filterName
        ) {
          this.props.data1.userProfile.filterListing.data[j].checked = true;
        }
      }
    }
  };

  handleSelect = (address, add1) => {
    const names = address;

    const nameArr = names.split(',');

    this.setState({
      address1: nameArr[0],
      address2: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
    });
    geocodeByPlaceId(add1)
      .then((results) => {
        for (let i = 0, len = results[0].address_components.length; i < len; i++) {
          if (results[0].address_components[i].types[0] === 'locality') {
            this.setState({ city: results[0].address_components[i].long_name });
          } else if (
            results[0].address_components[i].types[0]
            === 'administrative_area_level_1'
          ) {
            this.setState({ state: results[0].address_components[i].long_name });
          } else if (results[0].address_components[i].types[0] === 'country') {
            this.setState({ country: results[0].address_components[i].long_name });
          } else if (results[0].address_components[i].types[0] === 'postal_code') {
            this.setState({ zipCode: results[0].address_components[i].long_name });
          }
        }
      })
      .catch((error) => console.error(error));
  }

  pushDataMemberList(data) {
    self.setState({ member: data });
  }

  pushDataMemberList1(data) {
    self.setState({ member: data });
  }

  selectTimeZone(event) {
    this.setState({ timeZone: event });
  }

  addStudentData(data) {
    const { indivisualList } = this.props.data1.userProfile;

    for (let j = 0, len = indivisualList.length; j < len; j++) {
      if (data.id === indivisualList[j].id) {
        indivisualList[j].checked = true;
      }
    }
    if (this.props.data1.event.memberList.indexOf(data) === -1) {
      this.props.data1.event.memberList.push(data);
      this.setState({ member: this.state.member });
    }
  }

  deleteMember(index, value) {
    if (value.eventId) {
      this.props.eventInvitedDeleteUser(value.id);
      this.props.data1.event.memberList.splice(index, 1);
      this.props.data1.event.memberList = this.props.data1.event.memberList;
    } else {
      this.props.data1.event.memberList.splice(index, 1);
      this.props.data1.event.memberList = this.props.data1.event.memberList;
    }

    const activeFilters = [];

    this.props.data1.event.memberList.forEach((m) => {
      if (m.filter) {
        activeFilters.push(m.filter);
      }
    });

    this.setState({
      parentName: 'newName',
      selectedFilter: activeFilters,
    });
  }

  handleCheckbox1 = (event) => {
    const { name, value } = event.target;

    this.setState({ optionListTrue: true });
    const filterValue = JSON.parse(value).filterData;

    const filterValueData = JSON.parse(filterValue);

    filterValueData.filterList = JSON.parse(value).filterName;
    const dataValue = [];

    for (
      let j = 0, leng = self.props.data1.userProfile.filterListing.data.length;
      j < leng;
      j++
    ) {
      if (name === self.props.data1.userProfile.filterListing.data[j].filterName) {
        self.props.data1.userProfile.filterListing.data[j].checked = event.target.checked;
      }
    }
    if (event.target.checked) {
      self.state.selected_column.push({ value, checked: true });
      this.props.filterEventList(filterValueData);
    } else {
      self.state.selected_column.pop({ value, checked: 'unchecked' });
      for (let i = 0, leng = self.state.member.length; i < leng; i++) {
        if (self.state.member[i] && self.state.member[i].filterList) {
          if (self.state.member[i].filterList === name) {
            this.state.member.splice(i, 1);
          } else {
            dataValue.push(self.state.member[i]);
          }
        }
      }
    }
  };

  handleCheckboxAdd = (value, name) => {
    const { selectedFilter } = this.state;

    if (!selectedFilter.includes(value.key)) {
      selectedFilter.push(value.key);
    }

    this.setState({ optionListTrue: true });
    const filterValue = value.filterData;

    const filterValueData = JSON.parse(filterValue);

    filterValueData.filterList = value.filterName;
    for (
      let j = 0, leng = this.props.data1.userProfile.filterListing.data.length;
      j < leng;
      j++
    ) {
      if (name === this.props.data1.userProfile.filterListing.data[j].filterName) {
        this.props.data1.userProfile.filterListing.data[j].checked = true;
      }
    }
    const selectedColumn = this.state.selected_column;

    selectedColumn.push({ value: JSON.stringify(value), checked: true });

    this.setState({
      selected_column: selectedColumn,
      selectedFilter,
    });

    this.props.filterEventList(filterValueData);
  };

  sendClick(value, type) {
    // console.log('value', value, 'type', type);
    // console.log(this.props.data1.event.memberList);
    for (let j = 0, leng = this.props.data1.event.memberList.length; j < leng; j++) {
      if (value.memberId === this.props.data1.event.memberList[j].memberId) {
        // console.log('++++ type', !value[`${type}`]);
        // console.log('++++', value[`${type}`]);

        const option = {
          id: value.id,
          name: type,
          eventId: value.eventId,
          value: !value[`${type}`],
        };

        this.props.updateMemberEvent(option);
      }
    }
  }

  render() {
    const { data1 } = this.props;
    const {
      errors: { titleError, minuteError, hourError },
    } = this.state;
    // console.log('this.setState({optionListTrue:true})', data1)

    if (!this.state.eventId && this.props.usage && !this.props.usage.events) {
      return (<UpgradeSubscription heading="Create Events" href="/event" usage={this.props.usage} />);
    }

    const memberList = {};

    data1.event.memberList.forEach((m) => {
      const key = m.memberId || m.id;

      memberList[key] = m;

      if (typeof memberList[key].filter === 'undefined' || !memberList[key].filter) {
        memberList[key].filter = 'all';
      }

      /*
      if (m.filter && !memberList[key].filterList.includes(m.filter)) {
        memberList[key].filterList = m.filter;
      } */
    });

    const individualUser = data1.userProfile.indivisualList
      ? data1.userProfile.indivisualList.map((value) => (
          <ul className="list-dropdown-new" key={value.id}>
            <li className="selected">
              {value.firstName} {value.lastName}{' '}
              {value.checked ? (
                <span className="addbtn-new">added</span>
              ) : (
                <span
                  className="addbtn-new"
                  onClick={() => {
                    this.addStudentData(value);
                  }}
                >
                  add
                </span>
              )}
            </li>
          </ul>
      ))
      : '';
    const optionList = (
      <ul className="selected_list checklist copy-listul">
        {data1.userProfile.filterListing.data
          ? data1.userProfile.filterListing.data.map((value, i) => (
              <li key={`filter-${i}`}>
                {' '}
                {value.filterName}
                { this.state.selectedFilter.includes(value.key) ? (
                  <span className="addbtn-new added-btn">added</span>
                ) : (
                  <span
                    className="addbtn-new"
                    onClick={() => {
                      this.handleCheckboxAdd(
                        value,
                        value.filterName,
                      );
                    }}
                  >
                    add
                  </span>
                )}
              </li>
          ))
          : ''}
      </ul>
    );

    return (
      <div className="sm container">
        {/* <div className="container"> */}
        <TopHeader />
        <div className="csv_row heading-membership">
          <div className="btn_col">
            <a className="backarrow" href="/event">
              <img alt="leftarrow" src={leftarrow} />
            </a>
          </div>
          <h2>{this.state.eventId ? 'Edit Event' : 'Create Event'}</h2>
        </div>
        <form>
          <div className="panel center event">
            <div className="form-group cardnumber">
              <label>Date</label>
              <input
              className="form-control"
              placeholder="When is your event?"
              type="date"
              value={this.state.startDate || ''}
              onChange={(event) => this.handleChangeDate(event)}
              />
              {/* <DatePicker
                className="form-control"
                placeholder="When is your event?"
                selected={this.state.startDate ? moment(this.state.startDate) : null}
                value={this.state.startDate}
                onChange={(event) => this.handleChangeDate(event)}
              />
              <span className="calendricon">
                <i aria-hidden="true" className="fa fa-calendar"></i>
              </span>
              */}
            </div>

            <div className="form-group">
              <label>Time</label>
              <ul className="carddetail time row">
                <li className="col-sm-3">
                  <select
                    className="form-control form-control-main"
                    name="timeZone"
                    placeholder="Timezone"
                    value={this.state.timeZone}
                    onChange={this.onDropdownSelected}
                  >
                    <option> Time Zone</option>
                    <option value="GTM-11: Samoa Standard Time">
                      GTM-11: Samoa Standard Time
                    </option>
                    <option value="GTM-10: Hawaii-Aleutian Standard Time">
                      GTM-10: Hawaii-Aleutian Standard Time
                    </option>
                    <option value="GTM-9: Alaska Standard Time">
                      GTM-9: Alaska Standard Time
                    </option>
                    <option value="GTM−8: Pacific Standard Time">
                      GTM−8: Pacific Standard Time
                    </option>
                    <option value="GTM−7: Mountain Standard Time">
                      GTM−7: Mountain Standard Time
                    </option>
                    <option value="GTM−6: Central Standard Time">
                      {' '}
                      GTM−6: Central Standard Time
                    </option>
                    <option value="GTM−5: Eastern Standard Time">
                      GTM−5: Eastern Standard Time
                    </option>
                    <option value="GTM−4: Atlantic Standard Time">
                      GTM−4: Atlantic Standard Time
                    </option>
                    <option value="GTM+10: Chamorro Standard Time">
                      {' '}
                      GTM+10: Chamorro Standard Time
                    </option>
                    <option value="GTM+12: Wake Island Time Zone">
                      {' '}
                      GTM+12: Wake Island Time Zone
                    </option>
                  </select>
                </li>
                <li className="col-sm-3">
                  <select
                    className="form-control"
                    name="hour"
                    placeholder="Hour"
                    value={this.state.hour}
                    onChange={this.onDropdownSelected}
                  >
                    <option>Hour</option>
                    {this.createSelectItems(12)}
                  </select>
                  {!!minuteError && this.showError(minuteError)}
                </li>
                <li className="col-sm-3">
                  <select
                    className="form-control"
                    name="minute"
                    placeholder="Minutes"
                    value={this.state.minute}
                    onChange={this.onDropdownSelected}
                  >
                    <option>Minutes</option>
                    {this.createSelectItems(59)}
                  </select>
                  {!!hourError && this.showError(hourError)}
                </li>
                <li className="col-sm-3">
                  <select
                    className="form-control"
                    name="timeFormat"
                    placeholder="AM"
                    value={this.state.timeFormat}
                    onChange={this.onDropdownSelected}
                  >
                    <option>AM</option>
                    <option>PM</option>
                  </select>
                </li>
              </ul>
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                className="form-control"
                name="title"
                placeholder="What's your event title?"
                type="text"
                value={this.state.title}
                onChange={this.handleChange}
              />
              {!!titleError && this.showError(titleError)}
            </div>
            <div className="form-group">
              <label>Where are you hosting your event?</label>
              <PlacesAutocomplete
                value={this.state.address1}
                onChange={this.handleChangeAddress}
                onKeyPress={this.handleKeyPress1}
                onSelect={this.handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="margin-bottomnew">
                    <input
                      {...getInputProps({
                        placeholder: 'Address line 1',
                        className: 'location-search-input form-control',
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };

                        return (
                          <div
                            {...getSuggestionItemProps(
                              suggestion,
                              suggestion.placeId,
                              {
                                className,
                                style,
                              },
                            )}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              <input
                className="form-control"
                name="address2"
                placeholder="Address line 2"
                type="text"
                value={this.state.address2}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <label>City</label>
              <input
                className="form-control"
                name="city"
                placeholder="What's your city name?"
                type="text"
                value={this.state.city}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group">
              <ul className="carddetail loc">
                <li>
                  <label>State</label>
                  <input
                    className="form-control"
                    name="state"
                    placeholder="Select your state name?"
                    type="text"
                    value={this.state.state}
                    onChange={this.handleChange}
                  />
                </li>
                <li>
                  <label>Zip</label>
                  <input
                    className="form-control"
                    name="zipCode"
                    placeholder="What's your zip code?"
                    type="text"
                    value={this.state.zipCode}
                    onChange={this.handleChange}
                  />
                </li>
              </ul>
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                name="description"
                placeholder="Write something about your event"
                value={this.state.description}
                onChange={this.handleChange}
              ></textarea>
            </div>
            <div className="form-group">
              <h3>Invite Members</h3>
            </div>
            <div className="form-group">
              <label>Select filter to add a group</label>

              {data1.userProfile.filterListing.data
              && data1.userProfile.filterListing.data.length !== 0 ? (
                <span
                  className="form-control selecter-span"
                  onClick={this.onDropdownSelected12}
                >
                  {' '}
                  Select
                </span>
                ) : (
                <span className="form-control selecter-span">
                  {' '}
                  No Filter available
                </span>
                )}
              {this.state.optionList ? (
                <div ref={this.setWrapperRef}>{optionList}</div>
              ) : (
                ''
              )}
            </div>
            <div className="form-group dropnew-alignment">
              <label>Search to add individuals</label>
              <input
                className="form-control"
                placeholder="Search member to invite them"
                type="text"
                onKeyUp={() => this.handleLoginKeyUp(event, 'firstName')}
              />

              {individualUser}
            </div>
            <div className="form-group">
              <table className="table table_unfix custome-append-new">
                <thead>
                  <tr>
                    <th>Invited</th>
                    <th>Filter List</th>
                    <th>{this.state.eventId ? 'Status' : ''}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {memberList
                    ? Object.keys(memberList).map((key, i) => (
                        <tr key={i}>
                          <td>
                            {memberList[key].firstName} {memberList[key].lastName}
                          </td>
                          <td>{memberList[key].filter || ''}</td>
                          <td>
                            {memberList[key].eventId ? (
                              <span>
                                {' '}
                                {memberList[key].sent === null || memberList[key].sent === false ? (
                                  <a
                                    className="status cuser-pointer-cls"
                                    onClick={() => this.sendClick(memberList[key], 'sent')}
                                  >
                                    Send
                                  </a>
                                ) : (
                                  <a
                                    className="status active"
                                    onClick={() => this.sendClick(memberList[key], 'sent')}
                                  >
                                    Sent
                                  </a>
                                )}
                                {memberList[key].unopened === null
                                || memberList[key].unopened === false ? (
                                  <a
                                    className="status cuser-pointer-cls"
                                    onClick={() => this.sendClick(memberList[key], 'unopened')}
                                  >
                                    Unopened
                                  </a>
                                  ) : (
                                  <a
                                    className="status active"
                                    onClick={() => this.sendClick(memberList[key], 'unopened')}
                                  >
                                    Opened
                                  </a>
                                  )}
                                {memberList[key].rsvp === null || memberList[key].rsvp === false ? (
                                  <a
                                    className="status cuser-pointer-cls"
                                    onClick={() => this.sendClick(memberList[key], 'rsvp')}
                                  >
                                    Not RSVP'd
                                  </a>
                                ) : (
                                  <a
                                    className="status active"
                                    onClick={() => this.sendClick(memberList[key], 'rsvp')}
                                  >
                                    {' '}
                                    RSVP'd
                                  </a>
                                )}{' '}
                                {memberList[key].attended === null
                                || memberList[key].attended === false ? (
                                  <a
                                    className="status cuser-pointer-cls"
                                    onClick={() => this.sendClick(memberList[key], 'attended')}
                                  >
                                    Not attended
                                  </a>
                                  ) : (
                                  <a
                                    className="status active"
                                    onClick={() => this.sendClick(memberList[key], 'attended')}
                                  >
                                    Attended
                                  </a>
                                  )}
                              </span>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            <a
                              className="close"
                              onClick={() => {
                                this.deleteMember(i, memberList[key]);
                              }}
                            >
                              <i className="fa fa-close"></i>
                            </a>
                          </td>
                        </tr>
                    ))
                    : ''}
                </tbody>
              </table>
            </div>
            <div className="form-group">
              <ul className="checklist">
                <li>
                  <input
                    id="activity"
                    name="activity"
                    type="checkbox"
                    value="Activity"
                    onChange={this.individualsEmail}
                  />
                  <label htmlFor="activity">Email individuals</label>
                </li>
              </ul>
              <br></br>
              <div>
                {this.state.emailIndividuals ? (
                  <div>
                    <label>Invite Email</label>
                    <label>Email Subject</label>
                    <input
                      readOnly
                      className="form-control"
                      name="emailTitle"
                      placeholder="Organization name for (Event Title)"
                      type="text"
                      value={`Join ${this.state.organisationName} to ${this.state.title}`}
                      onChange={this.handleChange}
                    />
                    <label>Email Body</label>
                    <textarea
                      className="form-control"
                      name="body"
                      placeholder="Body (event description)"
                      value={this.state.body}
                      onChange={this.handleChange}
                    >
                      (rsvp button)
                    </textarea>
                    {this.state.errors.htmlTags
                      && this.showError(this.state.errors.htmlTags)}
                  </div>
                ) : (
                  ''
                )}
              </div>
              {
                this.state.eventId
                  ? null
                  : <div>
                      <input
                        id="opted"
                        name="Opted"
                        type="checkbox"
                        onChange={this.handleOptChange}
                      />
                      <label htmlFor="opted">
                        Would you like to create a campaign for this event?
                      </label>{' '}
                    </div>
              }
            </div>
            <div className="cent">
              <button className="btn btn-primary" onClick={this.saveAndContinue}>
                {this.state.eventId
                  ? 'Edit Event & Update'
                  : 'Save and create event'}{' '}
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data1: state,
  usage: state.userProfile.usage,
});

export default connect(mapStateToProps, {
  checkUserUsage,
  filterDataList,
  filterDataListing,
  filterList,
  addEvent,
  editEvent,
  indivisualUser,
  inviteMemberList,
  filterEventList,
  deleteMemberEvent,
  updateMemberEvent,
  eventInvitedDeleteUser,
  addCampaignStepOne,
})(CreateEvent);
