import React, { Component, Fragment } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, Link } from 'components';
import { forgotPassword } from 'actions/user-authentication-action-types';
import User from 'utils/user';
import logo from 'assets/images/LogoIcon.png';

const { validateEmail } = new User();

class ForgotPassword extends Component {
  static propTypes = { forgotPassword: func.isRequired };

  state = {
    email: '',
    error: '',
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    const { email } = this.state;

    this.setState({
      email,
      [name]: value,
    });
  };

  handleKeyPress = (event) => {
    User.spaceNotAllowed(event);
  };

  validateEmail = () => {
    const { email } = this.state;

    let isValid = true;

    if (!email) {
      this.setState({ error: <FormattedMessage id="emptyEmailValidation" /> });
      isValid = false;
    } else if (!validateEmail(email)) {
      this.setState({ error: <FormattedMessage id="emailValidation" /> });
      isValid = false;
    } else {
      this.setState({ error: '' });
    }

    return isValid;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { email } = this.state;
    const isValid = this.validateEmail();

    if (isValid) {
      this.props.forgotPassword({ email });
      this.setState({ email: '' });
    }
  };

  render() {
    const { email, error } = this.state;

    return (
      <Fragment>
        <div className="container">
          <div className="panel onboarding-bx">
            <img alt="logo" className="logo-sm" src={logo} />
            <h2>FORGOT PASSWORD</h2>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  className="form-control"
                  name="email"
                  placeholder="Email"
                  type="text"
                  value={email}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
                {!!error && <label className="ez-label ez-error">{error}</label>}
              </div>
              <div className="form-group">
                {/* {this.renderRedirect()} */}
                <input
                  className="btn btn-primary full"
                  type="submit"
                  value="Submit"
                />
              </div>
              <div className="form-group text-center">
                <Link className="link" href="/login">
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, { forgotPassword })(ForgotPassword);
