import axios from 'axios';
import { toast } from 'react-toastify';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import User from './user';
import { LOGIN_SERVICE_URL } from '../constants';

const { signOut, authUser, removeUserDetailsToken } = new User();

class HttpHelper {
  sendRequest = async (args) => {
    try {
      const response = await axios(args);

      return response;
    } catch (error) {
      if (error.response.status === 401) {
        console.error('Not logged in.');
        signOut();
      }

      return {
        error,
        status: error.response.status,
      };
    }
  };

  getRequest = async (args) => {
    const { data, error, status } = await this.sendRequest({
      ...args,
      method: 'get',
    });

    return this.makeResp(data, error, status);
  };

  postRequest = async (args) => {
    const { data, error, status } = await this.sendRequest({
      ...args,
      method: 'post',
    });

    return this.makeResp(data, error, status);
  };

  patchRequest = async (args) => {
    const { data, error, status } = await this.sendRequest({
      ...args,
      method: 'patch',
    });

    return this.makeResp(data, error, status);
  };

  putRequest = async (args) => {
    const { data, error, status } = await this.sendRequest({
      ...args,
      method: 'put',
    });

    return this.makeResp(data, error, status);
  };

  deleteRequest = async (args) => {
    const { data, error, status } = await this.sendRequest({
      ...args,
      method: 'delete',
    });

    return this.makeResp(data, error, status);
  };

  prepareReq = (url, payload = []) => {
    const userData = authUser();
    const payloadData = {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        authid: userData.authId,
        'Content-Type': 'application/json',
      },
      url: `${LOGIN_SERVICE_URL}/${url}`,
    };

    if (payload) {
      payloadData.data = payload;
    }

    return payloadData;
  };

  makeResp = (data, error, status) => {
    const success = [200, 201, 204];
    const isReqSuccess = success.includes(status);

    return {
      data: isReqSuccess ? data : null,
      error: isReqSuccess ? null : (error || data),
      status,
    };
  }
}

export function* handleResp(data, { bAction, sAction, fAction }) {
  if (bAction) yield put(bAction());
  if (data.status === 200) {
    if (data.data.message && data.data.message !== 'success') {
      toast.success(data.data.message, { autoClose: 4500 });
    }

    if (sAction) yield put(sAction(data.data.response));

    return true;
  }

  if (data.status === 401) {
    removeUserDetailsToken();
    yield put(push('/'));
  } else if (fAction) {
    yield put(fAction('Failed'));
    console.error('Failed Request Log: ', data);
  }

  if (data.error) {
    let errorMessage = '';

    if (data.error.response.status === 404) {
      return false;
    }

    if (data.error.response.data) {
      errorMessage = data.error.response.data.message;
    } else if (data.error.response.statusText) {
      errorMessage = data.error.response.statusText;
    }

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 4500 });
    }
  }

  return false;
}

export default HttpHelper;
