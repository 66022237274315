import React, { Component } from 'react';
import { connect } from 'react-redux';
import User from 'utils/user';
import socketIOClient from 'socket.io-client';
import { checkUserUsage, checkUserSubscription, getUserUsageStats } from 'actions/profile-info-action';
import { fetchLoginUserDetails } from 'actions/user-authentication-action-types';
import Link from './Link';
import './SubscriptionNotice.css';

const socket = socketIOClient(process.env.REACT_APP_WS_URL);

class SubscriptionNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // type: null,
    };

    this.userUtils = new User();
    this.currentUserId = this.userUtils.getLoggedInData();

    socket.on(`SubscriptionActivityChangedForUser_${this.currentUserId}`, () => {
      this.props.fetchLoginUserDetails();
      this.props.checkUserSubscription(this.currentUserId);
      this.props.checkUserUsage();
      this.props.getUserUsageStats();
    });
  }

  componentDidMount() {
    this.props.checkUserSubscription(this.currentUserId);
  }

  getDateTime(epochTime) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const d = new Date(epochTime * 1000);

    return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  }

  getAppropriateStatus() {
    if (!this.props.userSubscription) {
      return false;
    }

    /* eslint-disable camelcase */
    const { status, remarks, trial_end, trial_start } = this.props.userSubscription;

    if (status === 'canceled') {
      return 'canceled';
    }

    if (status === 'trialing' && trial_end && trial_start) {
      return 'trialEnding';
    }

    if (status === 'inactive' && remarks === 'Pending Payment' && trial_end && trial_start) {
      return 'trialEnded';
    }

    if (status === 'active' || status === 'completed') {
      return 'active';
    }

    /* eslint-enable camelcase */

    return 'subscriptionFailed';
  }

  maybeGetExpiryDate(type = 'trial') {
    /* eslint-disable camelcase */
    const { status, trial_end, current_period_end } = this.props.userSubscription;

    if (type === 'trial' && status === 'trialing') {
      return this.getDateTime(trial_end);
    }

    if (type === 'subscription' && status === 'active') {
      return this.getDateTime(current_period_end);
    }

    return '';

    /* eslint-enable camelcase */
  }

  renderNotice() {
    let noticeStyle = {};

    let noticeComponent = null;

    switch (this.getAppropriateStatus()) {
      case 'trialEnding':
        if (typeof this.props.cards === 'undefined' || (this.props.cards && this.props.cards.length)) {
          noticeComponent = null;
        } else {
          noticeStyle = {
            color: '#8a6d3b',
            backgroundColor: '#f9e26a',
            borderColor: '#f9e26a',
          };
          noticeComponent = (
            <div style={{ textAlign: 'center' }}>
              <span>Your trial is expiring on <strong>{this.maybeGetExpiryDate()}</strong>. If you want the service to be uninterrupted, <Link className="btn-link btn-inverse" href="/settings/cards">add your card</Link> now</span>
            </div>
          );
        }
        break;

      case 'trialEnded':
        noticeStyle = {
          color: '#fff',
          backgroundColor: '#d35f5f',
          borderColor: '#d35f5f',
        };
        noticeComponent = (
          <div style={{ textAlign: 'center' }}>
            <span>Your trial has ended and downgraded to free version. If you loved using our premium services <Link className="btn-link" href="/change-subscription" >upgrade your account</Link> now</span>
          </div>
        );
        break;

      case 'canceled':
        noticeStyle = {
          color: '#fff',
          backgroundColor: '#d35f5f',
          borderColor: '#d35f5f',
        };

        noticeComponent = (
          <div style={{ textAlign: 'center' }}>
            <span>Your suscription was cancelled. With this, your subscription was reverted back to Free. <Link className="btn-link" href="/change-subscription" >Click here</Link> to subscribe to a new plan.</span>
          </div>
        );
        break;

      case 'subscriptionFailed':
        noticeStyle = {
          color: '#fff',
          backgroundColor: '#d35f5f',
          borderColor: '#d35f5f',
        };

        noticeComponent = (
          <div style={{ textAlign: 'center' }}>
            <span>We couldnot renew your account. So we have downgraded your account to Free. Please <Link className="btn-link" href="/change-subscription" >click here</Link> if you want to use our premium features</span>
          </div>
        );
        break;

      default:
        noticeStyle = {};
    }

    return (
      <div
        className="alert"
        role="alert"
        style={noticeStyle}
      >{noticeComponent}</div>
    );
  }

  render() {
    if (!this.props.userSubscription || this.props.userSubscription.status === 'active') {
      return null;
    }

    return this.renderNotice();
  }
}

const mapStateToProps = (state) => ({
  ...state,
  userSubscription: state.userProfile ? state.userProfile.userSubscription : null,
  cards: state.settings.userDetails ? state.settings.userDetails.cardDetails : null,
});

export default connect(mapStateToProps, {
  fetchLoginUserDetails,
  checkUserSubscription,
  checkUserUsage,
  getUserUsageStats,
})(SubscriptionNotice);
