import React from 'react';
// import './Profile.scss';
import { connect } from 'react-redux';
import { Row, Col, Collapse, FormGroup, Label, Input, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { Loader } from 'components';
import User from 'utils/user';
import { updateProfileByField /* checkUserUsage */ } from 'actions/profile-info-action';
import api from '../../Dashboard/SettingStepProfile/Views/Services';

const { isAuthenticated } = new User();

class UpdateEmailByParcelNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisation: {},
      isLoading: true,
    };

    this.isAuthenticated = isAuthenticated();
  }

  componentDidMount() {
    api.getOrganisations(this.props.match.params.organisationId).then(async (data) => {
      // await this.props.checkUserUsage(data.createdBy);

      this.setState({
        organisation: data.response,
        isLoading: false,
      });
    });
  }

  getCurrentOrganisationType = () => {
    if (!this.state.organisation || !this.state.organisation.OrganisationType) {
      return '';
    }

    return this.state.organisation.OrganisationType.name;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { parcelnumber, organisation, email } = this.state;

    this.setState({ isLoading: true });

    if (
      !parcelnumber
      || !organisation
      || !email
      || !/\S+@\S+\.\S+/.test(email)
    ) {
      toast.error('All fields are required and email must be of valid format.');

      return;
    }

    const options = {
      data: { email },
      field: 'parcelnumber',
      organisation,
      value: parcelnumber,
      isAuthenticated: this.isAuthenticated,
    };

    this.props.updateProfileByField({ options });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="panel info">
            <div className="panel-header">
              <h2>Link Email</h2>
            </div>
            <Collapse isOpen={true}>
              <div className="panelbody" style={{ position: 'relative' }}>
                {
                  this.state.isLoading
                    ? <Loader />
                    : <Row>
                        <Col xs="12">
                          <form className="">
                            <h4>Linking email address for <strong>{this.state.organisation.name}</strong></h4>
                            {
                              this.getCurrentOrganisationType() === 'Home Owners Association'
                                ? <React.Fragment>
                                    <FormGroup floating>
                                      <Input
                                        required
                                        className="form-control"
                                        id="parcelnumber"
                                        name="parcelnumber"
                                        placeholder="Member Parcel Number?"
                                        type="text"
                                        value={this.state.parcelnumber || ''}
                                        onChange={this.handleChange}
                                      />
                                      <Label for="parcelnumber">Parcel Number</Label>
                                    </FormGroup>

                                    <FormGroup floating>
                                      <Input
                                        required
                                        className="form-control"
                                        id="parcel-email"
                                        name="email"
                                        placeholder="Enter your email address"
                                        type="email"
                                        value={this.state.email || ''}
                                        onChange={this.handleChange}
                                      />
                                      <Label for="parcel-email">Member Email</Label>
                                    </FormGroup>

                                    <div className="form_btn_rw">
                                      <Button
                                        className="save-continue-btn"
                                        color="primary"
                                        onClick={this.handleSubmit}
                                      >Update</Button>
                                    </div>
                                </React.Fragment>
                                : null
                            }
                        </form>
                        </Col>
                      </Row>
                    }
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stateData: state,
  // usage: state.userProfile.usage,
});

export default connect(mapStateToProps, {
  updateProfileByField,
  // checkUserUsage,
})(UpdateEmailByParcelNumber);
