// import uniqBy from 'lodash/uniqBy';
import {
  STEP_FORM_FIELDS_STATE,
  STEP_FORM_REQUESTED,
  STEP_FORM_SUCCESS,
  STEP_FORM_FAILURE,
  STEP2_FORM_FIELDS_STATE,
  STEP2_FORM_SUCCESS,
  STEP2_FORM_FAILURE,
  STEP2_FORM_REQUESTED,
  STEP3_FORM_FIELDS_STATE,
  STEP3_FORM_SUCCESS,
  STEP3_FORM_FAILURE,
  STEP3_FORM_REQUESTED,
  GET_USER_DETAILS_REQUESTED,
  GET_USER_DETAILS_SUCCESS,
  IS_LOADING,
  REFRESH_CUSTOM_FIELD_LISTING,
  CUSTOM_FIELD_LISTING_SUCCESS,
  ADD_CUSTOM_FIELD_SUCCESS,
  UPDATE_EMAIL_FOOTER_SUCCESS,
} from 'actions/settings-step-action';

const initialState = {
  customFields: [],
  isLoading: false,
  step2Success: {},
  step3Success: {},
  stepFormFieldsDetails: null,
  stepFormStatus: '',
  totalCustomFields: 0,
  userData: {},
  userDetails: {},
};

const Settings = (state = initialState, { type, payload }) => {
  switch (type) {
    case STEP_FORM_FIELDS_STATE:
      return {
        ...state,
        stepFormFieldsState: payload,
      };

    case STEP_FORM_REQUESTED:
      return {
        ...state,
        stepFormError: null,
        stepFormStatus: 'creating',
      };

    case STEP_FORM_SUCCESS:
      return {
        ...state,
        stepFormStatus: 'success',
        userData: payload,
      };

    case STEP_FORM_FAILURE:
      return {
        ...state,
        stepFormError: { ...payload },
        stepFormStatus: 'failure',
      };
    case STEP2_FORM_FIELDS_STATE:
      return {
        ...state,
        step2FormFieldsState: payload,
      };

    case STEP2_FORM_REQUESTED:
      return {
        ...state,
        step2FormError: null,
        step2FormStatus: 'creating',
      };

    case STEP2_FORM_SUCCESS:
      return {
        ...state,
        step2FormStatus: 'success',
        step2Success: payload,
      };

    case STEP2_FORM_FAILURE:
      return {
        ...state,
        step2FormError: { ...payload },
        step2FormStatus: 'failure',
      };
    case STEP3_FORM_FIELDS_STATE:
      return {
        ...state,
        step3FormFieldsState: payload,
      };

    case STEP3_FORM_REQUESTED:
      return {
        ...state,
        step3FormError: null,
        step3FormStatus: 'creating',
      };

    case STEP3_FORM_SUCCESS:
      return {
        ...state,
        step3FormStatus: 'success',
        step3Success: payload,
      };

    case STEP3_FORM_FAILURE:
      return {
        ...state,
        step3FormError: { ...payload },
        step3FormStatus: 'failure',
      };

    case GET_USER_DETAILS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        userDetails: {},
      };

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: payload,
      };

    case IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case CUSTOM_FIELD_LISTING_SUCCESS:
      return {
        ...state,
        customFields: [...state.customFields, ...payload.rows],
        isLoading: false,
        totalCustomFields: payload.count,
      };

    case ADD_CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        customFields: [],
        isLoading: false,
      };

    case REFRESH_CUSTOM_FIELD_LISTING:
      return {
        ...state,
        customFields: [],
      };

    case UPDATE_EMAIL_FOOTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default Settings;
