import { createAction } from 'redux-actions';

export const STEP_FORM_FIELDS_STATE = 'STEP_FORM_FIELDS_STATE';
export const stepFormFieldsState = createAction(STEP_FORM_FIELDS_STATE);

export const STEP_FORM = 'STEP_FORM';
export const stepForm = createAction(STEP_FORM);

export const STEP_FORM_REQUESTED = 'STEP_FORM_REQUESTED';
export const stepFormRequested = createAction(STEP_FORM_REQUESTED);

export const STEP_FORM_SUCCESS = 'STEP_FORM_SUCCESS';
export const stepFormSuccess = createAction(STEP_FORM_SUCCESS);

export const STEP_FORM_FAILURE = 'STEP_FORM_FAILURE';
export const stepFormFailure = createAction(STEP_FORM_FAILURE);

export const STEP2_FORM = 'STEP2_FORM';
export const step2Form = createAction(STEP2_FORM);

export const STEP2_FORM_FAILURE = 'STEP2_FORM_FAILURE';
export const step2FormFailure = createAction(STEP2_FORM_FAILURE);

export const STEP2_FORM_FIELDS_STATE = 'STEP2_FORM_FIELDS_STATE';
export const step2FormFieldsState = createAction(STEP2_FORM_FIELDS_STATE);

export const STEP2_FORM_SUCCESS = 'STEP2_FORM_SUCCESS';
export const step2FormSuccess = createAction(STEP2_FORM_SUCCESS);

export const STEP2_FORM_REQUESTED = 'STEP2_FORM_REQUESTED';
export const step2FormRequested = createAction(STEP2_FORM_REQUESTED);

export const STEP3_FORM = 'STEP3_FORM';
export const step3Form = createAction(STEP3_FORM);

export const STEP3_FORM_FAILURE = 'STEP3_FORM_FAILURE';
export const step3FormFailure = createAction(STEP3_FORM_FAILURE);

export const STEP3_FORM_FIELDS_STATE = 'STEP3_FORM_FIELDS_STATE';
export const step3FormFieldsState = createAction(STEP3_FORM_FIELDS_STATE);

export const STEP3_FORM_SUCCESS = 'STEP3_FORM_SUCCESS';
export const step3FormSuccess = createAction(STEP3_FORM_SUCCESS);

export const STEP3_FORM_REQUESTED = 'STEP3_FORM_REQUESTED';
export const step3FormRequested = createAction(STEP3_FORM_REQUESTED);

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const getUserDetails = createAction(GET_USER_DETAILS);

export const GET_USER_DETAILS_REQUESTED = 'GET_USER_DETAILS_REQUESTED';
export const getUserDetailsRequested = createAction(GET_USER_DETAILS_REQUESTED);

export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const getUserDetailsSuccess = createAction(GET_USER_DETAILS_SUCCESS);

export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const updateUserDetails = createAction(UPDATE_USER_DETAILS);

export const UPDATE_PAYPAL_DETAILS = 'UPDATE_PAYPAL_DETAILS';
export const updatePaypalDetails = createAction(UPDATE_PAYPAL_DETAILS);

export const IS_LOADING = 'IS_LOADING';
export const isLoading = createAction(IS_LOADING);

export const CUSTOM_FIELD_LISTING = 'CUSTOM_FIELD_LISTING';
export const customFieldListing = createAction(CUSTOM_FIELD_LISTING);

export const CUSTOM_FIELD_LISTING_SUCCESS = 'CUSTOM_FIELD_LISTING_SUCCESS';
export const customFieldListingSuccess = createAction(CUSTOM_FIELD_LISTING_SUCCESS);

export const REFRESH_CUSTOM_FIELD_LISTING = 'REFRESH_CUSTOM_FIELD_LISTING';
export const refreshCustomFieldListing = createAction(REFRESH_CUSTOM_FIELD_LISTING);

export const ADD_CUSTOM_FIELD = 'ADD_CUSTOM_FIELD';
export const addCustomField = createAction(ADD_CUSTOM_FIELD);

export const ADD_CUSTOM_FIELD_SUCCESS = 'ADD_CUSTOM_FIELD_SUCCESS';
export const addCustomFieldSuccess = createAction(ADD_CUSTOM_FIELD_SUCCESS);

export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';
export const deleteCustomField = createAction(DELETE_CUSTOM_FIELD);

export const UPDATE_EMAIL_FOOTER = 'UPDATE_EMAIL_FOOTER';
export const updateEmailFooter = createAction(UPDATE_EMAIL_FOOTER);

export const UPDATE_EMAIL_FOOTER_SUCCESS = 'UPDATE_EMAIL_FOOTER_SUCCESS';
export const updateEmailFooterSuccess = createAction(UPDATE_EMAIL_FOOTER_SUCCESS);
