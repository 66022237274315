const development = {
  PORT: process.env.SERVER_PORT || 8032,
  // URL_LOGIN_SERVICE:"  http://localhost:8033/api"
  URL_LOGIN_SERVICE:
    process.env.REACT_APP_API_URL
    || 'http://mastersoftwaretechnologies.com:8033/api',
};

const production = {
  PORT: process.env.SERVER_PORT || 8032,
  // URL_LOGIN_SERVICE:"http://mastersoftwaretechnologies.com:8031/api"
  URL_LOGIN_SERVICE:
    process.env.REACT_APP_API_URL || 'https://api.memberaudience.com/api',
};

module.exports = process.env.NODE_ENV === 'production' ? production : development;
