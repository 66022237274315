import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Row, Col, Table, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faInfo, faPowerOff, faInfinity, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Loader } from 'components';
import './Subscription.css';

import { deleteUserSubscription } from 'actions/profile-info-action';

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: false,
      showCancelConfirm: false,
      selectedSubscription: null,
    };
  }

  cancel = () => {
    this.props.deleteUserSubscription(this.state.selectedSubscription);
    this.setState({
      selectedSubscription: null,
      showCancelConfirm: false,
    });
  }

  renew = (id) => {
    this.props.deleteUserSubscription(id);
  }

  renderLoader() {
    return (
      <div className="card-loader">
        <Loader/>
      </div>
    );
  }

  renderAction(s) {
    let buttons = (
    <React.Fragment>
      <Button
      color="success"
      onClick={() => { this.setState({ showInfo: true }); }}
    >
      <FontAwesomeIcon icon={faInfo} /> Info
    </Button>
    {' '}
    <Button
      outline
      color="primary"
      onClick={() => this.props.push('/change-subscription')}
    >
      <FontAwesomeIcon icon={faExchangeAlt} /> Switch Plan
    </Button>
    </React.Fragment>
    );

    if (s.Subscription.code !== 'free') {
      buttons = (
        <React.Fragment>
        {' '}{buttons} <Button
          color="danger"
          onClick={() => this.setState({
            selectedSubscription: s.id,
            showCancelConfirm: true,
          })
        }
        >
          <FontAwesomeIcon icon={faPowerOff} /> Cancel
        </Button>
        </React.Fragment>
      );
    }

    return buttons;
  }

  renderStatusLabel(s) {
    return (
      <Badge pill className={`status bg-${this.getColorClass(s.status)}`} color={`${this.getColorClass(s.status)}`} title={s.status}></Badge>
    );
  }

  renderDate(s) {
    if (!s.current_period_start || !s.current_period_end) {
      return <FontAwesomeIcon icon={faInfinity} />;
    }

    return `${this.getDate(s.current_period_start)} - ${this.getDate(s.current_period_end)}`;
  }

  renderRemarks(s) {
    if (s.remarks) return s.remarks;

    if (s.status === 'trialing') {
      return `Trial started on ${this.getDate(s.trial_start)} and ends on ${this.getDate(s.trial_end)}.`;
    }

    return '';
  }

  getBillingPeriod = (s) => {
    if (s.Subscription.monthPlanId === s.plan) {
      return 'month';
    }

    return 'year';
  };

  getDate(timestamp) {
    const d = new Date(timestamp * 1000);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    let date = d.getDay().toString();

    date = date.length > 1 ? date : `0${date}`;

    return `${date} ${months[d.getMonth()]}, ${d.getFullYear()}`;
  }

  getColorClass(status) {
    if (status === 'active' || status === 'completed' || status === 'trialing') return 'success';

    if (status === 'inactive' || status === 'canceled') return 'danger';

    return 'primary';
  }

  getLabel(key) {
    const labels = {
      acceptPayment: 'Accept Payment',
      activeCampaignLimit: 'Active Campaign',
      committeeLimit: 'Committees',
      emailLimit: 'Emails',
      eventsLimit: 'Events',
      membersLimit: 'Members',
    };

    if (typeof labels[key] !== 'undefined') {
      return labels[key];
    }

    return '';
  }

  render() {
    const { userSubscription } = this.props;

    if (!userSubscription) {
      return null;
    }

    const subs = userSubscription.Subscription;

    return (
      <div style={{ position: 'relative' }}>
        <Row>
          <br />
          <h2>Your Subscription</h2>
          <Col xs="12">
          <Table responsive className='subscription-list' size="sm">
            <thead>
              <tr>
                <th>Subscription</th>
                <th style={{ width: '100px' }}>Amount</th>
                <th>Period</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='subscription-name'>{userSubscription.Subscription.name} {this.renderStatusLabel(userSubscription)}</td>
                <td>${userSubscription.amount}/{this.getBillingPeriod(userSubscription)}</td>
                <td>
                  <div>
                    <small>
                      {this.renderDate(userSubscription)}
                    </small>
                  </div>
                  <div><small>{this.renderRemarks(userSubscription)}</small></div>
                </td>
                <td>{this.renderAction(userSubscription)}</td>
              </tr>
            </tbody>
          </Table>
          <Modal isOpen={this.state.showInfo}>
            <ModalHeader>Subscription: {userSubscription.Subscription.name}</ModalHeader>
            <ModalBody>
              <Table bordered className='subscription-list package-detail'>
                <tbody>
                  <tr>
                    <th>{this.getLabel('membersLimit')}</th>
                    <td>{subs.membersLimit === -1 ? <FontAwesomeIcon icon={faInfinity} /> : subs.membersLimit}</td>
                  </tr>
                  <tr>
                    <th>{this.getLabel('activeCampaignLimit')}</th>
                    <td>{subs.activeCampaignLimit === -1 ? <FontAwesomeIcon icon={faInfinity} /> : subs.activeCampaignLimit}</td>
                  </tr>
                  <tr>
                    <th>{this.getLabel('committeeLimit')}</th>
                    <td>{subs.committeeLimit === -1 ? <FontAwesomeIcon icon={faInfinity} /> : subs.committeeLimit}</td>
                  </tr>
                  <tr>
                    <th>{this.getLabel('emailLimit')}</th>
                    <td>{subs.emailLimit === -1 ? <FontAwesomeIcon icon={faInfinity} /> : subs.emailLimit}</td>
                  </tr>
                  <tr>
                    <th>{this.getLabel('eventsLimit')}</th>
                    <td>{subs.eventsLimit === -1 ? <FontAwesomeIcon icon={faInfinity} /> : subs.eventsLimit}</td>
                  </tr>
                  <tr>
                    <th>{this.getLabel('acceptPayment')}</th>
                    <td>{subs.acceptPayment ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}</td>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick = {() => this.setState({ showInfo: false })} >Close</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.showCancelConfirm}>
            <ModalHeader>Are you sure?</ModalHeader>
            <ModalBody>You sure you want to cancel this subscription?</ModalBody>
            <ModalFooter>
              <Button
                color="default"
                onClick={() => this.setState({
                  showCancelConfirm: false,
                  selectedSubscription: null,
                })}
              >Cancel</Button>
              <Button
                color="danger"
                onClick={this.cancel}
              >Yes</Button>
            </ModalFooter>
          </Modal>
          </Col>
        </Row>
        {this.props.isProcessing ? this.renderLoader() : null}
      </div>
    );
  }
}

const mapStateToProps = ({ userProfile }) => ({
  userSubscription: userProfile.userSubscription,
  isProcessing: userProfile.isProcessing,
});

export default connect(mapStateToProps, {
  push,
  deleteUserSubscription,
})(Subscription);
