import {
  /* WORK_INFO_FAILURE,
  WORK_INFO_SUCCESS,
  WORK_INFO_REQUESTED,
  ADDRESS_INFO_FAILURE,
  ADDRESS_INFO_SUCCESS,
  ADDRESS_INFO_REQUESTED, */
  WORK_INFO_UPDATE_FAILURE,
  WORK_INFO_UPDATE_SUCCESS,
  WORK_INFO_UPDATE_REQUESTED,
  CUSTOM_FIELD_FAILURE,
  CUSTOM_FIELD_SUCCESS,
  CUSTOM_FIELD_REQUESTED,
  CUSTOM_FIELD_UPDATE_FAILURE,
  CUSTOM_FIELD_UPDATE_SUCCESS,
  CUSTOM_FIELD_UPDATE_REQUESTED,
  CUSTOM_FIELD_ADD_FAILURE,
  CUSTOM_FIELD_ADD_SUCCESS,
  CUSTOM_FIELD_ADD_REQUESTED,
  CUSTOM_FIELD_LIST_FAILURE,
  CUSTOM_FIELD_LIST_SUCCESS,
  CUSTOM_FIELD_LIST_REQUESTED,
  CSV_IMPORT_FAILURE,
  CSV_IMPORT_SUCCESS,
  CSV_IMPORT_REQUESTED,
  ADD_IMPORTANT_MEMBER_SUCCESS,
  DELETE_IMPORTANT_MEMBER_SUCCESS,
  GET_IMPORTANT_MEMBERS_SUCCESS,
  UPDATE_IMPORTANT_MEMBER_SUCCESS,
  ACCEPT_PROFILE_CHANGE_FAILURE,
  ACCEPT_PROFILE_CHANGE_SUCCESS,
  ACCEPT_PROFILE_CHANGE_REQUESTED,
  REJECT_PROFILE_CHANGE_FAILURE,
  REJECT_PROFILE_CHANGE_SUCCESS,
  REJECT_PROFILE_CHANGE_REQUESTED,
  /* ADD_TAGS_FAILURE,
  ADD_TAGS_SUCCESS,
  ADD_TAGS_REQUESTED, */
} from 'actions/member-view-action';

const initialState = {
  // addressInfo: '',
  customFieldList: '',
  customSuccess: '',
  customUpdateSuccess: '',
  updatedWorkSuccess: '',
  // workInfo: '',
};

const resetUserDetails = {
  updateUserDetailsError: null,
  updateUserDetailsStatus: null,
};

const MemberInfo = (state = initialState, { type, payload }) => {
  switch (type) {
    /* case WORK_INFO_REQUESTED:
      return {
        ...state,
        ...resetUserDetails,
      };

    case WORK_INFO_SUCCESS:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
        workInfo: payload,
      };

    case WORK_INFO_FAILURE:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      };
    case ADDRESS_INFO_FAILURE:
      return {
        ...state,
        ...resetUserDetails,
      };

    case ADDRESS_INFO_SUCCESS:
      return {
        ...state,
        addressInfo: payload,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
      };

    case ADDRESS_INFO_REQUESTED:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      }; */

    case WORK_INFO_UPDATE_FAILURE:
      return {
        ...state,
        ...resetUserDetails,
      };

    case WORK_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
        updatedWorkSuccess: payload,
      };

    case WORK_INFO_UPDATE_REQUESTED:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      };

    case GET_IMPORTANT_MEMBERS_SUCCESS:
      return {
        ...state,
        importantMemberList: payload.data.response,
        importantMemberListError: payload.data.error ? payload.data.message : false,
      };

    case ADD_IMPORTANT_MEMBER_SUCCESS:
      return {
        ...state,
        importantMember: payload,
        importantMemberAddError: null,
      };

    case DELETE_IMPORTANT_MEMBER_SUCCESS:
      return {
        ...state,
        importantMember: null,
        importantMemberAddError: null,
      };

    case UPDATE_IMPORTANT_MEMBER_SUCCESS:
      return {
        ...state,
        importantMember: payload,
      };

    case CUSTOM_FIELD_FAILURE:
      return {
        ...state,
        ...resetUserDetails,
      };

    case CUSTOM_FIELD_SUCCESS:
      return {
        ...state,
        customSuccess: payload,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
      };

    case CUSTOM_FIELD_REQUESTED:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      };

    case CUSTOM_FIELD_UPDATE_FAILURE:
      return {
        ...state,
        ...resetUserDetails,
      };

    case CUSTOM_FIELD_UPDATE_SUCCESS:
      return {
        ...state,
        customUpdateSuccess: payload,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
      };

    case CUSTOM_FIELD_UPDATE_REQUESTED:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      };

    case CUSTOM_FIELD_ADD_FAILURE:
      return {
        ...state,
        ...resetUserDetails,
      };

    case CUSTOM_FIELD_ADD_SUCCESS:
      return {
        ...state,
        customUpdateSuccess: payload,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
      };

    case CUSTOM_FIELD_ADD_REQUESTED:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      };

    case CUSTOM_FIELD_LIST_FAILURE:
      return {
        ...state,
        ...resetUserDetails,
      };

    case CUSTOM_FIELD_LIST_SUCCESS:
      return {
        ...state,
        customFieldList: payload,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
      };

    case CUSTOM_FIELD_LIST_REQUESTED:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      };

    case CSV_IMPORT_FAILURE:
      return {
        ...state,
        ...resetUserDetails,
      };

    case CSV_IMPORT_SUCCESS:
      return {
        ...state,
        customFieldList: payload,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
      };

    case CSV_IMPORT_REQUESTED:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      };

    case ACCEPT_PROFILE_CHANGE_FAILURE:
    case REJECT_PROFILE_CHANGE_FAILURE:
      return {
        ...state,
        isVerifyingData: false,
        verificationStatus: 'failed',
      };

    case ACCEPT_PROFILE_CHANGE_SUCCESS:
    case REJECT_PROFILE_CHANGE_SUCCESS:
      return {
        ...state,
        isVerifyingData: false,
        verificationStatus: 'success',
      };
    case ACCEPT_PROFILE_CHANGE_REQUESTED:
    case REJECT_PROFILE_CHANGE_REQUESTED:
      return {
        ...state,
        isVerifyingData: true,
        verificationStatus: null,
      };
      /* case ADD_TAGS_FAILURE:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      };

    case ADD_TAGS_SUCCESS:
      return {
        ...state,
        tags: payload,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'success',
      };

    case ADD_TAGS_REQUESTED:
      return {
        ...state,
        updateUserDetailsError: { ...payload },
        updateUserDetailsStatus: 'failure',
      }; */

    default:
      return state;
  }
};

export default MemberInfo;
