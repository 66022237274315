/* eslint-disable indent */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
import React from 'react';
import './CSVImport.scss';
import Select from 'react-select';
// import { Dropdown } from 'reactjs-dropdown-component';
import User from 'utils/user';
import Modal from 'react-responsive-modal';
import { FormattedMessage, CustomFieldType, Loader } from 'components';
import leftarrow from 'assets/images/leftarrow.png';
import { headerList } from 'actions/profile-info-action';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { customField, csvImport } from 'actions/member-view-action';
import Header from '../Header';
// import Header from './Header';

// let self;

const custommodal = 'menu';

class CsvImport extends React.Component {
  static propTypes = {
    headerList: func.isRequired,
    customField: func.isRequired,
    csvImport: func.isRequired,
  };

  state = {
    open: false,
    isLoading: true,
    // fields: [],
    csvDataValue: '',
    // csvDataHeader: '',
    fieldName: '',
    selectedItem: [],
    // fieldDescription: '',
    fieldType: 'String',
    errors: {},
    mapping: [],
    // idValue: '',
    indexId: '',
    availableAsColumn: true,
    /* isSortable: false,
    showInFilter: false, */
    openD: true,
  };

  /* static getDerivedStateFromProps(nextProps) {
    console.log('NextProps: ', nextProps);

    return ({
      data: nextProps.data,
      fields: nextProps.fields,
    });
  } */

  componentDidMount() {
    // self = this;

    const mapping = localStorage.getItem('mapping');

    if (!mapping) {
      window.location.reload();
      // self.props.push('/csv-import');
      localStorage.setItem('mapping', true);
    }

    // this.setState({ openD: true });
    this.props.headerList();
    this.state.csvDataValue = JSON.parse(localStorage.getItem('csvFilesHeader'));
    this.setState({ isLoading: false });
    // document.addEventListener('click', this.handleOutsideClick, false);
    // console.log("header ++++", this.state.fields);
    // console.log("+++++++ __++_+_", this.uniqueByFilter(this.state.fields, it => it.id));
  }

  onOpenModal = (id, i) => this.setState({ indexId: i, open: true });

  onCloseModal = () => this.setState({ open: false });

  uniqueByFilter(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  saveCsvValue = () => {
    this.setState({ isLoading: true });

    if (this.state.mapping.length === 0) {
      toast.error('You have not mapping.');
    } else if (this.dublicacyCheck(this.state.mapping) === false) {
      const csvPath = localStorage.getItem('csvpath');
      const options = {
        csvPath: JSON.parse(csvPath),
        mapping: JSON.stringify(this.state.mapping),
      };

      this.props.csvImport(options);
    } else {
      toast.error('You have selected duplicate columns.');
    }

    this.setState({ isLoading: false });
  }

  // dublicate value chack
  dublicacyCheck = (a) => {
    for (let i = 0; i <= a.length; i++) {
      for (let j = i; j <= a.length; j++) {
        if (a[j] && a[j].value && a[i] && a[i].value) {
          if (i !== j && a[i].value === a[j].value) {
            return true;
          }
        }
      }
    }

    return false;
  };

  /* resetThenSet = (id, key, i) => {
    if (id === this.props.fields.length) {
      this.setState({
        isSortable: false,
        showInFilter: false,
        fieldName: '',
        fieldType: '',
        errors: {},
      });
      this.onOpenModal(id, i);
    } else {
      // console.log('Key: ', key);
      // const temp = JSON.parse(JSON.stringify(this.props[key]));
      // const index = this.props[key].findIndex((o) => o.id === id);

      // const temp = this.props[key].find((o) => o.id === id);

      // console.log('this.props[key]; ', this.props[key]);
      // console.log('temp[id]: ', this.props[key][index]);
      // console.log('id: ', id);

      // eslint-disable-next-line no-param-reassign
      // this.props[key].forEach((item) => (item.selected = false));
      // this.props[key][index].selected = true;
      // this.setState({
      //   [key]: temp,
      // });

      const obj = this.props.fields.find((o) => o.id === id);

      if (obj) {
        const stateMapping = this.state.mapping;

        stateMapping[i] = {
          index: i,
          value: obj.value || obj.title2,
          id: obj.ids,
        };

        this.setState({
          mapping: stateMapping,
        });
      }
    }
  }; */

  onSelectChange = (selected, index) => {
    const { selectedItem } = this.state;

    selectedItem[index] = selected;
    this.setState({ selectedItem });

    if (selected.value === 'add_custom_field') {
      this.setState({
        /* isSortable: false,
        showInFilter: false, */
        availableAsColumn: true,
        fieldName: '',
        fieldType: 'String',
        errors: {},
      });

      this.onOpenModal(selected.id, index);
    } else {
      const stateMapping = this.state.mapping;

      stateMapping[index] = {
        index,
        value: selected.value,
        id: selected.id,
      };

      this.setState({
        mapping: stateMapping,
      });
    }
  }

  // handleOutsideClick(){
  // console.log("hello");
  // self.setState({openD:true});
  // }

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  /* columData(data) {
    const customColumns = [];
    const generalColumns = [];

    if (
      data
      && data.headerList
      && data.headerList.data
      && data.headerList.data.response1
    ) {
      for (let i = 0, len = data.headerList.data.response1.length; i < len; i++) {
        const obj = data.headerList.data.response1[i];

        if (Object.prototype.hasOwnProperty.call(obj, 'isCustomColumn') && data.headerList.data.response1[i].isCustomColumn) {
          customColumns.push(obj);
        } else {
          generalColumns.push(obj);
        }
      }

      const dataArray = customColumns.concat(generalColumns);

      const dataVal1 = {
        id: dataArray?.length + 1,
        title: 'Add custom field',
        selected: false,
        key: 'fields',
        ids: 2,
      };

      self.props.columnData.push(dataVal1);

      for (let i = 0, len = dataArray?.length; i < len; i++) {
        let title = '';

        if (dataArray[i] && dataArray[i].label) {
          title = dataArray[i].label;
        } else if (dataArray[i] && dataArray[i].column_name) {
          title = dataArray[i].column_name;
        }

        const ids = dataArray[i]?.id
          ? dataArray[i]?.id
          : '1';

        let dataVal = {};

        if (title === 'General' || title === 'Home' || title === 'Work' || title === 'Special') {
          dataVal = {
            id: i,
            title: `${title}`,
            title2: `${title}`,
            ids: '4',
            selected: false,
            key: 'fields',
          };

          self.props.columnData.push(dataVal);
        } else {
          dataVal = {
            id: i,
            title: `- ${title}`,
            title2: `${title}`,
            value: dataArray[i].column_name,
            ids,
            selected: false,
            key: 'fields',
          };

          self.props.columnData.push(dataVal);
        }
      }
    }
  } */

  handleKeyPress = (event) => {
    User.spaceNotAllowed(event);
  };

  handleKeyPressFirst = (event) => {
    User.firstCharSpaceNotAllowed(event);
  };

  validateAndHandleChange = (event) => {
    const { value } = event.target;
    const { errors } = this.state;

    const format = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

    const hasSpecialChars = format.test(value);

    if (hasSpecialChars) {
      errors.fieldNameError = 'invalidCharsInField';
    } else {
      errors.fieldNameError = '';
    }

    this.setState({ errors });

    this.handleChange(event);
  }

  handleChange = ({ target: { name, value } }) => {
    // const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  validateCustomFields = () => {
    const errors = {};

    let isValid = true;

    if (!this.state.fieldName) {
      isValid = false;
      errors.fieldNameError = 'requiredField';
    }

    if (!this.state.fieldType) {
      isValid = false;
      errors.fieldTypeError = 'requiredField';
    }

    this.setState({ errors });

    return isValid;
  };

  toggleColumnAvailabiltity = () => this.setState({ availableAsColumn: !this.state.availableAsColumn });

  /* onChangeSortable() {
    this.setState({ isSortable: !this.state.isSortable });
  }

  onChangeFilter() {
    this.setState({ showInFilter: !this.state.showInFilter });
  } */

  saveCustomField = async (event) => {
    event.preventDefault();
    const valid = this.validateCustomFields();

    if (valid) {
      // const fieldName = removeSpaceAndConvertToCapitalLetter(self.state.fieldName);
      // this.setState({openD:true});
      const { fieldName } = this.state;

      const options = {
        fieldName,
        fieldDescription: this.state.fieldDescription,
        fieldType: this.state.fieldType,
        /* isSortable: this.state.isSortable,
        showInFilter: this.state.showInFilter, */
        availableAsColumn: this.state.availableAsColumn,
        fieldValue: '',
      };

      const lucky = this.props.columns.filter((
        number,
      ) => number.column_name === fieldName);

      if (lucky.length === 0) {
        await this.props.customField(options);
        // this.props.headerList();
        // self.props.data.headerList.data.response[self.props.data.headerList.data.response.length]={ column_name: self.state.fieldName };
        // self.props.data.headerList.data.response.push({
        //   column_name: self.state.fieldName,
        // });

        /* console.log('this.props.data.headerList.data.response: ', this.props.data.headerList.data.response);
        console.log('this.state: ', this.state);
        this.props.data.headerList.data.response.unshift({ column_name: this.state.fieldName });
        console.log('this.props.data.headerList.data.response: ', this.props.data.headerList.data.response); */

        /* this.resetThenSet(
          this.props.fields,
          'fields',
          this.state.indexId,
        ); */

        const stateMapping = this.state.mapping;

        stateMapping[this.state.indexId] = {
          index: this.state.indexId,
          value: this.state.fieldName,
        };

        this.onCloseModal();
        this.setState({
          /* fieldName: '',
          fieldType: '',
          fieldDescription: '',
          isSortable: false,
          showInFilter: false, */
          errors: {},
          mapping: stateMapping,
        });
      } else {
        toast.error('Column already exists');
      }
    }
  };

  getSelectedFieldData = (i) => {
    const { columns } = this.props;
    const { fieldName, selectedItem, indexId } = this.state;

    if (columns && fieldName && indexId === i) {
      const currentItemGroup = columns.find((o) => o.key === 'custom');

      const currentItem = currentItemGroup.options.find((o) => o.label === fieldName);

      if (currentItem) {
        return currentItem;
      }

      return null;
    }

    return typeof selectedItem[i] === 'undefined' ? null : selectedItem[i];
  }

  render() {
    const { open } = this.state;
    const { columns } = this.props;
    const {
      errors: { fieldNameError, fieldTypeError, fieldDescriptionError },
    } = this.state;

    /* if (data.headerList && data.headerList.data && data.headerList.data.response) {
      console.log('_+_+_+ +++++++++++++', data.headerList.data.response);
    } */

    // console.log('Fields: ', this.state);

    // const filterList = (
    // 	data.headerList.data && data.headerList.data ? <Dropdown className="form-control" list={this.props.columnData} resetThenSet={this.resetThenSet} /> : ''
    // )

    const cardListValue = (
      <tbody>
        {
          this.state.csvDataValue
            ? this.state.csvDataValue.map((value, i) => (
              <tr key={`csv-field-${i}`}>
                <td>{value}</td>
                <td>
                  <Select
                    autosize={true}
                    className='mapping-fields'
                    classNamePrefix='mapping-fields'
                    isLoading={!columns}
                    options={columns}
                    placeholder='Select Field'
                    value={columns ? this.getSelectedFieldData(i) : null}
                    onChange={(selectedOption) => { this.onSelectChange(selectedOption, i); }}
                  />
                </td>
                {/* <td></td>
						<td></td> */}
              </tr>
            ))
            : ''
        }
      </tbody >
    );

    if (this.state.openD) {
      // this.setState({openD:false})
      // this.columData(data);
      // console.log('this.props data: ', this.props);
    }

    // this.setState({openD:true});

    return (
      <div className="App-Memership">
        <div className="Header-top">
          <Header />
        </div>
        <div className="steps-sect">
          <div className="container">
            {
              this.state.isLoading
              ? <div style={{ position: 'relative' }}><Loader /></div>
              : <React.Fragment>
                  <div className="csv_row heading-membership">
                    <div className="btn_col">
                      <a className="backarrow" href="/#">
                        <img alt="leftarrow" src={leftarrow} />
                      </a>
                    </div>
                    <h2>CSV Users Import List</h2>
                    <div className="btn_col last">
                      <a className="btn btn-default" href="/#">
                        Cancel
                      </a>
                      <a className="btn btn-primary" onClick={this.saveCsvValue}>
                        Save
                      </a>
                    </div>
                  </div>
                  <table className="table table-responsive table-member svc-import">
                    <thead>
                      <tr>
                        <th>CSV HEADING</th>
                        <th>FIELDS</th>
                      </tr>
                    </thead>
                    {cardListValue}
                  </table>
                  <Modal className={custommodal} open={open} onClose={this.onCloseModal}>
                    <div className="modal_bx">
                      <div className="modal-header">
                        <a className="backarrow" onClick={this.onCloseModal}>
                          <img alt="leftarrow" src={leftarrow} />
                        </a>
                        <h2>Add Custom Field</h2>
                      </div>
                      <div className="model-content-edit">
                        <div className="form-group">
                          <label>Label</label>
                          <input
                            className="form-control"
                            name="fieldName"
                            placeholder="What's custom field Name?"
                            type="text"
                            value={this.state.fieldName}
                            onChange={this.validateAndHandleChange}
                          />
                          {!!fieldNameError && this.showError(fieldNameError)}
                        </div>
                        <div className="form-group">
                          <label>Type</label>
                          <CustomFieldType
                            className="form-control"
                            name="fieldType"
                            value={this.state.fieldType}
                            onChange={this.handleChange}
                          />
                          {!!fieldTypeError && this.showError(fieldTypeError)}
                        </div>
                        <div className="form-group">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            name="fieldDescription"
                            onChange={(e) => this.handleChange(e)}
                          />
                          {!!fieldDescriptionError
                            && this.showError(fieldDescriptionError)}
                        </div>

                        <div className="form-group">
                          <ul>
                            <li>
                              <input
                                  checked={this.state.availableAsColumn}
                                  id="availableAsColumn"
                                  name="availableAsColumn"
                                  type="checkbox"
                                  value={this.state.availableAsColumn}
                                  onChange={this.toggleColumnAvailabiltity}
                                />
                              <label htmlFor="availableAsColumn">Make column available for member list</label>
                            </li>
                            {/* <li>
                              <input
                                id="opted2"
                                name="opted2"
                                type="checkbox"
                                value={this.state.isSortable}
                                onChange={(e) => {
                                  this.onChangeSortable(e);
                                }}
                              />
                              <label htmlFor="opted2">Sortable</label>
                            </li>
                            <li>
                              <input
                                id="opted3"
                                name="opted3"
                                type="checkbox"
                                value={this.state.showInFilter}
                                onChange={(e) => {
                                  this.onChangeFilter(e);
                                }}
                              />
                              <label htmlFor="opted3">Show in Filter</label>
                            </li> */}
                          </ul>
                        </div>
                        <button className="btn btn-primary" onClick={this.saveCustomField}>
                          Save
                        </button>
                      </div>
                    </div>
                  </Modal>
                </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

/* const mapStateToProps = (state) => ({
  data: state.userProfile,
}); */

const parseColumnToOptions = (data) => {
  const columnLen = data.length;

  if (columnLen) {
    const temp = {};

    temp.trigger = {
      label: 'Add Custom Field',
      value: 'add_custom_field',
    };

    for (let i = 0; i < columnLen; i++) {
      if (typeof data[i].key !== 'undefined') {
        const key = data[i].key === 'custom' ? `0_${data[i].key}` : data[i].key;

        if (typeof temp[key] === 'undefined') {
          temp[key] = {
            label: data[i].column_name,
            key: data[i].key,
            options: [],
          };
        } else {
          temp[key].label = data[i].column_name;
        }
      } else {
        const id = data[i]?.id ? data[i]?.id : '1';

        const category = data[i].category === 'custom' ? `0_${data[i].category}` : data[i].category;

        temp[category].options.push({
          value: data[i].column_name,
          label: data[i].isCustomColumn ? data[i].column_name : data[i].label,
          id,
        });
      }
    }

    return Object.values(temp);
  }

  return [];
};

/* const getColumnData = (data) => {
  const customColumns = [];
  const generalColumns = [];
  const finalCols = [];

  for (let i = 0, len = data.length; i < len; i++) {
    const obj = data[i];

    if (Object.prototype.hasOwnProperty.call(obj, 'isCustomColumn') && data[i].isCustomColumn) {
      customColumns.push(obj);
    } else {
      generalColumns.push(obj);
    }
  }

  const dataArray = customColumns.concat(generalColumns);

  const dataVal1 = {
    id: dataArray?.length + 1,
    title: 'Add custom field',
    selected: false,
    key: 'fields',
    ids: 2,
  };

  finalCols.push(dataVal1);

  for (let i = 0, len = dataArray?.length; i < len; i++) {
    let title = '';

    if (dataArray[i] && dataArray[i].label) {
      title = dataArray[i].label;
    } else if (dataArray[i] && dataArray[i].column_name) {
      title = dataArray[i].column_name;
    }

    const ids = dataArray[i]?.id
      ? dataArray[i]?.id
      : '1';

    let dataVal = {};

    if (title === 'General' || title === 'Home' || title === 'Work' || title === 'Special') {
      dataVal = {
        id: i,
        title: `${title}`,
        title2: `${title}`,
        ids: '4',
        selected: false,
        key: 'fields',
      };

      finalCols.push(dataVal);
    } else {
      dataVal = {
        id: i,
        title: `- ${title}`,
        title2: `${title}`,
        value: dataArray[i].column_name,
        ids,
        selected: false,
        key: 'fields',
      };

      finalCols.push(dataVal);
    }
  }

  return finalCols;
}; */

const mapStateToProps = (state) => {
  // let data = [];

  let initalColumns = [];

  if (
    state.userProfile
    && state.userProfile.headerList
    && state.userProfile.headerList.data
    && state.userProfile.headerList.data.response1
  ) {
    // data = [...state.userProfile.headerList.data.response1];
    initalColumns = [...state.userProfile.headerList.data.response1];
  }

  /* console.log('State: ', state);
  console.log('ownProps: ', ownProps); */

  /* const options = [
    {
      label: 'Flavours',
      options: [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ],
    },
  ]; */

  return {
    data: state.userProfile,
    // fields: getColumnData(data),
    columns: parseColumnToOptions(initalColumns),
  };
};

export default connect(mapStateToProps, {
  headerList,
  customField,
  csvImport,
  push,
})(CsvImport);
