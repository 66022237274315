/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table, FormGroup, Label, Input, Button } from 'reactstrap';
import './Preferences.scss';
// import api from '../SettingStepProfile/Views/Services';

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enable_data_integrity: null,
    };
  }

  handleCheckChange = (event) => {
    const { name, checked } = event.target;

    this.setState({ [name]: checked });
  }

  /* handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }; */

  savePreferences = () => {
    const payload = [{
      userId: this.props.userId,
      meta_key: 'enable_data_integrity',
      meta_value: this.state.enable_data_integrity ? this.state.enable_data_integrity : 'false',
      meta_type: 'preferences',
    }];

    this.props.onSubmit(payload);
  }

  render() {
    const pref = {};

    if (this.props.metadata) {
      const metadata = this.props.metadata.filter((m) => m.meta_type === 'preferences');

      if (metadata) {
        Object.keys(metadata).forEach((i) => {
          try {
            pref[metadata[i].meta_key] = JSON.parse(metadata[i].meta_value);
          } catch (e) {
            pref[metadata[i].meta_key] = metadata[i].meta_value;
          }
        });
      }
    }

    let enableDataIntegrity = pref.enable_data_integrity;

    if (this.state.enable_data_integrity !== null) {
      enableDataIntegrity = this.state.enable_data_integrity;
    }

    return (
        <div>
            <h2>Preferences</h2>
            <Row>
                <Col>
                    <Table>
                        <tbody>
                          <tr>
                              <th>Enable Data Integrity</th>
                              <td>
                              <FormGroup check>
                                  <Input
                                    checked={enableDataIntegrity ? 'checked' : ''}
                                    id='enable_data_integrity'
                                    name='enable_data_integrity'
                                    type="checkbox"
                                    onChange={this.handleCheckChange}
                                  />
                                  {' '}
                                  <Label check for='enable_data_integrity'>Enable this if you want verification of the updated data</Label>
                              </FormGroup>
                              </td>
                          </tr>
                          <tr>
                            <td
                              colSpan='2'>
                              <Button
                                outline
                                color="primary"
                                style={{
                                  display: 'block',
                                  border: '1px solid',
                                  minWidth: '170px',
                                  textAlign: 'center',
                                  margin: '0 auto',
                                }}
                                  onClick={this.savePreferences}
                              >Save</Button>
                            </td>
                          </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {})(Preferences);
