/* eslint-disable filenames/match-exported */
// temporary fix as old-dev is using these CSS classes in all pages
import CSVImport from 'pages/Dashboard/CSVImport';
import PaymentCompleted from 'pages/Dashboard/ProfilePayment/PaymentCompleted';
import PaymentCancelled from 'pages/Dashboard/ProfilePayment/PaymentCancelled';
import { Login, Paypalpay, ForgotPassword, SetPassword, NotFound, Signup, SettingStepProfile, ChangeSubscription,
  Step2, Logout, AdministrationDashboard, Settings, Profile, AddPerson,
  MembersListTable, memberView, importCSV, event, addevent, viewEvent, Comittees,
  AddComittees, Rsvp, EditComittee, ListCampaigns, CreateCampaign, MemberProfile, UpdateEmail, UpdateEmailByParcelNumber } from 'pages';

// Normal Routes
const routes = [
  {
    component: SettingStepProfile,
    exact: true,
    path: '/',
    title: 'SettingStepProfile',
  },
  {
    component: SettingStepProfile,
    exact: true,
    path: '/Setting-Step-Profile',
    title: 'SettingStepProfile',
  },
  {
    component: Step2,
    exact: true,
    path: '/step2',
    title: 'Step2',
  },
  {
    component: Logout,
    exact: true,
    path: '/logout',
    title: 'Logout',
  },
  {
    component: AdministrationDashboard,
    exact: true,
    path: '/administration-dashboard',
    title: 'AdministrationDashboard',
  },
  {
    component: CSVImport,
    exact: true,
    path: '/csv-import',
    title: 'CSVImport',
  },
  {
    component: Settings,
    exact: true,
    path: '/settings/:menuSlug/manage',
    title: 'Settings',
  },
  {
    component: Settings,
    exact: true,
    path: '/settings/:menuSlug',
    title: 'Settings',
  },
  {
    component: Settings,
    exact: true,
    path: '/settings',
    title: 'Settings',
  },
  {
    component: AdministrationDashboard,
    exact: false,
    path: '/reset-password',
    title: 'AdministrationDashboard',
  },
  {
    component: Profile,
    exact: false,
    path: '/profile',
    title: 'Profile',
  },
  {
    component: AddPerson,
    exact: true,
    path: '/add-person',
    title: 'AddPerson',
  },
  {
    component: MembersListTable,
    exact: true,
    path: '/members-list',
    title: 'MembersListTable',
  },
  {
    component: SettingStepProfile,
    exact: true,
    path: '/forgotpassword',
    title: 'SettingStepProfile',
  },
  {
    component: SettingStepProfile,
    exact: true,
    path: '/login',
    title: 'SettingStepProfile',
  },
  {
    component: SettingStepProfile,
    exact: true,
    path: '/signup',
    title: 'SettingStepProfile',
  },
  {
    component: ChangeSubscription,
    exact: true,
    path: '/change-subscription',
    title: 'Change Subscription',
  },
  {
    component: memberView,
    exact: true,
    path: '/member/:memberId/:tab',
    title: 'memberView',
  },
  {
    component: memberView,
    exact: true,
    path: '/member/:memberId',
    title: 'memberView',
  },
  {
    component: importCSV,
    exact: false,
    path: '/import-csv',
    title: 'importCSV',
  },
  {
    component: addevent,
    exact: false,
    path: '/addevent',
    title: 'addevent',
  },
  {
    component: event,
    exact: false,
    path: '/event',
    title: 'event',
  },
  {
    component: viewEvent,
    exact: false,
    path: '/view-event',
    title: 'viewEvent',
  },
  {
    component: Comittees,
    exact: true,
    path: '/committees',
    title: 'Comittees',
  },
  {
    component: AddComittees,
    exact: true,
    path: '/add-committee',
    title: 'AddComittees',
  },
  {
    component: Rsvp,
    exact: false,
    path: '/rsvp',
    title: 'Rsvp',
  },
  {
    component: EditComittee,
    exact: false,
    path: '/edit-committee/:id',
    title: 'EditComittee',
  },
  {
    component: ListCampaigns,
    exact: true,
    path: '/campaigns',
    title: 'ListCampaigns',
  },
  {
    component: CreateCampaign,
    exact: true,
    path: '/add-campaign',
    title: 'Create Campaign',
  },
  {
    component: CreateCampaign,
    exact: true,
    path: '/edit-campaign/:campaignId',
    title: 'Edit Campaign',
  },
  {
    component: MemberProfile,
    exact: false,
    path: '/update-profile/:key',
    title: 'Member Profile',
  },
  {
    component: UpdateEmailByParcelNumber,
    exact: false,
    path: '/update-email/:organisationId',
    title: 'Update Email',
  },
  /* {
    component: UpdateEmail,
    exact: false,
    path: '/update-email',
    title: 'Update Email',
  }, */
  /* {
    component: PaymentCompleted,
    exact: false,
    path: '/payment-completed',
    title: 'Payment Completed',
  }, */
  /* {
    component: PaymentCancelled,
    exact: false,
    path: '/payment-cancelled',
    title: 'Payment Cancelled',
  }, */
  {
    component: NotFound,
    exact: true,
    path: '/*',
    title: 'NotFound',
  },
];

export const authRoutesPath = {
  forgotpassword: '/forgotpassword',
  resetPassword: '/reset-password',
  loginRoot: '/',
  login: '/login',
  signup: '/signup',
  paypalpay: '/paypalpay',
  updateProfile: '/update-profile/:key',
  updateEmail: '/update-email',
  UpdateEmailByParcelNumber: '/update-email/:organisationId',
  notFound: '/*',
};

// Authentication Routes
const authRoutes = [
  {
    component: ForgotPassword,
    exact: true,
    path: '/forgotpassword',
    title: 'ForgotPassword',
  },
  {
    component: SetPassword,
    exact: false,
    path: '/reset-password',
    title: 'SetPassword',
  },
  {
    component: Login,
    exact: true,
    path: '/',
    title: 'Login',
  },
  {
    component: Login,
    exact: true,
    path: '/login',
    title: 'Login',
  },
  {
    component: Signup,
    exact: true,
    path: '/signup',
    title: 'Signup',
  },
  {
    component: Rsvp,
    exact: false,
    path: '/rsvp',
    title: 'Rsvp',
  },
  {
    component: Paypalpay,
    exact: false,
    path: '/paypalpay',
    title: 'Paypalpay',
  },
  {
    component: MemberProfile,
    exact: false,
    path: '/update-profile/:key',
    title: 'Member Profile',
  },
  {
    component: UpdateEmailByParcelNumber,
    exact: false,
    path: '/update-email/:organisationId',
    title: 'Update Email',
  },
  {
    component: UpdateEmail,
    exact: false,
    path: '/update-email',
    title: 'Update Email',
  },
  {
    component: PaymentCompleted,
    exact: false,
    path: '/payment-completed',
    title: 'Payment Completed',
  },
  {
    component: PaymentCancelled,
    exact: false,
    path: '/payment-cancelled',
    title: 'Payment Cancelled',
  },
  {
    component: NotFound,
    exact: true,
    path: '/*',
    title: 'NotFound',
  },
];

// Adding it in object
const Router = {
  authRoutes,
  routes,
};

export default Router;
