/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import './CreateMail.scss';
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';

import { FormattedMessage } from 'components';
import { addCampaignStepTwo } from 'actions/campaign';

const Loader = require('react-loader');

class CreateEmail extends React.Component {
  state = {
    errors: {},
    requestInformateUpdate: 'yes',
    emailBodyState: '',
  };

  componentDidMount() {
    if (Object.keys(this.props.stepTwoValues).length) {
      const {
        requestInformateUpdate,
        emailSubject,
        emailBody,
      } = this.props.stepTwoValues;

      this.setState({
        emailBodyState: this.getCreatedEditorState(emailBody),
        emailSubject,
        requestInformateUpdate: requestInformateUpdate ? 'yes' : 'no',
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getCreatedEditorState(html) {
    if (html) {
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);

      return editorState;
    }

    return null;
  }

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  onChangeHandler = (event) => {
    const {
      target: { name, value },
    } = event;

    this.setState({ formSubmit: false, [name]: value });
  };

  handleOptionChange = (event) => {
    this.setState({
      requestInformateUpdate: event.target.value,
    });
  };

  onValidateHandler = () => {
    const errors = {};

    const { emailBodyState } = this.state;
    const emailBodyData = this.getEditorContent(emailBodyState);

    if (
      !this.state.emailSubject
      || (this.state.emailSubject && !this.state.emailSubject.trim())
    ) {
      errors.emailSubject = 'emptyEmailSubject';
    }

    if (!emailBodyData) {
      errors.emailBody = 'emptyEmailBody';
    }

    this.setState({ errors });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  getEditorContent = (editorState) => {
    const emailBodyData = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();

    return emailBodyData;
  };

  saveAndContinue = (event) => {
    event.preventDefault();

    const { emailBodyState } = this.state;

    const emailBodyData = this.getEditorContent(emailBodyState);

    this.setState({ formSubmit: true });

    const isValid = this.onValidateHandler();

    if (isValid) {
      const formData = {
        campaignId:
          this.props.stepOneValues && this.props.stepOneValues.id
            ? this.props.stepOneValues.id
            : '',
        emailBody: emailBodyData,
        emailSubject: this.state.emailSubject.trim(),
        requestInformateUpdate:
          this.state.requestInformateUpdate === 'yes',
      };

      this.props.addCampaignStepTwo(formData);
    }
  };

  onEditorStateChange = (editorState, key) => {
    this.setState({
      [key]: editorState,
    });
  };

  render() {
    const {
      requestInformateUpdate,
      errors: { emailSubject },
      formSubmit,
      emailBodyState,
    } = this.state;

    const { isLoading, apiErrors, stepTwoValues } = this.props;

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    {
      formSubmit
        && apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: 1500,
        });
    }

    return (
      <form>
        <div className="panel">
          <div className="form-group">
            <label>Request Information update?</label>
            <div className="month_year">
              <ul>
                <li>
                  <input
                    checked={requestInformateUpdate === 'yes'}
                    id="yes"
                    name="requestInformateUpdate"
                    type="radio"
                    value="yes"
                    onChange={this.handleOptionChange}
                  />
                  <label htmlFor="yes">Yes</label>
                </li>
                <li>
                  <input
                    checked={requestInformateUpdate === 'no'}
                    id="no"
                    name="requestInformateUpdate"
                    type="radio"
                    value="no"
                    onChange={this.handleOptionChange}
                  />
                  <label htmlFor="no">No</label>
                </li>
              </ul>
            </div>

            <p>
              A unique link will be added to the bottom of the email with a note
              asking for the user to update their information.
            </p>
          </div>

          <div className="form-group">
            <label>Email Subjects</label>
            <input
              className="form-control"
              defaultValue={stepTwoValues.emailSubject}
              name="emailSubject"
              placeholder="Write a subject"
              type="text"
              onChange={this.onChangeHandler}
            />
            {emailSubject && this.showError(emailSubject)}
          </div>
          <div className="form-group">
            <label>Email Body</label>
            <Editor
              editorClassName="m-editor"
              editorState={emailBodyState}
              toolbarClassName="m-editor-toolbar"
              wrapperClassName="m-editor-wrapper"
              onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, 'emailBodyState')}
            />
            {/* <textarea
              className="form-control"
              defaultValue={stepTwoValues.emailBody}
              name="emailBody"
              placeholder="Write a message..."
              onChange={this.onChangeHandler}
            ></textarea>
            {emailBody && this.showError(emailBody)} */}
          </div>

          <div className="form-group disclaimer-text">
            <p>Note: Email footer/signature can be set in your user settings.</p>
          </div>
          <button className="btn btn-primary" onClick={this.saveAndContinue}>
            Next
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ campaign }) => ({
  apiErrors: campaign.updateCampaignError,
  isLoading: campaign.isLoading,
  step2Complete: campaign.step2Complete,
  stepOneValues: campaign.stepOneValues,
  stepTwoValues: campaign.stepTwoValues,
});

export default connect(mapStateToProps, {
  addCampaignStepTwo,
})(CreateEmail);
