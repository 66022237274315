/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './SetttingsView.scss';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { updateEmailFooter } from 'actions/settings-step-action';
import { FormattedMessage } from 'components';

const Loader = require('react-loader');

class Emails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: '',
      errors: {},
    };
  }

  componentDidMount() {
    const { userDetails } = this.props;

    this.setState({
      editorState: this.getCreatedEditorState(userDetails.emailFooter),
    });
  }

  componentWillReceiveProps(nextProps) {
    const { userDetails } = nextProps;

    if (userDetails && userDetails.emailFooter) {
      this.setState({
        editorState: this.getCreatedEditorState(userDetails.emailFooter),
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getCreatedEditorState(html) {
    if (html) {
      const contentBlock = htmlToDraft(html);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const editorState = EditorState.createWithContent(contentState);

      return editorState;
    }

    return null;
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  validateFields = (content) => {
    const errors = {};

    let isValid = true;

    if (!content) {
      isValid = false;
      errors.emailFooterError = 'requiredField';
    }

    this.setState({ errors });

    return isValid;
  };

  updateSignature = () => {
    // eslint-disable-next-line no-restricted-globals
    event.preventDefault();

    const { editorState } = this.state;

    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    content = content.trim();

    const possibleTags = [
      '<blockquote></blockquote>',
      '<pre></pre>',
      '<h1></h1>',
      '<h2></h2>',
      '<h3></h3>',
      '<h4></h4>',
      '<h5></h5>',
      '<h6></h6>',
      '<p></p>',
    ];

    if (possibleTags.includes(content)) {
      content = '';
    }

    const isValid = this.validateFields(content);

    if (isValid) {
      const input = {
        emailFooter: content,
      };

      this.props.updateEmailFooter(input);
    }
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  render() {
    const {
      editorState,
      errors: { emailFooterError },
    } = this.state;

    const { isLoading } = this.props;

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    return (
      <div>
        <h2>Email Footer/Signature</h2>
        <div className="editor">
          <Editor
            editorClassName="editorClassName"
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            onEditorStateChange={this.onEditorStateChange}
          />
        </div>
        {emailFooterError && this.showError(emailFooterError)}
        <button className="btn btn-primary" onClick={this.updateSignature}>
          Update Signature
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => ({
  isLoading: settings.isLoading,
  userDetails: settings.userDetails,
});

export default connect(mapStateToProps, {
  updateEmailFooter,
})(Emails);
