/* eslint-disable filenames/match-exported */
import React from 'react';
import './Settings.scss';
import { Loader } from 'components';
import Header from '../Header';
import Step1 from './Views/Step1';
import Step2 from './Views/Step2';
import Step3 from './Views/Step3';

class SettingStepProfile extends React.Component {
  state = {
    step: 1,
    isLoading: true,
  };

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    this.setState = () => null;
  }

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
  };

  handleChange = (input) => (event) => this.setState({ [input]: event.target.value });

  renderStep1 = () => (
    <div className="App-Memership">
      <div className="Header-top">
        <Header />
      </div>
      <div className="steps-sect">
        <div className="step-section">
          <h2>Welcome, We are Setting Up Your Profile</h2>
          <div className="step_bx">
            <ul>
              <li className="active">
                <span>1</span>
                <p>Step 1 </p>
              </li>
              <li>
                <span>2</span>
                <p>Step 2</p>
              </li>
              <li>
                <span>3</span>
                <p>Step 3</p>
              </li>
            </ul>
          </div>
          <Step1
            afterSubmit={() => this.setState({ isLoading: false })}
            beforeSubmit={() => this.setState({ isLoading: true })}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        </div>
      </div>
    </div>
  );

  renderStep2 = () => (
    <div className="App-Memership">
      <div className="Header-top">
        <Header />
      </div>
      <div className="steps-sect">
        <div className="step-section">
          <h2>You are almost ready</h2>
          <div className="step_bx">
            <ul>
              <li className="complete">
                <span>1</span>
                <p>Step 1 </p>
              </li>
              <li className="active">
                <span>2</span>
                <p>Step 2</p>
              </li>
              <li>
                <span>3</span>
                <p>Step 3</p>
              </li>
            </ul>
          </div>
          <Step2
            afterSubmit={() => this.setState({ isLoading: false })}
            beforeSubmit={() => this.setState({ isLoading: true })}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        </div>
      </div>
    </div>
  );

  renderStep3 = () => (
    <div className="App-Memership">
      <div className="Header-top">
        <Header />
      </div>
      <div className="steps-sect">
        <div className="step-section sm">
          <h2>Last Step and you are done</h2>
          <div className="step_bx">
            <ul>
              <li className="complete">
                <span>1</span>
                <p>Step 1 </p>
              </li>
              <li className="complete">
                <span>2</span>
                <p>Step 2</p>
              </li>
              <li className="active">
                <span>3</span>
                <p>Step 3</p>
              </li>
            </ul>
          </div>
          <Step3
            afterSubmit={() => this.setState({ isLoading: false })}
            beforeSubmit={() => this.setState({ isLoading: true })}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
          />
        </div>
      </div>
    </div>
  );

  renderSteps = () => {
    const { step } = this.state;

    switch (step) {
      case 1:
        // console.log('renderStep1');

        return this.renderStep1();
      case 2:
        // console.log('renderStep2');

        return this.renderStep2();
      default:
        // console.log('renderStep3');

        return this.renderStep3();
    }
  }

  render() {
    if (this.state.isLoading) return (<Loader/>);

    return this.renderSteps();
  }
}

export default SettingStepProfile;
