/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import './SetttingsView.scss';
import User from 'utils/user';
import { FormattedMessage, CustomFieldType } from 'components';
import {
  addCustomField,
  customFieldListing,
  deleteCustomField,
} from 'actions/settings-step-action';
import Storage from 'utils/storage';

const Loader = require('react-loader');

const { authId } = new User();

class CustomFields extends Component {
  state = {
    errors: {},
    fieldName: '',
    fieldDescription: '',
    fieldType: 'String',
    /* isSortable: false,
    showInFilter: false, */
    availableAsColumn: true,
    openModal: false,
    limit: 3,
    currentPage: 1,
    showAlert: false,
    selectedField: {},
  };

  componentDidMount() {
    const { currentPage, limit } = this.state;

    this.props.customFieldListing({
      limit,
      pageNo: currentPage,
    });
  }

  showMore = () => {
    const { currentPage, limit } = this.state;

    const setPage = currentPage + 1;

    this.props.customFieldListing({
      limit,
      pageNo: setPage,
    });

    // High-light current page number
    this.setState({ currentPage: setPage });
  };

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  /* onChangeSortable() {
    this.setState({ isSortable: !this.state.isSortable });
  } */

  /* onChangeFilter() {
    this.setState({ showInFilter: !this.state.showInFilter });
  } */

  toggleColumnAvailabiltity = () => this.setState({ availableAsColumn: !this.state.availableAsColumn });

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  validateCustomFields = () => {
    const errors = {};

    let isValid = true;

    const { fieldName, fieldType } = this.state;

    if (!fieldName) {
      isValid = false;
      errors.fieldNameError = 'requiredField';
    }

    if (!fieldType) {
      isValid = false;
      errors.fieldTypeError = 'requiredField';
    }

    this.setState({ errors });

    return isValid;
  };

  saveCustomField = (event) => {
    event.preventDefault();

    const valid = this.validateCustomFields();
    const { /* currentPage, */ limit } = this.state;

    if (valid) {
      // const fieldName = removeSpaceAndConvertToCapitalLetter(this.state.fieldName);
      const { fieldName } = this.state;
      const addFormInput = {
        fieldName,
        fieldDescription: this.state.fieldDescription,
        fieldType: this.state.fieldType,
        /* isSortable: this.state.isSortable,
        showInFilter: this.state.showInFilter, */
        availableAsColumn: this.state.availableAsColumn,
        fieldValue: '',
        limit,
        pageNo: 1,
      };

      /** add custom field api call */
      this.props.addCustomField(addFormInput);

      /* this.props.customFieldListing({
        limit,
        pageNo: currentPage,
      }); */

      this.setState({
        fieldName: '',
        fieldType: '',
        fieldDescription: '',
        /* isSortable: false,
        showInFilter: false, */
        availableAsColumn: true,
        errors: {},
        openModal: false,
        currentPage: 1,
      });
      window.location.reload();
    }
  };

  // Delete Custom Field popup
  handleDeletePop = (isEnable, selectedField) => {
    this.setState({
      showAlert: isEnable,
      selectedField,
    });
  };

  onConfirmDelete = () => {
    // eslint-disable-next-line no-shadow
    const { deleteCustomField } = this.props;
    const {
      selectedField: { id, fieldName },
      limit,
    } = this.state;

    // delete API Hit
    deleteCustomField({
      id,
      fieldName,
      pageNo: 1,
      limit,
    });

    // High-light current page number
    this.setState({ currentPage: 1 });

    // Hide popup
    this.handleDeletePop(false, {});

    const existingFilter = Storage.get('membersListFilter');
    const userId = authId();

    /** Also remove custom field from localstorage for members page listing */
    if (existingFilter && existingFilter.length) {
      const userExist = existingFilter.find((eachUser) => {
        const keys = Object.keys(eachUser);
        const eachUserId = keys['0'];

        return eachUserId === userId;
      });

      if (userExist) {
        const userIndex = existingFilter.findIndex((eachUser) => {
          const keys = Object.keys(eachUser);
          const eachUserId = keys['0'];

          return eachUserId === userId;
        });

        const filterArray = userExist[`${userId}`].filter(
          (eachColumn) => eachColumn.column_name !== fieldName,
        );

        const updatedArray = [...existingFilter];

        updatedArray[userIndex] = {
          [`${userId}`]: filterArray,
        };

        Storage.save('membersListFilter', updatedArray);
      }
    }

    window.location.reload();
  };

  // Creating UI for Custom Field list
  listingCustomFields = (arrayList) => {
    if (arrayList && arrayList.length) {
      return arrayList.map((eachField) => (
        <tr key={eachField.id}>
          {/* <td className="capitalize">{eachField.fieldName === "altemail" ? "Alter Email" : eachField.fieldName.replace(/([A-Z])/g, ' $1').trim()}</td> */}
          <td>
            {eachField.fieldName === 'altemail' ? 'Alter Email' : eachField.fieldName}
          </td>
          <td>{eachField.fieldType ? eachField.fieldType : 'String'}</td>
          <td>
            <span
              className="remove btn-action"
              onClick={() => this.handleDeletePop(true, eachField)}
            >
              Remove
            </span>
          </td>
        </tr>
      ));
    }

    return (
      <tr>
        <td className="no-camp" colSpan={3} style={{ textAlign: 'center' }}>
          No Custom Fields available, please create new one
        </td>
      </tr>
    );
  };

  render() {
    const {
      openModal,
      errors: { fieldNameError, fieldTypeError },
      showAlert,
    } = this.state;
    const { totalCustomFields, isLoading, customFields } = this.props;

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    return (
      <div>
        <div className="header-w-button">
          <h2 className="custom">Custom fields on account</h2>
          <button className="btn btn-default btn-xs addLink" onClick={this.toggleModal}>
            Add New
          </button>
        </div>
        <table className="table table-responsive table-Campaign">
          <thead>
            <tr>
              <th>NAME</th>
              <th>TYPE</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>{this.listingCustomFields(customFields)}</tbody>
        </table>

        <div className="center showmore">
          {totalCustomFields > customFields.length && (
            <span className="addLink" onClick={(e) => this.showMore(e)}>
              Show more
            </span>
          )}
        </div>

        {/* Add new custom field modal */}
        <Modal className="menu" open={openModal} onClose={this.toggleModal}>
          <div className="modal_bx">
            <div className="modal-header">
              <h2>Add Custom Field</h2>
            </div>
            <div className="model-content-edit">
              <div className="form-group">
                <label>Label</label>
                <input
                  className="form-control"
                  name="fieldName"
                  placeholder="What's custom field Name?"
                  type="text"
                  value={this.state.fieldName}
                  onChange={this.handleChange}
                />
                {fieldNameError && this.showError(fieldNameError)}
              </div>
              <div className="form-group">
                <label>Type</label>
                <CustomFieldType
                  className="form-control"
                  name="fieldType"
                  value={this.state.fieldType}
                  onChange={this.handleChange}
                />
                {fieldTypeError && this.showError(fieldTypeError)}
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  name="fieldDescription"
                  onChange={(e) => this.handleChange(e)}
                />
              </div>
              <div className="form-group">
                <ul>
                  <li>
                    <input
                        checked={this.state.availableAsColumn}
                        id="availableAsColumn"
                        name="availableAsColumn"
                        type="checkbox"
                        onChange={this.toggleColumnAvailabiltity}
                      />
                    <label htmlFor="availableAsColumn">Make column available for member list</label>
                  </li>
                  {/* <li>
                    <input
                      id="opted3"
                      name="opted3"
                      type="checkbox"
                      value={this.state.showInFilter}
                      onChange={(e) => {
                        this.onChangeFilter(e);
                      }}
                    />
                    <label htmlFor="opted3">Show in Filter</label>
                  </li> */}
                </ul>
              </div>
              <button className="btn btn-primary" onClick={this.saveCustomField}>
                Save
              </button>
            </div>
          </div>
        </Modal>

        <SweetAlert
          danger
          focusCancelBtn
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Confirm"
          show={showAlert}
          title="Are you sure you want to delete this Custom Field?"
          onCancel={() => this.handleDeletePop(false, {})}
          onConfirm={this.onConfirmDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => ({
  customFields: settings.customFields,
  totalCustomFields: settings.totalCustomFields,
  isLoading: settings.isLoading,
});

export default connect(mapStateToProps, {
  addCustomField,
  customFieldListing,
  deleteCustomField,
})(CustomFields);
