import React from 'react';
import { connect } from 'react-redux';
import { Modal, Table } from 'reactstrap';
import { Loader } from 'components';
/* import Modal from 'react-responsive-modal'; */
import {
  acceptProfileChange,
  rejectProfileChange,
} from 'actions/member-view-action';

class DataVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: null,
    };
  }

  handleAcceptance = () => {
    if (!this.props.member.pendingUpdate) {
      return false;
    }

    this.props.acceptProfileChange({
      data: this.props.member.pendingUpdate,
      memberId: this.props.member.id,
    });

    this.setState({ isPopupOpen: false });

    return false;
  }

  handleRejection = () => {
    if (!this.props.member.pendingUpdate) {
      return false;
    }

    this.props.rejectProfileChange(this.props.member.id);

    this.setState({ isPopupOpen: false });

    return false;
  }

  isPopupOpen = () => {
    if (this.state.isPopupOpen === null && this.props.open) {
      return this.props.open;
    }

    return this.state.isPopupOpen;
  }

  getName = (name) => name.replace('__temp_', '');

  getLabel = (name) => {
    const key = this.getName(name);

    const nameToLabel = {
      title: 'Title',
      firstName: 'First Name',
      middleName: 'Middle Name',
      lastName: 'Last Name',
      nickName: 'Nick Name',
      suffix: 'Suffix',
      newsletter: 'Newsletter',
      email: 'Email',
      altemail: 'Alternative Email',
      gender: 'Gender',
      dob: 'Date Of Birth',
      maritalStatus: 'Marital Status',
      facebook: 'Facebook',
      instagram: 'Instagram',
      linkedin: 'LinkedIn',
      twitter: 'Twitter',
      businessPhone: 'Business Phone',
      cellPhone: 'Cell Phone',
      companyName: 'Company Name',
      homeAddress1: 'Home Address 1',
      homeAddress2: 'Home Address 2',
      homeCity: 'Home City',
      homeCountry: 'Home Country',
      homeLatitude: 'Home Latitude',
      homeLongitude: 'Home Longitude',
      homePhone: 'Home Phone',
      homeFax: 'Home Fax',
      homeState: 'Home State',
      homeZip: 'Home Zip',
      position: 'Position',
      website: 'Website',
      workAddress1: 'Work Address 1',
      workAddress2: 'Work Address 2',
      workCity: 'Work City',
      workCountry: 'Work Country',
      workLatitude: 'Work Latitude',
      workLongitude: 'Work Longitude',
      workState: 'Work State',
      workZip: 'Work Zip',
    };

    if (typeof nameToLabel[key] === 'undefined') {
      return key;
    }

    return nameToLabel[key];
  }

  renderData() {
    /* eslint-disable no-restricted-syntax, guard-for-in */
    if (this.props.member.pendingUpdate) {
      const entries = Object.entries(this.props.member.pendingUpdate);

      const Rows = entries.map((value, index) => (
        <tr key={`pending_data_${index}`}>
          <th scope="row">{this.getLabel(value[0])}</th>
          <td className={value[1] ? '' : 'empty-cell'}>{value[1] ? value[1] : 'Empty' }</td>
        </tr>
      ));

      return (
        <Table>
          <tbody>{Rows}</tbody>
        </Table>
      );
    }

    return null;
  }

  render() {
    console.log(this.props.isVerifyingData);

    return (
      <Modal
          center='true'
          className='data-verification-popup'
          isOpen={this.isPopupOpen()}
          size='lg'
          onClose={this.props.onClose}
      >
          <div className="modal-header">
            <h5 className='modal-title'>Requested verification for Profile Change</h5>
          </div>
          <div className={`modal-body ${this.props.isVerifyingData ? 'loading' : ''}`}>
            {this.props.isVerifyingData ? <Loader /> : this.renderData()}
          </div>
          <div className="model-footer bs-btn-area">
              <div className="center-btn">
                  <button
                  className="btn btn-primary text-uppercase"
                  onClick={this.handleAcceptance}
                  >
                  Approve
                  </button>
                  <button
                  className="btn btn-danger text-uppercase"
                  onClick={this.handleRejection}
                  >
                  Reject
                  </button>
                  <button
                  className="btn btn-default text-uppercase"
                  onClick={this.props.onClose}
                  >
                  Verify Later
                  </button>
              </div>
          </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state,
  isVerifyingData: state.memberInfo.isVerifyingData,
});

export default connect(mapStateToProps, {
  acceptProfileChange,
  rejectProfileChange,
})(DataVerification);
