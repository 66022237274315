/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import './Schedule.scss';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  addCampaignStepThree,
} from 'actions/campaign';

const Loader = require('react-loader');

class ScheduleNow extends React.Component {
  state = {
    formSubmit: false,
    scheduleType: 'now',
  };

  componentDidMount() {
    if (this.props.selectedScheduleType) {
      const { selectedScheduleType } = this.props;

      this.setState({
        scheduleType: selectedScheduleType,
      });
    }
  }

  handleOptionChange = (event) => {
    this.setState({
      scheduleType: event.target.value,
    });

    // this.props.setScheduleType(event.target.value);
  };

  saveAndContinue = (event) => {
    event.preventDefault();

    this.setState({ formSubmit: true });

    const goToCampaignList = this.state.scheduleType === 'now';

    const formData = {
      campaignId:
        this.props.stepOneValues && this.props.stepOneValues.id
          ? this.props.stepOneValues.id
          : '',
      goToCampaignList,
      scheduleType: this.state.scheduleType.trim(),
    };

    this.props.addCampaignStepThree(formData);
  };

  render() {
    const { formSubmit, scheduleType } = this.state;

    const { isLoading, apiErrors } = this.props;

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    {
      formSubmit
        && apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: 1500,
        });
    }

    return (
      <div className="sm">
        <div className="panel">
          <div className="form-group">
            <h2>When should these be run?</h2>
            <div className="month_year col">
              <ul>
                <li>
                  <input
                    checked={scheduleType === 'now'}
                    id="now"
                    name="scheduleType"
                    type="radio"
                    value="now"
                    onChange={this.handleOptionChange}
                  />
                  <label htmlFor="now">Now</label>
                </li>
                <li>
                  <input
                    checked={scheduleType === 'once in future'}
                    id="onceInFuture"
                    name="scheduleType"
                    type="radio"
                    value="once in future"
                    onChange={this.handleOptionChange}
                  />
                  <label htmlFor="onceInFuture">Once in the future</label>
                </li>
                <li>
                  <input
                    checked={scheduleType === 'recurring schedule'}
                    id="reccuringSchedule"
                    name="scheduleType"
                    type="radio"
                    value="recurring schedule"
                    onChange={this.handleOptionChange}
                  />
                  <label htmlFor="reccuringSchedule">Recurring Schedule</label>
                </li>
              </ul>
              <button className="btn btn-primary cen" onClick={this.saveAndContinue}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default ScheduleNow;
const mapStateToProps = ({ campaign }) => ({
  apiErrors: campaign.updateCampaignError,
  isLoading: campaign.isLoading,
  selectedScheduleType: campaign.selectedScheduleType,
  stepOneValues: campaign.stepOneValues,
});

export default connect(mapStateToProps, {
  addCampaignStepThree,
  // setScheduleType,
})(ScheduleNow);
