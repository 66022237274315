import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

class Link extends Component {
  render() {
    const props = Object.assign({}, this.props);

    delete props.push;

    return (
      <a
        href={this.props.href}
        onClick={(e) => {
          e.preventDefault();
          this.props.push(this.props.href);
        }}
        {...props}
      >
        {this.props.children}
      </a>
    );
  }
}

export default connect(null, { push })(Link);
