import React from 'react';
import './AdministrationDashboard.scss';
import Modal from 'react-responsive-modal';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';
// import committees from 'assets/images/committees.png';
import committees from 'assets/images/committees.png';
import savedmembership from 'assets/images/savedmembership.png';
import events from 'assets/images/events.png';
import members from 'assets/images/members.png';
import engagement from 'assets/images/engagement.png';
import compaign from 'assets/images/compaign.png';
import { logOut } from 'actions/user-authentication-action-types';
import { TopHeader } from 'components';
import User from 'utils/user';

const { authUser, formInfo } = new User();
const custommodal = 'menu';

// let self;

class AdministrationDashboard extends React.Component {
  static propTypes = {
    logOut: func.isRequired,
  };

  constructor(state) {
    super(state);
    this.state = { open: false };
  }

  componentDidMount() {
    // self = this;
    const userData = authUser();
    const formInfoVal = formInfo();

    localStorage.removeItem('mapping');
    if (userData && userData.user && userData.user.paymentMethod) {
      this.props.push('/administration-dashboard');
    } else {
      this.props.push('/');
    }

    if (formInfoVal) {
      if (formInfoVal === 'step2') {
        this.props.push('/administration-dashboard');
      } else {
        this.props.push('/');
      }
    }
  }

  logOutUser = () => {
    this.props.logOut();
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;

    return (
      <div className="App-Memership">
        <div className="container">
          <div className="Header-top">
              <TopHeader />
          </div>
          <div className="csv_row txtleft">
            <h2>Administration</h2>
            <button className="btn btn-primary logout-btn" onClick={this.logOutUser}>
              Logout
            </button>
          </div>
          <div className="admin_bx">
            <div className="admin_bx_col">
              <Link to="/members-list">
                <img alt="members" src={members} />
                <h4>Members</h4>
              </Link>
            </div>
            <div className="admin_bx_col">
              <Link to="/committees">
                <img alt="committees" src={committees} />
                <h4>Committees</h4>
              </Link>
            </div>
            <div className="admin_bx_col">
              <Link to="/">
                {/* <a href="/MembersReports"> */}
                <img alt="savedmembership" src={savedmembership} />
                <h4>
                  Saved Membership <br /> View/Report
                </h4>
              </Link>
            </div>
            <div className="admin_bx_col">
              <Link to="/event">
                <img alt="events" src={events} />
                <h4>Events</h4>
              </Link>
            </div>

            <div className="admin_bx_col">
              <img alt="engagement" src={engagement} />
              <a onClick={this.onOpenModal}>
                <h4>Engagement</h4>
              </a>
              <Modal className={custommodal} open={open} onClose={this.onCloseModal}>
                <div className="modal_bx">
                  <div className="modal-header">
                    <h2>Engagement</h2>
                  </div>
                  <div className="model-content eng">
                    <div className="form-group">
                      <ul>
                        <li>20% Events -event attended in last 30days</li>
                        <li>20% Committees - current commmittee</li>
                        <li>20% Email - 100% of emails opened</li>
                        <li>20% Payments - payment made in last year</li>
                        <li>20% Profile updated - profile updated in last year</li>
                      </ul>
                      <div className="border-top">
                        <a href="/#">100% Max Engagement</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="admin_bx_col">
              <Link to="/campaigns">
                <img alt="compaign" src={compaign} />
                <h4>Emails</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  logOut,
  push,
})(AdministrationDashboard);
