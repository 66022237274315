import {
  ADD_CREDIT_CARD_REQUESTED,
  ADD_CREDIT_CARD_SUCCESS,
  ADD_CREDIT_CARD_FAILURE,
  GET_CREDIT_CARD_REQUESTED,
  GET_CREDIT_CARD_SUCCESS,
  GET_CREDIT_CARD_FAILURE,
  UPDATE_CREDIT_CARD_REQUESTED,
  UPDATE_CREDIT_CARD_SUCCESS,
  UPDATE_CREDIT_CARD_FAILURE,
  DELETE_CREDIT_CARD_REQUESTED,
  DELETE_CREDIT_CARD_SUCCESS,
  DELETE_CREDIT_CARD_FAILURE,
} from 'actions/payments-action';

const initialState = {};

const PaymentData = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_CREDIT_CARD_REQUESTED:
      return {
        ...state,
        isProcessing: true,
      };

    case ADD_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        cardList: payload,
        isProcessing: false,
        cardAdded: true,
      };

    case ADD_CREDIT_CARD_FAILURE:
      return {
        ...state,
        cardAdded: false,
        isProcessing: false,
      };

    case GET_CREDIT_CARD_REQUESTED:
      return {
        ...state,
        isProcessing: true,
      };

    case GET_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        cardList: payload.data,
        isProcessing: false,
      };

    case GET_CREDIT_CARD_FAILURE:
      return {
        ...state,
        isProcessing: false,
        cardList: null,
      };

    case UPDATE_CREDIT_CARD_REQUESTED:
      return {
        ...state,
        isProcessing: true,
      };

    case UPDATE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        cardList: payload,
      };

    case UPDATE_CREDIT_CARD_FAILURE:
      return {
        ...state,
        isProcessing: false,
        cardList: null,
      };

    case DELETE_CREDIT_CARD_REQUESTED:
      return {
        ...state,
        isProcessing: true,
      };

    case DELETE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        cardList: payload,
      };

    case DELETE_CREDIT_CARD_FAILURE:
      return {
        ...state,
        isProcessing: false,
        cardList: null,
      };

    default:
      return state;
  }
};

export default PaymentData;
