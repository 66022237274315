/* eslint-disable no-unused-expressions */
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  LOGIN_AUTHORIZATION,
  loginAuthorizationSuccess,
  loginAuthorizationFailure,
  FETCH_LOGIN_USER_DETAILS,
  fetchLoginUserDetailsSuccess,
  FORGOT_PASSWORD,
  signupAuthorizationRequested,
  signupAuthorizationFailure,
  forgotPasswordRequested,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  SET_PASSWORD,
  setPasswordRequested,
  setPasswordSuccess,
  setPasswordFailure,
  LOG_OUT,
  SIGNUP_AUTHORIZATION,
  logOutRequested,
  logOutSuccess,
  logOutFailure,
  GLOBAL_SEARCH,
  globalSearchRequested,
  globalSearchSuccess,
  globalSearchFailure,
} from 'actions/user-authentication-action-types';
import HttpHelper, { handleResp } from 'utils/http-helper';
import Storage from 'utils/storage';
import User from 'utils/user';

const { postRequest, putRequest, prepareReq } = new HttpHelper();
const { authUser } = new User();
const actions = (sAction, fAction = null, bAction = null) => ({ bAction, fAction, sAction });

export function* loginAuthorization({ payload }) {
  // eslint-disable-next-line no-param-reassign
  payload.env = process.env.NODE_ENV;
  const url = 'account/login';
  const response = yield call(postRequest, prepareReq(url, payload));

  if (yield* handleResp(response, actions(loginAuthorizationSuccess, loginAuthorizationFailure))) {
    Storage.save('userDetails', {
      ...response.data.response,
    });
    const userData = authUser();

    if (!userData || !userData.user || !userData.user.paymentMethod) {
      yield put(push('/Setting-Step-Profile'));
    } else {
      yield put(push('/'));
    }
  }
}

export function* signupAuthorization({ payload }) {
  yield put(signupAuthorizationRequested());
  const response = yield call(postRequest, prepareReq('account/register', payload));

  if (yield* handleResp(response, actions(loginAuthorizationSuccess, signupAuthorizationFailure))) {
    Storage.save('userDetails', {
      ...response.data.response,
    });
    yield put(push('/Setting-Step-Profile'));
  }
}

export function* forgotPassword({ payload }) {
  yield put(forgotPasswordRequested());
  const response = yield call(postRequest, prepareReq('account/forgot-password', payload));

  if (yield* handleResp(response, actions(forgotPasswordSuccess, forgotPasswordFailure))) yield put(push('/'));
}

export function* setPassword({ payload }) {
  yield put(setPasswordRequested());
  const url = `account/reset-password/${payload.authId}/${payload.token}`;
  const response = yield call(putRequest, prepareReq(url, { password: payload.password }));

  yield* handleResp(response, actions(setPasswordSuccess, setPasswordFailure));
}

function* fetchLoginUserDetails() {
  const userDetails = authUser();

  yield put(fetchLoginUserDetailsSuccess(userDetails));
}

function* logOut() {
  yield put(logOutRequested());
  const response = yield call(postRequest, prepareReq('account/logout'));

  if (yield* handleResp(response, actions(logOutSuccess, logOutFailure))) {
    Storage.clear();
    yield put(push('/'));
  }
}

export function* globalSearch({ payload }) {
  yield put(globalSearchRequested());
  const response = yield call(postRequest, prepareReq('members/search', payload));

  yield* handleResp(response, actions(globalSearchSuccess, globalSearchFailure));
}

function* UserAuthentication() {
  yield [
    takeLatest(LOGIN_AUTHORIZATION, loginAuthorization),
    takeLatest(FORGOT_PASSWORD, forgotPassword),
    takeLatest(SET_PASSWORD, setPassword),
    takeLatest(LOG_OUT, logOut),
    takeLatest(SIGNUP_AUTHORIZATION, signupAuthorization),
    takeLatest(FETCH_LOGIN_USER_DETAILS, fetchLoginUserDetails),
    takeLatest(GLOBAL_SEARCH, globalSearch),
  ];
}

export default UserAuthentication;
