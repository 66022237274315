import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, FormGroup, Label, Input, Card, CardBody, CardTitle, CardSubtitle, Table, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfinity, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

/* eslint-disable import/extensions */
import { isValid, isExpirationDateValid, isSecurityCodeValid } from 'creditcard.js';
/* eslint-enable import/extensions */

import { Loader, FormattedMessage } from 'components';
import User from 'utils/user';
import { logOut } from 'actions/user-authentication-action-types';
import { push } from 'connected-react-router';
import {
  step2Form,
} from 'actions/settings-step-action';
import api from '../Services';
import './Step2.scss';

const {
  authUser,
  formInfo,
} = new User();

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      paymentMethod: 'free', // 'trial',
      billingPeriod: 'month',
      cardName: '',
      cardNumber: '',
      cardCVV: '',
      expMonth: '',
      expYear: '',
      selectedSubscription: '',
      uncollapsedSubscription: '',
      subscription: '',
    };
    this.minYear = new Date().getFullYear();
    this.maxYear = this.minYear + 10;
  }

  componentDidMount() {
    const userData = authUser();
    const formInfoVal = formInfo();

    api.getSubscription().then((data) => {
      if (data && data.response) {
        const stateData = {
          subscription: data.response,
        };

        const defaultSubs = data.response.find((o) => o.isDefault);

        if (defaultSubs) {
          stateData.selectedSubscription = defaultSubs;
        }
        this.setState(stateData);
      }
    });

    if (userData && userData.user && userData.user.paymentMethod) {
      this.props.push('/administration-dashboard');
    }
    if (formInfoVal === 'step2') {
      this.props.push('/administration-dashboard');
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => null;
  }

  saveAndContinue = (e) => {
    e.preventDefault();
    this.props.beforeSubmit();

    const isCardValidated = this.validateFields();

    let subscribedPlan = '';

    let subscribedPayment = '';

    if (isCardValidated) {
      if (this.state.billingPeriod === 'month') {
        subscribedPlan = this.state.selectedSubscription.monthPlanId;
        subscribedPayment = this.state.selectedSubscription.monthPrice;
      } else {
        subscribedPlan = this.state.selectedSubscription.yearPlanId;
        subscribedPayment = this.state.selectedSubscription.yearPrice;
      }

      const options = {
        subscriptionType: this.state.selectedSubscription.id
          ? this.state.selectedSubscription.id
          : '',
        billingMethod: this.state.billingPeriod ? this.state.billingPeriod : '',
        paymentMethod: this.state.paymentMethod ? this.state.paymentMethod : '',
        cardName: this.state.cardName ? this.state.cardName : '',
        cardNumber: this.state.cardNumber ? this.state.cardNumber : '',
        cardExpiry: this.state.expMonth
          ? `${this.state.expMonth}/${this.state.expYear}`
          : '',
        cardCCV: this.state.cardCVV ? this.state.cardCVV : '',
        subscribePlan: subscribedPlan,
        payment: subscribedPayment,
      };

      this.props.step2Form(options);
    }

    this.props.afterSubmit();
  };

  validateFields = () => {
    const errors = {};

    let isCardValid = true;

    if (this.state.selectedSubscription && this.state.selectedSubscription.code === 'free') {
      return isCardValid;
    }

    const isCardValidated = isValid(this.state.cardNumber);

    if (!this.state.selectedSubscription) {
      isCardValid = false;
      errors.subscriptionTypeError = 'emptySubscriptionType';
    }

    if (!this.state.billingPeriod) {
      isCardValid = false;
      errors.billingPeriodError = 'emptyBillingPeriod';
    }

    if (!this.state.paymentMethod) {
      isCardValid = false;
      errors.paymentMethodError = 'emptyPaymentMethod';
    }

    if (this.state.paymentMethod === 'card') {
      if (!this.state.cardName) {
        isCardValid = false;
        errors.cardNameError = 'emptyCardName';
      }
      if (!this.state.cardNumber) {
        isCardValid = false;
        errors.cardNumberError = 'emptyCardNumber';
      } else if (!isCardValidated) {
        isCardValid = false;
        errors.cardNumberError = 'emptyCardNumberInvalid';
      }

      if (!this.state.cardCVV) {
        isCardValid = false;
        errors.cardCVVError = 'emptyCardCVV';
      } else if (!isSecurityCodeValid(this.state.cardNumber, this.state.cardCVV)) {
        isCardValid = false;
        errors.cardCVVError = 'invalidCardNumberInvalid';
      }

      if (!this.state.expYear && !this.state.expMonth) {
        isCardValid = false;
        errors.expMonthError = 'emptyExpireMonth';
        errors.expYearError = 'emptyExpYear';
      } else if (this.state.expYear && !this.state.expMonth) {
        isCardValid = false;
        errors.expMonthError = 'emptyExpireMonth';
      } else if (!this.state.expYear && this.state.expMonth) {
        isCardValid = false;
        errors.expYearError = 'emptyExpYear';
      } else if (!isExpirationDateValid(this.state.expMonth, this.state.expYear)) {
        isCardValid = false;
        errors.expMonthError = 'invalidExpireMonth';
        errors.expYearError = 'invalidExpYear';
      }
    }

    this.setState({ errors });

    return isCardValid;
  };

  setCollapseTrigger = (e, s) => {
    e.preventDefault();
    const shownSubscription = this.state.uncollapsedSubscription === s.code ? null : s.code;

    console.log('shownSubscription: ', shownSubscription);
    this.setState({ uncollapsedSubscription: shownSubscription });
  }

  showError = (error) => <div className="ez-label ez-error">{<FormattedMessage id={error} />}</div>;

  getPackageLabel = (key) => {
    const labels = {
      acceptPayment: 'Accept Payment',
      activeCampaignLimit: 'Campaigns',
      committeeLimit: 'Committee',
      emailLimit: 'Emails',
      membersLimit: 'Members',
    };

    if (typeof labels[key] !== 'undefined') {
      return labels[key];
    }

    return key;
  }

  getPackagePrice = (s, onlyOne = false) => {
    if (!s || s.code === 'free') {
      return <div className='monthly-price'>$0</div>;
    }

    if (onlyOne) {
      const { billingPeriod } = this.state;
      const periodKey = billingPeriod === 'month' ? 'monthPrice' : 'yearPrice';

      return (
        <React.Fragment>
          <div className='primary-price'>${s[periodKey]}/{billingPeriod}</div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className='primary-price monthly-price'>${s.monthPrice}/month</div>
        <div className='dark-area'>
          <div className='annual-price'>(${s.yearPrice}/year <span className="d-inline-block d-sm-none"> - 20% off annual</span>)</div>
          <div className='offered-price d-none d-sm-block'>20% off annual</div>
        </div>
      </React.Fragment>
    );
  }

  getPaymentLabel = (code) => {
    if (code === 'trial') {
      return 'Free Trial';
    }

    return 'Credit Card';
  }

  getPackageValue = (p, key) => {
    if (typeof p[key] === 'undefined') {
      return 'N/A';
    }

    if (p[key] === -1) {
      return <FontAwesomeIcon icon={faInfinity} />;
    }

    if (p[key] === true) {
      return <FontAwesomeIcon icon={faCheck} />;
    }

    if (p[key] === false) {
      return <FontAwesomeIcon icon={faTimes} />;
    }

    return p[key];
  };

  renderLoader() {
    return (
      <div className="subscription-loader">
        <Loader/>
      </div>
    );
  }

  renderPriceTableData = () => {
    const Packages = this.state.subscription.map((s) => {
      const isSelected = this.state.selectedSubscription && s.id === this.state.selectedSubscription.id;
      const paymentMethod = s.code === 'free' ? 'free' : this.state.paymentMethod;

      return (
        <Col className={`package${isSelected ? ' active' : ''}`} key={`package-${s.id}`} sm="2" xs="12" >
          <div
            className='package-head'
            href='#'
            onClick={(e) => this.setCollapseTrigger(e, s)}
          >
            {s.isDefault ? <div className='package-recommeded'>Recommeded</div> : ''}
            <div className='package-name'>{s.name}</div>
            <div className='package-price'>{this.getPackagePrice(s)}</div>
          </div>
          <div className='package-body d-none d-sm-block'>
            <div className='package-row'>{this.getPackageValue(s, 'acceptPayment')}</div>
            <div className='package-row'>{this.getPackageValue(s, 'activeCampaignLimit')}</div>
            <div className='package-row'>{this.getPackageValue(s, 'committeeLimit')}</div>
            <div className='package-row'>{this.getPackageValue(s, 'emailLimit')}</div>
            <div className='package-row'>{this.getPackageValue(s, 'membersLimit')}</div>
          </div>
          <Collapse isOpen={this.state.uncollapsedSubscription === s.code}>
            <div className='package-body package-mobile-body d-block d-sm-none'>
              <div className='package-row'>
                <div className="d-inline-block me-2 fw-bold">{this.getPackageLabel('acceptPayment')}:</div>
                {this.getPackageValue(s, 'acceptPayment')}
              </div>
              <div className='package-row'>
                <div className="d-inline-block me-2 fw-bold">{this.getPackageLabel('activeCampaignLimit')}:</div>
                {this.getPackageValue(s, 'activeCampaignLimit')}
              </div>
              <div className='package-row'>
                <div className="d-inline-block me-2 fw-bold">{this.getPackageLabel('committeeLimit')}:</div>
                {this.getPackageValue(s, 'committeeLimit')}
              </div>
              <div className='package-row'>
                <div className="d-inline-block me-2 fw-bold">{this.getPackageLabel('emailLimit')}:</div>
                {this.getPackageValue(s, 'emailLimit')}
              </div>
              <div className='package-row'>
                <div className="d-inline-block me-2 fw-bold">{this.getPackageLabel('membersLimit')}:</div>
                {this.getPackageValue(s, 'membersLimit')}
              </div>
            </div>
          </Collapse>
          <div className='package-action'>
            {
              isSelected
                ? <Button disabled >Selected</Button>
                : <Button
                    onClick={() => this.setState({ selectedSubscription: s, paymentMethod })}
                  >Select</Button>
            }
          </div>
        </Col>
      );
    });

    return (
      <React.Fragment>
        <Col className='package package-head-label d-none d-sm-block' sm="4" xs="12">
          <div className='package-head'></div>
          <div className='package-body'>
            <div className='package-row'>{this.getPackageLabel('acceptPayment')}</div>
            <div className='package-row'>{this.getPackageLabel('activeCampaignLimit')}</div>
            <div className='package-row'>{this.getPackageLabel('committeeLimit')}</div>
            <div className='package-row'>{this.getPackageLabel('emailLimit')}</div>
            <div className='package-row'>{this.getPackageLabel('membersLimit')}</div>
          </div>
          <div className='package-action'>
          </div>
        </Col>
        {Packages}
      </React.Fragment>
    );
  }

  renderSubscriptionPeriod() {
    if (!this.state.selectedSubscription || this.state.selectedSubscription.code === 'free') {
      return null;
    }

    return (
      <Card className='subscription-period'>
        <CardBody>
          <CardTitle tag="h5">Billing Cycle</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">What billing cycle do you want it to be?</CardSubtitle>
          <FormGroup check>
            <Input
              checked={this.state.billingPeriod === 'month' ? 'checked' : ''}
              id="billing-cycle-month"
              name="billingPeriod"
              type="radio"
              onChange={() => this.setState({ billingPeriod: 'month' })}
            />{' '}<Label check for='billing-cycle-month'>Monthly</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              checked={this.state.billingPeriod === 'year' ? 'checked' : ''}
              id="billing-cycle-year"
              name="billingPeriod"
              type="radio"
              onChange={() => this.setState({ billingPeriod: 'year' })}
            />{' '}<Label check for='billing-cycle-year'>Yearly</Label>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }

  renderPaymentTerm() {
    if (!this.state.selectedSubscription || this.state.selectedSubscription.code === 'free') {
      return null;
    }

    return (
      <Card className='subscription-period'>
        <CardBody>
          <CardTitle tag="h5">Mode of Payment</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">What method of payment do you prefer?</CardSubtitle>
          <FormGroup check>
            <Input
              checked={this.state.paymentMethod === 'trial' ? 'checked' : ''}
              id="payment-method-trial"
              name="paymentMethod"
              type="radio"
              onChange={() => this.setState({ paymentMethod: 'trial' })}
            />{' '}<Label check for="payment-method-trial">Free Trial</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              checked={this.state.paymentMethod === 'card' ? 'card' : ''}
              id="payment-method-card"
              name="paymentMethod"
              type="radio"
              onChange={() => this.setState({ paymentMethod: 'card' })}
            />{' '}<Label check for="payment-method-card">Card</Label>
          </FormGroup>
        </CardBody>
      </Card>
    );
  }

  renderPaymentGateway() {
    const {
      errors: {
        cardNameError,
        cardNumberError,
        cardCVVError,
        expMonthError,
        expYearError,
      },
    } = this.state;

    let PaymentComponent = null;

    if (this.state.paymentMethod === 'card') {
      PaymentComponent = (
        <Row style={{ position: 'relative' }}>
          <Col xs="12">
            <FormGroup floating>
              <Input
                required
                id="card-name"
                name="cardName"
                placeholder="Card Name"
                type="text"
                value={this.state.cardName}
                onChange={(e) => this.setState({ cardName: e.target.value })} />
              <Label for="card-name">Card Name</Label>
              {!!cardNameError && this.showError(cardNameError)}
            </FormGroup>
          </Col>
          <Col xs="12">
            <FormGroup floating>
              <Input
                required
                id="card-number"
                name="cardNumber"
                placeholder="Card Number"
                type="number"
                value={this.state.cardNumber}
                onChange={(e) => this.setState({ cardNumber: e.target.value })}
              />
              <Label for="card-number">Card Number</Label>
              {!!cardNumberError && this.showError(cardNumberError)}
            </FormGroup>
          </Col>
          <Col sm="3" xs="12" >
            <FormGroup floating>
              <Input
                required
                id="card-month"
                max="12"
                min="1"
                name="expMonth"
                placeholder="Month"
                type="number"
                value={this.state.expMonth}
                onChange={(e) => this.setState({ expMonth: e.target.value })}
              />
              <Label for="card-month">Month</Label>
              {!!expMonthError && this.showError(expMonthError)}
            </FormGroup>
          </Col>
          <Col sm="3" xs="12" >
            <FormGroup floating>
              <Input
                required
                id="card-year"
                max = {this.maxYear}
                min = {this.minYear}
                name="expYear"
                placeholder="Year"
                type="number"
                value={this.state.expYear}
                onChange={(e) => this.setState({ expYear: e.target.value })}
              />
              <Label for="card-year">Year</Label>
              {!!expYearError && this.showError(expYearError)}
            </FormGroup>
          </Col>
          <Col sm="6" xs="12">
            <FormGroup floating>
              <Input
                required
                id="card-cvv"
                name="cardCVV"
                placeholder="CVV"
                type="number"
                value={this.state.cardCVV}
                onChange={(e) => this.setState({ cardCVV: e.target.value })}
              />
              <Label for="card-cvv">CVV</Label>
              {!!cardCVVError && this.showError(cardCVVError)}
            </FormGroup>
          </Col>
        </Row>
      );
    }

    return (
      <Card body style={{ marginTop: '10px' }}>
        <CardTitle tag="h5">Your Subscription</CardTitle>
        <Table bordered className='checkout-info'>
          <tbody>
            <tr>
              <th>Subscription</th>
              <td>{this.state.selectedSubscription ? this.state.selectedSubscription.name : 'N/A'}</td>
            </tr>

            <tr>
              <th>Price</th>
              <td>{this.getPackagePrice(this.state.selectedSubscription, true)}</td>
            </tr>

            <tr>
              <th>Billing Cycle</th>
              <td>{this.state.billingPeriod === 'month' ? 'Monthly' : 'Anually'}</td>
            </tr>

            <tr>
              <th>Payment</th>
              <td>{this.state.selectedSubscription && this.state.selectedSubscription.code === 'free' ? 'Free' : this.getPaymentLabel(this.state.paymentMethod)}</td>
            </tr>
          </tbody>
        </Table>
        {PaymentComponent}
        <Button onClick={this.saveAndContinue}>Continue</Button>
      </Card>
    );
  }

  render() {
    const { data } = this.props;

    if (data.step2Success.data) {
      this.props.nextStep();
    }

    if (!this.state.subscription) {
      return this.renderLoader();
    }

    return (
      <React.Fragment>
        {/* <Row>{this.renderPriceTableData()}</Row> */}
        {/* <Row><Col className='text-end' style={{ fontSize: '12px' }}>Looking for more plans? <a href='https://memberaudience.com/contact' style={{ fontSize: '12px' }} target='_blank'>Click here</a></Col></Row> */}
        {/* <Row>{this.renderSubscriptionPeriod()}</Row>
        <Row>{this.renderPaymentTerm()}</Row> */}
        <Row>{this.renderPaymentGateway()}</Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.settings,
});

export default connect(mapStateToProps, {
  step2Form,
  logOut,
  push,
})(Step2);
