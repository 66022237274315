import React from 'react';
import { connect } from 'react-redux';
import './ProfileCommittees.scss';
import moment from 'moment';
import { fetchCommitteesByMember } from 'actions/committee';

class ProfileCommittees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem('selectedMember'),
    };
  }

  componentDidMount() {
    this.props.fetchCommitteesByMember({
      userId: this.state.userId,
    });
  }

  render() {
    console.log(this.props);
    const { committeesListByMember } = this.props;

    return (
      <div className="info">
        <h2 className="line-heading">Committee History</h2>

        <table className="table table-responsive table-Campaign">
          <thead>
            <tr>
              <th>TYPE</th>
              <th>COMMITTEE</th>
              <th>JOINED</th>
            </tr>
          </thead>
          <tbody>
            {committeesListByMember?.length > 0 ? (
              committeesListByMember.map((item) => (
                <tr key={item.id}>
                  <td>{item.committeeType}</td>
                  <td>{item.committeeName} </td>
                  <td>{moment(item.createdAt).format('MM/DD/YYYY')} </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No Data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = ({
  committee: { committeesListByMember, errors, isLoading },
}) => ({
  apiErrors: errors,
  committeesListByMember,
  isLoading,
});

export default connect(mapStateToProps, { fetchCommitteesByMember })(
  ProfileCommittees,
);
