import { LOGIN_SERVICE_URL } from '../../../../constants';

class ApiService {
  async getOrganisations(organisationId = null) {
    const fetchURL = organisationId ? `${LOGIN_SERVICE_URL}/organisation/${organisationId}` : `${LOGIN_SERVICE_URL}/organisations`;

    const response = await fetch(fetchURL, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      // body data type must match "Content-Type" header
    });

    return response.json();
  }

  async getOrganisationTypes() {
    const response = await fetch(`${LOGIN_SERVICE_URL}/organisation-types`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrer: 'no-referrer',
    });

    return response.json();
  }

  async getSecretKey(id) {
    const response = await fetch(`${LOGIN_SERVICE_URL}/payment/getSecretKey/${id}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      // body data type must match "Content-Type" header
    });

    return response.json();
  }

  async orgData(id) {
    const response = await fetch(`${LOGIN_SERVICE_URL}/account/orgDetails/${id}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      // body data type must match "Content-Type" header
    });

    return response.json();
  }

  async getSubscription() {
    const response = await fetch(`${LOGIN_SERVICE_URL}/subscriptions`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      // body data type must match "Content-Type" header
    });

    return response.json();
  }

  async hitRsvp(id) {
    const response = await fetch(
      `${LOGIN_SERVICE_URL}/invitedMember/rsvp/true/${id}`,
      {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        // body data type must match "Content-Type" header
      },
    );

    return response.json();
  }

  async subscriptionList(userRequest) {
    // console.log(userRequest);
    const response = await fetch(
      `${LOGIN_SERVICE_URL}/subscription/${userRequest}`,
      {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        // body data type must match "Content-Type" header
      },
    );

    return response.json();
  }

  async imageUpload(data) {
    const response = await fetch(`${LOGIN_SERVICE_URL}/account/upload-image`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: data, // body data type must match "Content-Type" header
    });

    return response.json();
  }

  async memberImageUpload(data, id) {
    const response = await fetch(`${LOGIN_SERVICE_URL}/member/upload-image/${id}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: data, // body data type must match "Content-Type" header
    });

    return response.json();
  }

  async addOrganisation(data) {
    console.log('data +++>>>', data);
    const response = await fetch(`${LOGIN_SERVICE_URL}/organisation-types`, {
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      body: data, // body data type must match "Content-Type" header
    });

    return response.json();
  }
}

export default new ApiService();
