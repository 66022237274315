// eslint-disable-next-line import/no-extraneous-dependencies
import { css } from '@emotion/core';
import React, { } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'components';
import './Step1.scss';
import { logOut } from 'actions/user-authentication-action-types';
import { stepForm } from 'actions/settings-step-action';
import User from 'utils/user';
import { toast } from 'react-toastify';
import api from '../Services';

// Another way to import. This is recommended to reduce bundle size
// import ClipLoader from 'react-spinners/ClipLoader';

const { authUser, formInfo } = new User();

let self;
const override = css`
  right: 0;
  display: block;
  margin: 0 auto;
  border-color: #5595ff;
`;

class Step1 extends React.Component {
  static propTypes = {
    logOut: func.isRequired,
    logOutStatus: string,
    push: func.isRequired,
    stepForm: func.isRequired,
    stepFormError: string,
    stepFormFieldsDetails: shape({
      email: string,
      name: string,
    }),
  };

  state = {
    errors: {},
    firstName: '',
    isOrganisationCustom: false,
    lastName: '',
    loading: false,
    organisationName: '',
    organisationType: '',
    organisationType1: '',
    organisations: [],
    profileImageErrorSize: '',
  };

  componentDidMount() {
    const userData = authUser();
    const formInfoVal = formInfo();

    self = this;
    api.getOrganisationTypes().then((data) => {
      self.setState({ organisations: data.response });
    });

    if (userData && userData.user && userData.user.organisation) {
      self.props.nextStep();
    } else if (formInfoVal === 'step1') {
      self.props.nextStep();
    } else if (formInfoVal === 'step2') {
      this.props.push('/administration-dashboard');
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => null;
  }

  logOutUser() {
    this.props.logOut();
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    if (value === 'Select your organization type') {
      this.setState({ [name]: '' });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleChange1 = (event) => {
    const { name, value } = event.target;

    if (value === 'Select your organization type') {
      this.setState({
        [name]: '',
        isOrganisationCustom: false,
        organisationType1: '',
      });
    } else if (name === 'organisationType' && (JSON.parse(value).name === 'Other' || JSON.parse(value).name === 'Others')) {
      this.setState({
        [name]: value,
        isOrganisationCustom: true,
      });

      /* this.setState({ [name]: value });
      this.setState({ isOrganisationCustom: true }); */
    } else if (name === 'organisationType' && JSON.parse(value).name !== 'Other') {
      this.setState({
        [name]: value,
        isOrganisationCustom: false,
        organisationType1: '',
      });
    } else {
      this.setState({ [name]: value });
    }
  };

  addOrg(data) {
    api.addOrganisation(JSON.stringify(data)).then((res) => {
      if (!res.error) {
        const options = {
          firstName: self.state.firstName,
          lastName: self.state.lastName,
          organisationType: res?.response?.id,
          organisationName: self.state.organisationName,
          profileImg: self.state.profileImage,
        };

        this.props.stepForm({ options });
      } else {
        toast.error(res.message, { autoClose: 4500 });
      }
    });
  }

  validateFields = () => {
    const errors = {};

    let isValid = true;

    if (!this.state.firstName) {
      isValid = false;
      errors.firstNameError = 'emptyNameValidation';
    } else if (this.state.firstName.length < 2) {
      isValid = false;
      errors.firstNameError = 'invalidNameValidation';
    }

    if (!this.state.lastName) {
      isValid = false;
      errors.lastNameError = 'emptyLastNameValidation';
    } else if (this.state.lastName.length < 2) {
      isValid = false;
      errors.lastNameError = 'invalidLnameValidation';
    }

    if (!this.state.organisationName) {
      isValid = false;
      errors.organisationNameError = 'emptyOrganisationName';
    } else if (this.state.organisationName.length < 2) {
      isValid = false;
      errors.organisationNameError = 'invalidOrgNameValidation';
    }

    if (!this.state.organisationType) {
      isValid = false;
      errors.organisationTypeError = 'emptyOrganisationType';
    } else if (this.state.isOrganisationCustom && !this.state.organisationType1) {
      isValid = false;
      errors.organisationTypeError = 'emptyOrganisationType';
    }

    this.setState({ errors });

    return isValid;
  };

  handleKeyPress = (event) => {
    User.spaceNotAllowed(event);
  };

  onChangeFile = (e) => {
    this.fileUpload(e.target.files[0]);
  }

  fileUpload(file) {
    if (file) {
      if (
        file.type === 'image/png'
        || file.type === 'image/jpeg'
        || file.type === 'image/jpg'
      ) {
        if (file.size < 5 * 1000 * 1000) {
          this.setState({ loading: true });
          const formData = new FormData();

          formData.append('profileImg', file);
          api.imageUpload(formData).then((data) => {
            // this.setState({ profileImg: data.location });
            this.state.profileImage = data.response.location;
            this.setState({ loading: false });
            this.setState({ imageName: file.name });
            this.setState({ profileImageErrorSize: '' });
          });
        } else {
          this.setState({
            profileImageErrorSize: 'Please select valid image size (Max. size 5MB).',
          });
          this.setState({ imageName: '' });
        }
      } else {
        this.setState({
          profileImageErrorSize:
            'Image format not supported (jpg, jpeg, png are acceptable).',
        });
        this.setState({ imageName: '' });
      }
    }
  }

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  onChangeOrg(event) {
    this.state.organisationType = event.target.value;
    this.state.organisationTypeValid = true;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.beforeSubmit();
    const isValid = this.validateFields();

    if (isValid) {
      if (this.state.organisationType1) {
        const opts = {
          createdBy: 'user',
          name: this.state.organisationType1,
        };

        this.addOrg(opts);
      } else {
        let orgType = null;

        if (this.state.organisationType1) {
          orgType = this.state.organisationType1;
        } else if (this.state.organisationType) {
          const parsedOrgType = JSON.parse(this.state.organisationType);

          if (parsedOrgType) {
            orgType = parsedOrgType.id;
          }
        }

        const options = {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          organisationType: orgType,
          organisationName: this.state.organisationName,
          profileImg: this.state.profileImage,
        };

        this.props.stepForm({ options });
      }
    }

    this.props.afterSubmit();
  };

  render() {
    const { data } = this.props;

    if (data.userData.data) {
      this.props.nextStep();
    }

    const {
      errors: {
        firstNameError,
        lastNameError,
        organisationNameError,
        organisationTypeError,
        profileImageError,
      },
    } = this.state;

    const { organisationName } = this.state;
    const { organisations } = this.state;
    const organisationsList = organisations.length > 0
      && organisations.map((item, i) => (item.createdBy === 'pre' ? (
        <option key={i} value={JSON.stringify(item)}>
          {item.name}
        </option>
      ) : (
        ''
      )), this);

    return (
      <div>
        <form autoComplete="off">
          <div className="step-section">
            <div className="panel">
              <div className="form-group">
                <label>First name</label>
                <input
                  autoFocus
                  autoComplete="false"
                  className="form-control"
                  name="firstName"
                  placeholder="What's your first name?"
                  type="text"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                />
                {!!firstNameError && this.showError(firstNameError)}
              </div>
              <div className="form-group">
                <label>Last name</label>
                <input
                  autoComplete="false"
                  className="form-control"
                  name="lastName"
                  placeholder="What's your last name?"
                  type="text"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                />
                {!!lastNameError && this.showError(lastNameError)}
              </div>
              <div className="form-group">
                <label>Profile image</label>
                <div className="file-field">
                  <input
                    accept="image/x-png,image/gif,image/jpeg"
                    name="profileImg"
                    placeholder="Upload image"
                    type="file"
                    onChange={this.onChangeFile}
                  />
                  {this.state.imageName}
                  <span>Upload</span>
                  <div className="sweet-loading">
                    <ClipLoader
                      color={'#123abc'}
                      css={override}
                      loading={this.state.loading}
                      size={40}
                      sizeUnit={'px'}
                    />
                  </div>
                </div>

                {!!profileImageError && this.showError(profileImageError)}
                <p className="error-profile">
                  {!profileImageError && this.state.profileImageErrorSize}
                </p>
              </div>
              <div className="form-group">
                <label>Organization name</label>
                <input
                  className="form-control"
                  name="organisationName"
                  placeholder="What is your organization name?"
                  type="text"
                  value={organisationName}
                  onChange={this.handleChange}
                />
                {!!organisationNameError && this.showError(organisationNameError)}
              </div>
              <div className="form-group drop-selector-new">
                <label>Organization type</label>
                <select
                  className="form-control"
                  name="organisationType"
                  value={this.state.organisationType}
                  onChange={(e) => this.handleChange1(e)}
                >
                  <option>Select your organization type</option>
                  {organisationsList}
                </select>
                <i aria-hidden="true" className="fa fa-caret-down"></i>
                {this.state.isOrganisationCustom ? (
                  <input
                    className="form-control"
                    name="organisationType1"
                    placeholder="What is your organisation type?"
                    type="text"
                    value={this.state.organisationType1}
                    onChange={this.handleChange}
                  />
                ) : (
                  ''
                )}
                {!!organisationTypeError && this.showError(organisationTypeError)}
              </div>
              <button className="btn btn-primary" onClick={this.handleSubmit}>
                Save and Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.settings,
});

export default connect(mapStateToProps, {
  logOut,
  push,
  stepForm,
})(Step1);
