import { createAction } from 'redux-actions';

export const GET_FILTER_MEMBERS = 'GET_FILTER_MEMBERS';
export const getFilterMembers = createAction(GET_FILTER_MEMBERS);

export const GET_FILTER_MEMBERS_REQUESTED = 'GET_FILTER_MEMBERS_REQUESTED';
export const getFilterMembersRequested = createAction(GET_FILTER_MEMBERS_REQUESTED);

export const GET_FILTER_MEMBERS_SUCCESS = 'GET_FILTER_MEMBERS_SUCCESS';
export const getFilterMembersSuccess = createAction(GET_FILTER_MEMBERS_SUCCESS);

export const GET_FILTER_MEMBERS_FAILURE = 'GET_FILTER_MEMBERS_FAILURE';
export const getFilterMembersFailure = createAction(GET_FILTER_MEMBERS_FAILURE);

export const DELETE_CAMPAIGN_MEMBER = 'DELETE_CAMPAIGN_MEMBER';
export const deleteCampaignMember = createAction(DELETE_CAMPAIGN_MEMBER);

export const UPDATE_INVITED_MEMBER = 'UPDATE_INVITED_MEMBER';
export const updateinvitedMember = createAction(UPDATE_INVITED_MEMBER);

export const ADD_CAMPAIGN_STEP1 = 'ADD_CAMPAIGN_STEP1';
export const addCampaignStepOne = createAction(ADD_CAMPAIGN_STEP1);

export const ADD_CAMPAIGN_STEP1_REQUESTED = 'ADD_CAMPAIGN_STEP1_REQUESTED';
export const addCampaignStepOneRequested = createAction(
  ADD_CAMPAIGN_STEP1_REQUESTED,
);

export const ADD_CAMPAIGN_STEP1_SUCCESS = 'ADD_CAMPAIGN_STEP1_SUCCESS';
export const addCampaignStepOneSuccess = createAction(ADD_CAMPAIGN_STEP1_SUCCESS);

export const ADD_CAMPAIGN_STEP1_FAILURE = 'ADD_CAMPAIGN_STEP1_FAILURE';
export const addCampaignStepOneFailure = createAction(ADD_CAMPAIGN_STEP1_FAILURE);

export const ADD_CAMPAIGN_STEP2 = 'ADD_CAMPAIGN_STEP2';
export const addCampaignStepTwo = createAction(ADD_CAMPAIGN_STEP2);

export const ADD_CAMPAIGN_STEP2_REQUESTED = 'ADD_CAMPAIGN_STEP2_REQUESTED';
export const addCampaignStepTwoRequested = createAction(
  ADD_CAMPAIGN_STEP2_REQUESTED,
);

export const ADD_CAMPAIGN_STEP2_SUCCESS = 'ADD_CAMPAIGN_STEP2_SUCCESS';
export const addCampaignStepTwoSuccess = createAction(ADD_CAMPAIGN_STEP2_SUCCESS);

export const ADD_CAMPAIGN_STEP2_FAILURE = 'ADD_CAMPAIGN_STEP2_FAILURE';
export const addCampaignStepTwoFailure = createAction(ADD_CAMPAIGN_STEP2_FAILURE);

export const ADD_CAMPAIGN_STEP3 = 'ADD_CAMPAIGN_STEP3';
export const addCampaignStepThree = createAction(ADD_CAMPAIGN_STEP3);

export const ADD_CAMPAIGN_STEP3_REQUESTED = 'ADD_CAMPAIGN_STEP3_REQUESTED';
export const addCampaignStepThreeRequested = createAction(
  ADD_CAMPAIGN_STEP3_REQUESTED,
);

export const ADD_CAMPAIGN_STEP3_SUCCESS = 'ADD_CAMPAIGN_STEP3_SUCCESS';
export const addCampaignStepThreeSuccess = createAction(ADD_CAMPAIGN_STEP3_SUCCESS);

export const ADD_CAMPAIGN_STEP3_FAILURE = 'ADD_CAMPAIGN_STEP3_FAILURE';
export const addCampaignStepThreeFailure = createAction(ADD_CAMPAIGN_STEP3_FAILURE);

export const CAMPAIGN_LISTING = 'CAMPAIGN_LISTING';
export const campListing = createAction(CAMPAIGN_LISTING);

export const IS_LIST_LOADING = 'IS_LIST_LOADING';
export const isListLoading = createAction(IS_LIST_LOADING);

export const CAMP_LIST_SUCCESS = 'CAMP_LIST_SUCCESS';
export const campListSuccess = createAction(CAMP_LIST_SUCCESS);

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const deleteCamp = createAction(DELETE_CAMPAIGN);

export const CLONE_CAMPAIGN = 'CLONE_CAMPAIGN';
export const cloneCampaign = createAction(CLONE_CAMPAIGN);

export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const getCamp = createAction(GET_CAMPAIGN);

export const SHOW_EDIT_CAMPAIGN = 'SHOW_EDIT_CAMPAIGN';
export const showEditCamp = createAction(SHOW_EDIT_CAMPAIGN);

export const START_NEW_CAMP = 'START_NEW_CAMP';
export const startNewCamp = createAction(START_NEW_CAMP);

export const STEP3_BACK_BUTTON = 'STEP3_BACK_BUTTON';
export const step3BackButton = createAction(STEP3_BACK_BUTTON);

export const SEARCH_MEMBER_REQUESTED = 'SEARCH_MEMBER_REQUESTED';
export const searchMemberRequested = createAction(SEARCH_MEMBER_REQUESTED);

export const PLAY_CAMPAIGN = 'PLAY_CAMPAIGN';
export const playCampaign = createAction(PLAY_CAMPAIGN);
