import {
  SEARCH_MEMBERS_REQUESTED,
  SEARCH_MEMBERS_SUCCESS,
  SEARCH_MEMBERS_FAILURE,
  UPDATE_SELECTED_MEMBER,
  ADD_COMMITTEE_REQUESTED,
  ADD_COMMITTEE_SUCCESS,
  ADD_COMMITTEE_FAILURE,
  FETCH_COMMITTEES_REQUESTED,
  FETCH_COMMITTEES_SUCCESS,
  FETCH_COMMITTEES_FAILURE,
  DELETE_COMMITTEE_SUCCESS,
  DELETE_COMMITTEE_FAILURE,
  GET_COMMITTEE_REQUESTED,
  GET_COMMITTEE_SUCCESS,
  GET_COMMITTEE_FAILURE,
  EDIT_COMMITTEE_FIELDS_STATE,
  EDIT_COMMITTEE_UPDATE_SELECTED_MEMBER,
  EDIT_COMMITTEE_UPDATE_ADDED_MEMBERS,
  EDIT_COMMITTEE_UPDATE_DELETED_MEMBERS,
  EDIT_COMMITTEE_REQUESTED,
  EDIT_COMMITTEE_SUCCESS,
  EDIT_COMMITTEE_FAILURE,
  TOGGLE_SEARCH_LIST,
  FETCH_COMMITTEES_BY_MEMBER_REQUESTED,
  FETCH_COMMITTEES_BY_MEMBER_SUCCESS,
  FETCH_COMMITTEES_BY_MEMBER_FAILURE,
} from 'actions/committee';

const initialState = {
  addCommitteeError: {},
  addedCommitteeMembers: [],
  committee: {},
  committeesList: [],
  committeesListByMember: [],
  deletedCommitteeMembers: [],
  editCommitteeError: {},
  editCommitteeSelectedMembers: [],
  errors: {},
  isLoading: false,
  membersList: [],
  selectedMembersList: [],
  showSearchList: false,
  totalCommittees: 0,
};

const Committee = (state = initialState, { payload, type }) => {
  switch (type) {
    case SEARCH_MEMBERS_REQUESTED:
      return {
        ...state,
        errors: {},
        isLoading: true,
        membersList: [],
      };

    case SEARCH_MEMBERS_SUCCESS:
      return {
        ...state,
        errors: {},
        isLoading: false,
        membersList: payload.response,
      };

    case SEARCH_MEMBERS_FAILURE:
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case UPDATE_SELECTED_MEMBER:
      return {
        ...state,
        selectedMembersList: payload,
      };

    case ADD_COMMITTEE_REQUESTED:
      return {
        ...state,
        addCommitteeError: {},
        isLoading: true,
      };

    case ADD_COMMITTEE_SUCCESS:
      return {
        ...state,
        addCommitteeError: {},
        isLoading: false,
        selectedMembersList: [],
      };

    case ADD_COMMITTEE_FAILURE:
      return {
        ...state,
        addCommitteeError: payload,
        isLoading: false,
      };

    case FETCH_COMMITTEES_BY_MEMBER_REQUESTED:
      return {
        ...state,
        committee: {},
        committeesListByMember: [],
        errors: {},
        isLoading: true,
      };

    case FETCH_COMMITTEES_BY_MEMBER_SUCCESS:
      return {
        ...state,
        committeesListByMember: payload.response,
        isLoading: false,
      };

    case FETCH_COMMITTEES_BY_MEMBER_FAILURE:
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case FETCH_COMMITTEES_REQUESTED:
      return {
        ...state,
        committee: {},
        errors: {},
        isLoading: true,
        selectedMembersList: [],
      };

    case FETCH_COMMITTEES_SUCCESS:
      return {
        ...state,
        committeesList: payload.response,
        errors: {},
        isLoading: false,
        totalCommittees: payload.totalCount,
      };

    case FETCH_COMMITTEES_FAILURE:
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case DELETE_COMMITTEE_SUCCESS:
      return {
        ...state,
        errors: {},
        isLoading: false,
      };

    case DELETE_COMMITTEE_FAILURE:
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };
    case GET_COMMITTEE_REQUESTED:
      return {
        ...state,
        committee: {},
        errors: {},
        isLoading: true,
      };

    case GET_COMMITTEE_SUCCESS:
      return {
        ...state,
        addedCommitteeMembers: [],
        committee: payload.response,
        deletedCommitteeMembers: [],
        editCommitteeSelectedMembers: payload.response.members,
        errors: {},
        isLoading: false,
      };

    case GET_COMMITTEE_FAILURE:
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case EDIT_COMMITTEE_FIELDS_STATE:
      return {
        ...state,
        committee: payload,
      };

    case EDIT_COMMITTEE_UPDATE_SELECTED_MEMBER:
      return {
        ...state,
        editCommitteeSelectedMembers: payload,
      };

    case EDIT_COMMITTEE_UPDATE_ADDED_MEMBERS:
      return {
        ...state,
        addedCommitteeMembers: payload,
      };

    case EDIT_COMMITTEE_UPDATE_DELETED_MEMBERS:
      return {
        ...state,
        deletedCommitteeMembers: payload,
      };

    case EDIT_COMMITTEE_REQUESTED:
      return {
        ...state,
        editCommitteeError: {},
        isLoading: true,
      };

    case EDIT_COMMITTEE_SUCCESS:
      return {
        ...state,
        committee: {},
        editCommitteeError: {},
        isLoading: false,
      };

    case EDIT_COMMITTEE_FAILURE:
      return {
        ...state,
        editCommitteeError: payload,
        isLoading: false,
      };

    case TOGGLE_SEARCH_LIST:
      return {
        ...state,
        showSearchList: payload.showSearchList,
      };

    default:
      return state;
  }
};

export default Committee;
