import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateinvitedMember } from 'actions/campaign';

class SearchMembers extends Component {
  handleEvent = (member, event) => {
    event.preventDefault();

    const updatedMembers = this.props.selectedMembers.concat(member);

    const uniqueArray = [...new Set(updatedMembers.map(({ id }) => id))].map((e) => updatedMembers.find(({ id }) => id === e));

    this.props.updateinvitedMember(uniqueArray);
  };

  render() {
    const { selectedMembers } = this.props;

    // console.log("selectedMembers .....................", selectedMembers)

    return (
      <ul className="list-dropdown-new">
        {this.props.membersList.map((eachMember) => {
          const alreadyAdded = selectedMembers.find(
            (item) => item.id === eachMember.id,
          );

          return eachMember.firstName || eachMember.lastName ? (
            <li className="selected" key={eachMember.id}>
              {eachMember.firstName} {eachMember.lastName}
              {alreadyAdded ? (
                <span className="search-list-li addbtn-new">added</span>
              ) : (
                <span
                  className="search-list-li addbtn-new"
                  onClick={(e) => this.handleEvent(eachMember, e)}
                >
                  add
                </span>
              )}
            </li>
          ) : (
            ''
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = ({ campaign }) => ({
  selectedMembers: campaign.selectedMembers,
});

export default connect(mapStateToProps, {
  updateinvitedMember,
})(SearchMembers);
