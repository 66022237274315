import React, { Component, Fragment } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import Popover from 'react-tiny-popover';
import { FormattedMessage } from 'components';
import {
  setPassword,
} from 'actions/user-authentication-action-types';
import User from 'utils/user';
import logo from 'assets/images/LogoIcon.png';

const { validatePassword } = new User();

class SetPassword extends Component {
  static propTypes = {
    error: string,
    location: shape({ search: string.isRequired }),
    setPassword: func.isRequired,
  };

  state = {
    error: '',
    isPopoverOpen: false,
    password: '',
    type: 'password',
  };

  handleChange = (event) => {
    const { value } = event.target;

    this.setState({ password: value });
  };

  handleKeyUp = () => {
    this.validatePassword();
  };

  getParams = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const email = urlParams.get('email');
    const verificationCode = urlParams.get('verification_code');
    const type = urlParams.get('type');

    return {
      email,
      type,
      verificationCode,
    };
  };

  toggleCheckbox = (isSelected) => (
    <img
      src={
        isSelected ? '/assets/images/checked-green.svg' : '/assets/images/error.svg'
      }
    />
  );

  handleKeyPress = (event) => {
    User.spaceNotAllowed(event);
  };

  validatePassword = () => {
    const { password } = this.state;

    let isValid = true;

    if (!password) {
      this.setState({ error: <FormattedMessage id="emptyPasswordValidation" /> });
      isValid = false;
    } else if (!validatePassword(password)) {
      this.setState({ error: <FormattedMessage id="passwordValidation" /> });
      isValid = false;
    } else {
      this.setState({ error: '' });
    }

    return isValid;
  };

  handleClick = () => this.setState(({ type }) => ({
    type: type === 'text' ? 'password' : 'text',
  }));

  handleSubmit = (event) => {
    event.preventDefault();
    const url = new URL(window.location.href);
    const authId = url.pathname.split('/')[2];
    const token = url.pathname.split('/')[3];
    const { password } = this.state;
    const isValid = this.validatePassword();

    if (isValid) {
      this.props.setPassword({
        authId,
        password,
        token,
      });
      this.setState({ password: '' });
    }
  };

  render() {
    const { password, error } = this.state;
    const { isPopoverOpen } = this.state;

    return (
      <Fragment>
        <div className="container">
          <div className="panel onboarding-bx">
            <img alt="logo" className="logo-sm" src={logo} />
            <h2>RESET PASSWORD</h2>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  className="form-control"
                  name="password"
                  placeholder="Create Password"
                  type={this.state.type}
                  value={password}
                  onChange={this.handleChange}
                  onKeyPress={this.handleKeyPress}
                />
                <span className="password_show" onClick={this.handleClick}>
                  {this.state.type === 'text' ? 'Hide' : 'Show'}
                </span>

                {!!error && <label className="ez-label ez-error">{error}</label>}

                <Popover
                  content={
                    <div>
                      Please enter a strong password. The password must have atleast
                      six characters long with 1 Lowercase and 1 uppercase, numeric
                      character, special symbol.
                    </div>
                  }
                  isOpen={isPopoverOpen}
                  padding={10}
                  position={['right', 'right']}
                  onClickOutside={() => this.setState({ isPopoverOpen: false })}
                >
                  <div
                    className="helpicon"
                    onClick={() => this.setState({ isPopoverOpen: !isPopoverOpen })}
                  >
                    <img src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM1Ni42MiAzNTYuNjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1Ni42MiAzNTYuNjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxwYXRoIGQ9Ik0xNzguMzEsMEM3OS45OSwwLDAsNzkuOTksMCwxNzguMzExUzc5Ljk5LDM1Ni42MiwxNzguMzEsMzU2LjYyczE3OC4zMS03OS45ODksMTc4LjMxLTE3OC4zMVMyNzYuNjMsMCwxNzguMzEsMHogICAgTTE3OC4zMSwzMjYuNjJDOTYuNTMyLDMyNi42MiwzMCwyNjAuMDg5LDMwLDE3OC4zMTFTOTYuNTMyLDMwLDE3OC4zMSwzMHMxNDguMzEsNjYuNTMyLDE0OC4zMSwxNDguMzExICAgUzI2MC4wODgsMzI2LjYyLDE3OC4zMSwzMjYuNjJ6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8cGF0aCBkPSJNMTc4LjMxLDY2Ljk0OWMtMzUuNTgsMC02NC41MjcsMjguOTQ3LTY0LjUyNyw2NC41MjdjMCw4LjI4NCw2LjcxNiwxNSwxNSwxNXMxNS02LjcxNiwxNS0xNSAgIGMwLTE5LjAzOCwxNS40ODktMzQuNTI3LDM0LjUyNy0zNC41MjdzMzQuNTI3LDE1LjQ4OSwzNC41MjcsMzQuNTI3cy0xNS40ODksMzQuNTI3LTM0LjUyNywzNC41MjdjLTguMjg0LDAtMTUsNi43MTYtMTUsMTV2MzMuOTE1ICAgYzAsOC4yODQsNi43MTYsMTUsMTUsMTVzMTUtNi43MTYsMTUtMTV2LTIwLjY3NWMyOC4zNzEtNi43NzksNDkuNTI3LTMyLjM1LDQ5LjUyNy02Mi43NjggICBDMjQyLjgzNyw5NS44OTYsMjEzLjg5LDY2Ljk0OSwxNzguMzEsNjYuOTQ5eiIgZmlsbD0iIzAwMDAwMCIvPgoJPGNpcmNsZSBjeD0iMTc4LjMxIiBjeT0iMjY1Ljg5OSIgcj0iMjAuOTk5IiBmaWxsPSIjMDAwMDAwIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" />
                  </div>
                </Popover>
              </div>
              <div className="form-group">
                <input
                  className="btn btn-primary full"
                  type="submit"
                  value="Submit"
                />
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default connect(null, {
  setPassword,
})(SetPassword);
