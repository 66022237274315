import React from 'react';
import { connect } from 'react-redux';
import socketIOClient from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThermometerEmpty, faThermometerHalf, faThermometerQuarter, faThermometerFull, faFire } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loader, TopHeader } from 'components';
import User from 'utils/user';
import { getSubscriptionsList, checkUserSubscription, updateUserSubscription, deleteUserSubscription } from 'actions/profile-info-action';
import './index.css';

const socket = socketIOClient(process.env.REACT_APP_WS_URL);

const getIconForPackage = (code) => {
  const icons = {
    free: faThermometerQuarter,
    good: faThermometerHalf,
    better: faThermometerFull,
    best: faFire,
    default: faThermometerEmpty,
  };

  return typeof icons[code] === 'undefined' ? icons.default : icons[code];
};

const getSwitchButton = (isCurrentPlan, code, onSwitched, onCancelled) => {
  if (isCurrentPlan) {
    return <button disabled className="btn btn-primary btn-current-plan" >Current Plan</button>;
  }

  if (code === 'free') {
    return <button className="btn btn-danger btn-switch-plan" onClick={onCancelled} >Downgrade</button>;
  }

  return <button className="btn btn-primary btn-switch-plan" onClick={onSwitched}>Switch</button>;
};

const PricingPackage = (props) => {
  const { name, code, monthPrice, yearPrice, monthPlanId, yearPlanId, isFeatured } = props.data;
  const { currentPlan, period, onSwitched, onCancelled } = props;

  if (!currentPlan) { return null; }

  let isCurrentPlan = false;

  if (period === 'annual' && currentPlan.plan === yearPlanId) {
    isCurrentPlan = true;
  } else if (period === 'monthly' && currentPlan.plan === monthPlanId) {
    isCurrentPlan = true;
  } else {
    isCurrentPlan = false;
  }

  const icon = getIconForPackage(code);
  const amount = code === 'free' ? 'free' : `$${period === 'monthly' ? monthPrice : yearPrice} (USD)`;

  return (
      <div className="col-md-3 col-xs-12">
        <div className={`panel panel-default price-scheme ${isFeatured ? 'featured-plan' : ''}`}>
            <div className="panel-heading price-scheme-head">
                <div className="price-scheme-icon"><FontAwesomeIcon icon={icon} /></div>
                <div className="price-scheme-name">{name}</div>
                <div className="price-scheme-amount" >{amount}</div>
            </div>
            <div className="panel-body price-scheme-body">
                <ul>
                  {props.data.package.map((p, i) => <li key={`package_${i}`}>{p}</li>)}
                </ul>
                {getSwitchButton(isCurrentPlan, code, onSwitched, onCancelled)}
            </div>
        </div>
    </div>
  );
};

class ChangeSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDowngradeConfirm: false,
      period: 'monthly',
      isProcessing: false,
    };

    this.userUtils = new User();
    this.currentUserId = this.userUtils.getLoggedInData();

    socket.on(`SubscriptionActivityChangedForUser_${this.currentUserId}`, () => {
      this.requestData();
    });
  }

  static getDerivedStateFromProps(nextProps) {
    let isProcessing = true;

    if (nextProps.userSubscription) {
      isProcessing = false;
    }

    return ({
      isProcessing,
    });
  }

  componentDidMount() {
    this.props.getSubscriptionsList();
    this.requestData();
  }

  requestData() {
    this.props.checkUserSubscription(this.currentUserId);
  }

  switchPlan(p) {
    const { period } = this.state;

    this.setState({ isProcessing: true });
    this.props.updateUserSubscription({
      subscriptionId: this.props.userSubscription.subscriptionId,
      currentSubscription: this.props.userSubscription.subscription,
      userId: this.currentUserId,
      plan: (period === 'monthly') ? p.monthPlanId : p.yearPlanId,
    });
  }

  cancelPlan = () => {
    this.props.deleteUserSubscription(this.props.userSubscription.id);
    this.setState({
      showDowngradeConfirm: false,
    });
  }

  renderPricingTable() {
    if (!this.props.subscriptions) return null;

    const packages = this.props.subscriptions.map((p) => <PricingPackage
      currentPlan={this.props.userSubscription}
      data={p}
      key={p.id}
      period={this.state.period}
      onCancelled={() => this.setState({
        showDowngradeConfirm: true,
      })}
      onSwitched={() => this.switchPlan(p)}
    />);

    return packages;
  }

  renderConfirmDowngrade = () => (
    <Modal isOpen={this.state.showDowngradeConfirm}>
      <ModalHeader>Downgrading the plan</ModalHeader>
      <ModalBody>Are you sure you want to downgrade to this plan?</ModalBody>
      <ModalFooter>
        <Button
          color="default"
          onClick={() => this.setState({
            showDowngradeConfirm: false,
          })}
        >Cancel</Button>
        <Button
          color="danger"
          onClick={this.cancelPlan}
        >Yes</Button>
      </ModalFooter>
    </Modal>
  );

  changePeriod = (e, value) => {
    e.preventDefault();
    this.setState({ period: value });
  }

  renderLoader() {
    return (
      <div className="subscription-loader">
        <Loader/>
      </div>
    );
  }

  render() {
    const { period } = this.state;

    return (
        <div className="container">
            <TopHeader hideNotice={true} />
            <div className="pricing-container">
                <div className="pricing-header">
                  <h1>Subscription Plan <span className="pricing-period">{period === 'annual' ? '( Annual )' : '( Monthly )'}</span></h1>
                  <h4>Choose the plan that you think best suits you</h4>
                </div>

                <div className="pricing-header-period">
                  <a
                    className={period === 'monthly' ? 'active' : ''}
                    href=""
                    onClick={(e) => this.changePeriod(e, 'monthly')}
                  >Monthly
                  </a> / {' '}
                  <a
                    className={period === 'annual' ? 'active' : ''}
                    href=""
                    onClick={(e) => this.changePeriod(e, 'annual')}
                  >Annual
                  </a>
                </div>

                <div className="pricing-content">
                  { this.props.subscriptions
                    ? <React.Fragment>
                        <div className="row">
                          {this.renderPricingTable()}
                        </div>
                        <br/>
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="package-footnote">Need a bigger package or more advanced/custom features? We have just what you need. <a href="https://memberaudience.com/enterprise" target="_blank">Click here</a> for more information</div>
                          </div>
                        </div>
                      </React.Fragment>
                    : ''
                  }
                </div>
                {this.renderConfirmDowngrade()}
                {this.state.isProcessing ? this.renderLoader() : null}
            </div>
        </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userSubscription: state.userProfile && state.userProfile.userSubscription ? state.userProfile.userSubscription : null,
  subscriptions: state.userProfile && state.userProfile.subscriptions ? state.userProfile.subscriptions : null,
  data: state,
});

export default connect(mapStateToProps, {
  getSubscriptionsList,
  checkUserSubscription,
  updateUserSubscription,
  deleteUserSubscription,
})(ChangeSubscription);
