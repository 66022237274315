/* eslint-disable no-unused-expressions */
/* eslint-disable filenames/match-exported */
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import User from 'utils/user';
import HttpHelper, { handleResp } from 'utils/http-helper';
import {
  EVENT_LISTING,
  eventListingRequested,
  eventListingSuccess,
  ADD_EVENT,
  addEventRequested,
  addEventFailure,
  EDIT_EVENT,
  editEventRequested,
  inviteMember,
  INVITE_MEMBER,
  INVITE_MEMBER_LIST,
  inviteMemberList,
  inviteMemberListRequested,
  inviteMemberListSuccess,
  FILTER_EVENT_LIST,
  filterEventListRequested,
  filterEventListSuccess,
  DELETE_MEMBER_EVENT,
  deleteMemberEventRequested,
  deleteMemberEventSuccess,
  UPDATE_MEMBER_EVENT,
  updateMemberEventRequested,
  USER_INVITED_EVENT_LIST,
  userInvitedEventListRequested,
  userInvitedEventListSuccess,
  EVENT_INVITED_USER_LIST,
  eventInvitedUserListRequested,
  eventInvitedUserListSuccess,
  EVENT_INVITED_DELETE_USER,
  eventInvitedDeleteUserRequested,
  eventInvitedDeleteUserSuccess,
} from 'actions/event-action';

const { deleteRequest, getRequest, postRequest, putRequest, prepareReq } = new HttpHelper();
const { authId } = new User();
const actions = (sAction, fAction = null, bAction = null) => ({ bAction, fAction, sAction });

export function* eventListingFun({ payload }) {
  yield put(eventListingRequested());
  const userId = authId();
  const response = yield call(getRequest, prepareReq(`event/list/${payload.offset}/10/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(eventListingSuccess({ data: response.data.response }));
  }
}

export function* addEvent({ payload }) {
  yield put(addEventRequested());
  const userId = authId();
  const response = yield call(postRequest, prepareReq(`event/${userId}`, payload));

  if (handleResp(response, actions(null, addEventFailure))) {
    yield put(push('/event'));
    const options = {
      body: payload.body,
      emailIndividuals: payload.emailIndividuals,
      eventId: response.data.response.id,
      members: JSON.stringify(payload.member),
      subject: payload.subject,
      title: payload.title,
    };

    yield put(inviteMember({ options }));
  }
}

export function* editEvent({ payload }) {
  yield put(editEventRequested());
  const response = yield call(putRequest, prepareReq(`event/edit/${payload.id}`, payload));

  if (yield* handleResp(response, {})) {
    const options = {
      body: payload.body,
      emailIndividuals: payload.emailIndividuals,
      eventId: payload.id,
      members: JSON.stringify(payload.member),
      subject: payload.subject,
      title: payload.title,
    };

    yield put(inviteMember({ options }));
    yield put(push('/event'));
  }
}

export function* inviteMemberFun({ payload }) {
  yield put(editEventRequested());
  const response = yield call(postRequest, prepareReq(`invite/member/${payload.options.eventId}`, payload));

  yield* handleResp(response, {});
}

export function* inviteMemberListing({ payload }) {
  yield put(inviteMemberListRequested());
  const response = yield call(getRequest, prepareReq(`event/member/list/${payload.id}`));

  if (yield* handleResp(response, {})) {
    yield put(inviteMemberListSuccess({
      data: response.data.response,
      offSet: payload.offSet }));
  }
}

export function* filterListEvent({ payload }) {
  yield put(filterEventListRequested());
  const userId = authId();
  const response = yield call(getRequest, prepareReq(`myorganisation/member/${payload.key}/${payload.type}/${payload.value}/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(filterEventListSuccess({ data: response.data.response }));
  }
}

export function* deleteMemberEvent({ payload }) {
  yield put(deleteMemberEventRequested());
  const response = yield call(deleteRequest, prepareReq(`event/member/delete/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(deleteMemberEventSuccess({ data: response.data.response }));
  }
}

export function* updateMemberEvent({ payload }) {
  yield put(updateMemberEventRequested());
  const response = yield call(getRequest, prepareReq(`invitedMember/${payload.name}/${payload.value}/${payload.id}`));

  if (yield* handleResp(response, {})) {
    const options = {
      id: payload.eventId,
      offSet: 0,
    };

    yield put(inviteMemberList(options));
  }
}

export function* eventInvitedUserList({ payload }) {
  yield put(eventInvitedUserListRequested());
  const response = yield call(getRequest, prepareReq(`event/member/list/${payload.eventId}`));

  if (yield* handleResp(response, {})) {
    yield put(eventInvitedUserListSuccess({ data: response.data.response }));
  }
}

export function* userInvitedEventList({ payload }) {
  yield put(userInvitedEventListRequested());
  const response = yield call(getRequest, prepareReq(`eventListByMember/${payload.userId}`));

  if (yield* handleResp(response, {})) {
    yield put(userInvitedEventListSuccess({ data: response.data.response }));
  }
}

export function* eventInvitedDeleteUser({ payload }) {
  yield put(eventInvitedDeleteUserRequested());
  const response = yield call(deleteRequest, prepareReq(`event/member/delete/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(eventInvitedDeleteUserSuccess({ data: response.data.response }));
  }
}

function* Settings() {
  yield [
    takeLatest(EVENT_LISTING, eventListingFun),
    takeLatest(ADD_EVENT, addEvent),
    takeLatest(EDIT_EVENT, editEvent),
    takeLatest(INVITE_MEMBER, inviteMemberFun),
    takeLatest(INVITE_MEMBER_LIST, inviteMemberListing),
    takeLatest(FILTER_EVENT_LIST, filterListEvent),
    takeLatest(DELETE_MEMBER_EVENT, deleteMemberEvent),
    takeLatest(UPDATE_MEMBER_EVENT, updateMemberEvent),
    takeLatest(USER_INVITED_EVENT_LIST, userInvitedEventList),
    takeLatest(EVENT_INVITED_USER_LIST, eventInvitedUserList),
    takeLatest(EVENT_INVITED_DELETE_USER, eventInvitedDeleteUser),
  ];
}

export default Settings;
