import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import warning from 'assets/images/warning.svg';
import { FormattedMessage } from 'components';

const authRoutesPaths = [
  '/forgotpassword',
  '/reset-password',
  '/',
  '/login',
  '/signup',
  '/paypalpay',
  '/update-profile/:key',
  '/update-email',
];

class NotFound extends Component {
  componentDidMount() {
    if (!authRoutesPaths.includes(this.props.match.url)) {
      window.location.href = '/login';
    }
  }

  render() {
    return (
      <div className="ez-wrapper">
        <div className="ez-error-page-content">
          <div className="ez-error-page">
            <img src={warning} />
            <h3>
              <FormattedMessage id="weCouldNotFindPage" />
            </h3>
            <p>
              <FormattedMessage id="errorCode404" />
            </p>
            <Link className="ez-button-small" to="/">
              <FormattedMessage id="returnToPreviousScreen" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
