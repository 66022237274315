import React from 'react';
import './Schedule.scss';
import { connect } from 'react-redux';
import { addCampaignStepThree } from 'actions/campaign';
import ScheduleInFuture from './ScheduleInFuture';
import ScheduleNow from './ScheduleNow';
import RecurringSchedule from './RecurringSchedule';

class Schedule extends React.Component {
  render() {
    const { selectedScheduleType, step3BackButtonClicked } = this.props;

    return (
      <div>
        {(selectedScheduleType === 'now' || step3BackButtonClicked) && (
          <ScheduleNow />
        )}
        {selectedScheduleType === 'once in future' && !step3BackButtonClicked && (
          <ScheduleInFuture />
        )}
        {selectedScheduleType === 'recurring schedule' && !step3BackButtonClicked && (
          <RecurringSchedule />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ campaign }) => ({
  apiErrors: campaign.updateCampaignError,
  isLoading: campaign.isLoading,
  selectedScheduleType: campaign.selectedScheduleType,
  step3BackButtonClicked: campaign.step3BackButtonClicked,
});

export default connect(mapStateToProps, {
  addCampaignStepThree,
})(Schedule);
