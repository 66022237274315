/* eslint-disable no-plusplus */
/* eslint-disable max-lines */
import React from 'react';
import './AddPerson.scss';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import leftarrow from 'assets/images/leftarrow.png';
import { FormattedMessage, TopHeader, UpgradeSubscription } from 'components';
import User from 'utils/user';
import { addPerson, checkUserUsage } from 'actions/profile-info-action';

import 'react-datepicker/dist/react-datepicker.css';

const {
  validateName,
  validateEmail,
  getUserOrgType,
} = new User();

class PersonAdd extends React.Component {
  static propTypes = {
    checkUserUsage: func.isRequired,
    logOutStatus: string,
    profileFormError: string,
    profileFormFieldsDetails: shape({
      email: string,
      name: string,
    }),
  };

  state = {
    alterEmail: '',
    collapse1: true,
    collapse2: false,
    dob: null,
    email: '',
    errors: {},
    facebook: '',
    firstName: '',
    gender: '',
    instagram: '',
    lastName: '',
    linkedin: '',
    marital: '',
    middleName: '',
    nickName: '',
    notes: '',
    optIn: true,
    setOptIn: false,
    suffix: '',
    title: '',
    twitter: '',
    parcelnumber: '',
  };

  componentDidMount() {
    this.props.checkUserUsage();
    const orgType = getUserOrgType();

    this.canHaveParcelNumber = orgType === 'Home Owners Association';
  }

  handleOptChange = () => {
    this.state.setOptIn = true;
    this.setState({
      optIn: !this.state.optIn,
    });
  };

  preventSpacedText = (event) => {
    User.spaceNotAllowed(event);
  };

  validatePrecedingSpace = (event) => {
    User.firstCharSpaceNotAllowed(event);
  };

  validateFields = () => {
    const errors = {};

    let isValid = true;

    if (this.state.title) {
      if (!validateName(this.state.title)) {
        isValid = false;
        errors.titleError = 'invalidTitleValidation';
      }
    }

    if (!this.state.firstName) {
      isValid = false;
      errors.firstNameError = 'emptyNameValidation';
    }

    if (!this.state.lastName) {
      isValid = false;
      errors.lastNameError = 'emptyLastNameValidation';
    }

    if (this.state.middleName) {
      if (!this.state.middleName) {
        isValid = false;
        errors.middleNameError = 'invalidMnameValidation';
      }
    }

    if (this.state.email) {
      if (!validateEmail(this.state.email)) {
        isValid = false;
        errors.errorEmail = 'emailValidation';
      }
    }

    if (this.state.alterEmail) {
      if (!validateEmail(this.state.alterEmail)) {
        isValid = false;
        errors.errorAlterEmail = 'emailValidation';
      }
    }

    if (this.state.nickName) {
      if (!validateName(this.state.nickName)) {
        isValid = false;
        errors.NickError = 'invalidNnameValidation';
      }
    }

    if (this.state.notes) {
      // if(!validateNotes(this.state.notes)){
      if (this.state.notes.length < 2) {
        isValid = false;
        errors.notesError = 'invalidNotesValidation';
      }
    }

    if (this.state.suffix) {
      if (!validateName(this.state.suffix)) {
        isValid = false;
        errors.SuffixError = 'invalidSuffixValidation';
      }
    }

    this.setState({ errors });

    return isValid;
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const valid = this.validateFields();

    if (valid) {
      const options = {
        altemail: this.state.alterEmail,
        dob: this.state.dob ? new Date(this.state.dob) : null,
        email: this.state.email,
        facebook: this.state.facebook,
        firstName: this.state.firstName,
        gender: this.state.gender ? this.state.gender : null,
        instagram: this.state.instagram,
        lastName: this.state.lastName,
        linkedin: this.state.linkedin,
        links: '',
        maritalStatus: this.state.marital ? this.state.marital : null,
        middleName: this.state.middleName,
        nickName: this.state.nickName,
        notes: this.state.notes,
        suffix: this.state.suffix,
        title: this.state.title,
        twitter: this.state.twitter,
      };

      if (this.canHaveParcelNumber) {
        options.parcelnumber = this.state.parcelnumber;
      }

      this.props.addPerson({ options });
    }
  };

  render() {
    const {
      errors: {
        errorEmail,
        titleError,
        firstNameError,
        notesError,
        optInError,
        maritalError,
        genderError,
        errorParcelNumber,
        middleNameError,
        SuffixError,
        NickError,
        lastNameError,
        errorAlterEmail,
      },
    } = this.state;

    if (this.props.usage && !this.props.usage.members) {
      return (<UpgradeSubscription heading="Add Person" href="/members-list" usage={this.props.usage}/>);
    }

    return (
      <div className="container">
        <TopHeader />
        <div className="csv_row heading-membership">
          <div className="btn_col">
            <a className="backarrow" href="/members-list">
              <img alt="leftarrow" src={leftarrow} />
            </a>
          </div>
          <h2>Add Person</h2>
        </div>

        <div className="panel center event col">
          <div className="panel-header">
            <h2>Member Information</h2>
            <div className="pull-right">
              {/* {this.state.collapse1 ? <a onClick={() => this.setState({ collapse1: !this.state.collapse1 })} className="btn btn-default">Edit</a> : ''} */}
              <span
                onClick={() => this.setState({ collapse1: !this.state.collapse1 })}
              >
                {this.state.collapse1 ? (
                  <i aria-hidden="true" className="fa fa-caret-up"></i>
                ) : (
                  <i aria-hidden="true" className="fa fa-caret-down"></i>
                )}
              </span>
            </div>
          </div>
          <Collapse isOpen={this.state.collapse1}>
            <form>
              <div className="form-group">
                <label>Nick Name:</label>
                <input
                  className="form-control"
                  name="nickName"
                  placeholder="What's your nickname?"
                  type="text"
                  value={this.state.nickName}
                  onChange={this.handleChange}
                  onKeyPress={this.validatePrecedingSpace}
                />
                {!!NickError && this.showError(NickError)}
              </div>
              <div className="form-group">
                <label>Title:</label>
                <input
                  className="form-control"
                  name="title"
                  placeholder="Select your title"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleChange}
                  onKeyPress={this.validatePrecedingSpace}
                />
                {!!titleError && this.showError(titleError)}
              </div>

              <div className="form-group">
                <label>First Name:</label>
                <input
                  className="form-control"
                  name="firstName"
                  placeholder="What's your first name?"
                  type="text"
                  value={this.state.firstName}
                  onChange={this.handleChange}
                  onKeyPress={this.validatePrecedingSpace}
                />
                {!!firstNameError && this.showError(firstNameError)}
              </div>

              <div className="form-group">
                <label> Middle Name:</label>
                <input
                  className="form-control"
                  name="middleName"
                  placeholder="What's your middle name?"
                  type="text"
                  value={this.state.middleName}
                  onChange={this.handleChange}
                  onKeyPress={this.validatePrecedingSpace}
                />
                {!!middleNameError && this.showError(middleNameError)}
              </div>

              <div className="form-group">
                <label>Last Name:</label>
                <input
                  className="form-control"
                  name="lastName"
                  placeholder="What's your last name?"
                  type="text"
                  value={this.state.lastName}
                  onChange={this.handleChange}
                  onKeyPress={this.validatePrecedingSpace}
                />
                {!!lastNameError && this.showError(lastNameError)}
              </div>

              <div className="form-group">
                <label>Suffix:</label>
                <input
                  className="form-control"
                  name="suffix"
                  placeholder="What's your Suffix?"
                  type="text"
                  value={this.state.suffix}
                  onChange={this.handleChange}
                  onKeyPress={this.validatePrecedingSpace}
                />
                {!!SuffixError && this.showError(SuffixError)}
              </div>

              <div className="form-group">
                <label>Email:</label>
                <input
                  className="form-control"
                  name="email"
                  placeholder="What's your email?"
                  type="text"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                {!!errorEmail && this.showError(errorEmail)}
              </div>

              <div className="form-group">
                <label>Gender:</label>
                <select
                  className="form-control"
                  name="gender"
                  value={this.state.gender}
                  onChange={this.handleChange}
                >
                  <option value="">Select your gender?</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                {!!genderError && this.showError(genderError)}
              </div>

              {
                this.canHaveParcelNumber
                  ? <div className="form-group">
                      <label>Parcel Number</label>
                      <input
                        className="form-control"
                        name="parcelnumber"
                        placeholder="123456"
                        type="text"
                        value={this.state.parcelnumber ? this.state.parcelnumber : ''}
                        onChange={this.handleChange}
                      />
                      {!!errorParcelNumber && this.showError(errorParcelNumber)}
                    </div>
                  : null
              }

              <div className="form-group">
                <label>Alternate Email</label>
                <input
                  className="form-control"
                  name="alterEmail"
                  placeholder="What's your alternate email?"
                  type="text"
                  value={this.state.alterEmail}
                  onChange={this.handleChange}
                />
                {!!errorAlterEmail && this.showError(errorAlterEmail)}
              </div>

              <div className="form-group">
                <label>Marital Status</label>
                <input
                  className="form-control"
                  name="marital"
                  placeholder="What's your marital status?"
                  type="text"
                  value={this.state.marital}
                  onChange={this.handleChange}
                />
                {!!maritalError && this.showError(maritalError)}
              </div>

              <div className="form-group db">
                <label>Date of Birth:</label>
                <input
                  className="form-control"
                  max={moment(new Date()).format('YYYY-MM-DD')}
                  name="dob"
                  placeholder="What's your date of birth?"
                  type="date"
                  value={this.state.dob ? this.state.dob : ''}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label>Linkedin</label>
                <input
                  className="form-control"
                  name="linkedin"
                  placeholder="Your Linkedin link"
                  type="text"
                  value={this.state.linkedin}
                  onChange={this.handleChange}
                  onKeyPress={this.preventSpacedText}
                />
              </div>

              <div className="form-group">
                <label>Twitter</label>
                <input
                  className="form-control"
                  name="twitter"
                  placeholder="Your Twitter link"
                  type="text"
                  value={this.state.twitter}
                  onChange={this.handleChange}
                  onKeyPress={this.preventSpacedText}
                />
              </div>

              <div className="form-group">
                <label>Facebook</label>
                <input
                  className="form-control"
                  name="facebook"
                  placeholder="Your Facebook link"
                  type="text"
                  value={this.state.facebook}
                  onChange={this.handleChange}
                  onKeyPress={this.preventSpacedText}
                />
              </div>

              <div className="form-group ">
                <label>Instagram</label>
                <input
                  className="form-control"
                  name="instagram"
                  placeholder="Your Instagram link"
                  type="text"
                  value={this.state.instagram}
                  onChange={this.handleChange}
                  onKeyPress={this.preventSpacedText}
                />
              </div>

              <div className="form-group full">
                <label>Notes</label>
                <textarea
                  className="form-control"
                  name="notes"
                  placeholder="Write your notes."
                  value={this.state.notes}
                  onChange={this.handleChange}
                  onKeyPress={this.validatePrecedingSpace}
                ></textarea>
                {!!notesError && this.showError(notesError)}
              </div>

              <div className="form-group full">
                <label>Email Opt in {this.state.optIn} </label>
                <input
                  id="yes"
                  name="optIn"
                  type="checkbox"
                  value={this.state.optIn}
                  onChange={this.handleOptChange}
                />
                <label htmlFor="yes">Yes</label>
                {!!optInError && this.showError(optInError)}
              </div>

              <button
                className="btn btn-primary"
                disabled={this.state.collapse2}
                onClick={this.handleSubmit}
              >
                Next
              </button>
            </form>
          </Collapse>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ data: state.userProfile, usage: state.userProfile.usage });

export default connect(mapStateToProps, {
  checkUserUsage,
  addPerson,
})(PersonAdd);
