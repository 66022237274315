import React from 'react';
import './ProfileEvents.scss';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { userInvitedEventList } from 'actions/event-action';

class ProfileEvents extends React.Component {
  static propTypes = {
    userInvitedEventList: func.isRequired,
    paymentForm: shape({
      email: string,
      name: string,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const userId = url.pathname.split('/')[2];

    this.props.userInvitedEventList({ userId });
  }

  renderTableHeader() {
    return (
      <React.Fragment>
        <th>EVENT</th>
        <th>LOCATION</th>
        <th>DATE</th>
        <th>ATTENDED</th>
        <th>RSVP</th>
      </React.Fragment>
    );
  }

  renderTableData() {
    if (!this.props.eventList.invitedEvents) {
      return null;
    }

    return this.props.eventList.invitedEvents.map((info) => (
        <tr key={`eventlist-${info.id}`}>
          <td>{info.title ? info.title : ''}</td>
          <td>{info.address1 ? info.address1 : ''}</td>
          <td>
            {info.date ? (
              <Moment format="MM/DD/YYYY">{info.date}</Moment>
            ) : (
              ''
            )}
          </td>
          <td>{info.InvitedMembers[0].attended ? 'Yes' : 'No'}</td>
          <td>{info.InvitedMembers[0].rsvp ? 'Yes' : 'No'}</td>
        </tr>
    ));
  }

  render() {
    return (
      <div className="info-event">
        <table className="table table-responsive table-Campaign">
          <thead>
            <tr>{this.renderTableHeader()}</tr>
          </thead>
          <tbody>{this.renderTableData()}</tbody>
        </table>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  eventList: state.event,
});

export default connect(mapStateToProps, {
  userInvitedEventList,
})(ProfileEvents);
