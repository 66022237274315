import en from 'reducers/languages/en';
import Storage from './storage';
// import fr from 'reducers/languages/fr';

class Translate {
  static translate = (key) => {
    const language = Storage.get('language');

    if (language === 'en') {
      return en.messages[key];
    }

    return en.messages[key];
  };
}

export default Translate;
