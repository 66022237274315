/* eslint-disable no-param-reassign */
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import HttpHelper, { handleResp } from 'utils/http-helper';
import User from 'utils/user';
import Storage from 'utils/storage';
import {
  STEP_FORM,
  STEP2_FORM,
  STEP3_FORM,
  step3FormFailure,
  step3FormRequested,
  step3FormSuccess,
  step2FormRequested,
  step2FormSuccess,
  stepFormRequested,
  stepFormFailure,
  stepFormSuccess,
  GET_USER_DETAILS,
  getUserDetailsRequested,
  getUserDetailsSuccess,
  UPDATE_USER_DETAILS,
  UPDATE_PAYPAL_DETAILS,
  CUSTOM_FIELD_LISTING,
  customFieldListingSuccess,
  isLoading,
  ADD_CUSTOM_FIELD,
  addCustomFieldSuccess,
  refreshCustomFieldListing,
  DELETE_CUSTOM_FIELD,
  UPDATE_EMAIL_FOOTER,
  updateEmailFooterSuccess,
} from 'actions/settings-step-action';
import { LOGIN_SERVICE_URL } from '../constants';

const { deleteRequest, getRequest, postRequest, putRequest, prepareReq } = new HttpHelper();
const { removeUserDetailsToken, authId } = new User();
const actions = (sAction, fAction = null, bAction = null) => ({ bAction, fAction, sAction });

export function* stepForm({ payload }) {
  const userId = authId();

  yield put(stepFormRequested());
  const response = yield call(putRequest, prepareReq(`account/update-organisation/${userId}`, payload.options));

  if (yield* handleResp(response, actions(stepFormSuccess, stepFormFailure))) {
    Storage.save('userDataValue', payload.options);
    Storage.save('setting-form', 'step1');
    Storage.save('userDetails', {
      ...response.data.response,
    });
    yield put(
      stepFormSuccess({
        data: true,
      }),
    );
  }
}

function* step2Form({ payload }) {
  const userId = authId();

  yield put(step2FormRequested());
  const response = yield call(putRequest, prepareReq(`account/update-subscription/${userId}`, payload));

  if (yield* handleResp(response, actions(step2FormSuccess))) {
    Storage.save('setting-form', 'step2');
    yield put(
      step2FormSuccess({
        data: true,
      }),
    );
  }
}

function* step3Form({ payload }) {
  const userId = authId();
  const payloadData = {
    data: payload,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    url: `${LOGIN_SERVICE_URL}/account/upload-file/${userId}`,
  };

  yield put(step3FormRequested());
  let data = {};

  try {
    data = yield call(postRequest, payloadData);

    if (data.status === 200) {
      toast.success('File uploaded successfully.', {
        autoClose: 1300,
      });
      Storage.save('csvpath', data.data.response.path);
      yield put(
        step3FormSuccess({
          data: true,
        }),
      );
      yield put(push('/csv-import'));
    } else if (data.status === 401) {
      removeUserDetailsToken();
      yield put(push('/'));
    }
  } catch (error) {
    yield put(step3FormFailure(error.error.response.data.message));
  }

  if (data.error) toast.error(data.error.response.data.message);
}

// eslint-disable-next-line no-unused-vars
export function* getUserDetails(payload = {}) {
  const userId = authId();

  yield put(getUserDetailsRequested());
  const response = yield call(getRequest, prepareReq(`account/details/${userId}`));

  yield* handleResp(response, actions(getUserDetailsSuccess));
}

export function* updateUserDetails({ payload }) {
  const userId = authId();
  const response = yield call(putRequest, prepareReq(`account/update-profile/${userId}`, payload));

  yield call(getUserDetails, { payload });
  yield* handleResp(response, {});
}

export function* updatePaypalDetails({ payload }) {
  const response = yield call(postRequest, prepareReq('payment/addPaypalkeys', payload));

  yield call(getUserDetails, { payload });
  yield* handleResp(response, {});
}

export function* customFieldListing({ payload: { limit, pageNo } }) {
  yield put(isLoading(true));

  const userId = authId();

  if (pageNo === 1) {
    yield put(refreshCustomFieldListing());
  }

  const response = yield call(getRequest, prepareReq(`custom-fields/${userId}/${limit}/${pageNo}`));

  yield* handleResp(response, actions(customFieldListingSuccess));
  yield put(isLoading(false));
}

export function* addCustomField({ payload }) {
  const userId = authId();

  payload = {
    ...payload,
    userId,
  };

  yield put(isLoading(true));
  const response = yield call(postRequest, prepareReq('custom-field/add/val', payload));

  yield call(customFieldListing, { payload });
  yield* handleResp(response, actions(addCustomFieldSuccess));
  yield put(isLoading(false));
}

export function* deleteCustomField({ payload }) {
  yield put(isLoading(true));
  const { id, fieldName } = payload;
  const response = yield call(deleteRequest, prepareReq(`custom-field/${id}`, { fieldName }));

  yield call(customFieldListing, { payload });
  yield* handleResp(response, actions(customFieldListing));
  yield put(isLoading(false));
}

export function* updateEmailFooter({ payload }) {
  yield put(isLoading(true));
  const userId = authId();
  const { emailFooter } = payload;
  const url = `account/email-footer/${userId}`;
  const response = yield call(putRequest, prepareReq(url, { emailFooter }));

  yield call(getUserDetails, { payload });
  yield* handleResp(response, actions(updateEmailFooterSuccess));
  yield put(isLoading(false));
}

function* Settings() {
  yield [
    takeLatest(STEP_FORM, stepForm),
    takeLatest(STEP2_FORM, step2Form),
    takeLatest(STEP3_FORM, step3Form),
    takeLatest(GET_USER_DETAILS, getUserDetails),
    takeLatest(UPDATE_USER_DETAILS, updateUserDetails),
    takeLatest(UPDATE_PAYPAL_DETAILS, updatePaypalDetails),
    takeLatest(ADD_CUSTOM_FIELD, addCustomField),
    takeLatest(CUSTOM_FIELD_LISTING, customFieldListing),
    takeLatest(DELETE_CUSTOM_FIELD, deleteCustomField),
    takeLatest(UPDATE_EMAIL_FOOTER, updateEmailFooter),
  ];
}

export default Settings;
