import {
  GET_FILTER_MEMBERS_REQUESTED,
  GET_FILTER_MEMBERS_SUCCESS,
  GET_FILTER_MEMBERS_FAILURE,
  UPDATE_INVITED_MEMBER,
  ADD_CAMPAIGN_STEP1_REQUESTED,
  ADD_CAMPAIGN_STEP1_SUCCESS,
  ADD_CAMPAIGN_STEP1_FAILURE,
  ADD_CAMPAIGN_STEP2_REQUESTED,
  ADD_CAMPAIGN_STEP2_SUCCESS,
  ADD_CAMPAIGN_STEP2_FAILURE,
  ADD_CAMPAIGN_STEP3_REQUESTED,
  ADD_CAMPAIGN_STEP3_SUCCESS,
  ADD_CAMPAIGN_STEP3_FAILURE,
  CAMP_LIST_SUCCESS,
  IS_LIST_LOADING,
  SHOW_EDIT_CAMPAIGN,
  START_NEW_CAMP,
  STEP3_BACK_BUTTON,
  SEARCH_MEMBER_REQUESTED,
} from 'actions/campaign';

const initialState = {
  addCampaignError: {},
  campList: [],
  errors: {},
  isListLoading: false,
  isLoading: false,
  onceInFutureForm: {},
  recurringForm: {},
  selectedMembers: [],
  selectedScheduleType: 'now',
  step: 1,
  step2Complete: false,
  step3BackButtonClicked: false,
  stepOneValues: {},
  stepTwoValues: {},
  updateCampaignError: {},
};
// Second Step data
const secondStep = (payload) => ({
  campaignId: payload.id,
  emailBody: payload.emailBody,
  emailSubject: payload.emailSubject,
  requestInformateUpdate: payload.requestInformateUpdate || false,
});

const onceInFuture = (payload) => ({
  futureDate: payload.futureDate,
  hour: payload.futureHour,
  minute: payload.futureMinute,
  timeFormat: payload.futureTimeFormat,
});

const recurringForm = (payload) => ({
  numberOfOccurences: payload.numberOfOccurences,
  recurringStartDate: payload.recurringStartDate,
  recurringType: payload.recurringType,
});

const Campaign = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_FILTER_MEMBERS_REQUESTED:
      return {
        ...state,
        errors: {},
        isLoading: true,
      };

    case GET_FILTER_MEMBERS_SUCCESS:
      return {
        ...state,
        errors: {},
        isLoading: false,
        selectedMembers: payload,
      };

    case GET_FILTER_MEMBERS_FAILURE:
      return {
        ...state,
        errors: payload,
        isLoading: false,
      };

    case UPDATE_INVITED_MEMBER:
      return {
        ...state,
        selectedMembers: payload,
      };

    case ADD_CAMPAIGN_STEP1_REQUESTED:
      return {
        ...state,
        addCampaignError: {},
        isLoading: true,
        stepOneValues: {},
      };

    case ADD_CAMPAIGN_STEP1_SUCCESS:
      return {
        ...state,
        addCampaignError: {},
        isLoading: false,
        step: 2,
        stepOneValues: payload,
      };

    case ADD_CAMPAIGN_STEP1_FAILURE:
      return {
        ...state,
        addCampaignError: payload,
        isLoading: false,
        stepOneValues: {},
      };

    case ADD_CAMPAIGN_STEP2_REQUESTED:
      return {
        ...state,
        isLoading: true,
        step2Complete: false,
        stepTwoValues: {},
        updateCampaignError: {},
      };

    case ADD_CAMPAIGN_STEP2_SUCCESS:
      return {
        ...state,
        isLoading: false,
        step: 3,
        step2Complete: true,
        step3BackButtonClicked: true,
        stepTwoValues: payload,
        updateCampaignError: {},
      };

    case ADD_CAMPAIGN_STEP2_FAILURE:
      return {
        ...state,
        isLoading: false,
        step2Complete: false,
        stepTwoValues: {},
        updateCampaignError: payload,
      };

    case ADD_CAMPAIGN_STEP3_REQUESTED:
      return {
        ...state,
        isLoading: true,
        updateCampaignError: {},
      };

    case ADD_CAMPAIGN_STEP3_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedScheduleType: payload.scheduleType,
        step3BackButtonClicked: false,
        updateCampaignError: {},
      };

    case ADD_CAMPAIGN_STEP3_FAILURE:
      return {
        ...state,
        isLoading: false,
        updateCampaignError: payload,
      };

    case IS_LIST_LOADING:
      return {
        ...state,
        isListLoading: true,
      };

    case CAMP_LIST_SUCCESS:
      return {
        ...state,
        campList: payload,
        isListLoading: false,
      };

    case SHOW_EDIT_CAMPAIGN:
      return {
        ...state,
        onceInFutureForm: onceInFuture(payload),
        recurringForm: recurringForm(payload),
        selectedMembers: payload.members,
        selectedScheduleType: payload.scheduleType,
        step: 1,
        stepOneValues: payload,
        stepTwoValues: secondStep(payload),
      };

    case START_NEW_CAMP:
      return {
        ...state,
        ...initialState,
      };

    case STEP3_BACK_BUTTON:
      return {
        ...state,
        step3BackButtonClicked: true,
      };

    case SEARCH_MEMBER_REQUESTED:
      return {
        ...state,
        addCampaignError: {},
      };

    default:
      return state;
  }
};

export default Campaign;
