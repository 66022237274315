import React, { Component } from 'react';
import { connect } from 'react-redux';
import leftarrow from 'assets/images/leftarrow.png';
import TopHeader from './TopHeader';
import Link from './Link';
import './UpgradeSubscription.scss';

class UpgradeSubscription extends Component {
  render() {
    if (!this.props.usage) { return null; }

    const noticeText = <div><strong>Sorry!</strong> You have reached your limit to add new resources. Please <Link className="btn-link" href="/change-subscription" style={{ color: '#000', borderColor: '#000', textDecoration: 'none' }} >upgrade your account</Link> to higher one</div>;

    if (this.props.justNotice) {
      return (
        <div className={`alert${this.props.focusIt ? ' focused' : ''}`} role="alert" style={{
          color: '#8a6d3b',
          backgroundColor: '#efe9cd',
          borderColor: '#f9e26a',
          margin: '10px 0',
        }} >
          <div style={{ textAlign: 'center' }}>{noticeText}</div>
        </div>
      );
    }

    return (
      <div className="container">
          <TopHeader />
          <div className="csv_row heading-membership">
            <div className="btn_col">
              <a className="backarrow" href={this.props.href}>
                <img alt="leftarrow" src={leftarrow} />
              </a>
            </div>
            <h2>{this.props.heading}</h2>
          </div>
          <div className="panel center event col">
            <div className="panel-body">{noticeText}</div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {})(UpgradeSubscription);
