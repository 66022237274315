import {
  EVENT_LISTING_SUCCESS,
  EVENT_LISTING_FAILURE,
  EVENT_LISTING_REQUESTED,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_REQUESTED,
  ADD_EVENT_FAILURE,
  INVITE_MEMBER_REQUESTED,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAILURE,
  INVITE_MEMBER_LIST_REQUESTED,
  INVITE_MEMBER_LIST_SUCCESS,
  INVITE_MEMBER_LIST_FAILURE,
  FILTER_EVENT_LIST_SUCCESS,
  FILTER_EVENT_LIST_FAILURE,
  FILTER_EVENT_LIST_REQUESTED,
  DELETE_MEMBER_EVENT_REQUESTED,
  DELETE_MEMBER_EVENT_SUCCESS,
  DELETE_MEMBER_EVENT_FAILURE,
  UPDATE_MEMBER_EVENT_SUCCESS,
  UPDATE_MEMBER_EVENT_REQUESTED,
  UPDATE_MEMBER_EVENT_FAILURE,
  USER_INVITED_EVENT_LIST_REQUESTED,
  USER_INVITED_EVENT_LIST_SUCCESS,
  USER_INVITED_EVENT_LIST_FAILURE,
  EVENT_INVITED_USER_LIST_REQUESTED,
  EVENT_INVITED_USER_LIST_SUCCESS,
  EVENT_INVITED_USER_LIST_FAILURE,
  EVENT_INVITED_DELETE_USER_REQUESTED,
  EVENT_INVITED_DELETE_USER_SUCCESS,
  EVENT_INVITED_DELETE_USER_FAILURE,
} from 'actions/event-action';

const initialState = {
  addEvent: '',
  deleteUser: [],
  eventInviteList: [],
  eventList: [],
  eventListByMember: [],
  memberEventUpdate: '',
  memberList: [],
  memberListing: [],
};

const resetMemberView = {
  memberViewError: null,
  memberViewStatus: null,
};

const Event = (state = initialState, { type, payload }) => {
  switch (type) {
    case EVENT_LISTING_SUCCESS:
      return {
        ...state,
        eventList: payload,
        ...resetMemberView,
      };
    case EVENT_LISTING_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case EVENT_LISTING_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        addEvent: payload,
        ...resetMemberView,
      };
    case ADD_EVENT_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case ADD_EVENT_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };
    case INVITE_MEMBER_SUCCESS:
      return {
        ...state,
        addEvent: payload,
        ...resetMemberView,
      };
    case INVITE_MEMBER_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case INVITE_MEMBER_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };
    case INVITE_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        memberList:
          payload.offSet === 0 ? payload.data : state.memberList.concat(payload.data),
        ...resetMemberView,
      };
    case INVITE_MEMBER_LIST_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case INVITE_MEMBER_LIST_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };
    case FILTER_EVENT_LIST_SUCCESS:
      return {
        ...state,
        memberList: state.memberList.concat(payload.data),
        ...resetMemberView,
      };
    case FILTER_EVENT_LIST_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case FILTER_EVENT_LIST_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };

    case DELETE_MEMBER_EVENT_SUCCESS:
      return {
        ...state,
        memberList: payload.data,
        ...resetMemberView,
      };
    case DELETE_MEMBER_EVENT_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case DELETE_MEMBER_EVENT_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };
    case UPDATE_MEMBER_EVENT_SUCCESS:
      return {
        ...state,
        memberEventUpdate: payload.data,
        ...resetMemberView,
      };
    case UPDATE_MEMBER_EVENT_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case UPDATE_MEMBER_EVENT_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };
    case USER_INVITED_EVENT_LIST_SUCCESS:
      return {
        ...state,
        invitedEvents: payload.data,
        ...resetMemberView,
      };
    case USER_INVITED_EVENT_LIST_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case USER_INVITED_EVENT_LIST_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };

    case EVENT_INVITED_USER_LIST_SUCCESS:
      return {
        ...state,
        invitedUsers: payload.data,
        ...resetMemberView,
      };

    case EVENT_INVITED_USER_LIST_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };

    case EVENT_INVITED_USER_LIST_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };

    case EVENT_INVITED_DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUser: payload.data,
        ...resetMemberView,
      };
    case EVENT_INVITED_DELETE_USER_FAILURE:
      return {
        ...state,
        ...resetMemberView,
      };
    case EVENT_INVITED_DELETE_USER_REQUESTED:
      return {
        ...state,
        ...resetMemberView,
      };

    default:
      return state;
  }
};

export default Event;
