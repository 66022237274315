import React, { useEffect } from 'react';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { Dashboard, UserAuthentication } from 'pages';
import User from 'utils/user';
import ReduxConnectedIntlProvider from '../redux-connected-intl-provider';

// Array of routes
import Router from './routes';
import history from './history';

// Store
import configureStore from '../store';

// Setting history in store
const store = configureStore(history);
const { isAuthenticated } = new User();

ReactGA.initialize('UA-180198413-1');

addLocaleData([...en]);

// get routes for logged in or logged out user
const getRouting = (routeType) => Router[routeType].map((route, index) => {
  const { component: RouteComponent, routes, exact, path, title } = route;

  return (
      <Route
        exact={exact}
        key={index}
        path={path}
        render={(props) => (
          <RouteComponent {...props} routes={routes} title={title} />
        )}
      />
  );
});

// setting stores and history
const Routes = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => (history.listen((location) => {
    window._mfq.push(['newPageView', location.pathname]);
  })), [history]);

  return (
    <Provider store={store}>
      <ReduxConnectedIntlProvider>
        <ConnectedRouter history={history}>
          <Switch>
            <Route
              exact={false}
              path="/"
              render={() => (isAuthenticated() ? (
                  <Dashboard>
                    <Switch>{getRouting('routes')}</Switch>
                  </Dashboard>
              ) : (
                  <UserAuthentication>
                    <Switch>{getRouting('authRoutes')}</Switch>
                  </UserAuthentication>
              ))
              }
            />
          </Switch>
        </ConnectedRouter>
      </ReduxConnectedIntlProvider>
    </Provider>
  );
};

export default Routes;
