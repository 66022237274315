import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcAmex, faCcDinersClub, faCcDiscover, faCcJcb } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import socketIOClient from 'socket.io-client';
// import CreditCard from 'assets/creditcard';
/* eslint-disable import/extensions */
import { isValid } from 'creditcard.js';
/* eslint-enable import/extensions */
import User from 'utils/user';
import { FormattedMessage, Loader } from 'components';
import './Card.css';

import { addCreditCard, getCreditCard, deleteCreditCard, updateCreditCard } from 'actions/payments-action';

const socket = socketIOClient(process.env.REACT_APP_WS_URL);

const {
  // validateCardNumber,
  validateCardCVV,
  validationExpMonth,
  validationExpYear,
  getLoggedInData,
} = new User();

class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddCardModal: false,
      cardName: '',
      cardNumber: '',
      cardCVV: '',
      cardMonth: '',
      cardYear: '',
      errors: {},
      defaultCard: null,
    };
    this.minYear = new Date().getFullYear();
    this.maxYear = this.minYear + 10;
    this.userUtils = new User();
    this.currentUserId = this.userUtils.getLoggedInData();

    socket.on(`SubscriptionActivityChangedForUser_${this.currentUserId}`, () => {
      console.log('Get Credit Card');
      this.props.getCreditCard();
    });
  }

  componentDidMount() {
    this.props.getCreditCard();
  }

  validateCardInput = () => {
    const errors = {};

    let canProceed = true;
    const validatedCard = isValid(this.state.cardNumber);

    if (!this.state.cardName) {
      canProceed = false;
      errors.cardNameError = 'emptyCardName';
    }

    if (!this.state.cardNumber) {
      canProceed = false;
      errors.cardNumberError = 'emptyCardNumber';
    } else if (!validatedCard) {
      canProceed = false;
      errors.cardNumberError = 'emptyCardNumberInvalid';
    }

    if (!this.state.cardCVV) {
      canProceed = false;
      errors.cardCVVError = 'emptyCardCVV';
    } else if (!validateCardCVV(this.state.cardCVV)) {
      canProceed = false;
      errors.cardCVVError = 'invalidCardNumberInvalid';
    }
    if (!this.state.cardMonth) {
      canProceed = false;
      errors.expMonthError = 'emptyExpireMonth';
    } else if (!validationExpMonth(this.state.cardMonth)) {
      canProceed = false;
      errors.expMonthError = 'invalidExpireMonth';
    } else if (
      !(this.state.cardMonth < 13 || this.state.cardMonth === 0)
      && this.state.cardMonth === '00'
    ) {
      canProceed = false;
      errors.expMonthError = 'invalidExpireMonth';
    } else if (this.state.cardMonth === '00') {
      canProceed = false;
      errors.expMonthError = 'invalidExpireMonth';
    } else if (this.state.cardMonth > 12) {
      canProceed = false;
      errors.expMonthError = 'invalidExpireMonth';
    }
    if (!this.state.cardYear) {
      canProceed = false;
      errors.expYearError = 'emptyExpYear';
    } else if (
      !validationExpYear(this.state.cardYear)
      || this.state.cardYear === '0000'
    ) {
      canProceed = false;
      errors.expYearError = 'invalidExpYear';
    }

    this.setState({ errors });

    return canProceed;
  }

  handleFormChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmission = () => {
    const canProceed = this.validateCardInput();

    if (!canProceed) { return false; }

    const payload = {
      cardName: this.state.cardName,
      cardNumber: this.state.cardNumber,
      expiryMonth: this.state.cardMonth,
      expiryYear: this.state.cardYear,
      cardCCV: this.state.cardCVV,
      userId: getLoggedInData(),
    };

    this.setState({
      showAddCardModal: false,
      cardName: '',
      cardNumber: '',
      cardCVV: '',
      cardMonth: '',
      cardYear: '',
      defaultCard: null,
    });

    this.props.addCreditCard(payload);

    return false;
  }

  removeCard = (card) => {
    this.props.deleteCreditCard(card.id);
  }

  setAsDefault = (card) => {
    const payload = {
      id: card.id,
      isDefault: true,
    };

    this.setState({ defaultCard: card.id });

    this.props.updateCreditCard(payload);
  }

  showError = (error) => <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>;

  getIconByBrand = (brand) => {
    switch (brand) {
      case 'MasterCard':
        return faCcMastercard;
      case 'American Express':
        return faCcAmex;
      case 'Discover':
        return faCcDiscover;
      case 'Diners Club':
        return faCcDinersClub;
      case 'JCB':
        return faCcJcb;
      case 'Visa':
        return faCcVisa;
      default:
        return faCreditCard;
    }
  }

  isCardChecked = (card) => {
    let ret = false;

    if (this.state.defaultCard === card.id) {
      ret = true;
    } else if (!this.state.defaultCard && card.isDefault) {
      ret = true;
    }

    return ret;
  }

  renderLoader() {
    return (
      <div className="card-loader">
        <Loader/>
      </div>
    );
  }

  renderCardForm = () => {
    const {
      errors: {
        cardNameError,
        cardNumberError,
        cardCVVError,
        expMonthError,
        expYearError,
      },
    } = this.state;

    return (
      <Row style={{ position: 'relative' }}>
        <Col xs="12">
          <FormGroup>
            <Label htmlFor="card-name">Card Name</Label>
            <Input required id="card-name" name="cardName" type="text" value={this.state.cardName} onChange={this.handleFormChange} />
            {!!cardNameError && this.showError(cardNameError)}
          </FormGroup>
        </Col>
        <Col xs="12">
          <FormGroup>
            <Label htmlFor="card-number">Card Number</Label>
            <Input required id="card-number" name="cardNumber" type="number" value={this.state.cardNumber} onChange={this.handleFormChange} />
            {!!cardNumberError && this.showError(cardNumberError)}
          </FormGroup>
        </Col>
        <Col sm="3" xs="12" >
          <FormGroup>
            <Label htmlFor="card-month">Month</Label>
            <Input required id="card-month" max="12" min="1" name="cardMonth" type="number" value={this.state.cardMonth} onChange={this.handleFormChange} />
            {!!expMonthError && this.showError(expMonthError)}
          </FormGroup>
        </Col>
        <Col sm="3" xs="12" >
          <FormGroup>
            <Label htmlFor="card-year">Year</Label>
            <Input required id="card-year" max = {this.maxYear} min = {this.minYear} name="cardYear" type="number" value={this.state.cardYear} onChange={this.handleFormChange} />
            {!!expYearError && this.showError(expYearError)}
          </FormGroup>
        </Col>
        <Col sm="6" xs="12">
          <FormGroup>
            <Label htmlFor="card-cvv">CVV</Label>
            <Input required id="card-cvv" name="cardCVV" type="number" value={this.state.cardCVV} onChange={this.handleFormChange} />
            {!!cardCVVError && this.showError(cardCVVError)}
          </FormGroup>
        </Col>
      </Row>
    );
  };

  renderAddNewCard = () => <Modal
        centered
        className='add-new-card-modal'
        isOpen={this.state.showAddCardModal}
        size="lg"
      >
        <ModalHeader>Add a new Card</ModalHeader>
        <ModalBody>{this.renderCardForm()}</ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" onClick={this.handleSubmission}>Add</Button>{' '}
          <Button size="sm" onClick={() => this.setState({ showAddCardModal: false })}>Cancel</Button>
    </ModalFooter>
  </Modal>

  renderCard = (card) => (
    <Col key={`card_${card.cardId}`}sm="4" xs="12">
      <div className="debit-card">
        <div className="d-flex flex-column h-100">
          <label className="d-block" htmlFor={`check_${card.id}`}>
            <div className="d-flex position-relative">
                <div>
                  <FontAwesomeIcon icon={this.getIconByBrand(card.brand)} style={{ fontSize: '50px', color: '#fff' }} />
                  <p className="mt-2 mb-4 text-white fw-bold">{card.name}</p>
                </div>
                <div className={`input ${card.isDefault ? 'default' : ''}`}>
                  {card.isDefault ? <span className='default-label'>Default</span> : null}
                  <input
                    checked={this.isCardChecked(card) ? 'checked' : ''}
                    id={`check_${card.id}`}
                    name="card"
                    type="radio"
                    onChange={() => this.setAsDefault(card)}
                  />
                </div>
            </div>
          </label>
          <div className="mt-auto fw-bold d-flex align-items-center justify-content-between">
              <p>**** **** **** {card.last4}</p>
              <p>{card.exp_month}/{card.exp_year}</p>
          </div>
        </div>
      </div>
      <div className="card-action">
        <Button onClick={() => this.removeCard(card)}>Remove</Button>
      </div>
    </Col>
  );

  renderCards = () => {
    const { payment: { cardList } } = this.props;

    if (!cardList) { return null; }

    return cardList.map((card) => this.renderCard(card));
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <Row>
          <Col>
            <h3>Cards {' '}
                <a
                  href=""
                  onClick={(e) => { e.preventDefault(); this.setState({ showAddCardModal: true }); }}
                >[Add New]  </a>
              </h3>
          </Col>
        </Row>
        <Row>
          {this.renderCards()}
          {this.renderAddNewCard()}
        </Row>
        {this.props.isLoading && !this.props.cardList ? this.renderLoader() : null}
        <br/>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.payment.isProcessing,
  userDetails: state.settings.userDetails,
  ...state,
});

export default connect(mapStateToProps, {
  addCreditCard,
  getCreditCard,
  updateCreditCard,
  deleteCreditCard,
})(Cards);
