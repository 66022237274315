import React from 'react';
import './ProfileMain.scss';
import Moment from 'react-moment';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
// import GoogleMapReact from 'google-map-react';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import {
  addTags,
  tagsList,
  removeTags,
  updateMemberInfo,
  searchTags,
  searchTagsRequested,
  memberListView,
} from 'actions/profile-info-action';
import User from 'utils/user';
import leftarrow from 'assets/images/leftarrow.png';
import DataVerification from './DataVerification';
import api from '../SettingStepProfile/Views/Services';

/* const AnyReactComponent = () => (
  <div className="mark-div">
    <img className="marker-img" src="images/marker.png" />
  </div>
); */

const { authUser, authUserValue } = new User();
const Userimg = 'https://erpsys-dev.s3.amazonaws.com/dummie.png';

class ProfileMain extends React.Component {
  static propTypes = {
    addTags: func.isRequired,
    removeTags: func.isRequired,
    profileFormError: string,
    profileFormFieldsDetails: shape({
      email: string,
      name: string,
    }),
    tagsList: func.isRequired,
  };

  /* static defaultProps = {
    center: {
      lat: 39.2849724,
      lng: -103.3780544,
    },
    setCenter: {
      lat: 39.2849724,
      lng: -103.3780544,
    },
    zoom: 4,
  }; */

  state = {
    alreadyExist: true,
    emptyValue: true,
    openDelete: false,
    openDeleteTag: false,
    searchKeyword: '',
    selectedFile: null,
    selectedTag: null,
    tagName: '',
    openVerificationModel: false,
  };

  componentDidMount() {
    const url = new URL(window.location.href);
    const userId = url.pathname.split('/')[2];

    // this.getLocation();
    this.props.tagsList(userId);
    // let scoll= localStorage.getItem("scoll-up");
    window.scrollTo(0, 0);
  }

  cancelNotesModal = () => {
    this.setState({ openDelete: false });
  };

  handleKeyPress1 = (event) => {
    User.firstCharSpaceNotAllowed(event);
  };

  openAddTags = () => {
    this.setState({ tagName: '' });
    this.setState({ emptyValue: true });
    this.setState({
      tags: this.props.userData.tags ? this.props.userData.tags.split(',') : [],
    });
    this.setState({ alreadyExist: true });
    this.setState({ openDelete: true });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });

    event.preventDefault();
    let keyword = event.target.value.trim();

    document.getElementById('main-tag-list').style.display = 'block';

    const checkHtmlTags = /(<([^>]+)>)/gi.test(keyword);

    if (keyword && !checkHtmlTags) {
      this.props.searchTags(keyword);
    }

    if (checkHtmlTags) {
      keyword = '';
    }

    this.setState({
      searchKeyword: keyword,
    });
  };

  addTags = () => {
    const url = new URL(window.location.href);
    const userId = url.pathname.split('/')[2];
    const available = this.state.tags.indexOf(this.state.tagName);

    if (this.state.tagName.length !== 0) {
      this.setState({ emptyValue: true });
      if (available === -1) {
        this.setState({ alreadyExist: true });
        this.state.tags.push(this.state.tagName);

        const commaString = this.state.tags.toString();

        // this.props.updateMemberInfo({ options });
        if (userId && commaString) {
          const options = {
            memberId: userId,
            tagName: commaString,
          };

          this.props.addTags(options);
          this.setState({ openDelete: !this.state.openDelete });
        }
      } else {
        this.setState({ alreadyExist: false });
      }
    } else {
      this.setState({ emptyValue: false });
    }
  }

  /* getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  } */

  /* showPosition = (position) => {
    this.setState({ let: position.coords.latitude });
    this.setState({ long: position.coords.longitude });
  } */

  tagRemoveRequested = (tag) => {
    this.setState({
      openDeleteTag: true,
      selectedTag: tag,
    });
  }

  cancelDeleteRequest = () => {
    this.setState({
      openDeleteTag: false,
      selectedTag: null,
    });
  }

  deleteTag = () => {
    this.props.removeTags(this.state.selectedTag);
    // console.log('Delete request: ', this.state.selectedTag);
    this.setState({
      openDeleteTag: false,
      selectedTag: null,
    });
  }

  onChangeFile = (e) => {
    this.fileUpload(e.target.files[0]);
  }

  // On file upload (click the upload button)
  fileUpload = (file) => {
    console.log('_++_+_+_+_+', file);
    const formData = new FormData();

    formData.append('profileImg', file);
    console.log('++___+_+_+', this.props.userData.id);
    const memberId = this.props.userData.id;

    api.memberImageUpload(formData, memberId).then(() => {
      toast.success('Member profile updated successfully.');
      this.props.memberListView(this.props.userData.id);
    });
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {this.state.selectedFile.name}</p>
          <p>File Type: {this.state.selectedFile.type}</p>
          <p>
            Last Modified: {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    }

    return <h4>Choose before Clicking button Upload </h4>;
  };

  addTagsItem = (e) => {
    this.setState({ tagName: e.target.getAttribute('name') });
    document.getElementById('main-tag-list').style.display = 'none';
  }

  render() {
    const { searchKeyword } = this.state;

    const member = this.props.userData;
    // const member1 = this.props.userDataInfo;

    const userData = authUser();
    const userValue = authUserValue();
    const { profileData } = this.props;

    /* if (
      member1.memberInfo.addressInfo.data
      && member1.memberInfo.addressInfo.data.latitude
    ) {
      const ctr = {
        let: parseInt(member1.memberInfo.addressInfo.data.latitude, 10),
        lng: parseInt(member1.memberInfo.addressInfo.data.longitude, 10),
      };

      this.onFileUpload111(ctr);
    } */

    let searchTagsList = [];

    if (profileData) {
      // eslint-disable-next-line prefer-destructuring
      searchTagsList = profileData.userProfile.searchTagsList;
    }

    return (
      <div className="sm profile-page">
        <div className="csv_row heading-membership">
          <div className="btn_col">
            <a className="backarrow" href="/members-list">
              <img alt="leftarrow" src={leftarrow} />
            </a>
          </div>
          <h2>Edit Profile</h2>
          <div className="btn_col last">
            <p>
              Last updated on{' '}
              {member && member.updatedAt ? (
                <Moment format="MMMM, D YYYY">{member.updatedAt}</Moment>
              ) : (
                ''
              )}{' '}
              by{' '}
              <span className="capitalize-text">
                {userData.user.firstName
                  ? userData.user.firstName
                  : userValue.firstName}{' '}
                {userData.user.lastName
                  ? userData.user.lastName
                  : userValue.lastName}
              </span>
            </p>
          </div>
        </div>
        { Object.keys(member.pendingUpdate).length ? <div className='alert alert-info'><strong>Alert!!</strong> There is a new change request for this profile. <a className='btn-link text-dark cursor-pointer' onClick={() => this.setState({ openVerificationModel: true })}>Click here</a> to view and verify</div> : null }
        <div className="profile_bx">
          <div className="profile_left panel">
            <div className="profilepic">
              <img
                alt="Userimg"
                src={member && member.profileImg ? member.profileImg : Userimg}
              />
            </div>
            <div>
              <input type="file" onChange={this.onChangeFile} />
            </div>
          </div>
          <div className="profile_right panel">
            <h1>{member && member.firstName ? member.firstName : ''}{' '}
            {member && member.lastName ? member.lastName : ''}</h1>
            <br/>
            <h2>Tags</h2>
            <div className="tags">
              <ul>
                {profileData && profileData.userProfile && profileData.userProfile.tagList
                  ? profileData.userProfile.tagList.map(
                    (tag) => tag && (
                          <li key={`tag-${tag.id}`}>
                            <a className="capitalize-text" href="#" onClick={() => this.tagRemoveRequested(tag)}>{tag.tagName}</a>
                          </li>
                    ),
                  )
                  : null}
                <li>
                  <a className="active" onClick={this.openAddTags}>
                    <i className="fa fa-plus"></i>
                  </a>
                </li>
              </ul>
            </div>
            <Modal
              center
              open={this.state.openDeleteTag}
              onClose={this.cancelDeleteRequest}
            >
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2>Delete Tag</h2>
                </div>
                <div className="model-content eng">
                  <div>
                    <p>Are you sure you want to remove this tag?</p>
                  </div>
                  <div className="center-btn">
                    <button
                      className="btn btn-primary text-uppercase btn-big"
                      onClick={this.cancelDeleteRequest}
                    >
                      I have changed my mind
                    </button>
                    <button
                      className="btn btn-danger text-uppercase btn-big margin-btn"
                      onClick={this.deleteTag}
                    >
                      Please Proceed
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              center
              open={this.state.openDelete}
              onClose={this.cancelNotesModal}
            >
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2>Tag Name</h2>
                </div>
                <div className="model-content eng">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="tagName"
                      placeholder="Tag Name"
                      type="text"
                      value={this.state.tagName}
                      onChange={this.handleChange}
                    />
                    <ul id="main-tag-list">
                      {searchTagsList
                        && searchTagsList.map((item) => (item.includes(searchKeyword) ? (
                            <li name={item} onClick={this.addTagsItem}>
                              {item}
                            </li>
                        ) : (
                          ''
                        )))}
                    </ul>

                    {this.state.emptyValue ? (
                      this.state.emptyValue
                    ) : (
                      <p className="error-msg">This field is required.</p>
                    )}
                    {this.state.alreadyExist ? (
                      this.state.alreadyExist
                    ) : (
                      <p className="error-msg">Tag already exist.</p>
                    )}
                  </div>
                  <div className="center-btn">
                    <button
                      className="btn btn-primary margin-btn"
                      onClick={this.cancelNotesModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary margin-btn"
                      onClick={this.addTags}
                    >
                      Add Tag
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            <DataVerification member={member} open={this.state.openVerificationModel} onClose={() => this.setState({ openVerificationModel: false })} />
            {/* <h2>My Location</h2>
            <div className="map">
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBfwQm6qQXkKa8JwckqWXAZNu_gSa0paeo' }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
              >
                {member1.memberInfo.addressInfo.data
                && member1.memberInfo.addressInfo.data.latitude ? (
                  <AnyReactComponent
                    lat={
                      member1.memberInfo.addressInfo.data
                        ? member1.memberInfo.addressInfo.data.latitude
                        : ''
                    }
                    lng={
                      member1.memberInfo.addressInfo.data
                        ? member1.memberInfo.addressInfo.data.longitude
                        : ''
                    }
                    text="A"
                  />
                  ) : (
                    ''
                  )}
                {member1.memberInfo.workInfo.data
                && member1.memberInfo.workInfo.data.latitude ? (
                  <AnyReactComponent
                    lat={
                      member1.memberInfo.workInfo.data
                        ? member1.memberInfo.workInfo.data.latitude
                        : ''
                    }
                    lng={
                      member1.memberInfo.workInfo.data
                        ? member1.memberInfo.workInfo.data.longitude
                        : ''
                    }
                    text="B"
                  />
                  ) : (
                    ''
                  )}
              </GoogleMapReact>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state,
});

export default connect(mapStateToProps, {
  addTags,
  removeTags,
  memberListView,
  searchTags,
  searchTagsRequested,
  tagsList,
  updateMemberInfo,
})(ProfileMain);
