/* eslint-disable no-unused-expressions */
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import User from 'utils/user';
import HttpHelper, { handleResp } from 'utils/http-helper';
import {
  GET_FILTER_MEMBERS,
  getFilterMembersRequested,
  getFilterMembersSuccess,
  getFilterMembersFailure,
  DELETE_CAMPAIGN_MEMBER,
  updateinvitedMember,
  ADD_CAMPAIGN_STEP1,
  addCampaignStepOneRequested,
  addCampaignStepOneSuccess,
  addCampaignStepOneFailure,
  ADD_CAMPAIGN_STEP2,
  addCampaignStepTwoRequested,
  addCampaignStepTwoSuccess,
  addCampaignStepTwoFailure,
  ADD_CAMPAIGN_STEP3,
  addCampaignStepThreeRequested,
  addCampaignStepThreeSuccess,
  addCampaignStepThreeFailure,
  CAMPAIGN_LISTING,
  isListLoading,
  campListSuccess,
  DELETE_CAMPAIGN,
  CLONE_CAMPAIGN,
  GET_CAMPAIGN,
  showEditCamp,
  PLAY_CAMPAIGN,
} from 'actions/campaign';

const { authId } = new User();
const { deleteRequest, getRequest, postRequest, putRequest, prepareReq } = new HttpHelper();
const actions = (sAction, fAction = null, bAction = null) => ({ bAction, fAction, sAction });

export function* getFilterMembers({ payload }) {
  yield put(getFilterMembersRequested());

  const state = yield select();

  const {
    campaign: { selectedMembers },
  } = state;

  const userId = authId();

  let { filterData } = payload;

  filterData = JSON.parse(filterData);

  const { key, type, value } = filterData;

  const response = yield call(getRequest, prepareReq(`getFilterMembers/${key}/${type}/${value}/${userId}`));
  const updatedMembers = [...selectedMembers, ...response.data.response];
  const uniqueArray = [...new Set(updatedMembers.map(({ id }) => id))].map((e) => updatedMembers.find(({ id }) => id === e));

  yield put(getFilterMembersSuccess(uniqueArray));
  yield* handleResp(response, actions(null, getFilterMembersFailure));
}

export function* deleteCampaignMember({ payload }) {
  const state = yield select();

  const {
    campaign: { selectedMembers },
  } = state;

  let remainingMembers = [];

  remainingMembers = selectedMembers.filter(
    (eachMember) => eachMember.id !== payload.id,
  );

  yield put(updateinvitedMember(remainingMembers));
}

export function* addCampaignStepOne({ payload }) {
  yield put(addCampaignStepOneRequested());
  const userId = authId();
  const response = yield call(postRequest, prepareReq('campaign/add/step1', { ...payload, createdBy: userId }));

  toast.success('Step1 information added successfully', { autoClose: 1500 });
  yield* handleResp(response, actions(
    addCampaignStepOneSuccess,
    addCampaignStepOneFailure,
  ));
}

export function* addCampaignStepTwo({ payload }) {
  yield put(addCampaignStepTwoRequested());
  const response = yield call(postRequest, prepareReq('campaign/update/step2', payload));

  if (yield* handleResp(response, actions(null, addCampaignStepTwoFailure))) {
    yield put(addCampaignStepTwoSuccess(payload));
    toast.success('Step2 information added successfully', { autoClose: 1500 });
  }
}

export function* addCampaignStepThree({ payload }) {
  yield put(addCampaignStepThreeRequested());
  const response = yield call(postRequest, prepareReq('campaign/update/step3', payload));

  if (yield* handleResp(response, actions(null, addCampaignStepThreeFailure))) {
    yield put(addCampaignStepThreeSuccess(payload));
    toast.success('Step3 information added successfully', { autoClose: 1500 });
    if (payload.goToCampaignList) {
      yield put(push('/campaigns'));
    }
  }
}

export function* getCampList({ payload: { limit, pageNo } }) {
  const userId = authId();

  // yield put(isListLoading(true));
  const response = yield call(getRequest, prepareReq(`campaign/list/${userId}/${limit}/${pageNo}`));

  yield* handleResp(response, actions(campListSuccess));
}

export function* deleteCamp({ payload }) {
  yield put(isListLoading(true));
  const response = yield call(deleteRequest, prepareReq(`campaign/${payload.id}`));

  yield call(getCampList, { payload });
  yield* handleResp(response, {});
}

export function* cloneCampaign({ payload }) {
  yield put(isListLoading(true));
  const response = yield call(postRequest, prepareReq(`campaign/${payload.id}/clone`));

  yield call(getCampList, { payload });
  yield* handleResp(response, {});
}

export function* getCamp({ payload: { campaignId } }) {
  yield put(addCampaignStepOneRequested());
  const response = yield call(getRequest, prepareReq(`campaign/${campaignId}`));

  yield* handleResp(response, actions(showEditCamp));
}

export function* playCampaign({ payload }) {
  yield put(isListLoading(true));
  const response = yield call(putRequest, prepareReq(`campaign/play/${payload.id}`));

  yield call(getCampList, { payload });
  yield* handleResp(response, {});
}

function* campaign() {
  yield [
    takeLatest(GET_FILTER_MEMBERS, getFilterMembers),
    takeLatest(DELETE_CAMPAIGN_MEMBER, deleteCampaignMember),
    takeLatest(ADD_CAMPAIGN_STEP1, addCampaignStepOne),
    takeLatest(ADD_CAMPAIGN_STEP2, addCampaignStepTwo),
    takeLatest(ADD_CAMPAIGN_STEP3, addCampaignStepThree),
    takeLatest(CAMPAIGN_LISTING, getCampList),
    takeLatest(DELETE_CAMPAIGN, deleteCamp),
    takeLatest(CLONE_CAMPAIGN, cloneCampaign),
    takeLatest(GET_CAMPAIGN, getCamp),
    takeLatest(PLAY_CAMPAIGN, playCampaign),
  ];
}

export default campaign;
