import React from 'react';
// import './Profile.scss';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { toast } from 'react-toastify';
import { updateProfileByField } from 'actions/profile-info-action';
import api from '../../Dashboard/SettingStepProfile/Views/Services';

class UpdateEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { organisations: [] };
  }

  componentDidMount() {
    api.getOrganisations().then((data) => {
      // console.log('getOrganisations: ', data.response);
      this.setState({ organisations: data.response });
    });

    /* api.getOrganisationTypes().then((data) => {
      console.log(data.response);
      this.setState({ organisations: data.response });
    }); */
  }

  getCurrentOrganisationType = () => {
    if (!this.state.organisations) {
      return '';
    }

    const org = this.state.organisations.find((o) => o.id === this.state.organisation);

    if (org && org.OrganisationType) {
      return org.OrganisationType.name;
    }

    return '';
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { parcelnumber, organisation, email } = this.state;

    if (
      !parcelnumber
      || !organisation
      || !email
      || !/\S+@\S+\.\S+/.test(email)
    ) {
      toast.error('All fields are required and email must be of valid format.');

      return;
    }
    const options = {
      data: { email },
      field: 'parcelnumber',
      organisation,
      value: parcelnumber,
    };

    this.props.updateProfileByField({ options });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    return (
      <div className="container">
        <div className="container">
          <div className="panel info">
            <div className="panel-header">
              <h2>Link Email</h2>
            </div>
            <Collapse isOpen={true}>
              <div className="panelbody">
                <form className="form_opt two_col">
                  <div className="form-group">
                    <label>Organisation</label>
                    <select
                      required
                      className="form-control"
                      name="organisation"
                      value={this.state.organisation}
                      onChange={this.handleChange}
                    >
                      <option value="">Select Your Organisation</option>
                      {this.state.organisations.length > 0
                        && this.state.organisations.map(
                          (item, i) => (
                              <option key={i} value={item.id}>
                                {item.name}
                              </option>
                          ),
                        )}
                    </select>
                  </div>
                  {
                    this.getCurrentOrganisationType() === 'Home Owners Association'
                      ? <React.Fragment>
                        <div className="form-group">
                          <label>Parcel Number</label>
                          <input
                            required
                            className="form-control"
                            name="parcelnumber"
                            placeholder="Member Parcel Number?"
                            type="text"
                            value={this.state.parcelnumber || ''}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="form-group">
                          <label>Member Email</label>
                          <input
                            required
                            className="form-control"
                            name="email"
                            placeholder="Member Email?"
                            type="email"
                            value={this.state.email || ''}
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="form_btn_rw">
                          <button
                            className="btn btn-primary save-continue-btn"
                            onClick={this.handleSubmit}
                          >
                            Update
                          </button>
                        </div>
                      </React.Fragment>
                      : null
                  }
                </form>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ stateData: state });

export default connect(mapStateToProps, { updateProfileByField })(UpdateEmail);
