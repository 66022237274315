import React from 'react';
import { connect } from 'react-redux';

class PaymentCancelled extends React.Component {
  render() {
    return (
      <div className="container payment-block">
        <div className="container-inner">
          <h1>Your Payment was Cancelled</h1>
          <p className="lead">Sorry! Your payment was cancelled</p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(PaymentCancelled);
