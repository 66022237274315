/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import leftarrow from 'assets/images/leftarrow.png';
import {
  step3BackButton,
  addCampaignStepThree,
} from 'actions/campaign';
import { FormattedMessage } from 'components';
import { toast } from 'react-toastify';

const Loader = require('react-loader');

class ScheduleInFuture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      futureDate: null,
      hour: '',
      minute: '',
      timeFormat: 'am',
      datePickerIsOpen: false,
      formSubmit: false,
    };

    this.toggleDatePicker = this.toggleDatePicker.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.onceInFutureForm).length) {
      let { hour, minute } = this.props.onceInFutureForm;
      const { futureDate, timeFormat } = this.props.onceInFutureForm;

      if (!hour) {
        hour = '';
      }

      if (typeof hour === 'number' && hour < 10) {
        hour = `0${hour}`;
      }

      if (!minute) {
        minute = '';
      }

      if (typeof minute === 'number' && minute < 10) {
        minute = `0${minute}`;
      }

      this.setState({
        futureDate: futureDate ? moment(futureDate) : null,
        hour,
        minute,
        timeFormat,
      });
    }
  }

  handleChangeDate(date) {
    this.setState({ futureDate: date, datePickerIsOpen: false });
  }

  createSelectItems(start, end) {
    const items = [];

    // eslint-disable-next-line no-plusplus
    for (let i = start; i <= end; i++) {
      let counter = i;

      if (counter < 10) {
        counter = `0${counter}`;
      }

      items.push(
        <option key={i} value={counter}>
          {counter}
        </option>,
      );
      // here I will be creating my options dynamically based on
      // what props are currently passed to the parent component
    }

    return items;
  }

  onDropdownSelected(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  toggleDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  openDatePicker() {
    this.setState({
      datePickerIsOpen: true,
    });
  }

  previousStep() {
    this.props.step3BackButton();
  }

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  onValidateHandler = () => {
    const errors = {};

    if (!this.state.futureDate) {
      errors.futureDate = 'emptyFutureDate';
    }

    if (!this.state.hour) {
      errors.futureHour = 'emptyFutureHour';
    }

    if (!this.state.minute) {
      errors.futureMinute = 'emptyFutureMinute';
    }

    this.setState({ errors });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  saveAndContinue = (event) => {
    event.preventDefault();

    this.setState({ formSubmit: true });

    const isValid = this.onValidateHandler();

    let invalidTime = false;

    if (isValid) {
      let futureDate;

      if (Object.keys(this.props.onceInFutureForm).length) {
        futureDate = moment(this.state.futureDate).toDate();
      } else {
        futureDate = new Date(this.state.futureDate);
      }

      const day = futureDate.getDate();
      const year = futureDate.getFullYear();

      let month = futureDate.getMonth();

      month += 1;

      if (month < 10) {
        month = `0${month}`;
      }

      let scheduleDate = `${year}-${month}-${day} ${this.state.hour}:${this.state.minute} ${this.state.timeFormat}`;

      scheduleDate = new Date(scheduleDate).getTime();

      const currentTime = new Date().getTime();

      if (scheduleDate <= currentTime) {
        invalidTime = true;
        const errors = {};

        errors.invalidTime = 'invalidTime';
        this.setState({ errors });
      }

      // console.log("currentTime...........", scheduleDate, currentTime);
      // console.log("invalidTime...........", invalidTime);

      if (!invalidTime) {
        const formData = {
          scheduleType: 'once in future',
          campaignId:
            this.props.stepOneValues && this.props.stepOneValues.id
              ? this.props.stepOneValues.id
              : '',
          futureDate: this.state.futureDate,
          futureHour: this.state.hour,
          futureMinute: this.state.minute,
          futureTimeFormat: this.state.timeFormat,
          goToCampaignList: true,
        };

        this.props.addCampaignStepThree(formData);
      }
    }
  };

  render() {
    const {
      errors: { futureDate, futureHour, futureMinute, invalidTime },
      formSubmit,
    } = this.state;

    const { isLoading, apiErrors } = this.props;

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    {
      formSubmit
        && apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: 1500,
        });
    }

    return (
      <form>
        <div className="sm">
          <div className="panel">
            <div className="form-group">
              <div className="btn_col">
                <span className="backarrow" onClick={this.previousStep}>
                  <img alt="leftarrow" src={leftarrow} />
                </span>
              </div>
              <h2>Schedule your date</h2>
            </div>
            <div className="form-group cardnumber">
              <label>Date</label>
              <DatePicker
                className="form-control"
                dateFormat="MM/DD/YYYY"
                minDate={new Date()}
                open={this.state.datePickerIsOpen}
                /* value={this.state.futureDate} */ placeholderText="Select your date"
                selected={this.state.futureDate}
                onChange={(event) => this.handleChangeDate(event)
                }
                onClickOutside={this.toggleDatePicker}
                onFocus={(event) => this.openDatePicker(event)}
              />
              <span className="calendricon">
                <i
                  aria-hidden="true"
                  className="fa fa-calendar"
                  onClick={this.toggleDatePicker}
                ></i>
              </span>
              {futureDate && this.showError(futureDate)}
            </div>

            <div className="form-group">
              <label>Time</label>
              <ul className="carddetail time row">
                <li className="col-sm-3">
                  <select
                    className="form-control"
                    name="hour"
                    placeholder="Hour"
                    value={this.state.hour || ''}
                    onChange={(event) => this.onDropdownSelected(event)}
                  >
                    <option disabled value="">
                      Hours
                    </option>
                    {this.createSelectItems(1, 12)}
                  </select>
                  {futureHour && this.showError(futureHour)}
                </li>

                <li className="col-sm-3">
                  <select
                    className="form-control"
                    name="minute"
                    placeholder="Minutes"
                    value={this.state.minute || ''}
                    onChange={(event) => this.onDropdownSelected(event)}
                  >
                    <option disabled value="">
                      Minutes
                    </option>
                    {this.createSelectItems(0, 59)}
                  </select>
                  {futureMinute && this.showError(futureMinute)}
                </li>

                <li className="col-sm-3">
                  <select
                    className="form-control"
                    name="timeFormat"
                    placeholder="AM"
                    value={this.state.timeFormat || ''}
                    onChange={(event) => this.onDropdownSelected(event)}
                  >
                    <option value="am">AM</option>
                    <option value="pm">PM</option>
                  </select>
                </li>
              </ul>
            </div>
            {invalidTime && this.showError(invalidTime)}
            <div className="cent">
              <button className="btn btn-primary" onClick={this.saveAndContinue}>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ campaign }) => ({
  isLoading: campaign.isLoading,
  apiErrors: campaign.updateCampaignError,
  stepOneValues: campaign.stepOneValues,
  onceInFutureForm: campaign.onceInFutureForm,
});

export default connect(mapStateToProps, {
  step3BackButton,
  addCampaignStepThree,
})(ScheduleInFuture);
