/* eslint-disable filenames/match-exported */
import React, { Component } from 'react';

class NoMemberInvited extends Component {
  render() {
    return (
      <tbody>
        <tr>
          <td colSpan="4">No member invited</td>
        </tr>
      </tbody>
    );
  }
}

export default NoMemberInvited;
