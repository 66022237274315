/* eslint-disable no-unused-expressions */
/* eslint-disable filenames/match-exported */
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import User from 'utils/user';
import {
  SEARCH_MEMBERS,
  searchMembersRequested,
  searchMembersSuccess,
  searchMembersFailure,
  ADD_MEMBER,
  updateSelectedMember,
  DELETE_MEMBER,
  ADD_COMMITTEE,
  addCommitteeRequested,
  addCommitteeSuccess,
  addCommitteeFailure,
  FETCH_COMMITTEES,
  fetchCommitteesRequested,
  fetchCommitteesSuccess,
  fetchCommitteesFailure,
  DELETE_COMMITTEE,
  deleteCommitteeSuccess,
  deleteCommitteeFailure,
  getCommitteeRequested,
  GET_COMMITTEE,
  getCommitteeSuccess,
  getCommitteeFailure,
  updateSelectedMemberInEditCommittee,
  editCommitteeUpdateAddedMembers,
  editCommitteeUpdateDeletedMembers,
  EDIT_COMMITTEE,
  editCommitteeRequested,
  editCommitteeSuccess,
  editCommitteeFailure,
  FETCH_COMMITTEES_BY_MEMBER,
  fetchCommitteesByMemberRequested,
  fetchCommitteesByMemberSuccess,
  fetchCommitteesByMemberFailure,
} from 'actions/committee';
import HttpHelper, { handleResp } from 'utils/http-helper';

const { deleteRequest, getRequest, postRequest, putRequest, prepareReq } = new HttpHelper();
const { authId } = new User();
const actions = (sAction, fAction = null, bAction = null) => ({
  bAction,
  fAction,
  sAction,
});

export function* searchMembers({ payload }) {
  const userId = authId();

  yield put(searchMembersRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/searchMembers/${userId}/${payload}`));

  yield* handleResp(response, actions(null, searchMembersFailure));
  yield put(searchMembersSuccess(response.data));
}

export function* addMember({ payload }) {
  const state = yield select();

  const { type } = payload;

  const {
    committee: {
      selectedMembersList,
      editCommitteeSelectedMembers,
      addedCommitteeMembers,
    },
  } = state;

  let alreadyAdded = {};

  if (type === 'add') {
    alreadyAdded = selectedMembersList.find(
      (eachMember) => eachMember.id === payload.id,
    );
  } else {
    alreadyAdded = editCommitteeSelectedMembers.find(
      (eachMember) => eachMember.id === payload.id,
    );
  }

  if (!alreadyAdded) {
    if (type === 'add') {
      const selectedMembers = [...selectedMembersList, payload];

      yield put(updateSelectedMember(selectedMembers));
    } else {
      const selectedMembers = [...editCommitteeSelectedMembers, payload];

      yield put(updateSelectedMemberInEditCommittee(selectedMembers));

      const addedMembers = addedCommitteeMembers.find(
        (eachMember) => eachMember.id === payload.id,
      );

      if (!addedMembers) {
        const updateAraay = [...state.committee.addedCommitteeMembers, payload];

        yield put(editCommitteeUpdateAddedMembers(updateAraay));
      }
    }
  }
}

export function* deleteCommiteeMember({ payload }) {
  const state = yield select();

  const { type } = payload;

  const {
    committee: {
      selectedMembersList,
      editCommitteeSelectedMembers,
      deletedCommitteeMembers,
      addedCommitteeMembers,
    },
  } = state;

  let remainingMembers = [];

  if (type === 'add') {
    remainingMembers = selectedMembersList.filter(
      (eachMember) => eachMember.id !== payload.id,
    );

    yield put(updateSelectedMember(remainingMembers));
  } else {
    const existInOldMembers = editCommitteeSelectedMembers.find(
      (member) => member.id === payload.id,
    );

    const alreadyDeleted = deletedCommitteeMembers.find(
      (eachMember) => eachMember.id === payload.id,
    );

    if (!alreadyDeleted && existInOldMembers) {
      yield put(
        editCommitteeUpdateDeletedMembers([...deletedCommitteeMembers, payload]),
      );
    }

    remainingMembers = editCommitteeSelectedMembers.filter(
      (eachMember) => eachMember.id !== payload.id,
    );
    yield put(updateSelectedMemberInEditCommittee(remainingMembers));

    const alreadyAdded = addedCommitteeMembers.find(
      (member) => member.id === payload.id,
    );

    if (alreadyAdded) {
      const remainingAddedMembers = addedCommitteeMembers.filter(
        (eachMember) => eachMember.id !== payload.id,
      );

      yield put(editCommitteeUpdateAddedMembers(remainingAddedMembers));
    }
  }
}

export function* addCommittee({ payload }) {
  const userId = authId();

  yield put(addCommitteeRequested());
  const response = yield call(postRequest, prepareReq('committee/add', { ...payload, userId }));

  yield* handleResp(response, actions(null, addCommitteeFailure));
  yield put(addCommitteeSuccess(response));
  yield put(push('/committees'));
}

export function* fetchCommittees({ payload: { limit, pageNo } }) {
  const userId = authId();

  yield put(fetchCommitteesRequested());
  const response = yield call(getRequest, prepareReq(`committee/list/${userId}/${limit}/${pageNo}`));

  yield* handleResp(response, actions(null, fetchCommitteesFailure));
  yield put(fetchCommitteesSuccess(response.data));
}

export function* fetchCommitteesByMember({ payload: { userId } }) {
  yield put(fetchCommitteesByMemberRequested());
  const response = yield call(getRequest, prepareReq(`committee/listByMember/${userId}`));

  yield* handleResp(response, actions(null, fetchCommitteesByMemberFailure));
  yield put(fetchCommitteesByMemberSuccess(response.data));
}

export function* deleteCommittee({ payload }) {
  const response = yield call(deleteRequest, prepareReq(`committee/delete/${payload.committeeId}`, { isDelete: true }));

  yield* handleResp(response, actions(deleteCommitteeSuccess, deleteCommitteeFailure));
  yield fetchCommittees({ payload });
}

export function* getCommittee({ payload: { id } }) {
  yield put(getCommitteeRequested());
  const response = yield call(getRequest, prepareReq(`committee/${id}`));

  yield* handleResp(response, actions(null, getCommitteeFailure));
  yield put(getCommitteeSuccess(response.data));
}

export function* editCommittee({ payload }) {
  const { id } = payload;

  const input = {
    ...payload,
    committeeName: payload.committeeName.trim(),
    committeeType: payload.committeeType.trim(),
    description: payload.description ? payload.description.trim() : '',
  };

  delete input.id;
  yield put(editCommitteeRequested());
  const response = yield call(putRequest, prepareReq(`committee/${id}`, input));

  yield put(push('/committees'));
  yield* handleResp(response, actions(editCommitteeSuccess, editCommitteeFailure));
}

function* committeeSaga() {
  yield [
    takeLatest(SEARCH_MEMBERS, searchMembers),
    takeLatest(ADD_MEMBER, addMember),
    takeLatest(DELETE_MEMBER, deleteCommiteeMember),
    takeLatest(ADD_COMMITTEE, addCommittee),
    takeLatest(FETCH_COMMITTEES, fetchCommittees),
    takeLatest(FETCH_COMMITTEES_BY_MEMBER, fetchCommitteesByMember),
    takeLatest(DELETE_COMMITTEE, deleteCommittee),
    takeLatest(GET_COMMITTEE, getCommittee),
    takeLatest(EDIT_COMMITTEE, editCommittee),
  ];
}

export default committeeSaga;
