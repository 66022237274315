/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import { Collapse } from 'reactstrap';
import { FormattedMessage, ReactQR } from 'components';

import {
  getUserDetails,
  updateUserDetails,
} from 'actions/settings-step-action';

const Loader = require('react-loader');

class General extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customOrgTypeValue: '',
      errors: {},
      firstName: '',
      lastName: '',
      organisationName: '',
      organisationType: '',
      organisations: [],
      showCustomOrgType: false,
      showProfileInfo: true,
    };
  }

  componentDidMount() {
  }

  showProfileUpdateForm = () => {
    if (Object.keys(this.props.userDetails).length) {
      const {
        userDetails: { firstName, lastName, organistaionName, Organisation },
      } = this.props;

      let organisationType = '';

      let showCustomOrgType = false;

      let customOrgTypeValue = '';

      /** set organization type and check if it custom type or not */
      if (Organisation && Organisation.createdBy === 'pre') {
        organisationType = Organisation.id;
      }

      if (Organisation && Organisation.createdBy === 'user') {
        const findOthers = this.state.organisations.find(
          (eachOrg) => eachOrg.name === 'Other',
        );

        organisationType = findOthers.id;

        showCustomOrgType = true;
        customOrgTypeValue = Organisation.name;
      }

      this.setState({
        customOrgTypeValue,
        firstName,
        lastName,
        organisationName: organistaionName,
        organisationType,
        showCustomOrgType,
        showProfileInfo: !this.state.showProfileInfo,
      });
    }
  };

  changeOrgType = (event) => {
    let showCustomOrgType = false;
    const selectedOrgTypeId = event.target.value;

    let customOrgTypeValue = '';

    const findOthers = this.state.organisations.find(
      (eachOrg) => eachOrg.name === 'Other',
    );

    if (findOthers && (selectedOrgTypeId === findOthers.id)) {
      showCustomOrgType = true;
      // eslint-disable-next-line prefer-destructuring
      customOrgTypeValue = this.state.customOrgTypeValue;
    } else {
      customOrgTypeValue = '';
    }

    this.setState({
      customOrgTypeValue,
      organisationType: selectedOrgTypeId,
      showCustomOrgType,
    });
  };

  showError = (error) => <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>;

  validateFields = () => {
    const errors = {};

    let isValid = true;

    const {
      firstName,
      lastName,
      organisationName,
      customOrgTypeValue,
      organisationType,
    } = this.state;

    const findOthers = this.state.organisations.find(
      (eachOrg) => eachOrg.name === 'Other',
    );

    if (!firstName) {
      isValid = false;
      errors.firstNameError = 'emptyNameValidation';
    }
    if (firstName && firstName.length < 2) {
      isValid = false;
      errors.firstNameError = 'invalidNameValidation';
    }
    if (!lastName) {
      isValid = false;
      errors.lastNameError = 'emptyLastNameValidation';
    }
    if (lastName && lastName.length < 2) {
      isValid = false;
      errors.lastNameError = 'invalidLnameValidation';
    }
    if (!organisationName) {
      isValid = false;
      errors.organisationNameError = 'emptyOrganisationName';
    }
    if (organisationName && organisationName.length < 2) {
      isValid = false;
      errors.organisationNameError = 'invalidOrgNameValidation';
    }
    /** if user select other type, then need to also provide its name */
    if (findOthers && (organisationType === findOthers.id) && !customOrgTypeValue) {
      isValid = false;
      errors.customOrgTypeError = 'customOrgTypeError';
    }

    this.setState({ errors });

    return isValid;
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  updateProfile = (event) => {
    event.preventDefault();
    const isValid = this.validateFields();
    const { userDetails } = this.props;

    if (isValid) {
      const input = {
        customOrgTypeValue: this.state.customOrgTypeValue,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        oldOrganisationType: '',
        organisationType: this.state.organisationType ? this.state.organisationType : null,
        organistaionName: this.state.organisationName,
      };

      if (userDetails.Organisation && userDetails.Organisation.createdBy === 'user') {
        input.oldOrganisationType = userDetails.Organisation.id;
      }

      this.setState({
        showProfileInfo: !this.state.showProfileInfo,
      });

      this.props.updateUserDetails(input);
    }
  };

  render() {
    const { userDetails, isLoading } = this.props;

    if (!userDetails) {
      return null;
    }

    const {
      showProfileInfo,
      firstName,
      lastName,
      organisationName,
      organisationType,
      organisations,
      showCustomOrgType,
      customOrgTypeValue,
      errors: {
        firstNameError,
        lastNameError,
        organisationNameError,
        customOrgTypeError,
      },
    } = this.state;

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    const updateEmailURL = userDetails.Organisation ? `${process.env.REACT_APP_URL}/update-email/${userDetails.Organisation.id}` : '';

    return (
        <div className="setting_block">
            <Collapse isOpen={showProfileInfo}>
            <div className="setting_topinfo">
                <ul>
                <li>
                    Organization
                    <h2 className="capitalize">
                    {userDetails.Organisation ? userDetails.Organisation.name : '-'}
                    </h2>
                </li>
                <li>
                    First name
                    <h2 className="capitalize">
                    {userDetails.firstName ? userDetails.firstName : '-'}
                    </h2>
                </li>
                <li>
                    Last name
                    <h2 className="capitalize">
                    {userDetails.lastName ? userDetails.lastName : '-'}
                    </h2>
                </li>
                <li>
                    Organization Type
                    <h2 className="capitalize">
                    {userDetails.OrganisationTypes ? userDetails.OrganisationTypes.name : '-'}
                    </h2>
                </li>
                <li>
                    Email
                    <h2 className="">
                    {userDetails.email ? userDetails.email : '-'}
                    </h2>
                </li>
                <li className="last">
                    <a
                    className="link"
                    onClick={(e) => this.showProfileUpdateForm(e)}
                    >
                    Edit
                    </a>
                </li>
                </ul>
            </div>
            </Collapse>
            <Collapse isOpen={!showProfileInfo}>
            <div className="setting_topinfo">
                <div className="form-group">
                <label>First name</label>
                <input
                    autoFocus
                    autoComplete="false"
                    className="form-control"
                    name="firstName"
                    placeholder="What's your first name?"
                    type="text"
                    value={firstName}
                    onChange={this.handleChange}
                />
                {firstNameError && this.showError(firstNameError)}
                </div>
                <div className="form-group">
                <label>Last name</label>
                <input
                    autoComplete="false"
                    className="form-control"
                    name="lastName"
                    placeholder="What's your last name?"
                    type="text"
                    value={lastName}
                    onChange={this.handleChange}
                />
                {lastNameError && this.showError(lastNameError)}
                </div>
                <div className="form-group">
                <label>Organization name</label>
                <input
                    className="form-control"
                    name="organisationName"
                    placeholder="What is your organization name?"
                    type="text"
                    value={organisationName}
                    onChange={this.handleChange}
                />
                {organisationNameError && this.showError(organisationNameError)}
                </div>
                <div className="form-group drop-selector-new">
                <label>Organization type</label>
                <select
                    className="form-control"
                    name="organisationType"
                    value={organisationType}
                    onChange={(e) => this.changeOrgType(e)}
                >
                    <option value="">Select your organization type</option>
                    {
                        organisations.length && organisations.map((item, i) => (
                          item.createdBy === 'pre' && (
                            <option key={`orgTypes-${i}`} value={item.id}>
                            {item.name}
                            </option>
                          )
                        ))
                    }
                </select>
                <i aria-hidden="true" className="fa fa-caret-down"></i>

                {showCustomOrgType && (
                    <input
                    className="form-control"
                    name="customOrgTypeValue"
                    placeholder="What is your organisation type?"
                    type="text"
                    value={customOrgTypeValue}
                    onChange={this.handleChange}
                    />
                )}

                {customOrgTypeError && this.showError(customOrgTypeError)}
                </div>
                <div className="form-group drop-selector-new">
                <label>Email</label>
                <input
                    readOnly
                    autoComplete="false"
                    className="form-control"
                    name="lastName"
                    type="text"
                    value={userDetails.email || ''}
                />
                </div>
                <button className="btn btn-primary" onClick={this.updateProfile}>
                Update
                </button>
            </div>
            </Collapse>
            { updateEmailURL ? <ReactQR
                enableDownload={true}
                fileName={`update_link_${userDetails.id}.jpg`}
                title="Link For Public Member Information Update Without Email Address"
                value={updateEmailURL}
                /> : null
            }
        </div>
    );
  }
}

const mapStateToProps = ({ settings }) => ({
  isLoading: settings.isLoading,
  userDetails: settings.userDetails,
});

export default connect(mapStateToProps, {
  getUserDetails,
  updateUserDetails,
})(General);
