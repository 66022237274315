import React, { Component } from 'react';
import { connect } from 'react-redux';

class CustomFieldType extends Component {
  render() {
    return (
        <select
            className={this.props.className}
            name={this.props.name}
            value={this.props.value ? this.props.value : 'String'}
            onChange={(e) => this.props.onChange(e)}
        >
            <option value="">Select Your Field Data Type</option>
            <option value="String">Text</option>
            <option value="Number">Number</option>
            <option value="Date">Date</option>
            <option value="Boolean">Boolean/Checkbox</option>
        </select>
    );
  }
}

export default connect(null, {})(CustomFieldType);
