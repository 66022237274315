import React, { Component } from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getImportantMembers, addImportantMember, deleteImportantMember, updateImportantMember } from '../actions/member-view-action';

class ImportantPeople extends Component {
  state = {
    requestedImportantMemberLabel: '',
    selectedItem: null,
    confirmMessageContent: '',
    showConfirmBox: false,
    payload: {},
  };

  componentDidMount = () => {
    this.props.getImportantMembers(this.props.memberId);
  }

  onLabelChange = (e) => {
    this.setState({ requestedImportantMemberLabel: e.target.value });
  }

  handleAddRequest = () => {
    if (this.state.requestedImportantMemberLabel) {
      this.props.addImportantMember({
        memberId: this.props.memberId,
        metaKey: this.state.requestedImportantMemberLabel,
      });

      this.setState({ requestedImportantMemberLabel: '' });
    }
  }

  handleUpdateRequest = () => {
    if (this.props.importantPeople) {
      this.props.updateImportantMember({
        data: this.state.payload,
        memberId: this.props.memberId,
      });
    }
  }

  handleDeleteReq = (id) => {
    this.setState({
      selectedItem: id,
      showConfirmBox: true,
      confirmMessageContent: 'Are you sure you want to delete this data?',
    });
  }

  fillMetaPayload = (people) => ({
    id: people.id,
    meta_key: people.meta_key,
    meta_value: {},
    meta_type: 'important_people',
  });

  getPayloadKey = (people) => `people_${people.id}`;

  onPeopleNameAdded = (people, peopleName) => {
    const { payload } = this.state;
    const key = this.getPayloadKey(people);

    if (typeof payload[key] === 'undefined') {
      payload[key] = this.fillMetaPayload(people);
    }

    payload[key].meta_value.name = peopleName;

    this.setState({ payload });
  }

  onPeopleDateAdded = (people, peopleDate) => {
    const { payload } = this.state;
    const key = this.getPayloadKey(people);

    if (typeof payload[key] === 'undefined') {
      payload[key] = this.fillMetaPayload(people);
    }

    payload[key].meta_value.date = peopleDate;

    this.setState({ payload });
  }

  onPopupConfirmed = () => {
    this.props.deleteImportantMember({
      memberId: this.props.memberId,
      metaId: this.state.selectedItem,
    });

    this.setState({
      confirmMessageContent: '',
      selectedItem: null,
      showConfirmBox: false,
    });
  }

  getMetaValue = (key, people) => {
    const index = this.getPayloadKey(people);

    const statePayload = this.state.payload[index] ? this.state.payload[index].meta_value : '';

    if (statePayload) {
      return statePayload[key] ? statePayload[key] : '';
    }

    const metadata = people.meta_value ? JSON.parse(people.meta_value) : '';

    return metadata && metadata[key] ? metadata[key] : '';
  }

  renderPeopleList(importantPeople) {
    if (!importantPeople) {
      return null;
    }

    return importantPeople.map((people) => (
        <div className="col12 col_wrap" key={`people_${people.id}`}>
          <div className="form-group">
            <label>{people.meta_key}</label>
            <input
              className="form-control"
              name="name"
              placeholder={`Name for ${people.meta_key}`}
              type="text"
              value={this.getMetaValue('name', people)}
              onChange={(e) => this.onPeopleNameAdded(people, e.target.value)}
            />
          </div>
          <div className="form-group" style={{ position: 'relative' }}>
            <label>Birthday</label>
            <input
              className="form-control"
              max="2021-12-07"
              name="birthday"
              placeholder="What's the Birthday?"
              type="date"
              value={this.getMetaValue('date', people)}
              onChange={(e) => this.onPeopleDateAdded(people, e.target.value)}
            />
            <a
              className="delete-people"
              href=""
              onClick={(e) => {
                e.preventDefault();
                this.handleDeleteReq(people.id);
              }}
            >
              <i className="fa fa-remove"></i>
            </a>
          </div>
        </div>
    ));
  }

  render() {
    const { importantPeople } = this.props;

    return (
      <React.Fragment>
        <div className="panel info">
          <div className="panel-header">
            <h2>Important People</h2>
            <div className="pull-right">
              <span><i aria-hidden="true" className="fa fa-caret-up"></i></span>
            </div>
          </div>
          <div className="collapse show">
            <div className="panelbody" style={{ display: 'block' }}>
              <div className="row">
                <div className="col-xs-12" style={{ margin: '10px 0' }}>
                  <div className="row">
                      <div className="col-sm-9 col-xs-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            placeholder="What do we call a new member?"
                            type="text"
                            value={this.state.requestedImportantMemberLabel}
                            onChange={this.onLabelChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3 col-xs-12">
                        <button
                          className="btn btn-default"
                          disabled={!this.state.requestedImportantMemberLabel}
                          style={{
                            width: '100%',
                            boxShadow: 'none',
                            fontSize: '16px',
                            backgroundColor: '#d3d0d0',
                            color: '#000',
                          }}
                          onClick={this.handleAddRequest}
                        >Add New Member</button>
                      </div>
                  </div>
                </div>
              </div>
              {this.renderPeopleList(importantPeople)}
              <div className="row">
                <div className="col-xs-12 text-center">
                  <button
                    className="btn btn-primary"
                    disabled={!(importantPeople && importantPeople.length)}
                    onClick={this.handleUpdateRequest}
                  >Update Important People</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SweetAlert
          confirmBtnStyle={{
            padding: '0.5rem 1rem',
            fontSize: '1.25rem',
          }}
          show={this.state.showConfirmBox}
          showCancel={true}
          title="Are you Sure?"
          type='warning'
          onCancel={() => this.setState({ showConfirmBox: false })}
          onConfirm={this.onPopupConfirmed}
        >
          {this.state.confirmMessageContent}
        </SweetAlert>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state,
  importantPeople: state.memberInfo.importantMemberList,
});

export default connect(mapStateToProps, {
  getImportantMembers,
  addImportantMember,
  deleteImportantMember,
  updateImportantMember,
})(ImportantPeople);
