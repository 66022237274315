import React from 'react';
import './ProfileActivity.scss';

import { func } from 'prop-types';
import { connect } from 'react-redux';
import { formatDistance } from 'date-fns';
import { activityList } from 'actions/profile-info-action';

class ProfileActivity extends React.Component {
  // checking the props type
  static propTypes = {
    activityList: func.isRequired,
  };

  componentDidMount() {
    this.props.activityList();
  }

  render() {
    const {
      // eslint-disable-next-line no-shadow
      data: { activityList },
    } = this.props;

    return (
      <div className=" info">
        <table className="table table-responsive table-Campaign">
          <thead>
            <tr>
              <th>ACTION</th>
              <th>DETAILS</th>
              <th>DATE</th>
            </tr>
          </thead>
          <tbody>
            {activityList.map((activity) => (
              <tr key={activity.id}>
                <td>{activity.activity}</td>
                <td> {activity.message} </td>
                <td>
                  {formatDistance(new Date(), new Date(activity.createdAt), {
                    addSuffix: true,
                    includeSeconds: true,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.userProfile,
});

export default connect(mapStateToProps, {
  activityList,
})(ProfileActivity);
