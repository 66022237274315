import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addMember, toggleSearchList } from 'actions/committee';

class SearchMembers extends Component {
  handleEvent = (member, event) => {
    event.preventDefault();

    const payload = {
      ...member,
      type: this.props.type,
    };

    this.props.addMember(payload);
  };

  render() {
    const { selectedMembersList, type, editCommitteeSelectedMembers } = this.props;

    return (
      <ul className="list-dropdown-new">
        {this.props.membersList.map((eachMember) => {
          let alreadyAdded = {};

          if (type === 'add') {
            alreadyAdded = selectedMembersList.find(
              (item) => item.id === eachMember.id,
            );
          } else {
            alreadyAdded = editCommitteeSelectedMembers.find(
              (item) => item.id === eachMember.id,
            );
          }

          return eachMember.firstName || eachMember.lastName ? (
            <li className="selected" key={eachMember.id}>
              {eachMember.firstName} {eachMember.lastName}
              {alreadyAdded ? (
                <span className="search-list-li addbtn-new">added</span>
              ) : (
                <span
                  className="search-list-li addbtn-new"
                  onClick={(e) => this.handleEvent(eachMember, e)}
                >
                  add
                </span>
              )}
            </li>
          ) : (
            ''
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = (state) => ({
  editCommitteeSelectedMembers: state.committee.editCommitteeSelectedMembers,
  selectedMembersList: state.committee.selectedMembersList,
});

export default connect(mapStateToProps, {
  addMember,
  toggleSearchList,
})(SearchMembers);
