import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCamp, startNewCamp } from 'actions/campaign';
import './CreateCampaign.scss';
import { TopHeader, UpgradeSubscription } from 'components';
import leftarrow from 'assets/images/leftarrow.png';
import { checkUserUsage } from 'actions/profile-info-action';
import BuildList from '../Steps/BuildList';
import CreateEmail from '../Steps/CreateEmail';
import Schedule from '../Steps/Schedule';

class CreateCampaign extends React.Component {
  componentDidMount() {
    this.props.checkUserUsage();

    // eslint-disable-next-line prefer-destructuring
    const params = this.props.match.params;

    if (params && params.campaignId) {
      // Get the edit campaign
      this.props.getCamp({ campaignId: params.campaignId });
    } else {
      this.props.startNewCamp();
    }
  }

  renderSwitch = (step) => {
    switch (step) {
      case 1:
        return (
          <div className="steps-sect">
            <div className="step-section">
              <div className="step_bx">
                <ul>
                  <li className="active">
                    <span>1</span>
                    <p>Build Your List </p>
                  </li>
                  <li>
                    <span>2</span>
                    <p>Step 2</p>
                  </li>
                  <li>
                    <span>3</span>
                    <p>Step 3</p>
                  </li>
                </ul>
              </div>
              <BuildList campaignId={this.props.match.params.campaignId || null} />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="steps-sect">
            <div className="step-section">
              <div className="step_bx">
                <ul>
                  <li className="complete">
                    <span>1</span>
                    <p>Build Your List </p>
                  </li>
                  <li className="active">
                    <span>2</span>
                    <p>Create Email</p>
                  </li>
                  <li>
                    <span>3</span>
                    <p>Step 3</p>
                  </li>
                </ul>
              </div>
              <CreateEmail />
            </div>
          </div>
        );
      default:
        return (
          <div className="steps-sect">
            <div className="step-section">
              <h2>Last Step and you're done</h2>
              <div className="step_bx">
                <ul>
                  <li className="complete">
                    <span>1</span>
                    <p>Step 1 </p>
                  </li>
                  <li className="complete">
                    <span>2</span>
                    <p>Step 2</p>
                  </li>
                  <li className="active">
                    <span>3</span>
                    <p>Step 3</p>
                  </li>
                </ul>
              </div>
              <Schedule />
            </div>
          </div>
        );
    }
  };

  render() {
    const { step } = this.props;
    const { campaignId } = this.props.match.params;

    if (!campaignId && this.props.usage && !this.props.usage.activeCampaigns) {
      return (<UpgradeSubscription heading="New Campaign" href="/campaigns" usage={this.props.usage} />);
    }

    return (
      <div>
        <div className="container">
          <div className="Header-top">
            <TopHeader />
          </div>
          <div className="csv_row heading-membership">
            <div className="btn_col">
              <Link className="backarrow" to="/campaigns">
                <img alt="leftarrow" src={leftarrow} />
              </Link>
            </div>
            <h2>{campaignId ? 'Edit' : 'New'} Campaign</h2>
          </div>
          {this.renderSwitch(step)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ campaign: { step }, userProfile: { usage } }) => ({
  step,
  usage,
});

export default connect(mapStateToProps, { getCamp, startNewCamp, checkUserUsage })(CreateCampaign);
