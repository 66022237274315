/* eslint no-useless-escape: 0 */
import Storage from './storage';
import Translate from './translate';

class User {
  getAccessToken = () => {
    const { accessToken } = Storage.get('userDetails');

    return accessToken;
  };

  authUser = () => {
    const userDetails = Storage.get('userDetails');

    return userDetails;
  }

  getUserOrgType = () => {
    const currentUser = this.authUser();

    if (!currentUser) {
      return false;
    }

    return currentUser.user.Organisation.OrganisationType.name;
  }

  authUserValue = () => {
    const userDataValue = Storage.get('userDataValue');

    return userDataValue;
  };

  getLoggedInData = () => {
    let userDetails = localStorage.getItem('userDetails');

    if (!userDetails) {
      return false;
    }

    userDetails = JSON.parse(userDetails);

    if (!userDetails) {
      return false;
    }

    return userDetails.user ? userDetails.user.id : false;
  }

  formInfo = () => {
    const userDetails = Storage.get('setting-form');

    return userDetails;
  };

  adminDetails = () => {
    const adminDetails = Storage.get('adminDetails');

    return adminDetails || {};
  };

  removeUserDetailsToken = () => {
    Storage.delete('userDetails');
    Storage.delete('adminDetails');
  };

  isAuthenticated = () => {
    const userDetails = Storage.get('userDetails');

    if (typeof pendo !== 'undefined') {
      const visitorObj = {
        id: 'VISITOR-UNIQUE-ID', // Required if user is logged in
      };

      const accountObj = {
        id: 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      };

      if (userDetails) {
        visitorObj.id = userDetails.user.id;
        visitorObj.full_name = [userDetails.user.firstName, userDetails.user.lastName].join(' ');
        visitorObj.email = userDetails.user.email;
        // visitorObj.role = '';

        accountObj.id = userDetails.user.id;
      }
      window.pendo.initialize({
        visitor: visitorObj,
        account: accountObj,
      });
    }

    if (!userDetails.token) {
      return false;
    }

    return true;
  };

  validateEmail = (email) => {
    if (
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)
    ) {
      return true;
    }

    return false;
  };

  validateName = (name) => {
    if (name.length > 24) {
      return false;
    }

    if (/[0-9a-zA-Z]{2,25}$/.test(name)) {
      return true;
    }

    return false;
  };

  validateNotes = (name) => {
    if (name.length < 2) {
      return false;
    }

    if (/[0-9a-zA-Z]{2,500}$/.test(name)) {
      return true;
    }

    return false;
  };

  validateCardNumber(cardNumber) {
    if (cardNumber.length <= 15) {
      return false;
    }

    if (/[0-9]{15,19}$/.test(cardNumber)) {
      return true;
    }

    return false;
  }

  validateCardCVV(cardNumber) {
    console.log(cardNumber.length);
    if (cardNumber.length <= 2) {
      return false;
    }

    if (/[0-9]{0,3}$/.test(cardNumber)) {
      return true;
    }

    return false;
  }

  validationExpMonth(cardNumber) {
    if (cardNumber.length <= 1) {
      return false;
    }

    if (/[0-9]{0,2}$/.test(cardNumber)) {
      return true;
    }

    return false;
  }

  validationExpYear(cardNumber) {
    if (cardNumber.length <= 3) {
      return false;
    }

    if (/[0-9]{0,4}$/.test(cardNumber)) {
      return true;
    }

    return false;
  }

  static validatePhoneNumber(phoneNumber) {
    if (/^[0-9]+$/.test(phoneNumber)) {
      return true;
    }

    return false;
  }

  static validateGuid(guid) {
    if (
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        guid,
      )
    ) {
      return true;
    }

    return false;
  }

  validatePassword = (password) => {
    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/.test(password)) {
      return true;
    }

    return false;
  };

  validateOrg = (name) => {
    if (/^(?=.*[a-zA-Z\d].*)[a-zA-Z\d\!@#\$%&\*]{7,}$/.test(name)) {
      return true;
    }

    return false;
  };

  static validateUpperCaseChar(char) {
    if (/(?=.*[A-Z])/.test(char)) {
      return true;
    }

    return false;
  }

  static validateLowerCaseChar(char) {
    if (/(?=.*[a-z])/.test(char)) {
      return true;
    }

    return false;
  }

  validateSpecialCaseChar(char) {
    if (/(?=.*?[!#$%&'*+-/=?^_`{|}~;])/.test(char)) {
      return true;
    }

    return false;
  }

  static validateNumericCaseChar(char) {
    if (/(?=.*[0-9])/.test(char)) {
      return true;
    }

    return false;
  }

  static getSurveyId() {
    return '4983e3bd-b787-4d4d-9191-85f2a674a1db';
  }

  static firstCharSpaceNotAllowed(event) {
    if (event.which === 32 && event.currentTarget.selectionStart === 0) {
      event.preventDefault();
    }
  }

  static spaceNotAllowed(event) {
    if (event.which === 32) {
      event.preventDefault();
    }
  }

  validateMatch(event, ev) {
    return event.match(ev);
  }

  static restrictSpecialChar(event) {
    const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);

    if (/^[a-z\d\-_\s]+$/i.test(str)) {
      return true;
    }

    event.preventDefault();

    return false;
  }

  dateTimeFormatter = (date) => {
    if (date) {
      const options = {
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        month: 'short',
        year: 'numeric',
      };

      return new Date(date).toLocaleDateString([], options);
    }

    return '-';
  };

  defaultLanguage = () => Storage.get('language') || 'en';

  statusFormatter = (status) => {
    if (status) return Translate.translate('active');

    return Translate.translate('inactive');
  };

  statusColorFormatter = (status) => {
    if (status) return 'green';

    return 'red';
  };

  signOut = () => {
    this.removeUserDetailsToken();
  };

  // common function to get user id
  authId = () => {
    const userDetails = Storage.get('userDetails');

    const userId = userDetails.user ? userDetails.user.id : '';

    return userId;
  };

  removeSpaceAndConvertToCapitalLetter(string) {
    let result = string
      .replace(/\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1))
      .replace(/\s/g, '');

    result = result.charAt(0).toLowerCase() + result.slice(1);

    return result;
  }
}

export default User;
