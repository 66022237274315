/* eslint-disable no-shadow */
import React from 'react';
import { Link } from 'react-router-dom';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import './CampaignsTable.scss';
import { Loader, TopHeader, UpgradeSubscription } from 'components';
import leftarrow from 'assets/images/leftarrow.png';
import { campListing, deleteCamp, playCampaign, cloneCampaign } from 'actions/campaign';
import {
  checkUserUsage,
  getUserUsageStats,
} from 'actions/profile-info-action';
import User from 'utils/user';
import socketIOClient from 'socket.io-client';

const socket = socketIOClient(process.env.REACT_APP_WS_URL);

// Listing campaigns
class ListCampaigns extends React.Component {
  // checking the props type
  static propTypes = {
    campListing: func.isRequired,
    checkUserUsage: func.isRequired,
    getUserUsageStats: func.isRequired,
    isListLoading: bool,
  };

  // class base method
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      limit: 5,
      selectedCamp: '',
      showAlert: false,
      showPlayAlert: false,
      focusUpgradeNotice: false,
    };

    this.userUtils = new User();
    const currentUserId = this.userUtils.getLoggedInData();

    socket.on(`UsageChangedForUser_${currentUserId}`, () => {
      this.props.checkUserUsage();
      this.props.getUserUsageStats();
    });
  }

  componentDidMount() {
    // Getting List of campiagns on load of component
    this.props.checkUserUsage();
    this.props.getUserUsageStats();
    this.props.campListing({
      limit: this.state.limit,
      pageNo: 1,
    });
  }

  getPaginationOptions = () => {
    const { currentPage, limit } = this.state;

    const {
      campList: { rows },
    } = this.props;

    let setPage = currentPage;

    if (currentPage > 1 && rows.length === 1) {
      // setting second last page if current page has only one item
      setPage = currentPage - 1;
    }
    if (currentPage === 1 && rows.length === 1) {
      setPage = 1;
    }

    return { limit, setPage };
  }

  redirectToAddInterface = () => {
    const canAdd = this.props.usage && this.props.usage.events;

    if (!canAdd) {
      this.setState({ focusUpgradeNotice: true });
      setTimeout(() => this.setState({ focusUpgradeNotice: false }), 500);
      toast.error('You are not authorized to add new committee. Please upgrade your account first');
    } else {
      this.props.history.push({
        pathname: '/addevent',
      });
    }
  }

  // Creating UI for Campaign list
  listingCamp = (arrayList) => {
    if (arrayList && arrayList.length) {
      return arrayList.map((camp) => (
        <tr key={camp.id}>
          <td>{camp.campaignName}</td>
          <td>{camp.filterNames ? camp.filterNames : 'N/A'}</td>
          <td>{`${camp.User.firstName} ${camp.User.lastName}`}</td>
          <td>{new Date(camp.createdAt).toDateString('yyyy-MM-dd')}</td>
          <td>
            {camp.lastRunDate
              ? new Date(camp.lastRunDate).toDateString('yyyy-MM-dd')
              : 'N/A'}
          </td>
          <td>
            <div className="icons_list">
              <a href="#" onClick={(e) => { e.preventDefault(); this.handlePlayPop(true, camp.id); }}>
                <i className="fa fa-play"></i>
              </a>
              <Link className="edit-campaign" to={`/edit-campaign/${camp.id}`}>
                <i className="fa fa-pencil"></i>
              </Link>
              <a
                className="cloneIcon"
                href="#"
                onClick={(e) => { e.preventDefault(); this.handleClone(camp.id); }}
                >
                <i className="fa fa-clone"></i>
              </a>
              <a
                className="deleteIcon"
                href="#"
                onClick={(e) => { e.preventDefault(); this.handleDeletePop(true, camp.id); }}
              >
                <i className="fa fa-trash"></i>
              </a>
            </div>
          </td>
        </tr>
      ));
    }

    return (
      <tr>
        <td className="no-camp" colSpan={6} style={{ textAlign: 'center' }}>
          No Email available, please create new one
        </td>
      </tr>
    );
  };

  // Delete Campaign popup
  handleDeletePop = (isEnable, selectedCamp) => {
    this.setState({
      selectedCamp,
      showAlert: isEnable,
    });
  };

  handleClone = (selectedCamp) => {
    const { setPage, limit } = this.getPaginationOptions();
    const canAdd = this.props.usage && this.props.usage.activeCampaigns;

    if (!canAdd) {
      this.setState({ focusUpgradeNotice: true });
      setTimeout(() => this.setState({ focusUpgradeNotice: false }), 500);
      toast.error('Your limit to add new campaign has exceeded. Please upgrade your account');
    } else {
      this.props.cloneCampaign({
        id: selectedCamp,
        limit,
        pageNo: setPage,
      });
    }
  };

  // Play Campaign popup
  handlePlayPop = (isEnable, selectedCamp) => {
    const canSendEmail = this.props.usage && this.props.usage.emails;

    if (!canSendEmail) {
      this.setState({ focusUpgradeNotice: true });
      setTimeout(() => this.setState({ focusUpgradeNotice: false }), 500);
      toast.error('Your limit to send an email has exceeded. Please upgrade your account');
    } else {
      this.setState({
        selectedCamp,
        showPlayAlert: isEnable,
      });
    }
  };

  // Page change
  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });

    // Getting List of campiagns on load of component
    this.props.campListing({
      limit: this.state.limit,
      pageNo: pageNumber,
    });
  };

  // Delete confirmation
  onConfirmDelete = () => {
    const { setPage, limit } = this.getPaginationOptions();
    const { deleteCamp } = this.props;
    const { selectedCamp } = this.state;

    // delete API Hit
    deleteCamp({
      id: selectedCamp,
      limit,
      pageNo: setPage,
    });

    // High-light current page number
    this.setState({ currentPage: setPage });

    // Hide popup
    this.handleDeletePop(false, '');
  };

  // Play campaign confirmation
  onConfirmPlay = () => {
    const { setPage, limit } = this.getPaginationOptions();
    const { playCampaign } = this.props;
    const { selectedCamp } = this.state;

    // play campaign API Hit
    playCampaign({
      id: selectedCamp,
      limit,
      pageNo: setPage,
    });

    // High-light current page number
    this.setState({ currentPage: setPage });

    // Hide popup
    this.handlePlayPop(false, '');
  };

  getStatsCount() {
    if (!this.props.usageStats || this.props.usageStats.activeCampaigns.total === -1) {
      return null;
    }

    return <small>({this.props.usageStats.activeCampaigns.used}/{this.props.usageStats.activeCampaigns.total})</small>;
  }

  maybeRenderEmailNotice() {
    if (!this.props.usageStats) {
      return null;
    }

    if ((this.props.usageStats.emails.total - this.props.usageStats.emails.used) > 10) {
      return null;
    }

    if (this.props.usageStats.emails.total - this.props.usageStats.emails.used === 0) {
      return <div className="alert alert-danger text-center" style={{ marginTop: '40px' }}>You have used all of your quota for Emails. Please upgrade your account for further service</div>;
    }

    return <div className="alert alert-warning text-center" style={{ marginTop: '40px' }}>You can only send email to {this.props.usageStats.emails.total - this.props.usageStats.emails.used} member(s). Please upgrade to higher package for uninterrupted service</div>;
  }

  redirectToAddInterface = () => {
    const canSendEmail = this.props.usage && this.props.usage.emails;
    const canAdd = this.props.usage && this.props.usage.activeCampaigns;

    if (canAdd && canSendEmail) {
      this.props.history.push('/add-campaign');
    } else {
      this.setState({ focusUpgradeNotice: true });
      setTimeout(() => this.setState({ focusUpgradeNotice: false }), 500);
      toast.error('You are not authorized to add new committee or send email. Please upgrade your account first');
    }
  }

  render() {
    // Getting list
    const {
      campList: { count, rows },
      isListLoading,
    } = this.props;
    const { showAlert, currentPage, limit, showPlayAlert } = this.state;

    if (isListLoading) {
      // loading time
      return <Loader />;
    }

    const canSendEmail = this.props.usage && this.props.usage.emails;
    const canAdd = this.props.usage && this.props.usage.activeCampaigns;

    // When component is loaded
    return (
      <div className="container">
          <TopHeader />
        <div className="csv_row heading-membership">
          <div className="btn_col">
            <Link className="backarrow" to="/administration-dashboard">
              <img alt="leftarrow" src={leftarrow} />
            </Link>
          </div>
          <h2>Emails {this.getStatsCount()}</h2>
          <div className="btn_col last">
            <a className="btn btn-primary" onClick={this.redirectToAddInterface}>
              <i className="fa fa-plus"></i> Create
            </a>
          </div>
        </div>
        { !canAdd || !canSendEmail ? <UpgradeSubscription focusIt={this.state.focusUpgradeNotice} justNotice={true} usage={this.props.usage} /> : null }
        {this.maybeRenderEmailNotice()}
        <div className="scrolling">
          <table className="table table-responsive table-campaign">
            <thead>
              <tr>
                <th>EMAIL NAME</th>
                <th>FILTER NAME</th>
                <th>CREATED BY</th>
                <th>CREATED ON</th>
                <th>LAST RUN</th>
                <th></th>
              </tr>
            </thead>
            <tbody>{this.listingCamp(rows)}</tbody>
          </table>
        </div>
        {count > 0 && (
          <div>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={limit}
              pageRangeDisplayed={5}
              totalItemsCount={count}
              onChange={this.handlePageChange}
            />
          </div>
        )}
        <SweetAlert
          danger
          focusCancelBtn
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Confirm"
          show={showAlert}
          title="Are you sure you want to delete this Email?"
          onCancel={() => this.handleDeletePop(false, '')}
          onConfirm={this.onConfirmDelete}
        />
        <SweetAlert
          focusCancelBtn
          info
          showCancel
          confirmBtnBsStyle="info"
          confirmBtnText="Confirm"
          show={showPlayAlert}
          title="Are you sure you want to play this Email?"
          onCancel={() => this.handlePlayPop(false, '')}
          onConfirm={this.onConfirmPlay}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ campaign: { campList, isListLoading }, userProfile: { usage, usageStats } }) => ({
  campList,
  isListLoading,
  usage,
  usageStats,
});

export default connect(mapStateToProps, {
  checkUserUsage,
  getUserUsageStats,
  campListing,
  deleteCamp,
  cloneCampaign,
  playCampaign,
})(ListCampaigns);
