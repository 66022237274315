import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteCampaignMember } from 'actions/campaign';

class InvitedMembers extends Component {
  handleEvent = (member, event) => {
    event.preventDefault();

    const payload = {
      ...member,
      // type: this.props.type
    };

    this.props.deleteCampaignMember(payload);

    if (this.props.onDeleted) {
      this.props.onDeleted(member);
    }
  };

  render() {
    return (
      <tbody>
        {this.props.selectedMembers.map((invitedMember) => (
            <tr key={invitedMember.id}>
              <td>
                {invitedMember.firstName} {invitedMember.lastName}
              </td>
              <td>{invitedMember.filterName}</td>
              <td></td>
              <td>
                <a
                  className="close"
                  onClick={(e) => this.handleEvent(invitedMember, e)}
                >
                  <i className="fa fa-times"></i>
                </a>
              </td>
            </tr>
        ))}
      </tbody>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  deleteCampaignMember,
})(InvitedMembers);
