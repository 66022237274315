/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  ADD_CREDIT_CARD,
  addCreditCardRequested,
  GET_CREDIT_CARD,
  getCreditCard,
  getCreditCardSuccess,
  getCreditCardRequested,
  UPDATE_CREDIT_CARD,
  updateCreditCardRequested,
  DELETE_CREDIT_CARD,
  deleteCreditCardSuccess,
  deleteCreditCardRequested,
} from 'actions/payments-action';
import HttpHelper, { handleResp } from 'utils/http-helper';
import User from 'utils/user';

const { authId } = new User();

const actions = (sAction, fAction = null, bAction = null) => ({ bAction, fAction, sAction });

const { deleteRequest, getRequest, postRequest, putRequest, prepareReq } = new HttpHelper();

export function* getCard(payload = { }) {
  const userId = authId();

  yield put(getCreditCardRequested());
  const response = yield call(getRequest, prepareReq(`account/list-cards/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(getCreditCardSuccess({ data: response.data.response }));
  }
}

export function* addNewCard({ payload }) {
  const userId = authId();

  yield put(addCreditCardRequested());
  const response = yield call(putRequest, prepareReq(`account/add-card/${payload.userId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(getCreditCard());
  }
}

export function* updateCard({ payload }) {
  const userId = authId();

  yield put(updateCreditCardRequested());
  const response = yield call(putRequest, prepareReq(`payment/card/${payload.id}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(getCreditCard());
  }
}

export function* deleteCard({ payload }) {
  const userId = authId();

  yield put(deleteCreditCardRequested());
  const response = yield call(deleteRequest, prepareReq(`payment/card/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(getCreditCard());
  }
}

function* Payments() {
  yield [
    takeLatest(ADD_CREDIT_CARD, addNewCard),
    takeLatest(GET_CREDIT_CARD, getCard),
    takeLatest(UPDATE_CREDIT_CARD, updateCard),
    takeLatest(DELETE_CREDIT_CARD, deleteCard),
  ];
}

export default Payments;
