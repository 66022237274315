/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import './SetttingsView.scss';
import { TopHeader } from 'components';
import leftarrow from 'assets/images/leftarrow.png';
import { updateUserMeta } from 'actions/profile-info-action';
import User from 'utils/user';
import General from './General';
import CustomFields from './CustomFields';
import Subscription from './Subscription';
import Emails from './Emails';
import PayPal from './PayPal';
import Cards from './Cards';
import Preferences from './Preferences';
import './index.scss';

const { getLoggedInData } = new User();

const Loader = require('react-loader');

class SettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fee: 0,
      enableUpcharge: false,
    };
    this.currentUserId = getLoggedInData();
  }

  togglUpChargeCredit = (e) => {
    this.setState({
      enableUpcharge: e.target.checked,
    });
  }

  saveAccountFee = (e) => {
    e.preventDefault();
    const { userDetails } = this.props;
    const amountOwnedMeta = userDetails && userDetails.metadata ? userDetails.metadata.find((o) => o.meta_key === 'account_charge') : false;
    const defaultAmountOwned = amountOwnedMeta ? parseFloat(amountOwnedMeta.meta_value) : 0;
    const payload = [
      {
        userId: this.currentUserId,
        meta_key: 'account_charge',
        meta_value: this.state.fee ? this.state.fee : defaultAmountOwned,
      },
      {
        userId: this.currentUserId,
        meta_key: 'enable_upcharge',
        meta_value: this.state.enableUpcharge,
      }];

    this.props.updateUserMeta(payload);
  }

  renderGeneralInterface = (slug) => {
    if (typeof slug !== 'undefined') return null;

    return <General />;
  }

  renderEmailInterface = (slug) => {
    if (typeof slug !== 'undefined') return null;

    return <Emails />;
  }

  renderSubscriptionInterface = (slug) => {
    if (slug !== 'subscriptions') return null;

    return <Subscription subscription={this.props.userDetails.Subscription} />;
  }

  renderCardInterface = (slug) => {
    if (slug !== 'cards') return null;

    return <Cards />;
  }

  renderPreferencesInterface = (slug) => {
    if (slug !== 'preferences') return null;

    return <Preferences metadata={this.props.userDetails.metadata}userId={this.currentUserId} onSubmit={this.props.updateUserMeta}/>;
  }

  renderCustomFieldInterface = (slug) => {
    if (typeof slug !== 'undefined') return null;

    return <CustomFields />;
  }

  renderPaymentInterface = (slug) => {
    if (slug !== 'payments') return null;

    const { userDetails, userProfile } = this.props;

    let isInterfaceReady = true;

    if (!userDetails.Organisation) {
      isInterfaceReady = false;
      // return null;
    }

    if (userProfile && userProfile.savingUserMeta) {
      isInterfaceReady = false;
    }

    const amountOwnedMeta = userDetails && userDetails.metadata ? userDetails.metadata.find((o) => o.meta_key === 'account_charge') : false;
    const amountOwned = amountOwnedMeta ? parseFloat(amountOwnedMeta.meta_value) : 0;

    const enableUpchargeMeta = userDetails && userDetails.metadata ? userDetails.metadata.find((o) => o.meta_key === 'enable_upcharge') : false;
    const enableUpcharge = enableUpchargeMeta ? enableUpchargeMeta.meta_value === 'true' : false;

    return (
        <React.Fragment>
          <div style={{ position: 'relative', padding: '50px' }}>
            <Loader loaded={isInterfaceReady}>
              <div className='row setting_topinfo'>
                <div className='col-sm-12'>
                  <FormGroup
                    check
                    style={{
                      marginBottom: '20px',
                    }}
                  >
                    <Input
                      checked={this.state.enableUpcharge ? this.state.enableUpcharge : enableUpcharge}
                      id="enable_up_charge"
                      type="checkbox"
                      onChange={this.togglUpChargeCredit}
                    />
                    <Label check for="enable_up_charge" style={{ fontSize: 'inherit' }}>Enable Upcharge Credit</Label>
                  </FormGroup>
                  {
                    this.state.enableUpcharge || enableUpcharge
                      ? <div className="form-group">
                          <label>Fee (In Percentage)</label>
                          <input
                            autoComplete="off"
                            className="form-control"
                            name="fee"
                            placeholder="Fee"
                            type="number"
                            value={this.state.fee ? this.state.fee : amountOwned}
                            onChange={(e) => this.setState({ fee: e.target.value })}
                          />
                        </div>
                      : null
                  }
                  <button className="btn btn-primary" onClick={this.saveAccountFee}>Save</button>
                </div>
              </div>
              <PayPal organisation={userDetails.Organisation} />
            </Loader>
          </div>
        </React.Fragment>
    );
  };

  render() {
    const { userDetails, isLoading, match: { params: { menuSlug } } } = this.props;

    if (!userDetails) {
      return null;
    }

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    return (
      <div className="container">
        <div className="Header-top">
          <TopHeader />
        </div>
        <div className="info container">
          <div className="csv_row heading-membership">
            <div className="btn_col">
              <Link className="backarrow" to="/administration-dashboard">
                <img alt="leftarrow" src={leftarrow} />
              </Link>
            </div>
            <h2>Settings</h2>
          </div>
          <div className='btn-nav' style={{ textAlign: 'center' }}>
            <Button
              outline
              active={typeof menuSlug === 'undefined'}
              color="primary"
              onClick={() => this.props.push('/settings')}
            >General</Button>
            <Button
              outline
              active={menuSlug === 'cards'}
              color="primary"
              onClick={() => this.props.push('/settings/cards')}
            >Cards</Button>
            <Button
              outline
              active={menuSlug === 'payments'}
              color="primary"
              onClick={() => this.props.push('/settings/payments')}
            >Payments</Button>
            <Button
              outline
              active={menuSlug === 'subscriptions'}
              color="primary"
              onClick={() => this.props.push('/settings/subscriptions')}
            >Subscriptions</Button>
            <Button
              outline
              active={menuSlug === 'preferences'}
              color="primary"
              onClick={() => this.props.push('/settings/preferences')}
            >Preferences</Button>
          </div>

          <div className="setting_info">
            {this.renderGeneralInterface(menuSlug)}
            {this.renderEmailInterface(menuSlug)}
            {this.renderSubscriptionInterface(menuSlug)}
            {this.renderPaymentInterface(menuSlug)}
            {this.renderCardInterface(menuSlug)}
            {this.renderPreferencesInterface(menuSlug)}
          </div>
          {this.renderCustomFieldInterface(menuSlug)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.settings.isLoading,
  userDetails: state.settings.userDetails,
  userProfile: state.settings.userProfile,
  ...state,
});

export default connect(mapStateToProps, { push, updateUserMeta })(SettingsView);
