/* eslint-disable filenames/match-exported */
import React from 'react';
import './Rsvp.scss';
import api from '../SettingStepProfile/Views/Services';

class Rsvp extends React.Component {
  /* eslint-disable no-useless-constructor */
  constructor(props) {
    super(props);
    const url = new URL(window.location.href);
    const rsvpId = url.pathname.split('/')[2];

    if (rsvpId) {
      api.hitRsvp(rsvpId);
    }
  }

  /* componentDidMount() {
    const url = new URL(window.location.href);
    const rsvpId = url.pathname.split('/')[2];

    if (rsvpId) {
      api.hitRsvp(rsvpId);
    }
  } */

  render() {
    return (
      <div className="sm">
        <div className="text-center successfully-text">
          <div className="centered-contentnew">
            <div className="check-new"></div>
            <h2>RSVP successfully updated.</h2>
          </div>
        </div>
      </div>
    );
  }
}

export default Rsvp;
