import React from 'react';
import './ProfileEmail.scss';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { formatDistance } from 'date-fns';
import { emailList } from 'actions/profile-info-action';

class ProfileEmail extends React.Component {
  // checking the props type
  static propTypes = {
    emailList: func.isRequired,
  };

  componentDidMount() {
    this.props.emailList();
  }

  render() {
    const {
      // eslint-disable-next-line no-shadow
      data: { emailList },
    } = this.props;

    return (
      <div className=" info">
        <table className="table table-responsive table-Campaign">
          <thead>
            <tr>
              <th>ACTION</th>
              <th>DETAILS</th>
              <th>DATE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {emailList.map((record) => (
              <tr key={record.id}>
                <td>{record.activity}</td>
                <td> {record.message} </td>
                <td>
                  {formatDistance(new Date(), new Date(record.createdAt), {
                    addSuffix: true,
                    includeSeconds: true,
                  })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.userProfile,
});

export default connect(mapStateToProps, {
  emailList,
})(ProfileEmail);
