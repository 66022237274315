import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { deleteCommiteeMember } from 'actions/committee';

class AddedMembers extends Component {
  handleEvent = (member, event) => {
    event.preventDefault();

    const payload = {
      ...member,
      type: this.props.type,
    };

    this.props.deleteCommiteeMember(payload);
  };

  render() {
    return (
      <ul>
        {this.props.selectedMembers.map((addedMember) => (
            <li key={addedMember.id}>
              <span>
                {addedMember.firstName} {addedMember.lastName}
              </span>{' '}
              <Link target="_blank" to={`/member/${addedMember.id}`}>
                <i aria-hidden="true" className="fa fa-external-link"></i>
              </Link>
              <a className="close" onClick={(e) => this.handleEvent(addedMember, e)}>
                <i className="fa fa-close"></i>
              </a>
            </li>
        ))}
      </ul>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  deleteCommiteeMember,
})(AddedMembers);
