/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import HttpHelper, { handleResp } from 'utils/http-helper';
import User from 'utils/user';
import {
  // WORK_INFO,
  // workInfo,
  csvImportRequested,
  CSV_IMPORT,
  // workInfoSuccess,
  // workInfoRequested,
  // ADDRESS_INFO,
  /* addressInfo,
  addressInfoSuccess,
  addressInfoRequested, */
  // WORK_INFO_UPDATE,
  /* workInfoUpdateSuccess,
  workInfoUpdateRequested, */
  // ADDRESS_INFO_UPDATE,
  customFieldRequested,
  CUSTOM_FIELD_LIST,
  customFieldListSuccess,
  customFieldListRequested,
  CUSTOM_FIELD_ADD,
  customFieldAddRequested,
  customFieldUpdate,
  CUSTOM_FIELD_UPDATE,
  CUSTOM_FIELD,
  customFieldUpdateRequested,
  /* addressInfoUpdateSuccess,
  addressInfoUpdateRequested, */
  ADD_IMPORTANT_MEMBER,
  addImportantMemberRequested,
  DELETE_IMPORTANT_MEMBER,
  deleteImportantMemberRequested,
  getImportantMembers,
  GET_IMPORTANT_MEMBERS,
  getImportantMemberRequested,
  getImportantMemberSuccess,
  UPDATE_IMPORTANT_MEMBER,
  updateImportantMemberRequested,
  ACCEPT_PROFILE_CHANGE,
  REJECT_PROFILE_CHANGE,
  acceptProfileChangeRequested,
  acceptProfileChangeSuccess,
  rejectProfileChangeRequested,
  rejectProfileChangeSuccess,
  /* ADD_TAGS,
  addTagsRequested, */
} from 'actions/member-view-action';
import { headerList, memberListView } from 'actions/profile-info-action';
// import { memberListView } from 'actions/profile-info-action';

const { getRequest, deleteRequest, postRequest, putRequest, prepareReq } = new HttpHelper();
const { authId } = new User();

/* export function* workInfoList({ payload }) {
  yield put(workInfoRequested());
  const response = yield call(getRequest, prepareReq(`works/list/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(workInfoSuccess({ data: response.data.response }));
  }
} */

/* export function* workInfoUpdate({ payload }) {
  yield put(workInfoUpdateRequested());
  const response = yield call(postRequest, prepareReq(`works/${payload.memberId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(workInfo(payload.memberId));
    yield put(workInfoUpdateSuccess({ data: { data: true } }));
  }
} */

/* export function* addressInfoList({ payload }) {
  yield put(addressInfoRequested());
  const response = yield call(getRequest, prepareReq(`member-info/list/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(addressInfoSuccess({ data: response.data.response }));
  }
}

export function* addressInfoUpdate({ payload }) {
  yield put(addressInfoUpdateRequested());
  const response = yield call(postRequest, prepareReq(`member-info/${payload.memberId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(addressInfo(payload.memberId));
    yield put(addressInfoUpdateSuccess({ data: { data: true } }));
  }
} */

export function* customFieldAdded({ payload }) {
  const userId = authId();

  const updatedPayload = {
    ...payload,
    userId,
  };

  yield put(customFieldRequested());
  const response = yield call(postRequest, prepareReq('custom-field/add/val', updatedPayload));

  if (yield* handleResp(response, {})) {
    if (updatedPayload?.memberId) {
      yield put(customFieldUpdate(updatedPayload));
    } else {
      yield put(headerList({ userId }));
    }
  }
}

export function* customFieldUpdateValue({ payload }) {
  yield put(customFieldUpdateRequested());
  const response = yield call(putRequest, prepareReq(`custom-field/update/${payload.memberId}`, payload));

  if ((yield* handleResp(response, {})) && payload.addf === 'false') {
    window.location.reload();
  }
}

export function* customFieldAddValue({ payload }) {
  const userId = authId();

  yield put(customFieldAddRequested());
  const response = yield call(postRequest, prepareReq(`custom-field/${userId}`, payload));

  yield* handleResp(response, {});
}

export function* customFieldListValue({ payload: { userId } }) {
  let user = authId();

  if (!user && userId) {
    user = userId;
  }

  yield put(customFieldListRequested());
  const response = yield call(getRequest, prepareReq(`custom-field/list/${user}`));

  if (yield* handleResp(response, {})) {
    yield put(
      customFieldListSuccess({
        data: response.data.response,
      }),
    );
    // yield put(memberListView(userId));
  }
}

export function* csvImport({ payload }) {
  const userId = authId();

  yield put(csvImportRequested());
  const response = yield call(postRequest, prepareReq(`myorganisation/csv/${userId}`, payload));

  yield* handleResp(response, {});
  yield put(push('/members-list'));
}

export function* fetchImportantMembers({ payload }) {
  console.log('payload: ', payload);
  yield put(getImportantMemberRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/important-members/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(getImportantMemberSuccess(response));
  }
}

export function* addImportantMember({ payload }) {
  const { memberId } = payload;

  yield put(addImportantMemberRequested());
  const response = yield call(postRequest, prepareReq(`myorganisation/important-members/${memberId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(getImportantMembers(memberId));
  }
}

export function* deleteImportantMember({ payload: { memberId, metaId } }) {
  yield put(deleteImportantMemberRequested());
  const response = yield call(deleteRequest, prepareReq(`myorganisation/important-members/${metaId}`));

  if (yield* handleResp(response, {})) {
    yield put(getImportantMembers(memberId));
  }
}

export function* updateImportantMember({ payload: { data, memberId } }) {
  yield put(updateImportantMemberRequested());
  const response = yield call(putRequest, prepareReq(`myorganisation/important-members/${memberId}`, data));

  if (yield* handleResp(response, {})) {
    yield put(getImportantMembers(memberId));
  }
}

export function* acceptProfileChangeRequest({ payload: { data, memberId } }) {
  yield put(acceptProfileChangeRequested());
  const response = yield call(putRequest, prepareReq(`myorganisation/accept-change-request/${memberId}`, data));

  if (yield* handleResp(response, {})) {
    yield put(acceptProfileChangeSuccess(memberId));
    yield put(memberListView(memberId));
  }
}

export function* rejectProfileChangeRequest({ payload }) {
  yield put(rejectProfileChangeRequested());
  const response = yield call(putRequest, prepareReq(`myorganisation/reject-change-request/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(rejectProfileChangeSuccess({ data: [] }));
    yield put(memberListView(payload));
  }
}

/* export function* addTags({ payload }) {
  const { memberId } = payload;

  yield put(addTagsRequested());
  const response = yield call(postRequest, prepareReq(`myorganisation/tags/${memberId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(tagsList(memberId));
  }
} */

function* MemberInfo() {
  yield [
    // takeLatest(WORK_INFO, workInfoList),
    // takeLatest(WORK_INFO_UPDATE, workInfoUpdate),
    // takeLatest(ADDRESS_INFO, addressInfoList),
    // takeLatest(ADDRESS_INFO_UPDATE, addressInfoUpdate),
    takeLatest(CUSTOM_FIELD, customFieldAdded),
    takeLatest(CUSTOM_FIELD_UPDATE, customFieldUpdateValue),
    takeLatest(CUSTOM_FIELD_LIST, customFieldListValue),
    takeLatest(CUSTOM_FIELD_ADD, customFieldAddValue),
    takeLatest(CSV_IMPORT, csvImport),
    takeLatest(GET_IMPORTANT_MEMBERS, fetchImportantMembers),
    takeLatest(ADD_IMPORTANT_MEMBER, addImportantMember),
    takeLatest(DELETE_IMPORTANT_MEMBER, deleteImportantMember),
    takeLatest(UPDATE_IMPORTANT_MEMBER, updateImportantMember),
    takeLatest(ACCEPT_PROFILE_CHANGE, acceptProfileChangeRequest),
    takeLatest(REJECT_PROFILE_CHANGE, rejectProfileChangeRequest),
    // takeLatest(ADD_TAGS, addTags),
  ];
}

export default MemberInfo;
