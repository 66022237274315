import React from 'react';
import './Profile.scss';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { func, shape, string } from 'prop-types';
import { TopHeader } from 'components';
import User from 'utils/user';
import { memberListView, myProfile } from 'actions/profile-info-action';
import ProfileMain from '../ProfileMain';
import ProfileInfo from '../ProfileInfo';
import ProfileCommittees from '../ProfileCommittees';
import ProfileEvents from '../ProfileEvents';
import ProfileEmail from '../ProfileEmail';

const { authUser } = new User();

class Profile extends React.Component {
  static propTypes = {
    memberListView: func.isRequired,
    myProfile: func.isRequired,
    paymentForm: shape({
      email: string,
      name: string,
    }),
  };

  static componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const userData = authUser();
    // const { member } = this.props;

    return (
      <div className="container">
        <div className="Header-top">
            <TopHeader />
        </div>
        <div className="container">
          <ProfileMain userData={userData.user} />
          <div className="tab_bar">
            <Tabs>
              <TabList>
                <Tab>Info</Tab>
                <Tab>Committees</Tab>
                <Tab>Events</Tab>
                <Tab>Email</Tab>
              </TabList>

              <TabPanel>
                <ProfileInfo userData={userData.user} />
              </TabPanel>
              <TabPanel>
                <ProfileCommittees />
              </TabPanel>
              <TabPanel>
                <ProfileEvents />
              </TabPanel>
              <TabPanel>
                <ProfileEmail />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => (
  {
    member: state.userProfile.memberView,
  }
);

export default connect(mapStateToProps, {
  memberListView,
  myProfile,
})(Profile);
