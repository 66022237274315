import React from 'react';
import { connect } from 'react-redux';
import { getTransactionDetail } from 'actions/profile-info-action';
import { func } from 'prop-types';
import './ProfilePayment.scss';

class PaymentCompleted extends React.Component {
  static propTypes = {
    getTransactionDetail: func.isRequired,
  };

  /* state = {
    gettingTransactionDetails: true,
    transactionId: null,
    description: '',
    amount: '',
    currency: '',
    recipient_name: '',
  }; */

  componentDidMount() {
    const URLParams = new URLSearchParams(this.props.location.search);
    const paymentId = URLParams.get('paymentId');
    const token = URLParams.get('token');
    const PayerID = URLParams.get('PayerID');

    if (paymentId && token && PayerID) {
      this.props.getTransactionDetail({
        paymentId,
        token,
        PayerID,
      });
    }

    // console.log('componentDidMount: ', this.props);
  }

  render() {
    if (!this.props) {
      return null;
    }

    if (this.props.gettingTransactionDetails) {
      return null;
    }

    if (!this.props.transaction) {
      return null;
    }

    return (
      <div className="container payment-block">
        <div className="container-inner">
          <h1>Thank you for the payment</h1>
          <p className="lead">You will receive an email regarding the payment</p>
          <br/>
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="bg-primary">Transaction No</td>
                      <td style={{ fontSize: '20px' }}>{this.props.transaction.transactionId}</td>
                    </tr>
                    <tr>
                      <td className="bg-primary">Amount</td>
                      <td style={{ fontSize: '20px' }}><span>{this.props.transaction.symbol}</span>{this.props.transaction.amount} <span>({this.props.transaction.currency})</span></td>
                    </tr>
                    <tr>
                      <td className="bg-primary">Paid To</td>
                      <td style={{ fontSize: '20px' }}>{this.props.transaction.recipient_name}</td>
                    </tr>
                    <tr>
                      <td className="bg-primary">Notes</td>
                      <td style={{ fontSize: '20px' }}>{this.props.transaction.description}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
          <a className="btn btn-success btn-lg" href="/"><i className="fa fa-home"></i> Go Back</a>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state,
  transaction: state.userProfile.transaction,
  gettingTransactionDetails: state.userProfile.gettingTransactionDetails,
});

export default connect(mapStateToProps, { getTransactionDetail })(PaymentCompleted);
