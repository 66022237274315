/* eslint-disable max-len */

module.exports = {
  lang: 'en',
  messages: {
    active: 'Active',
    activeUsers: 'Active',
    activity: 'Activity',
    add: 'Add',
    addNewEnterprise: 'Add New Enterprise',
    addNewFolder: 'Add New Folder',
    addNewGroup: 'Add New Group',
    addNewUser: 'Add New User',
    addNewVariables: 'Add New Variables',
    addUser: 'Add User',
    all: 'All',
    allFields: 'All Fields',
    alreadyPasswordConfirmation: 'Already have a password? LOGIN',
    atLeastLowerChar: 'Atleast one lower case character',
    atLeastNumericChar: 'Atleast one numeric character',
    atLeastSixChar: 'Atleast six characters',
    atLeastSpecialChar: 'Atleast one special character',
    atLeastUpperChar: 'Atleast one upper case character',
    aud: 'Aud',
    back: 'Back',
    backToAdmin: 'Back to Admin',
    calculate: 'Calculate',
    callOrScheduleDemo: 'Call or email to schedule a demo',
    canNotDeleteGroupsHavingUsers: 'You can not delete the group having users',
    cancel: 'Cancel',
    captchaRequired: 'Captcha is required',
    cells: 'Cells',
    characterPerLine: 'Character per line',
    close: 'Close',
    code: 'Code',
    columnDataWithSize: 'Column data with size',
    columns: 'Columns',
    companyAddress: '576 Fifth Avenue, Suite 903 New York, NY 10036-4825',
    complete: 'Complete',
    complexPassword: 'Password shall be at least six characters long with atleast one special character, one numeric value and one character in upper case and lower case',
    confirmPassword: 'Confirm password',
    contactAdministator: 'Incase you forgot your email, please contact your administrator',
    contactSupport: 'Contact Support',
    contactUs: 'Contact Us',
    countryName: 'United States Headquarters',
    csv: 'Csv',
    customOrgTypeError: 'Please provide name of other organization type',
    dataItem: 'Data Item',
    date: 'Date',
    delete: 'Delete',
    deleteDataset: 'Delete Dataset',
    deleteGroup: 'Delete Group',
    deleteGroupSuccessfully: 'Group Deleted Successfully',
    deleteSurvey: 'Delete Survey',
    deleteThisFolder: 'Delete This Folder',
    description: 'Description',
    dictionaryElements: 'Dictionary Elements',
    dictionaryFileErrorMessageForCsvAndXlsxFile: 'Dictionary file should not be txt file for this type of survey Please upload CSV or XLSX file',
    dictionaryFileErrorMessageForTxtFile: 'Dictionary file should be .txt file for this type of survey. Please upload .txt file.',
    dictionaryFileName: 'Dictionary File Name',
    dictionaryIsRequiredForASCII: 'Dictionary file is required for ASCII survey, please import dictionary file to upload survey.',
    displaySettings: 'Display Settings',
    displaySurveyPane: 'Display Survey Pane',
    doYouWantToDelete: 'Are you sure you want to delete ',
    doYouWantToDeleteGroup: 'Are you sure you want to delete this group?',
    doYouWantToDeleteSurvey: 'Are you sure you want to delete this survey?',
    editAGroup: 'Edit a Group',
    editEnterprise: 'Edit Enterprise',
    editFolderName: 'Edit Folder Name',
    editGroup: 'Edit a Group',
    editSurveyName: 'Edit Survey Name',
    editUser: 'Edit User',
    email: 'Email',
    emailAddress: 'Email Address',
    emailId: 'Email Id',
    emailValidation: 'Please enter valid email address',
    emptyBillingMethod: 'Please select billing method',
    emptyCampaignName: 'Campaign name is required',
    emptyCardCVV: 'Empty CVV number',
    emptyCardName: 'Please enter card name',
    emptyCardNumber: 'Please enter card number',
    emptyCardNumberInvalid: 'Invalid card number',
    emptyCommitteeName: 'Committee name is required',
    emptyCommitteeType: 'Committee type is required',
    emptyCouponCode: 'Empty coupon card',
    emptyEmailBody: 'Email body is required',
    emptyEmailSubject: 'Email subject is required',
    emptyEmailValidation: 'Please enter email address',
    emptyEnterpriseName: 'Please enter enterprise name',
    emptyExpYear: 'Empty expiry year',
    emptyExpireMonth: 'Empty expiry month',
    emptyFilterFieldValidation: 'Filter name is required',
    emptyFirstNameValidation: 'First name is required',
    emptyFutureDate: 'Please provide date',
    emptyFutureHour: 'Please provide hours',
    emptyFutureMinute: 'Please provide minutes',
    emptyLastNameValidation: 'Last name is required',
    emptyMessage: 'Please enter Message',
    emptyMiddleNameValidation: 'Middle name is required',
    emptyName: 'Please enter name',
    emptyNameValidation: 'First name is required',
    emptyNumberOfOccurences: 'Please provide number of occurences',
    emptyOrgType: 'Organization type is required',
    emptyOrganisationName: ' Organization name is required',
    emptyOrganisationType: ' Organization type is required',
    emptyPassword: 'Please enter password',
    emptyPasswordValidation: 'Please enter password',
    emptyPaymentMethod: 'Please select payment method',
    emptyProfile: 'Profile image is required',
    emptySubscriptionType: 'Please select subscription type',
    emptyTimeZone: 'Please select timezone',
    emptyTitleValidation: 'Title is required',
    emptyYourName: 'Please enter your name',
    emptyfieldValueValidation: 'Empty value name',
    english: 'English',
    enterConfirmPassword: 'Enter Confirm Password',
    enterEmailAddress: 'Enter Email Address',
    enterFolderName: 'Enter Folder Name',
    enterGroupName: 'Enter group name',
    enterName: 'Enter Name',
    enterNewGroupName: 'Enter New Group Name',
    enterNewPassword: 'Enter New Password',
    enterPassword: 'Enter Password',
    enterPhoneNumber: 'Enter Your Phone Number',
    enterRoleName: 'Enter role name',
    enterSurveyCode: 'Enter Survey Code',
    enterSurveyName: 'Enter Survey Name',
    enterTimeFormat: 'Enterprise Time Format',
    enterTitle: 'Enter Title',
    enterUserName: 'Enter User Name',
    enterVariableDescription: 'Enter variable Description here',
    enterVariableName: 'Enter Variable Name',
    enterYourMessage: 'Enter Your Message',
    enterYourName: 'Enter Your Name',
    enterpriseLifecycleEndDate: 'Enterprise Lifecycle End Date',
    enterpriseLogo: 'Enterprise Logo',
    enterpriseName: 'Enterprise Name',
    enterpriseNameAndEmail: 'Enterprise admin needs to be added to create an enterprise. Please add an enterprise admin\'s name and Email.',
    error: 'Error',
    errorCode404: 'Error Code: 404',
    errorContactMessage: 'Error while contact, Please try again.',
    errorDeletedSurvey: 'Error while deleting survey.',
    errorEnterpriseCreatedSuccessfully: 'Error while creating enterprise, Please try again.',
    errorEnterpriseUpdatedSuccessfully: 'Error while updating enterprise, Please try again.',
    errorForgotPasswordNotification: 'Error while sending email, Please try again.',
    errorInLoadingReportSettings: 'Error in loading report settings',
    errorSetPasswordNotification: 'Error while setting new password, Please Try again.',
    errorUserCreateNotification: 'Error while creating user, Please try again.',
    errorUserUpdateNotification: 'Error in data update, Please try again.',
    errorWhileCreatingUser: 'There is some technical issue while creating user, Please try after sometime.',
    expirationDate: 'Expiration Date',
    exportAs: 'Export as',
    expression: 'Expression',
    fieldNameValidation: 'Empty field name',
    fieldRequiredErrorMessage: 'This is required field.',
    fileContainDictionary: 'My Survey Data File Contains Dictionary',
    fileUploadProcessInProgress: 'File upload to server is in progress. Please wait!!',
    filter: 'Filter',
    filters: 'Filters',
    folderDescription: 'Folder Description',
    folderName: 'Folder Name',
    footnotes: 'Footnotes',
    forgotPassword: 'Forgot Password?',
    french: 'French',
    fullScreenPreview: 'Full screen Preview',
    futureDateValidation: 'The date inputted shall be a future date',
    genderError: 'Gender field is required',
    group: 'Group',
    groupAddedSuccessfully: 'Group added successfully.',
    groupName: 'Group Name',
    groupNameValidation: 'Please input group name.',
    groupPermission: 'Group Permission',
    groupUpdatedSuccessfully: 'Group Updated Successfully',
    headingsFooterNotes: 'Headings/Footernotes',
    help: 'Help',
    horiz: '% Horiz',
    htmlTagsError: 'Html tags are not allowed',
    inProgress: 'In Progress',
    inactive: 'InActive',
    inactiveUsers: 'Inactive',
    includeThisInReport: 'include this in report',
    index: 'index',
    internalServerError: 'Internal server error.',
    invalidCardNumberInvalid: 'Invalid cvv number',
    invalidExpYear: 'Invalid expiry year',
    invalidExpireMonth: 'Invalid expiry month',
    invalidField: 'This field is invalid',
    invalidFieldName: 'Label length must be greater than 2 characters',
    invalidFieldNameValidation: 'Invalid field name',
    invalidFileFormat: 'Failed to upload file the format is not supported',
    invalidFilterFieldValidation: 'Invalid name is required',
    invalidLnameValidation: 'Last name length must be greater than 2 characters',
    invalidMnameValidation: 'Invalid middle name',
    invalidNameValidation: 'First name length must be greater than 2 characters',
    invalidNnameValidation: 'Invalid nick name',
    invalidNotesValidation: 'Invalid notes',
    invalidOptInValidation: 'Please say yes to optIn',
    invalidOrgNameValidation: 'Organization name length must be greater than 2 characters',
    invalidSuffixValidation: 'Invalid suffix',
    invalidTime: 'Selected time should be greater than current time',
    invalidTitleValidation: 'Invalid title',
    invalidfieldValueValidation: 'Invalid value name',
    isFileContainHeader: 'Survey data file contains headers',
    language: 'Language',
    lastLogin: 'Last Login',
    lengthValidation: 'Length should be less than 15 digits',
    linerPerPage: 'Line per page',
    linerPerRowLabelWithSize: 'Line per row label with size',
    listAllChildFolders: 'List all child folders.',
    logIntoYourAccount: 'Login into your account',
    login: 'Login',
    logoSize200: 'Recommended size: 100 kb',
    logout: 'Logout',
    logoutNotification: 'You are logged out successfully',
    manageCustomPermissions: 'Manage Custom Permissions',
    manageEnterprise: 'Manage Enterprise',
    manageSurvey: 'Manage Survey',
    manageUser: 'Manage User',
    manageUsers: 'Manage Users',
    manangeEnterpriseSettings: 'Manage Enterprise Settings',
    maritalError: 'Marital status is required',
    matchPasswordValidation: 'The Password and Confirm Password do not match',
    message: 'Message',
    minLengthCommitteeName: 'Minimum 2 characters required',
    myProfile: 'My Profile',
    mySurvey: 'My Survey',
    mySurveys: 'My Surveys',
    name: 'Name',
    newAgeMedia: 'New Age Media',
    newGroupName: 'New Group Name',
    newPassword: 'New Password',
    newSurveyUploaded: 'Survey file has been uploaded successfully. Now your file is in processing queue. You will get notification once files is processed.',
    newVariableAddedSuccessfully: 'New variable added successfully.',
    no: 'No',
    noDataToDisplay: 'There is no data to display',
    noMembersAdded: 'Please add any member in committeee',
    noMembersInvited: 'Please add any member in campaign',
    noSurveyUploadedYet: 'No survey uploaded yet.',
    nonPaginatedStack: 'Non-Paginated stacked targets',
    noteSettingWeight: 'Note:use \'house\' for household weight file',
    numberOfOccurencesNotNumber: 'Number of occurences should be number',
    numberOfUserLicenses: 'Number of User Licenses',
    outOf: 'out of',
    pageLayout: 'Page Layout',
    paginatedOnePagePerSheet: 'Paginated one page per sheet',
    paginatedStack: 'Paginated stacked targets',
    parent: 'Parent',
    parentFolder: 'Parent Folder',
    password: 'Password',
    passwordComplexity: 'Password Complexity',
    passwordLink: 'A password reset link will be emailed to you provided that account with this email exists.',
    passwordMismatch: 'Password mismatch. Please try again.',
    // eslint-disable-next-line max-len
    passwordValidation: 'Please enter a strong password. The password must have atleast six characters long with  1 Lowercase and 1 uppercase, numeric character, special symbol.',
    pdf: 'Pdf',
    pending: 'Pending',
    permissionName: 'Permission',
    phoneNumber: 'Phone Number',
    phoneNumberValidation: 'Please enter numeric value',
    pleaseSelectGroup: 'Please select a group',
    pleaseSelectPermission: 'Please select a permission',
    printFileNameOfReport: 'Print file name of report',
    printInputListWithReport: 'Print input list with report',
    printReportHeading: 'Print report heading',
    printReportProgramName: 'Print report program name',
    profile: 'Profile',
    profileUpdate: 'Profile has been updated successfully',
    reportOptions: 'Report Options',
    requiredField: 'This field is required',
    invalidCharsInField: 'The field cannot have special characters',
    resetFilters: 'Reset Filters',
    resetPasswordNotification: 'Please enter your email address below and we will send you instructions to reset your password.',
    respondents: 'Respondents',
    results: 'results',
    returnLoginLink: 'Return back to login screen',
    returnToPreviousScreen: 'Return back to previous screen',
    roleName: 'Role Name',
    rows: 'Rows',
    save: 'Save',
    search: 'Search',
    searchUsers: 'Search Users',
    selectGroup: 'Please select a group.',
    selectSurvey: 'Please select survey',
    selectValueBySearch: 'Please select value by filter',
    selectWeightColumnName: 'Select weight column name',
    selectaGroupNameToEdit: 'Select a Group Name To Edit',
    sendInstructions: 'Send Instructions',
    sendUsMessage: 'Send Us Message',
    sendUsMessageDescription: 'You can contact us with anything related to our product. We will get in touch with you as soon as possible.',
    serverIsNotProcessingRequest: 'Error in request processing, please try again later',
    setNewPassword: 'Set New Password',
    setNewPasswordLabel: 'Set a New Password',
    shareSurvey: 'Share Survey',
    showing: 'showing',
    signOut: 'Sign Out',
    spacingBetweenColumns: 'Spacing between columns',
    status: 'Status',
    successCSVMessageSuccessfully: 'File uploaded successfully',
    successContactMessageSuccessfully: 'Contact Send.',
    successDeletedSurvey: 'Survey Deleted Successfully.',
    successEnterpriseCreatedSuccessfully: 'Enterprise Created Succcessfully.',
    successEnterpriseUpdatedSuccessfully: 'Enterprise Updated Succcessfully.',
    successForgotPasswordNotification: 'A password reset link will be emailed to you provided that account with this email exists.',
    successSetPasswordNotification: 'Your password has been updated successfully.',
    successUserCreateNotification: 'User is created Successfully.User will receive a confirmation email with link to configure password for the user account.',
    successUserUpdateNotification: 'User successfully updated.',
    suppressDuplicateColumnsHeadings: 'Suppress duplicate columns headings',
    suppressDuplicateRowsHeadings: 'Suppress duplicate rows headings',
    survey: 'Survey',
    surveyCode: 'Survey Code',
    surveyCodeIsNotUnique: 'Code is already is in use, please enter a unique code',
    surveyDataFile: 'Survey Data File',
    surveyDescription: 'Survey Description',
    surveyDictionaryFile: 'Survey Dictionary File',
    surveyInProgressUploadedError: 'A survey has encountered some error, Please check the uploaded file.',
    surveyInProgressUploadedSuccess: 'Your survey has been processed Please check Survey/Activity List.',
    surveyIsWeighted: 'Survey Is Weighted',
    surveyName: 'Survey Name',
    switchEnterprise: 'Switch Enterprise',
    timeZone: 'Time Zone',
    title: 'Title',
    to: 'to',
    total: 'Total',
    totalUserLicense: 'Total User Licenses',
    update: 'Update',
    updateSurveyDataPopup: 'Update Survey',
    upload: 'Upload',
    uploadAndMergeSurvey: 'Upload and Merge Survey',
    uploadDataset: 'Upload Dataset',
    uploadFile: 'Upload a file',
    uploadSurvey: 'Upload Survey',
    uploadedFileSize: 'Size should be less than 100kb',
    userEmail: 'User Email',
    userGroup: 'User Group',
    userLicensesAvailable: 'Total User Licenses',
    userName: 'User Name',
    userNameValidation: 'Please input user\'s name.',
    userPermission: 'User Permission',
    users: 'Users',
    variableDescription: 'variable Description',
    variableName: 'Variable Name',
    vert: '% Vert',
    viewDictionary: 'View Dictionary',
    viewRespondentData: 'View Respondent Data',
    viewVariables: 'View Variables',
    weCouldNotFindPage: 'We could not find the page you are looking for',
    weight: 'weight',
    weightFile: 'Weight File',
    worksheetOptions: 'WorkSheet Options',
    xls: 'Xls',
    xtab: 'XTAB',
    yes: 'Yes',
    yourName: 'Your Name',
    emailLimitExceedsForCampaign: 'Sorry, you donot have sufficient quota available for sending email to all these members. Please upgrade your account',
  },
};
