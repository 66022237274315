import { createAction } from 'redux-actions';

export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD';
export const addCreditCard = createAction(ADD_CREDIT_CARD);

export const ADD_CREDIT_CARD_FAILURE = 'ADD_CREDIT_CARD_FAILURE';
export const addCreditCardFailure = createAction(ADD_CREDIT_CARD_FAILURE);

export const ADD_CREDIT_CARD_SUCCESS = 'ADD_CREDIT_CARD_SUCCESS';
export const addCreditCardSuccess = createAction(ADD_CREDIT_CARD_SUCCESS);

export const ADD_CREDIT_CARD_REQUESTED = 'ADD_CREDIT_CARD_REQUESTED';
export const addCreditCardRequested = createAction(ADD_CREDIT_CARD_REQUESTED);

export const GET_CREDIT_CARD = 'GET_CREDIT_CARD';
export const getCreditCard = createAction(GET_CREDIT_CARD);

export const GET_CREDIT_CARD_FAILURE = 'GET_CREDIT_CARD_FAILURE';
export const getCreditCardFailure = createAction(GET_CREDIT_CARD_FAILURE);

export const GET_CREDIT_CARD_SUCCESS = 'GET_CREDIT_CARD_SUCCESS';
export const getCreditCardSuccess = createAction(GET_CREDIT_CARD_SUCCESS);

export const GET_CREDIT_CARD_REQUESTED = 'GET_CREDIT_CARD_REQUESTED';
export const getCreditCardRequested = createAction(GET_CREDIT_CARD_REQUESTED);

export const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';
export const updateCreditCard = createAction(UPDATE_CREDIT_CARD);

export const UPDATE_CREDIT_CARD_FAILURE = 'UPDATE_CREDIT_CARD_FAILURE';
export const updateCreditCardFailure = createAction(UPDATE_CREDIT_CARD_FAILURE);

export const UPDATE_CREDIT_CARD_SUCCESS = 'UPDATE_CREDIT_CARD_SUCCESS';
export const updateCreditCardSuccess = createAction(UPDATE_CREDIT_CARD_SUCCESS);

export const UPDATE_CREDIT_CARD_REQUESTED = 'UPDATE_CREDIT_CARD_REQUESTED';
export const updateCreditCardRequested = createAction(UPDATE_CREDIT_CARD_REQUESTED);

export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const deleteCreditCard = createAction(DELETE_CREDIT_CARD);

export const DELETE_CREDIT_CARD_FAILURE = 'DELETE_CREDIT_CARD_FAILURE';
export const deleteCreditCardFailure = createAction(DELETE_CREDIT_CARD_FAILURE);

export const DELETE_CREDIT_CARD_SUCCESS = 'DELETE_CREDIT_CARD_SUCCESS';
export const deleteCreditCardSuccess = createAction(DELETE_CREDIT_CARD_SUCCESS);

export const DELETE_CREDIT_CARD_REQUESTED = 'DELETE_CREDIT_CARD_REQUESTED';
export const deleteCreditCardRequested = createAction(DELETE_CREDIT_CARD_REQUESTED);
