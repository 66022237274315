/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import './BuildList.scss';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'components';
import { filterDataListing, getUserUsageStats } from 'actions/profile-info-action';
import {
  getFilterMembers,
  addCampaignStepOne,
  addCampaignStepOneRequested,
  searchMemberRequested,
} from 'actions/campaign';

import { searchMembers } from 'actions/committee';
import NoMemberInvited from './NoMemberInvited';
import InvitedMembers from './InvitedMembers';
import SearchMembers from './SearchMembers';

const Loader = require('react-loader');

class BuildList extends React.Component {
  state = {
    errors: {},
    selectedFilterIds: [],
    selectedFilter: [],
    showFilterList: false,
  };

  componentDidMount() {
    this.props.filterDataListing();
    this.props.getUserUsageStats();

    if (Object.keys(this.props.stepOneValues).length) {
      this.setState({ campaignName: this.props.stepOneValues.campaignName });
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if(this.props.stepOneValues && (this.props.stepOneValues !==  prevProps.stepOneValues)) {
  //     this.setState({ campaignName : this.props.stepOneValues.campaignName });
  //   }
  // }

  toggleFilter = () => {
    this.setState({ showFilterList: !this.state.showFilterList });
  };

  addFilter = (event, filter) => {
    event.preventDefault();

    const { selectedFilter } = this.state;

    let { selectedFilterIds } = this.state;

    selectedFilterIds = selectedFilterIds.concat(filter.id);

    this.props.getFilterMembers(filter);

    if (!selectedFilter.includes(filter.key)) {
      selectedFilter.push(filter.key);
    }

    this.setState({
      selectedFilterIds,
      selectedFilter,
    });

    console.log(selectedFilterIds);
  };

  getMembers = (event) => {
    event.preventDefault();

    let keyword = event.target.value.trim();

    this.props.searchMemberRequested();

    const checkHtmlTags = /(<([^>]+)>)/gi.test(keyword);

    if (keyword && !checkHtmlTags) {
      this.props.searchMembers(keyword);
    }

    if (checkHtmlTags) {
      keyword = '';
    }

    this.setState({
      searchKeyword: keyword,
    });
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  onValidateHandler = () => {
    const errors = {};

    const campaignName = this.state.campaignName
      ? this.state.campaignName
      : this.props.stepOneValues.campaignName;

    console.log('campaignName............', campaignName);

    if (!campaignName || (campaignName && !campaignName.trim())) {
      errors.campaignName = 'emptyCampaignName';
    }

    if (campaignName && campaignName.length < 2) {
      errors.campaignName = 'minLengthCommitteeName';
    }

    if (!this.props.selectedMembers.length) {
      errors.noMembers = 'noMembersInvited';
    }

    this.setState({ errors });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  onInvitedMemberDeleted = (member) => {
    const activeFilters = [];

    this.props.selectedMembers.forEach((m) => {
      if (m.id !== member.id && m.filter) {
        activeFilters.push(m.filter);
      }
    });

    this.setState({
      selectedFilter: activeFilters,
    });
  }

  onChangeHandler = (event) => {
    const {
      target: { name, value },
    } = event;

    this.setState({ formSubmit: false, [name]: value });
  };

  onMemberAdded = () => console.log('onMemberAdded: Check for usage');

  saveAndContinue = (event) => {
    event.preventDefault();

    this.setState({ formSubmit: true });

    const isValid = this.onValidateHandler();

    if (isValid) {
      const selectedFilterNames = [];

      const selectedMembers = this.props.selectedMembers.length
        ? this.props.selectedMembers.map((eachMember) => {
          const member = {
            filterName: eachMember.filterName ? eachMember.filterName : '',
            memberId: eachMember.id,
          };

          if (eachMember.filterName) {
            selectedFilterNames.push(eachMember.filterName);
          }

          return member;
        })
        : [];

      let filterNames = '';

      if (selectedFilterNames.length) {
        const uniqueArray = [...new Set(selectedFilterNames)];

        filterNames = uniqueArray.join(', ');
      }

      let selectedFilterIds = [...this.state.selectedFilterIds];

      if (
        this.props.stepOneValues
        && this.props.stepOneValues.filterIds
        && this.props.stepOneValues.filterIds.length
      ) {
        selectedFilterIds = [
          ...this.props.stepOneValues.filterIds,
          ...this.state.selectedFilterIds,
        ];
      }

      const formData = {
        campaignId:
          this.props.stepOneValues && this.props.stepOneValues.id
            ? this.props.stepOneValues.id
            : '',
        campaignName: this.state.campaignName
          ? this.state.campaignName.trim()
          : this.props.stepOneValues.campaignName
            && this.props.stepOneValues.campaignName.trim(),
        // filterIds: this.state.selectedFilterIds,
        filterIds: selectedFilterIds,
        filterNames,
        members: selectedMembers,
      };

      this.props.addCampaignStepOne(formData);
    }
  };

  maybeShowTotalMembersCount() {
    if (!this.props.usageStats || !this.props.usageStats.members) {
      return null;
    }

    const totalMembersLimit = this.props.usageStats.members.total;
    const totalUsed = this.props.usageStats.members.used;
    const totalMembers = this.props.selectedMembers.length;

    return (
      <small>({totalMembers + totalUsed}/{totalMembersLimit})</small>
    );
  }

  maybeRenderSubmitButton() {
    if (this.props.usageStats && this.props.usageStats.emails) {
      const totalEmailLimit = this.props.usageStats.emails.total;
      const totalUsed = this.props.usageStats.emails.used;
      const totalMembers = this.props.selectedMembers.length;

      if ((totalEmailLimit > totalMembers) && (totalEmailLimit >= totalMembers + totalUsed)) {
        return (
          <button className="btn btn-primary">
            Next<i className="fa fa-next"></i>
          </button>
        );
      }

      return this.showError('emailLimitExceedsForCampaign');
    }

    return null;
  }

  render() {
    const {
      filtersList,
      selectedMembers,
      membersList,
      isLoading,
      apiErrors,
      stepOneValues,
      campaignId,
    } = this.props;

    const {
      searchKeyword,
      showFilterList,
      // selectedFilterIds,
      selectedFilter,
      errors: { campaignName, noMembers },
      formSubmit,
    } = this.state;

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    {
      formSubmit
        && apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: 1500,
        });
    }

    return (
      <form noValidate onSubmit={this.saveAndContinue}>
        <div className="panel">
          <div className="form-group">
            <label>Campaign Name</label>
            <input
              className="form-control"
              defaultValue={campaignId ? stepOneValues.campaignName : ''}
              name="campaignName"
              placeholder="Give a name to your Campaign"
              type="text"
              onChange={this.onChangeHandler}
            />
            {campaignName && this.showError(campaignName)}
          </div>

          <div className="form-group">
            <h3>Invite Members {this.maybeShowTotalMembersCount()}</h3>
          </div>

          <div className="form-group drop-selector-new">
            <label>Select filter to add a group</label>
            {filtersList && filtersList.length ? (
              <div onClick={this.toggleFilter}>
                <span className="form-control selecter-span">Select</span>
                <i aria-hidden="true" className="fa fa-caret-down"></i>
              </div>
            ) : (
              <span className="form-control selecter-span">No Filter available</span>
            )}
            {showFilterList && (
              <ul className="selected_list checklist copy-listul">
                {filtersList.map((eachFilter) => {
                  let alreadyAdded = selectedFilter.includes(eachFilter.key);
                  // let alreadyAdded = selectedFilterIds.includes(eachFilter.id);

                  if (
                    stepOneValues
                    && stepOneValues.filterIds
                    && stepOneValues.filterIds.length
                  ) {
                    alreadyAdded = stepOneValues.filterIds.includes(eachFilter.id);
                  }

                  return (
                    <li key={eachFilter.id}>
                      {eachFilter.filterName}
                      {alreadyAdded ? (
                        <span className="addbtn-new added-btn">added</span>
                      ) : (
                        <span
                          className="addbtn-new"
                          onClick={(e) => {
                            this.addFilter(e, eachFilter);
                          }}
                        >
                          add
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>

          <div className="form-group">
            <label>Search to add individuals</label>
            <div className="form-group dropnew-alignment">
              <input
                className="form-control search-input"
                placeholder="Search member to invite them"
                type="text"
                onFocus={this.getMembers}
                onKeyUp={this.getMembers}
              />
              {searchKeyword && membersList && (
                <SearchMembers membersList={membersList} onAdded={this.onMemberAdded} />
              )}
            </div>
          </div>

          <div className="form-group">
            <table className="table table_unfix">
              <thead>
                <tr>
                  <th>Invited</th>
                  <th>Filter List</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              {selectedMembers.length ? (
                <InvitedMembers
                  selectedMembers={selectedMembers}
                  onDeleted={this.onInvitedMemberDeleted}
                />
              ) : (
                <NoMemberInvited />
              )}
            </table>
          </div>
          {noMembers && this.showError(noMembers)}
          {this.maybeRenderSubmitButton()}
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ userProfile, campaign, committee }) => ({
  apiErrors: campaign.addCampaignError,
  filtersList: userProfile.filterListing.data,
  usageStats: userProfile.usageStats,
  isLoading: campaign.isLoading,
  membersList: committee.membersList,
  selectedMembers: campaign.selectedMembers,
  stepOneValues: campaign.stepOneValues,
});

export default connect(mapStateToProps, {
  addCampaignStepOne,
  addCampaignStepOneRequested,
  filterDataListing,
  getFilterMembers,
  searchMemberRequested,
  searchMembers,
  getUserUsageStats,
})(BuildList);
