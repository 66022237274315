/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable no-plusplus */
import React from 'react';
import './MembersListTable.scss';
import CSVIMG from 'assets/images/csv.png';
import leftarrow from 'assets/images/leftarrow.png';
import {
  checkUserUsage,
  getUserUsageStats,
  memberList,
  headerList,
  filterList,
  orderByDataList,
  filterDataList,
  filterDataListing,
  deletedMember,
} from 'actions/profile-info-action';
import Modal from 'react-responsive-modal';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { func } from 'prop-types';
import { TopHeader, FormattedMessage, UpgradeSubscription } from 'components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import User from 'utils/user';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import CsvLink from 'react-csv-export';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Storage from 'utils/storage';
import { step3Form } from 'actions/settings-step-action';

import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import groupicon from 'assets/images/group-icon.png';
import socketIOClient from 'socket.io-client';

const socket = socketIOClient(process.env.REACT_APP_WS_URL);

const { authUser, getLoggedInData } = new User();
const userData = authUser();

let userId = '';

if (userData && userData.user) {
  userId = userData.user.id;
}

class MembersListTable extends React.Component {
  static propTypes = {
    checkUserUsage: func.isRequired,
    getUserUsageStats: func.isRequired,
    memberList: func.isRequired,
    headerList: func.isRequired,
    filterList: func.isRequired,
    orderByDataList: func.isRequired,
    filterDataList: func.isRequired,
    step3Form: func.isRequired,
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      filterValue: '',
      open: false,
      openFilterPopup: false,
      open2: false,
      offset: 0,
      focusUpgradeNotice: false,
      selected_colum1: [
        { column_name: 'firstName', label: 'First Name' },
        { column_name: 'lastName', label: 'Last Name' },
        { column_name: 'email', label: 'Email' },
        { column_name: 'instagram', label: 'Instagram' },
        { column_name: 'notes', label: 'Notes' },
        { column_name: 'facebook', label: 'Facebook' },
        { column_name: 'twitter', label: 'Twitter' },
        { column_name: 'linkedin', label: 'Linkedin' },
        { column_name: 'id', label: 'ID' },
        { column_name: 'addedBy', label: 'Added By' },
        /* { column_name: 'linkedin', label: 'Linkedin' },
        { column_name: 'twitter', label: 'Twitter' },
        { column_name: 'facebook', label: 'Facebook' },
        { column_name: 'instagram', label: 'Instagram' }, */
        { column_name: 'emailLink', label: 'Email Link' },
      ],
      selected_colum: [
        {
          column_name: 'firstName',
          isSortable: true,
          showInFilter: true,
          isCustomColumn: false,
          label: 'First Name',
        },
        {
          column_name: 'lastName',
          isSortable: true,
          showInFilter: true,
          isCustomColumn: false,
          label: 'Last Name',
        },
        {
          column_name: 'email',
          isSortable: true,
          showInFilter: true,
          isCustomColumn: false,
          label: 'Email',
        },
      ],
      selected_colum_value: [],
      checkListData: [
        {
          column_name: '',
          checked: false,
        },
      ],
      order: 'ASC',
      filterField: '',
      filterDataSearch: '',
      dropdownOpen: false,
      errorCsv: '',
      numRows: '',
      numRowsData: '',
      errors: {},
      startDate: new Date(),
      selectedFilterName: '',
      currentPage: 1,
      limit: 10,
      showAlert: false,
    };

    const currentUserId = getLoggedInData();

    socket.on(`UsageChangedForUser_${currentUserId}`, () => {
      this.props.checkUserUsage();
      this.props.getUserUsageStats();
    });
  }

  componentDidMount = () => {
    this.props.checkUserUsage(userId);
    this.props.getUserUsageStats();
    this.props.memberList({ offset: this.state.offset });
    this.props.headerList();
    this.props.filterDataListing();
    localStorage.removeItem('mapping');
    const existingFilter = this.getFilters();

    this.selectColumns(existingFilter);
  }

  selectColumns = (membersListFilter, forceClear = false) => {
    if (forceClear) {
      this.setState({
        selected_colum: membersListFilter,
      });
    } else if (!forceClear && membersListFilter.length) {
      const userExist = membersListFilter.find((eachUser) => {
        const keys = Object.keys(eachUser);
        const eachUserId = keys['0'];

        return eachUserId === userId;
      });

      if (userExist && userExist[`${userId}`].length) {
        const userFilters = userExist[`${userId}`];

        const selectedColumns = [...this.state.selected_colum, ...userFilters];

        this.setState({
          selected_colum: selectedColumns,
        });
      }
    }
  }

  getFilters = () => {
    const existingFilter = Storage.get('membersListFilter');

    if (!existingFilter && this.props.owner && this.props.owner.metadata) {
      const obj = this.props.owner.metadata.find((o) => o.meta_key === '__members_filters');

      if (obj) {
        const dbFilters = JSON.parse(obj.meta_value);

        this.selectColumns(dbFilters);

        Storage.save('membersListFilter', dbFilters);

        return dbFilters;
      }

      return [];
    }

    return existingFilter;
  };

  // onOpenExport = () => console.log('dada+++', this.props.data.memberList);

  seeMore = (e) => {
    e.preventDefault();
    this.setState({ offset: this.state.offset + 1 });
    this.props.memberList({ offset: this.state.offset + 1 });
  }

  defaultMember() {
    this.props.memberList({ offset: 0 });
    this.setState({
      filterValue: '',
      selectedFilterName: '',
      filterField: '',
      filterDataSearch: '',
    });
  }

  onClickFilter = () => this.props.filterDataListing();

  setChecklistData = (data, checkboxValue) => {
    for (let i = 0, len = this.state.checkListData.length; i < len; i++) {
      if (this.state.checkListData[i].column_name === data.column_name) {
        this.state.checkListData[i].checked = checkboxValue;
      }

      this.setState({ checkListData: this.state.checkListData });
    }
  }

  onColumnSelected = (data) => {
    this.setState({ openFilterPopup: true });
    this.setState((state) => ({ collapse: !state.collapse }));
    const selectedData1 = this.getUnCommonData(data, this.state.selected_colum1);

    const membersListFilter = this.getFilters();

    if (membersListFilter.length) {
      const userExist = membersListFilter.find((eachUser) => {
        const keys = Object.keys(eachUser);
        const eachUserId = keys['0'];

        return eachUserId === userId;
      });

      if (userExist && userExist[`${userId}`].length) {
        const userSelectedFilter = userExist[`${userId}`];

        for (let i = 0, len = selectedData1.length; i < len; i++) {
          for (let j = 0, len1 = userSelectedFilter.length; j < len1; j++) {
            if (selectedData1[i].column_name === userSelectedFilter[j].column_name) {
              selectedData1[i].checked = true;
            }
          }
        }

        this.setState({ checkListData: selectedData1 });
      } else if (selectedData1) {
        this.setHeaders(selectedData1);
      }
    } else if (selectedData1) {
      this.setHeaders(selectedData1);
    }
  };

  setHeaders = (selectedData1) => {
    for (let i = 0, len = Object.keys(selectedData1).length; i < len; i++) {
      // eslint-disable-next-line no-param-reassign
      selectedData1[i].checked = false;

      if (this.state.selected_colum_value) {
        for (let j = 0, len1 = this.state.selected_colum_value.length; j < len1; j++) {
          if (
            selectedData1[i].column_name
            === this.state.selected_colum_value[j].column_name
          ) {
            // eslint-disable-next-line no-param-reassign
            selectedData1[i].checked = true;
          }
        }
      }
    }

    this.setState({ checkListData: selectedData1 });
  };

  onOpenModalimport = () => {
    const canAdd = this.props.data.usage && this.props.data.usage.members;

    if (!canAdd) {
      this.setState({ focusUpgradeNotice: true });
      setTimeout(() => this.setState({ focusUpgradeNotice: false }), 500);
      toast.error('You are not authorized to add new member. Please upgrade your account first');
    } else {
      this.setState({ open2: true });
    }
  }

  onCloseModalimport = () => this.setState({ open2: false });

  /* onOpenModal1 = () => {
    this.setState({ openFilterPopup: true });
  }; */

  onMemberFilterPopupClosed = () => this.setState({ openFilterPopup: false });

  onOpenModal = () => this.setState({
    filterField: '',
    errors: {},
    open: true,
  });

  onCloseModal = () => this.setState({ open: false });

  viewMember = (id) => {
    localStorage.setItem('selectedMember', id);
    this.props.push(`/member/${id}`);
  };

  addPerson = () => {
    const canAdd = this.props.data.usage && this.props.data.usage.members;

    if (!canAdd) {
      this.setState({ focusUpgradeNotice: true });
      setTimeout(() => this.setState({ focusUpgradeNotice: false }), 500);
      toast.error('You are not authorized to add new member. Please upgrade your account first');
    } else {
      this.props.push('/add-person');
    }
  }

  saveMemberFilters = () => {
    this.setState({ open: false });
    this.onMemberFilterPopupClosed();

    const existingFilter = this.getFilters();

    socket.emit('memberListForUserChanged', existingFilter);
  };

  handleCheckbox1 = (event) => {
    const { value } = event.target;
    // eslint-disable-next-line camelcase
    const { selected_colum, selected_colum_value } = this.state;

    const findColumn = this.props.data.headerList.data.response.find(
      (eachColumn) => eachColumn.column_name === value,
    );

    if (event.target.checked) {
      selected_colum.push({
        column_name: value,
        checked: true,
        isCustomColumn: findColumn.isCustomColumn,
        isSortable: findColumn.isSortable,
        showInFilter: findColumn.showInFilter,
        label: findColumn.label,
      });

      selected_colum_value.push({
        column_name: value,
        checked: true,
        isCustomColumn: findColumn.isCustomColumn,
        isSortable: findColumn.isSortable,
        showInFilter: findColumn.showInFilter,
        label: findColumn.label,
      });

      this.setState({ selected_colum, selected_colum_value });

      /*  this.state.selected_colum.push({
        column_name: value,
        checked: true,
        isCustomColumn: findColumn.isCustomColumn,
        isSortable: findColumn.isSortable,
        showInFilter: findColumn.showInFilter,
      });
      this.state.selected_colum_value.push({
        column_name: value,
        checked: true,
        isCustomColumn: findColumn.isCustomColumn,
        isSortable: findColumn.isSortable,
        showInFilter: findColumn.showInFilter,
      }); */
    } else {
      const updatedColumns = this.state.selected_colum.filter(
        (item) => item.column_name !== value,
      );

      this.setState({
        selected_colum: updatedColumns,
      });

      this.state.selected_colum_value.pop({
        column_name: value,
        checked: false,
        isCustomColumn: findColumn.isCustomColumn,
        isSortable: findColumn.isSortable,
        showInFilter: findColumn.showInFilter,
        label: findColumn.label,
      });
    }

    this.setChecklistData({ column_name: value }, event.target.checked);

    const existingFilter = this.getFilters();

    let membersListFilter = [];

    if (existingFilter && existingFilter.length) {
      const userExist = existingFilter.find((eachUser) => {
        const keys = Object.keys(eachUser);
        const eachUserId = keys['0'];

        return eachUserId === userId;
      });

      if (userExist) {
        let updateFilter = [
          ...userExist[`${userId}`],
          ...this.state.selected_colum_value,
        ];

        if (!event.target.checked) {
          updateFilter = updateFilter.filter((item) => item.column_name !== value);
        }

        const uniqueArray = updateFilter.filter(
          (v, i, a) => a.findIndex((t) => t.column_name === v.column_name) === i,
        );

        const userIndex = existingFilter.findIndex((eachUser) => {
          const keys = Object.keys(eachUser);
          const eachUserId = keys['0'];

          return eachUserId === userId;
        });

        const updatedArray = [...existingFilter];

        updatedArray[userIndex] = {
          [`${userId}`]: uniqueArray,
        };

        Storage.save('membersListFilter', updatedArray);
      } else {
        membersListFilter = [
          ...existingFilter,
          {
            [`${userId}`]: this.state.selected_colum_value,
          },
        ];

        Storage.save('membersListFilter', membersListFilter);
      }
    } else {
      membersListFilter = [
        {
          [`${userId}`]: this.state.selected_colum_value,
        },
      ];

      Storage.save('membersListFilter', membersListFilter);
    }
  };

  getData(data) {
    if (data && data.headerList) {
      return (this.getUnCommonData(
        data.headerList.data,
        this.state.selected_colum1,
      ));
    }

    return [];
  }

  getUnCommonData(data1, data2) {
    const props = ['column_name', 'checked', 'label'];

    return data1.response
      .filter((o1) => (o1.isSortable && o1.showInFilter && !data2.some((o2) => o1.column_name === o2.column_name)))
      .map((o) =>
        // use reduce to make objects with only the required properties
        // and map to apply this to the filtered array as a whole
        // eslint-disable-next-line implicit-arrow-linebreak
        props.reduce((newo, name) => {
          // eslint-disable-next-line no-param-reassign
          newo[name] = o[name];

          return newo;
        }, {}));
  }

  filterField(data) {
    const currentValue = this.state.filterValue;

    if (currentValue === data) {
      this.props.memberList({ offset: this.state.offset });
      this.setState({ filterValue: '' });
    } else {
      this.setState({ filterValue: data });
    }
  }

  handleLoginKeyUp = (event, data) => {
    if (event.target.value.length !== 0) {
      this.setState({
        filterDataSearch: { key: data, value: event.target.value, type: 'text' },
      });
      this.props.filterList({ key: data, value: event.target.value, type: 'text' });
    }
  };

  onClickSelect = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
    if (event.target.value.length !== 0) {
      this.setState({ filterDataSearch: { key: name, value, type: 'enum' } });
      this.props.filterList({ key: name, value, type: 'enum' });
    }
  };

  orderByData(key) {
    if (this.state.order === 'DESC') {
      this.setState({ order: 'ASC' });
      if (this.state.filterDataSearch.value) {
        this.props.orderByDataList({
          key,
          order: 'ASC',
          value: this.state.filterDataSearch.value,
          type: this.state.filterDataSearch.type,
          filterKey: this.state.filterDataSearch.key,
        });
      } else {
        this.props.orderByDataList({
          key,
          order: 'ASC',
          value: 'full',
          type: 'full',
          filterKey: key,
        });
      }
    } else {
      this.setState({ order: 'DESC' });
      if (this.state.filterDataSearch.value) {
        this.props.orderByDataList({
          key,
          order: 'DESC',
          value: this.state.filterDataSearch.value,
          type: this.state.filterDataSearch.type,
          filterKey: this.state.filterDataSearch.key,
        });
      } else {
        this.props.orderByDataList({
          key,
          order: 'DESC',
          value: 'full',
          type: 'full',
          filterKey: key,
        });
      }
    }
  }

  handleChangeDate = (date, data) => {
    this.setState({ startDate: date });
    const date1 = new Date(date);

    const dataValue = moment(date1).format('YYYY-MM-DD');

    this.setState({
      filterDataSearch: { key: data, value: dataValue, type: 'date' },
    });
    this.props.filterList({ key: data, value: dataValue, type: 'date' });
  };

  fileUpload = (file) => {
    const fileExt = file.name;

    if (file) {
      if (
        fileExt.lastIndexOf('.csv') !== -1
        || fileExt.lastIndexOf('.xlsx') !== -1
        || fileExt.lastIndexOf('.xls') !== -1
      ) {
        const uploadcsvPath = document.getElementById('uploadcsv').value;

        localStorage.setItem('uploadcsvPath', uploadcsvPath);
        const formData = new FormData();

        formData.append('uploadcsv', file);
        this.props.step3Form(formData);
      } else {
        this.setState({
          errorCsv: 'File type not supported  only CSV, XLS acceptable.',
        });
      }
    }
  }

  submitCvs = (event) => {
    this.importCSV(event.target.files[0]);

    this.setState({ fileName: event.target.files[0] });
  };

  importCSV = (data) => {
    const fileExt = data.name;

    if (fileExt.lastIndexOf('.csv') !== -1) {
      Papa.parse(data, {
        complete: this.updateData,
        header: false,
      });
    } else if (
      fileExt.lastIndexOf('.xlsx') !== -1
      || fileExt.lastIndexOf('.xls') !== -1
    ) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const d = e.target.result;

        const workbook = XLSX.read(d, {
          type: 'binary',
        });

        // Here is your object

        const XLRowObject = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          header: 1,
          blankrows: false,
        });

        // const jsonObject = JSON.stringify(XLRowObject);
        if (XLRowObject === '') {
          toast.error('Empty xlsx file.');
        } else {
          this.fileUpload(this.state.fileName);
          // sheet_to_csv(ws,{header:0});

          /* Update state */
          const finalData = [];

          // // eslint-disable-next-line no-restricted-syntax
          // for (const propName in XLRowObject[0]) {
          //   // eslint-disable-next-line no-prototype-builtins
          //   if (XLRowObject[0].hasOwnProperty(propName)) {
          //     // do something with each element here
          //     finalData.push(propName);
          //   }
          // }

          for (let i = 0; i < XLRowObject[0].length; i++) {
            finalData.push(XLRowObject[0][i]);
          }

          localStorage.setItem('csvFilesHeader', JSON.stringify(finalData));
        }
      };

      reader.onerror = (ex) => console.log(ex);

      reader.readAsBinaryString(data);

      // reader.onload = (evt) => {
      //   // evt = on_file_select event
      //   /* Parse data */
      //   const bstr = evt.target.result[0];

      //   console.log('bstr', bstr, evt.target.result[0]);
      //   const wb = XLSX.read(bstr, { type: 'binary' });

      //   console.log('wb', wb);
      //   /* Get first worksheet */
      //   const wsname = wb.SheetNames[0];
      //   const ws = wb.Sheets[wsname];

      //   /* Convert array of arrays */
      //   csvData = XLSX.utils.sheet_to_json(ws);
      //   console.log('csvData', csvData);
      //   if (csvData === '') {
      //     toast.error('Empty xlsx file.');
      //   } else {
      //     this.fileUpload(this.state.fileName);
      //     // sheet_to_csv(ws,{header:0});

      //     /* Update state */
      //     const finalData = [];

      //     // eslint-disable-next-line no-restricted-syntax
      //     for (const propName in csvData[0]) {
      //       // eslint-disable-next-line no-prototype-builtins
      //       if (csvData[0].hasOwnProperty(propName)) {
      //         // do something with each element here
      //         finalData.push(propName);
      //       }
      //     }

      //     // console.log("finalData", finalData);
      //     localStorage.setItem('csvFilesHeader', JSON.stringify(finalData));
      //   }
      // };
      // const obj = { hello: 'world' };

      // const blob = new Blob([JSON.stringify(obj, null, 2)], { type: 'application/json' });

      // console.log('blob', blob);
      // reader.readAsBinaryString(blob);
    }
  };

  updateData = (result) => {
    const { data } = result;

    if (data[0][0] === '' || data[1][0] === '') {
      toast.error('Empty csv file.');
    } else {
      this.fileUpload(this.state.fileName);
      localStorage.setItem('csvFilesHeader', JSON.stringify(data[0]));
    }
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
    this.onClickFilter();
  }

  saveFilterName = (event) => this.setState({ [event.target.name]: event.target.value });

  slectedFilter = (data, filterName) => {
    const filtervalue = JSON.parse(data);

    this.props.filterList(filtervalue);
    this.setState({
      filterDataSearch: filtervalue,
      selectedFilterName: filterName,
    });
  };

  deletedMember = (memberId) => {
    this.setState({
      showAlert: true,
      memberId,
    });
  };

  onCancel = () => this.setState({ showAlert: false });

  onConfirmDelete = () => {
    const { currentPage } = this.state;

    this.setState({
      showAlert: false,
      currentPage,
    });

    this.props.deletedMember({
      memberId: this.state.memberId,
      limit: this.state.limit,
      pageNo: currentPage,
    });
  };

  validateFields = () => {
    const errors = {};

    let isValid = true;

    if (!this.state.filterField) {
      isValid = false;
      errors.filterFieldError = 'emptyFilterFieldValidation';
    } else if (!this.state.filterDataSearch.key) {
      isValid = false;
      errors.filterFieldError = 'selectValueBySearch';
    }

    this.setState({ errors });

    return isValid;
  };

  showError = (error) => <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>;

  saveFilterValue = () => {
    const valid = this.validateFields();

    if (valid) {
      const options = {
        filterName: this.state.filterField,
        filterData: JSON.stringify(this.state.filterDataSearch),
      };

      this.props.filterDataList(options);
      this.setState({ filterValue: '' });
      this.setState({ filterField: '' });
      this.setState({ filterDataSearch: '' });
      this.onCloseModal();
    }
  };

  getMetaKeyByColumnName = (column) => (column.toLowerCase().replace(' ', '_'));

  getMetaValueIfExists = (column, metadata = '') => {
    const metakey = column.toLowerCase().replace(' ', '_');

    if (metadata) {
      const selectedData = metadata.find((m) => m.meta_key === metakey);

      return selectedData ? selectedData.meta_value : '';
    }

    return '';
  };

  getStatsCount() {
    if (!this.props.usageStats || this.props.usageStats.members.total === -1) {
      return null;
    }

    return <small>({this.props.usageStats.members.used}/{this.props.usageStats.members.total})</small>;
  }

  render = () => {
    const {
      open,
      selectedFilterName,
      openFilterPopup,
      open2,
      showAlert,
    } = this.state;
    const { data, owner } = this.props;
    const {
      errors: { filterFieldError },
    } = this.state;

    if (!owner) {
      return null;
    }

    const canAdd = this.props.data.usage && this.props.data.usage.members;

    if (data && data.filterListing.data) {
      const membersToRender = data.filterListing.data.map(
        (member) => member.filterData,
      );

      this.state.numRows = membersToRender.length;
    }

    if (data && data.memberList.length !== 0) {
      const membersToRender1 = data.memberList.map((member) => (member.firstName ? member.firstName : 'Undefined'));

      this.state.numRowsData = membersToRender1.length;
    }

    // eslint-disable-next-line no-shadow
    const filterList = (
      <Dropdown
        className="d-inline-block dropto"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle caret className="btn-default">
          Filter
        </DropdownToggle>
        <DropdownMenu>
          {data.filterListing.data
            ? data.filterListing.data.map((value, i) => (
              <DropdownItem
                key={i}
                onClick={() => this.slectedFilter(value.filterData, value.filterName)
                }

              >
                {' '}
                <span className="capitalize-text">{value.filterName}</span>
              </DropdownItem>
            ))
            : ''}
        </DropdownMenu>
      </Dropdown>
    );

    const checkList = (
      <div className="list_flex">
        {this.state.checkListData.length !== 0 ? (
          <div>
            <ul className="selected_list checklist">
              {this.state.checkListData
                ? this.state.checkListData.map((value, i) => (
                  <li key={i}>
                    {' '}
                    <input
                      checked={value.checked || false}
                      id={value.column_name}
                      key={i + 11}
                      name="employee"
                      type="checkbox"
                      value={value.column_name}
                      onChange={this.handleCheckbox1}
                    />{' '}
                    <label className="text-capitalize" htmlFor={value.column_name}>
                      {value.label
                        ? value.label.trim()
                        : value.column_name.replace(/([A-Z])/g, ' $1').trim()}
                    </label>
                  </li>
                ))
                : ''}
              {/* // <li key={i}>  <input key={i + 11} type="checkbox" onChange={this.handleCheckbox1} checked={value.checked || false} id={value.column_name} value={value.column_name} name="employee" /> <label htmlFor={value.column_name} className="text-capitalize">{value.column_name === "altemail" ? "Alter Email" : value.column_name}</label></li>) : ''} */}
            </ul>
            <button className="btn btn-primary" onClick={this.saveMemberFilters}>
              Save
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    );

    const cardListValue = (
      <div className="scrolling">
        <table className="table table-responsive table-memberlist">
          <thead>
            <tr>
              {data.headerList.data
                ? this.state.selected_colum.map((value, index) => (
                  <th key={index}>
                    <span>
                      {value.label
                        ? value.label
                        : value.column_name.replace(/([A-Z])/g, ' $1').trim()}
                    </span>

                    {value.showInFilter ? (
                      <i
                        className="fa fa-filter"
                        style={{ margin: '0 6px' }}
                        onClick={() => this.filterField(value.column_name)}
                      ></i>
                    ) : null}

                    {this.state.filterValue === value.column_name ? (
                      value.column_name === 'updatedAt'
                        || value.column_name === 'createdAt'
                        || value.column_name === 'dob' ? (
                        <DatePicker
                          selected={moment(this.state.startDate)}
                          value={event}
                          onChange={(event) => this.handleChangeDate(event, value.column_name)
                          }

                        />) : (
                        <input
                          htmlFor={value.column_name}
                          id={value.column_name}
                          style={{ verticalAlign: 'top', margin: '0' }}
                          type="text"
                          onKeyUp={() => this.handleLoginKeyUp(event, value.column_name)
                          }

                        />)
                    ) : null}

                    {value.isSortable ? (
                      <i
                        className="fa fa-sort"
                        style={{ margin: '0 6px' }}
                        onClick={() => this.orderByData(value.column_name)}
                      ></i>
                    ) : null}
                  </th>
                ))
                : null}
              <th>ACTION</th>
              <th>QUICK LINKS</th>
            </tr>
          </thead>
          <tbody>
            <Modal open={openFilterPopup} onClose={this.onMemberFilterPopupClosed}>
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2>Filter</h2>
                </div>
                <div className="model-content eng">{checkList}</div>
              </div>
            </Modal>
            {data.memberList.length !== 0 ? (
              data.memberList.map((value, index) => (
                <tr key={index}>
                  {data.headerList.data
                    ? this.state.selected_colum.map((val, inIndex) => (val.column_name === 'instagram'
                      || val.column_name === 'linkedin'
                      || val.column_name === 'twitter'
                      || val.column_name === 'facebook' ? (
                      <td htmlFor={val.column_name}>
                        {value[val.column_name] ? value[val.column_name] : null}
                      </td>) : (
                      <td htmlFor={val.column_name} key={inIndex}>
                        {val.column_name === 'createdAt'
                          || val.column_name === 'updatedAt'
                          || val.column_name === 'dob' ? (value[val.column_name] ? (
                            <Moment format="MM/DD/YYYY" placeholder="select">
                              {value[val.column_name]}
                            </Moment>) : null) : val.column_name === 'email' ? (
                              <a
                                className="linking"
                                href={`mailto:${value[val.column_name]}`}
                              >
                                {value[val.column_name]}
                              </a>) : val.column_name === 'firstName'
                                || val.column_name === 'lastName' ? (
                          <a className="linking">
                            <span
                              className="cur_ic capitalize-text"
                              onClick={this.viewMember.bind(this, value.id)}
                            >
                              {value[val.column_name]}
                            </span>
                          </a>) : val.column_name === 'tags' ? (/* value[val.column_name]:value[val.column_name]} */ value[val.column_name]
                            && value[val.column_name].length && (
                              <div className="tags">
                                <ul>
                                  {value[val.column_name].split(',').map(
                                    (item, index1) => item && (
                                      <li key={index1}>
                                        <a className="capitalize-text">
                                          {item}
                                        </a>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              </div>)) : (value[val.column_name] ? value[val.column_name] : this.getMetaValueIfExists(val.column_name, value.metadata))}
                      </td>)))
                    : null}

                  <td>
                    <span className="btn-action" onClick={() => this.deletedMember(value.id, index)}>
                      <i className="fa fa-trash"></i>
                    </span>
                  </td>
                  <td>
                    <div className="social">
                      <ul>
                        {value.emailLink !== null && value.emailLink !== undefined ? (
                          <li>
                            <a href={`mailto:${value.emailLink}`}>
                              <i className="fa fa-envelope"></i>
                            </a>
                          </li>
                        ) : null}
                        {value.twitter ? (
                          <li>
                            <a href={value.twitter} target="_blank">
                              {' '}
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                        ) : null}
                        {value.facebook ? (
                          <li>
                            <a href={value.facebook} target="_blank">
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                        ) : null}
                        {value.instagram ? (
                          <li>
                            <a href={value.instagram} target="_blank">
                              <i className="fa fa-instagram"></i>
                            </a>
                          </li>
                        ) : null}
                        {value.linkedin ? (
                          <li>
                            <a href={value.linkedin} target="_blank">
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No Record!</td>
              </tr>
            )}
          </tbody>
        </table>
        {this.props.totalMembers !== data.memberList.length && (
          <a className="see-more" href="#" onClick={this.seeMore}>
            <h3>See more</h3>
          </a>
        )}
      </div>
    );

    /* eslint-disable no-param-reassign */
    data.memberList.map((member) => {
      if (member.metadata && member.metadata.length) {
        member.metadata.forEach((m) => {
          member[m.meta_key] = m.meta_value;

          return m;
        });

        delete member.metadata;
      }

      return member;
    });
    /* eslint-enable no-param-reassign */

    return (
      <div className="container">
        <div className="Header-top">
          <TopHeader />
        </div>
        <Modal center open={open} onClose={this.onCloseModal}>
          <div className="modal_bx member">
            <div className="modal-header head">
              <h2>Create a New Filter</h2>
            </div>
            <div className="model-content eng">
              <div className="form-group">
                <label>Name of Filter</label>
                <input
                  className="form-control"
                  name="filterField"
                  type="text"
                  value={this.state.filterField}
                  onChange={this.saveFilterName}
                />
              </div>
              <p className="bottom-padd">
                {' '}
                {!!filterFieldError && this.showError(filterFieldError)}
              </p>
              <button className="btn btn-primary" onClick={this.saveFilterValue}>
                Save
              </button>
            </div>
          </div>
        </Modal>
        <div className="csv_row heading-membership"><h2>Members {this.getStatsCount()}</h2></div>
        { !canAdd ? <UpgradeSubscription focusIt={this.state.focusUpgradeNotice} justNotice={true} usage={this.props.data.usage} /> : null }
        <div className="csv_row heading-membership membership-responsive">
          <div className="btn_col box">
            <a className="backarrow" href="/">
              <img alt="leftarrow" src={leftarrow} />
            </a>
            <div>
              <a className="btn btn-primary" onClick={this.addPerson}>
                + Add Person
              </a>
              <a className="btn btn-default" onClick={this.onOpenModalimport}>
                {' '}
                Import
              </a>
            </div>
            <CsvLink data={data.memberList} fileName={'member.csv'}>
              <button className="btn btn-default">Export</button>
            </CsvLink>
            <Modal center open={open2} onClose={this.onCloseModalimport}>
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2>Import CSV / XLS</h2>
                </div>
                <div className="model-content cent">
                  <div className="form-group img_bx">
                    <img alt="csv" src={CSVIMG} />
                    <h3>Import users (CSV/XLS)</h3>
                  </div>
                  <div className="upload-btn-wrapper">
                    <button className="btn btn-primary">Upload</button>
                    <input
                      accept=".csv, .xlsx, .xls"
                      id="uploadcsv"
                      name="uploadcsv"
                      type="file"
                      onChange={this.submitCvs}
                    />
                    <p className="error-msg-csv">{this.state.errorCsv}</p>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="btn_col last">
            {filterList}
            <a className="btn btn-default" onClick={this.onOpenModal}>
              {' '}
              Save Filter
            </a>
            {
              this.props.data.usage && this.props.data.usage.activeCampaigns
                ? <Link className="btn btn-default" to="/add-campaign">
                    Create Campaign
                  </Link>
                : null
            }

            <button
              className="btn btn-default click_imgbtn"
              onClick={() => this.onColumnSelected(data.headerList.data)}
            >
              <img src={groupicon} />
            </button>
            <div className="selected-filter">
              {selectedFilterName && (
                <span>
                  <b>Selected Filter:</b> {selectedFilterName}
                </span>
              )}
            </div>
          </div>
        </div>
        {cardListValue}

        <SweetAlert
          danger
          focusCancelBtn
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Confirm"
          show={showAlert}
          title="Are you sure you want to delete this member?"
          onCancel={this.onCancel}
          onConfirm={this.onConfirmDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.userProfile,
  totalMembers: state.userProfile.totalMembers,
  usage: state.userProfile.usage,
  usageStats: state.userProfile.usageStats,
  owner: state.settings.userDetails,
});

export default connect(mapStateToProps, {
  checkUserUsage,
  getUserUsageStats,
  memberList,
  headerList,
  push,
  filterList,
  orderByDataList,
  filterDataList,
  filterDataListing,
  step3Form,
  deletedMember,
})(MembersListTable);
