import { createAction } from 'redux-actions';

/* export const WORK_INFO = 'WORK_INFO';
export const workInfo = createAction(WORK_INFO);

export const WORK_INFO_FAILURE = 'WORK_INFO_FAILURE';
export const workInfoFailure = createAction(WORK_INFO_FAILURE);

export const WORK_INFO_SUCCESS = 'WORK_INFO_SUCCESS';
export const workInfoSuccess = createAction(WORK_INFO_SUCCESS);

export const WORK_INFO_REQUESTED = 'WORK_INFO_REQUESTED';
export const workInfoRequested = createAction(WORK_INFO_REQUESTED); */

export const WORK_INFO_UPDATE = 'WORK_INFO_UPDATE';
export const workInfoUpdate = createAction(WORK_INFO_UPDATE);

export const WORK_INFO_UPDATE_FAILURE = 'WORK_INFO_UPDATE_FAILURE';
export const workInfoUpdateFailure = createAction(WORK_INFO_UPDATE_FAILURE);

export const WORK_INFO_UPDATE_SUCCESS = 'WORK_INFO_UPDATE_SUCCESS';
export const workInfoUpdateSuccess = createAction(WORK_INFO_UPDATE_SUCCESS);

export const WORK_INFO_UPDATE_REQUESTED = 'WORK_INFO_UPDATE_REQUESTED';
export const workInfoUpdateRequested = createAction(WORK_INFO_UPDATE_REQUESTED);

/* export const ADDRESS_INFO = 'ADDRESS_INFO';
export const addressInfo = createAction(ADDRESS_INFO);

export const ADDRESS_INFO_FAILURE = 'ADDRESS_INFO_FAILURE';
export const addressInfoFailure = createAction(ADDRESS_INFO_FAILURE);

export const ADDRESS_INFO_SUCCESS = 'ADDRESS_INFO_SUCCESS';
export const addressInfoSuccess = createAction(ADDRESS_INFO_SUCCESS);

export const ADDRESS_INFO_REQUESTED = 'ADDRESS_INFO_REQUESTED';
export const addressInfoRequested = createAction(ADDRESS_INFO_REQUESTED); */

/* export const ADDRESS_INFO_UPDATE = 'ADDRESS_INFO_UPDATE';
export const addressInfoUpdate = createAction(ADDRESS_INFO_UPDATE);

export const ADDRESS_INFO_UPDATE_FAILURE = 'ADDRESS_INFO_UPDATE_FAILURE';
export const addressInfoUpdateFailure = createAction(ADDRESS_INFO_UPDATE_FAILURE);

export const ADDRESS_INFO_UPDATE_SUCCESS = 'ADDRESS_INFO_UPDATE_SUCCESS';
export const addressInfoUpdateSuccess = createAction(ADDRESS_INFO_UPDATE_SUCCESS);

export const ADDRESS_INFO_UPDATE_REQUESTED = 'ADDRESS_INFO_UPDATE_REQUESTED';
export const addressInfoUpdateRequested = createAction(
  ADDRESS_INFO_UPDATE_REQUESTED,
); */

export const ADD_IMPORTANT_MEMBER = 'ADD_IMPORTANT_MEMBER';
export const addImportantMember = createAction(ADD_IMPORTANT_MEMBER);

export const ADD_IMPORTANT_MEMBER_REQUESTED = 'ADD_IMPORTANT_MEMBER_REQUESTED';
export const addImportantMemberRequested = createAction(ADD_IMPORTANT_MEMBER_REQUESTED);

export const ADD_IMPORTANT_MEMBER_SUCCESS = 'ADD_IMPORTANT_MEMBER_SUCCESS';
export const addImportantMemberSuccess = createAction(ADD_IMPORTANT_MEMBER_SUCCESS);

export const DELETE_IMPORTANT_MEMBER = 'DELETE_IMPORTANT_MEMBER';
export const deleteImportantMember = createAction(DELETE_IMPORTANT_MEMBER);

export const DELETE_IMPORTANT_MEMBER_REQUESTED = 'DELETE_IMPORTANT_MEMBER_REQUESTED';
export const deleteImportantMemberRequested = createAction(DELETE_IMPORTANT_MEMBER_REQUESTED);

export const DELETE_IMPORTANT_MEMBER_SUCCESS = 'DELETE_IMPORTANT_MEMBER_SUCCESS';
export const deleteImportantMemberSuccess = createAction(DELETE_IMPORTANT_MEMBER_SUCCESS);

export const GET_IMPORTANT_MEMBERS = 'GET_IMPORTANT_MEMBERS';
export const getImportantMembers = createAction(GET_IMPORTANT_MEMBERS);

export const GET_IMPORTANT_MEMBERS_REQUESTED = 'GET_IMPORTANT_MEMBERS_REQUESTED';
export const getImportantMemberRequested = createAction(GET_IMPORTANT_MEMBERS_REQUESTED);

export const GET_IMPORTANT_MEMBERS_SUCCESS = 'GET_IMPORTANT_MEMBERS_SUCCESS';
export const getImportantMemberSuccess = createAction(GET_IMPORTANT_MEMBERS_SUCCESS);

export const UPDATE_IMPORTANT_MEMBER = 'UPDATE_IMPORTANT_MEMBER';
export const updateImportantMember = createAction(UPDATE_IMPORTANT_MEMBER);

export const UPDATE_IMPORTANT_MEMBER_REQUESTED = 'UPDATE_IMPORTANT_MEMBER_REQUESTED';
export const updateImportantMemberRequested = createAction(UPDATE_IMPORTANT_MEMBER_REQUESTED);

export const UPDATE_IMPORTANT_MEMBER_SUCCESS = 'UPDATE_IMPORTANT_MEMBER_SUCCESS';
export const updateImportantMemberSuccess = createAction(UPDATE_IMPORTANT_MEMBER_SUCCESS);

export const CUSTOM_FIELD = 'CUSTOM_FIELD';
export const customField = createAction(CUSTOM_FIELD);

export const CUSTOM_FIELD_FAILURE = 'CUSTOM_FIELD_FAILURE';
export const customFieldFailure = createAction(CUSTOM_FIELD_FAILURE);

export const CUSTOM_FIELD_SUCCESS = 'CUSTOM_FIELD_SUCCESS';
export const customFieldSuccess = createAction(CUSTOM_FIELD_SUCCESS);

export const CUSTOM_FIELD_REQUESTED = 'CUSTOM_FIELD_REQUESTED';
export const customFieldRequested = createAction(CUSTOM_FIELD_REQUESTED);

export const CUSTOM_FIELD_UPDATE = 'CUSTOM_FIELD_UPDATE';
export const customFieldUpdate = createAction(CUSTOM_FIELD_UPDATE);

export const CUSTOM_FIELD_UPDATE_FAILURE = 'CUSTOM_FIELD_UPDATE_FAILURE';
export const customFieldUpdateFailure = createAction(CUSTOM_FIELD_UPDATE_FAILURE);

export const CUSTOM_FIELD_UPDATE_SUCCESS = 'CUSTOM_FIELD_UPDATE_SUCCESS';
export const customFieldUpdateSuccess = createAction(CUSTOM_FIELD_UPDATE_SUCCESS);

export const CUSTOM_FIELD_UPDATE_REQUESTED = 'CUSTOM_FIELD_UPDATE_REQUESTED';
export const customFieldUpdateRequested = createAction(
  CUSTOM_FIELD_UPDATE_REQUESTED,
);

export const CUSTOM_FIELD_ADD = 'CUSTOM_FIELD_ADD';
export const customFieldAdd = createAction(CUSTOM_FIELD_ADD);

export const CUSTOM_FIELD_ADD_FAILURE = 'CUSTOM_FIELD_ADD_FAILURE';
export const customFieldAddFailure = createAction(CUSTOM_FIELD_ADD_FAILURE);

export const CUSTOM_FIELD_ADD_SUCCESS = 'CUSTOM_FIELD_ADD_SUCCESS';
export const customFieldAddSuccess = createAction(CUSTOM_FIELD_ADD_SUCCESS);

export const CUSTOM_FIELD_ADD_REQUESTED = 'CUSTOM_FIELD_ADD_REQUESTED';
export const customFieldAddRequested = createAction(CUSTOM_FIELD_ADD_REQUESTED);

export const CUSTOM_FIELD_LIST = 'CUSTOM_FIELD_LIST';
export const customFieldList = createAction(CUSTOM_FIELD_LIST);

export const CUSTOM_FIELD_LIST_FAILURE = 'CUSTOM_FIELD_LIST_FAILURE';
export const customFieldListFailure = createAction(CUSTOM_FIELD_LIST_FAILURE);

export const CUSTOM_FIELD_LIST_SUCCESS = 'CUSTOM_FIELD_LIST_SUCCESS';
export const customFieldListSuccess = createAction(CUSTOM_FIELD_LIST_SUCCESS);

export const CUSTOM_FIELD_LIST_REQUESTED = 'CUSTOM_FIELD_LIST_REQUESTED';
export const customFieldListRequested = createAction(CUSTOM_FIELD_LIST_REQUESTED);

export const CSV_IMPORT = 'CSV_IMPORT';
export const csvImport = createAction(CSV_IMPORT);

export const CSV_IMPORT_FAILURE = 'CSV_IMPORT_FAILURE';
export const csvImportFailure = createAction(CSV_IMPORT_FAILURE);

export const CSV_IMPORT_SUCCESS = 'CSV_IMPORT_SUCCESS';
export const csvImportSuccess = createAction(CSV_IMPORT_SUCCESS);

export const CSV_IMPORT_REQUESTED = 'CSV_IMPORT_REQUESTED';
export const csvImportRequested = createAction(CSV_IMPORT_REQUESTED);

export const ACCEPT_PROFILE_CHANGE = 'ACCEPT_PROFILE_CHANGE';
export const acceptProfileChange = createAction(ACCEPT_PROFILE_CHANGE);

export const ACCEPT_PROFILE_CHANGE_FAILURE = 'ACCEPT_PROFILE_CHANGE_FAILURE';
export const acceptProfileChangeFailure = createAction(ACCEPT_PROFILE_CHANGE_FAILURE);

export const ACCEPT_PROFILE_CHANGE_SUCCESS = 'ACCEPT_PROFILE_CHANGE_SUCCESS';
export const acceptProfileChangeSuccess = createAction(ACCEPT_PROFILE_CHANGE_SUCCESS);

export const ACCEPT_PROFILE_CHANGE_REQUESTED = 'ACCEPT_PROFILE_CHANGE_REQUESTED';
export const acceptProfileChangeRequested = createAction(ACCEPT_PROFILE_CHANGE_REQUESTED);

export const REJECT_PROFILE_CHANGE = 'REJECT_PROFILE_CHANGE';
export const rejectProfileChange = createAction(REJECT_PROFILE_CHANGE);

export const REJECT_PROFILE_CHANGE_FAILURE = 'REJECT_PROFILE_CHANGE_FAILURE';
export const rejectProfileChangeFailure = createAction(REJECT_PROFILE_CHANGE_FAILURE);

export const REJECT_PROFILE_CHANGE_SUCCESS = 'REJECT_PROFILE_CHANGE_SUCCESS';
export const rejectProfileChangeSuccess = createAction(REJECT_PROFILE_CHANGE_SUCCESS);

export const REJECT_PROFILE_CHANGE_REQUESTED = 'REJECT_PROFILE_CHANGE_REQUESTED';
export const rejectProfileChangeRequested = createAction(REJECT_PROFILE_CHANGE_REQUESTED);

/* export const ADD_TAGS_FAILURE = 'ADD_TAGS_FAILURE';
export const addTagsFailure = createAction(ADD_TAGS_FAILURE);

export const ADD_TAGS_SUCCESS = 'ADD_TAGS_SUCCESS';
export const addTagsSuccess = createAction(ADD_TAGS_SUCCESS);

export const ADD_TAGS_REQUESTED = 'ADD_TAGS_REQUESTED';
export const addTagsRequested = createAction(ADD_TAGS_REQUESTED);

export const ADD_TAGS = 'ADD_TAGS';
export const addTags = createAction(ADD_TAGS); */
