/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import './AddCommitteeStable.scss';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { FormattedMessage, TopHeader, UpgradeSubscription } from 'components';
import leftarrow from 'assets/images/leftarrow.png';

import {
  searchMembers,
  addCommittee,
  toggleSearchList,
} from 'actions/committee';
import { checkUserUsage } from 'actions/profile-info-action';
import SearchMembers from './SearchMembers';
import AddedMembers from './AddedMembers';
import NoMemberAdded from './NoMemberAdded';

const Loader = require('react-loader');

class AddCommittee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'on',
      errors: {},
      formSubmit: false,
      searchKeyword: '',
    };

    this.props.checkUserUsage();
    this.getMembers = this.getMembers.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  getMembers = (event) => {
    event.preventDefault();

    let keyword = event.target.value.trim();

    const checkHtmlTags = /(<([^>]+)>)/gi.test(keyword);

    if (keyword && !checkHtmlTags) {
      this.props.searchMembers(keyword);
    }

    this.props.toggleSearchList({
      showSearchList: true,
    });

    if (checkHtmlTags) {
      keyword = '';
    }

    this.setState({
      searchKeyword: keyword,
    });
  };

  handleOptionChange = (event) => {
    this.setState({
      active: event.target.value,
    });
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  onChangeHandler = (event) => {
    const {
      target: { name, value },
    } = event;

    this.setState({
      formSubmit: false,
      [name]: value,
    });
  };

  onValidateHandler = () => {
    const errors = {};

    if (
      !this.state.committeeName
      || (this.state.committeeName && !this.state.committeeName.trim())
    ) {
      errors.committeeName = 'emptyCommitteeName';
    }
    if (this.state.committeeName && this.state.committeeName.length < 2) {
      errors.committeeName = 'minLengthCommitteeName';
    }

    if (
      !this.state.committeeType
      || (this.state.committeeType && !this.state.committeeType.trim())
    ) {
      errors.committeeType = 'emptyCommitteeType';
    }
    if (!this.props.selectedMembersList.length) {
      errors.noMembers = 'noMembersAdded';
    }

    this.setState({ errors });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  onSubmitHandler = (event) => {
    event.preventDefault();

    this.setState({ formSubmit: true });

    const isValid = this.onValidateHandler();

    if (isValid) {
      const formData = {
        committeeName: this.state.committeeName.trim(),
        committeeType: this.state.committeeType.trim(),
        description: this.state.description ? this.state.description.trim() : '',
        isActive: this.state.active === 'on',
        members: this.props.selectedMembersList.length
          ? this.props.selectedMembersList.map((eachMember) => eachMember.id)
          : [],
      };

      this.props.addCommittee(formData);
    }
  };

  render() {
    const {
      membersList,
      selectedMembersList,
      apiErrors,
      isLoading,
      showSearchList,
    } = this.props;

    const {
      searchKeyword,
      active,
      errors: { committeeName, committeeType, noMembers },
      formSubmit,
    } = this.state;

    {
      formSubmit
        && apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: 1500,
        });
    }

    if (this.props.usage && !this.props.usage.committee) {
      return (<UpgradeSubscription heading="Add Committees" href="/committees" usage={this.props.usage} />);
    }

    return (
      <div className="container">
          <TopHeader />
        <div className="csv_row heading-membership">
          <div className="btn_col">
            <Link className="backarrow" to="/committees">
              <img alt="leftarrow" src={leftarrow} />
            </Link>
          </div>
          <h2>Add Committees</h2>
        </div>

        {isLoading && <Loader loaded={!isLoading} />}

        <form noValidate onSubmit={this.onSubmitHandler}>
          <div className="panel center">
            <div className="form-group">
              <label>Committee Name</label>
              <input
                className="form-control"
                name="committeeName"
                placeholder="What is your Committee name?"
                type="text"
                onChange={this.onChangeHandler}
              />
              {committeeName && this.showError(committeeName)}
            </div>

            <div className="form-group">
              <label>Active</label>
              <div className="month_year">
                <ul>
                  <li>
                    <input
                      checked={active === 'on'}
                      id="on"
                      name="isActive"
                      type="radio"
                      value="on"
                      onChange={this.handleOptionChange}
                    />
                    <label htmlFor="on">On</label>
                  </li>
                  <li>
                    <input
                      checked={active === 'off'}
                      id="off"
                      name="isActive"
                      type="radio"
                      value="off"
                      onChange={this.handleOptionChange}
                    />
                    <label htmlFor="off">Off</label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="form-group">
              <h3>Add Members</h3>
            </div>
            <div className="form-group">
              <label>Search to add members</label>
              <div className="form-group dropnew-alignment">
                <input
                  className="form-control search-input"
                  placeholder="Search member to invite them"
                  type="text"
                  onFocus={this.getMembers}
                  onKeyUp={this.getMembers}
                />
                {showSearchList && searchKeyword && membersList && (
                  <SearchMembers membersList={membersList} type="add" />
                )}
              </div>
              {noMembers && this.showError(noMembers)}
            </div>

            <div className="form-group added">
              <h3>Added Members</h3>
              {selectedMembersList.length ? (
                <AddedMembers selectedMembers={selectedMembersList} type="add" />
              ) : (
                <NoMemberAdded />
              )}
            </div>

            <div className="form-group">
              <label>Committee type</label>
              <input
                className="form-control"
                name="committeeType"
                placeholder="What is your Committee type?"
                type="text"
                onChange={this.onChangeHandler}
              />
              {committeeType && this.showError(committeeType)}
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                name="description"
                placeholder="Write something about your committee"
                onChange={this.onChangeHandler}
              ></textarea>
            </div>
            <button
              className="btn btn-primary btn-cent"
              onClick={this.saveAndContinue}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({
  committee: {
    membersList,
    selectedMembersList,
    addCommitteeError,
    isLoading,
    showSearchList,
  },
  userProfile: { usage },
}) => ({
  apiErrors: addCommitteeError,
  isLoading,
  membersList,
  selectedMembersList,
  showSearchList,
  usage,
});

export default connect(mapStateToProps, {
  checkUserUsage,
  addCommittee,
  searchMembers,
  toggleSearchList,
})(AddCommittee);
