import React from 'react';
import './ProfilePayment.scss';
import 'react-tabs/style/react-tabs.css';
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { transactionList, profilePay } from 'actions/profile-info-action';
import { formatDistance } from 'date-fns';
// import { LOGIN_SERVICE_URL } from '../../../constants';
import { Loader } from 'components';
import api from '../SettingStepProfile/Views/Services';

class ProfilePayment extends React.Component {
  static propTypes = {
    transactionList: func.isRequired,
    profilePay: func.isRequired,
  };

  state = {
    organisations: {},
    amount: 0,
    description: '',
    isLoading: false,
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.data.profilePaymentSuccess.redirectURI) {
      window.location.href = nextProps.data.profilePaymentSuccess.redirectURI;
    }

    return ({
      data: nextProps.data,
      // redirectURI: nextProps.data.profilePaymentSuccess.redirectURI,
    });
  }

  componentDidMount() {
    this.props.transactionList(this.props.userData.id);

    api.orgData(this.props.userData.addedBy).then((data) => {
      if (data.response.Organisation) {
        this.setState({
          organisations: data.response.Organisation,
          paymentDetails: data.response.paymentDetails,
        });
      }
    });
  }

  doPayment = () => {
    const member = this.props.userData;
    const orgId = this.state.organisations.id;
    const organisationName = this.state.organisations.name;
    const userData = this.props.userProfile.memberView.data;

    let paymentAmount = this.state.amount;

    if (!paymentAmount && userData && userData.metadata) {
      const amountMetaData = userData.metadata.find((o) => o.meta_key === 'amount_owned');

      paymentAmount = amountMetaData ? parseFloat(amountMetaData.meta_value) : paymentAmount;
    }

    const paymentPayload = {
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      localtoken: orgId,
      user: member.id,
      description: this.state.description,
      price: paymentAmount,
      event: '',
      orgId,
      organisationName,
    };

    this.setState({ isLoading: true });
    this.props.profilePay(paymentPayload);
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  renderPaymentBox = () => {
    if (!this.state.organisations && !this.state.paymentDetails) {
      return null;
    }

    const clientId = (this.state.paymentDetails && this.state.paymentDetails.clientId) ? this.state.paymentDetails.clientId : null;
    const clientSecret = (this.state.paymentDetails && this.state.paymentDetails.clientSecret) ? this.state.paymentDetails.clientSecret : null;

    if (clientId && clientSecret) {
      const userData = this.props.userProfile.memberView.data;

      let paymentAmount = this.state.amount;

      if (!paymentAmount && userData && userData.metadata) {
        const amountMetaData = userData.metadata.find((o) => o.meta_key === 'amount_owned');

        paymentAmount = amountMetaData ? parseFloat(amountMetaData.meta_value) : paymentAmount;
      }

      return (
        <div className="row">
          <div className="col-md-6" style={{ float: 'none', margin: '0 auto' }}>
            <div className="payment-block well" style={{ margin: '0 0 30px' }} >
              <p>Pay to the order for <b>{this.state.organisations.name}</b></p>
              <div className="">
                <div className="form-group" style={{ marginRight: '5px' }}>
                  <label className="sr-only">Amount (in dollars)</label>
                  <InputGroup>
                    <InputGroupText>$</InputGroupText>
                    <Input
                      className="form-control"
                      name="amount"
                      placeholder="Amount"
                      style={{ marginBottom: '0' }}
                      type="number"
                      value={paymentAmount}
                      onChange={this.onChange}
                    />
                    <InputGroupText>USD</InputGroupText>
                  </InputGroup>
                </div>
                <div className="form-group" style={{ marginRight: '5px' }}>
                  <label className="sr-only">Memo</label>
                  <textarea className="form-control" name="description" placeholder="Note" onChange={this.onChange} ></textarea>
                </div>
                <button className="btn btn-primary btn-block" type="button" onClick={this.doPayment}>Pay</button>
              </div>
            </div>
          </div>
          <hr />
        </div>
      );
    }

    return null;
  }

  // eslint-disable-next-line no-shadow
  renderPaymentHistory({ data: { transactionList } }) {
    return (
      <table className="table table-responsive table-Campaign">
      <thead>
        <tr>
          <th>DATE</th>
          <th>AMOUNT</th>
          <th>FOR</th>
          <th>Payment Status</th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        {transactionList.length > 0
          ? transactionList.map((record) => {
            const statusCode = record.httpStatusCode ? parseInt(record.httpStatusCode, 10) : 0;

            let paymentStatus = '';

            if (statusCode >= 500 && statusCode <= 503) {
              paymentStatus = 'Internal Server Error';
            } else if (statusCode >= 400 && statusCode <= 429) {
              paymentStatus = 'Failed';
            } else if (statusCode >= 200 && statusCode <= 204) {
              paymentStatus = 'Success';
            } else {
              paymentStatus = 'Payment initiated, not completed';
            }

            return (
              <tr key={record.id}>
                <td>
                  {`${formatDistance(new Date(), new Date(record.createdAt), {
                    addSuffix: false,
                    includeSeconds: true,
                  })} ago`}
                </td>
                <td>
                  {record.price} {record.currency}
                </td>
                <td>{record.organisationName}</td>
                <td>{paymentStatus}</td>
              </tr>
            );
          })
          : <tr><td colSpan="5">No payment history found</td></tr>
        }
      </tbody>
    </table>
    );
  }

  render() {
    return (
      <div className="info" style={{ position: 'relative' }}>
        {
          this.state.isLoading && !this.props.data.profilePaymentError
            ? <Loader/>
            : <div>
                {this.renderPaymentBox()}
                {this.renderPaymentHistory(this.props)}
              </div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.userProfile,
  ...state,
});

export default connect(mapStateToProps, {
  transactionList,
  profilePay,
})(ProfilePayment);
