import React from 'react';
import './EventList.scss';
import leftarrow from 'assets/images/leftarrow.png';
import plusicon from 'assets/images/add.png';
import { func } from 'prop-types';
import { eventListing } from 'actions/event-action';
import { toast } from 'react-toastify';
import { checkUserUsage, getUserUsageStats } from 'actions/profile-info-action';
import Moment from 'react-moment';
import { TopHeader, UpgradeSubscription } from 'components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import User from 'utils/user';
import socketIOClient from 'socket.io-client';

const socket = socketIOClient(process.env.REACT_APP_WS_URL);

const { getLoggedInData } = new User();

let self;

class EventList extends React.Component {
  static propTypes = {
    eventListing: func.isRequired,
    checkUserUsage: func.isRequired,
    getUserUsageStats: func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      focusUpgradeNotice: false,
    };
    const currentUserId = getLoggedInData();

    socket.on(`UsageChangedForUser_${currentUserId}`, () => {
      this.props.checkUserUsage();
      this.props.getUserUsageStats();
    });
  }

  componentDidMount() {
    this.props.checkUserUsage();
    this.props.getUserUsageStats();
    this.props.eventListing({ offset: this.state.offset });
  }

  redirectToAddInterface = () => {
    const canAdd = this.props.usage && this.props.usage.events;

    if (!canAdd) {
      this.setState({ focusUpgradeNotice: true });
      setTimeout(() => this.setState({ focusUpgradeNotice: false }), 500);
      toast.error('You are not authorized to add new committee. Please upgrade your account first');
    } else {
      this.props.history.push({
        pathname: '/addevent',
      });
    }
  }

  sendEditdata(value) {
    this.props.history.push({
      pathname: '/addevent',
      data: value, // your data array of objects
    });
  }

  seeMore() {
    self.setState({ offset: self.state.offset + 1 });
    self.props.eventListing({ offset: self.state.offset + 1 });
  }

  getStatsCount() {
    if (!this.props.usageStats || this.props.usageStats.events.total === -1) {
      return null;
    }

    return <small>({this.props.usageStats.events.used}/{this.props.usageStats.events.total})</small>;
  }

  render() {
    const { data } = this.props;
    const canAdd = this.props.usage && this.props.usage.events;

    const eventListingData = data.eventList && data.eventList.data
      ? data.eventList.data.map((value) => (
            <div className="eventlist" key={value.id}>
              <div className="event_cal">
                <ul>
                  <li>
                    <i className="fa fa-group"></i>
                    {value.peopleInvited} People invited
                  </li>
                  <li>
                    <i className="fa fa-map-marker"></i>{' '}
                    {value.address1 ? `${value.address1}` : ''}{' '}
                    {value.country ? `, ${value.country}` : ''}
                  </li>
                  <li>
                    <i className="fa fa-calendar-o"></i>
                    {value.date ? (
                      <Moment format="MMMM, D YYYY">{value.date}</Moment>
                    ) : (
                      ''
                    )}
                  </li>
                </ul>
              </div>
              <div className="event_summ">
                <div className="event_header">
                  <h2>{value.title}</h2>
                </div>
                <div className="event_desc">
                  <p>{value.description}</p>
                  <div className="btn_rw">
                    <Link
                      className="btn btn-default"
                      params={{ eventName: value }}
                      to={{
                        pathname: '/view-event',
                        state: { eventData: value }, // your data array of objects
                      }}
                    >
                      View
                    </Link>
                    <Link
                      className="btn btn-default"
                      params={{ eventName: value }}
                      to={{
                        pathname: '/addevent',
                        state: { fromNotifications: value }, // your data array of objects
                      }}
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
      ))
      : '';

    <div>
      <h3 className="no-event">No Event found!</h3>
    </div>;

    return (
      <div className="container">
        <div className="Header-top">
          <TopHeader />
        </div>
        <div className="csv_row heading-membership">
          <div className="btn_col">
            <a className="backarrow" href="/administration-dashboard">
              <img alt="leftarrow" src={leftarrow} />
            </a>
          </div>
          <h2>Events {this.getStatsCount()}</h2>
          <div className="btn_col last">
            <a className="btn btn-primary" onClick={this.redirectToAddInterface}>
              <img alt="plusicon" src={plusicon} /> Add New
            </a>
          </div>
        </div>
        { !canAdd ? <UpgradeSubscription focusIt={this.state.focusUpgradeNotice} justNotice={true} usage={this.props.usage} /> : null }
        {eventListingData}
        {data.eventList && data.eventList.data && data.eventList.data.length >= 9 ? (
          <div>
            <h3 className="no-event" onClick={this.seeMore}>
              See More
            </h3>
          </div>
        ) : (
          ''
        )}

        {data.eventList.data && data.eventList.data.length === 0 ? (
          <div>
            <h3 className="no-event">No event available</h3>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.event,
  usage: state.userProfile.usage,
  usageStats: state.userProfile.usageStats,
});

export default connect(mapStateToProps, {
  eventListing,
  checkUserUsage,
  getUserUsageStats,
})(EventList);
