import React, { Component, Fragment } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { FormattedMessage } from 'components';
import {
  loginAuthorization,
  loginFieldsState,
  showLanguageDropdown,
} from 'actions/user-authentication-action-types';
import User from 'utils/user';
import { selectLocale } from 'actions/locale-action-types';
import logo from 'assets/images/LogoIcon.png';

const { validateEmail } = new User();
// import './Login.scss';
// const {validatePassword} =new User();

// Login Component
class Login extends Component {
  static propTypes = {
    lang: string.isRequired,
    logOutStatus: string,
    loginAuthorization: func.isRequired,
    loginAuthorizationError: string,
    loginFieldsDetails: shape({
      email: string,
      name: string,
    }),
    loginFieldsState: func.isRequired,
    selectLocale: func.isRequired,
  };

  state = {
    error1: 1,
    type: 'password',
  };

  componentDidMount() {
    // document.body.addEventListener('click', this.handleBodyClick);
    const { loginFieldsDetails } = this.props;

    if (loginFieldsDetails.email || loginFieldsDetails.password) {
      this.props.loginFieldsState(
        update(loginFieldsDetails, {
          $merge: {
            email: '',
            password: '',
          },
        }),
      );
    }
  }
  // componentWillReceiveProps(){
  //   console.log("_++_+_+-",this.props.loginAuthorizationError)
  // }
  // componentDidUpdate(prevProps, prevState){

  // //  console.log("this.props.loginAuthorizationError", this.props.loginAuthorizationError);
  // if(this.props.loginAuthorizationError){
  //   this.setState({loaded:true})
  // }
  // }

  handleChange = (event) => {
    const { name, value } = event.target;

    const { loginFieldsDetails } = this.props;

    const updatedLoginFieldsDetails = {
      ...loginFieldsDetails,
      [name]: value,
    };

    if (name === 'language') {
      this.props.selectLocale(value);
    }

    this.props.loginFieldsState(updatedLoginFieldsDetails);
  };

  validateFields = () => {
    const {
      loginFieldsDetails: { email, password },
    } = this.props;

    const errors = {};

    let isValid = true;

    if (!email) {
      isValid = false;
      errors.userEmail = 'emptyEmailValidation';
    } else if (!validateEmail(email)) {
      isValid = false;
      errors.userEmail = 'emailValidation';
    }
    if (!password) {
      isValid = false;
      errors.userPassword = 'emptyPassword';
    }

    return isValid;
  };

  handleKeyPress = (event) => {
    User.spaceNotAllowed(event);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const isValid = this.validateFields();

    if (isValid) {
      const { loginFieldsDetails } = this.props;

      this.setState({ error1: 1 });
      this.props.loginAuthorization({ ...loginFieldsDetails });

      this.props.loginFieldsState(
        update(loginFieldsDetails, { $merge: { password: '' } }),
      );
    }
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  // showMenu = () => {
  //   this.props.showLanguageDropdown(true);
  //   this.setState({ shouldShowLanguageMenu: !this.state.shouldShowLanguageMenu });
  // };
  handleClick = () => this.setState(({ type }) => ({
    type: type === 'text' ? 'password' : 'text',
  }));

  selectedFlag = (data) => {
    const { loginFieldsDetails } = this.props;

    const updatedLoginFieldsDetails = {
      ...loginFieldsDetails,
    };

    this.props.selectLocale(data.value);

    this.props.loginFieldsState(updatedLoginFieldsDetails);
  };

  func() {
    this.setState({ error1: 2 });
  }

  render() {
    const {
      loginAuthorizationError,
    } = this.props;

    if (loginAuthorizationError && this.state.error1 === 1) {
      this.func();
    }

    return (
      <Fragment>
        <div className="container">
          <div className="panel onboarding-bx">
            <img alt="logo" className="logo-sm" src={logo} />
            <h2>Paypalpay</h2>
            <h3>
              <b>$200</b>
            </h3>
            <button onClick={this.showPaypalButtons}> Pay </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  login: { loginFieldsDetails, logOutStatus, loginAuthorizationError },
  locale: { lang },
}) => ({
  lang,
  logOutStatus,
  loginAuthorizationError,
  loginFieldsDetails,
});

export default connect(mapStateToProps, {
  loginAuthorization,
  loginFieldsState,
  selectLocale,
  showLanguageDropdown,
})(Login);
