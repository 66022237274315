import React from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
// import GoogleMapReact from 'google-map-react';
import Modal from 'react-responsive-modal';
import User from 'utils/user';
import Userimg from 'assets/images/Userimg.png';
import { /* addTags, */ tagsList, updateMemberInfo } from 'actions/profile-info-action';
// import { workInfo, addressInfo } from 'actions/member-view-action';

// const AnyReactComponent = ({ text }) => <div className="ddddd">{text}</div>;

class ProfileHeader extends React.Component {
  static propTypes = {
    /* addTags: func.isRequired, */
    profileFormError: string,
    profileFormFieldsDetails: shape({
      email: string,
      name: string,
    }),
    tagsList: func.isRequired,
  };

  static defaultProps = {
    center: {
      lat: 38.0807632,
      lng: -106.4098929,
    },
    zoom: 5,
  };

  state = {
    alreadyExist: true,
    emptyValue: true,
    openDelete: false,
    tagName: '',
    tags: [],
  };

  componentDidMount() {
    // this.getLocation();
    let memberId = null;

    if (this.state.memberId) {
      ({ memberId } = this.state);
    } else if (this.props.memberId) {
      ({ memberId } = this.props);
    }

    if (memberId) {
      this.props.tagsList(this.props.memberId);
      // this.props.workInfo(memberId);
      // this.props.addressInfo(memberId);
    }
  }

  cancelNotesModal = () => {
    this.setState({ openDelete: false });
  };

  handleKeyPress1 = (event) => {
    User.firstCharSpaceNotAllowed(event);
  };

  openAddTags = () => {
    this.setState({
      alreadyExist: true,
      emptyValue: true,
      openDelete: true,
      tagName: '',
      tags: this.props.userData.tags ? this.props.userData.tags.split(',') : [],
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  addTags() {
    const { memberId } = this.props;

    const available = this.state.tags.indexOf(this.state.tagName);

    if (this.state.tagName.length) {
      this.setState({ emptyValue: true });

      if (available === -1) {
        this.setState({ alreadyExist: true });
        this.state.tags.push(this.state.tagName);

        const commaString = this.state.tags.toString();

        const options = {
          memberId,
          tags: commaString,
        };

        this.props.updateMemberInfo({ options });

        this.setState({ openDelete: !this.state.openDelete });
      } else {
        this.setState({ alreadyExist: false });
      }
    } else {
      this.setState({ emptyValue: false });
    }
  }

  render() {
    const member = this.props.userData;
    const { profileData } = this.props;

    return (
      <div className="sm">
        <div className="csv_row heading-membership">
          <div className="btn_col">
            {/* <a href="/members-list" className="backarrow"><img src={leftarrow} alt="leftarrow"/></a> */}
          </div>
          <h2>Profile</h2>
        </div>

        <div className="profile_bx">
          <div className="profile_left panel">
            <div className="profilepic">
              <img
                alt="Userimg"
                src={member && member.profileImg ? member.profileImg : Userimg}
              />
            </div>
            <div>
              <input type="file" onChange={this.props.onFileChange} />
            </div>
            <h2>
              {member && member.firstName ? member.firstName : ''}{' '}
              {member && member.lastName ? member.lastName : ''}
            </h2>
            {/* <p>Score: 80/100</p> */}
          </div>
          <div className="profile_right panel">
            <h1>
              {member && member.firstName ? member.firstName : ''}{' '}
              {member && member.lastName ? member.lastName : ''}
            </h1>
            <br />
            <h2>Tags</h2>
            <div className="tags">
              <ul>
                {profileData && profileData.userProfile && profileData.userProfile.tagList
                  ? profileData.userProfile.tagList.map(
                    (tag) => tag && (
                          <li key={`tag-${tag.id}`}>
                            <a className="capitalize-text" href="#" onClick={() => this.tagRemoveRequested(tag)}>{tag.tagName}</a>
                          </li>
                    ),
                  )
                  : null}
                <li>
                  {/* <a className="active" onClick={this.openAddTags}>
                    <i className="fa fa-plus"></i>
                  </a> */}
                </li>
              </ul>
            </div>
            <Modal
              center
              open={this.state.openDelete}
              onClose={this.cancelNotesModal}
            >
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2> Tag Name </h2>
                </div>
                <div className="model-content eng">
                  <div className="form-group">
                    <input
                      className="form-control"
                      name="tagName"
                      placeholder="Tag Name"
                      type="text"
                      value={this.state.tagName}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress1}
                    />
                    {this.state.emptyValue ? (
                      this.state.emptyValue
                    ) : (
                      <p className="error-msg">This field is required.</p>
                    )}
                    {this.state.alreadyExist ? (
                      this.state.alreadyExist
                    ) : (
                      <p className="error-msg">Tag already exist.</p>
                    )}
                  </div>
                  <div className="center-btn">
                    <button
                      className="btn btn-primary margin-btn"
                      onClick={this.cancelNotesModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary margin-btn"
                      onClick={(e) => this.addTags(e)}
                    >
                      Add Tag
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            {/* <h2>My Location 123234434</h2>
            <div className="map">
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBfwQm6qQXkKa8JwckqWXAZNu_gSa0paeo' }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
              >
                <AnyReactComponent
                  lat={
                    member1.memberInfo.addressInfo.data
                      ? member1.memberInfo.addressInfo.data.latitude
                      : ''
                  }

                  lng={
                    member1.memberInfo.addressInfo.data
                      ? member1.memberInfo.addressInfo.data.longitude
                      : ''
                  }

                  text="A"
                />
                <AnyReactComponent
                  lat={
                    member1.memberInfo.workInfo.data
                      ? member1.memberInfo.workInfo.data.latitude
                      : ''
                  }

                  lng={
                    member1.memberInfo.workInfo.data
                      ? member1.memberInfo.workInfo.data.longitude
                      : ''
                  }

                  text="B"
                />
              </GoogleMapReact>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state,
});

export default connect(mapStateToProps, {
  /* addTags, */
  /* addressInfo, */
  tagsList,
  updateMemberInfo,
  // workInfo,
})(ProfileHeader);
