import {
  LOGIN_AUTHORIZATION_REQUESTED,
  LOGIN_AUTHORIZATION_SUCCESS,
  SIGNUP_AUTHORIZATION_REQUESTED,
  SIGNUP_AUTHORIZATION_SUCCESS,
  LOGIN_AUTHORIZATION_FAILURE,
  SIGNUP_AUTHORIZATION_FAILURE,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  LOG_OUT_REQUESTED,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAILURE,
  FETCH_LOGIN_USER_DETAILS_SUCCESS,
  SET_PASSWORD_REQUESTED,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAILURE,
  SET_PASSWORD_VALIDATION,
  SHOW_LANGUAGE_DROPDOWN,
  LOGIN_FIELDS_STATE,
  SIGNUP_FIELDS_STATE,
  GLOBAL_SEARCH_REQUESTED,
  GLOBAL_SEARCH_SUCCESS,
  GLOBAL_SEARCH_FAILURE,
} from 'actions/user-authentication-action-types';

const initialState = {
  contactFieldDetails: {},
  forgotPasswordError: {},
  forgotPasswordStatus: null,
  globalSearchError: {},
  globalSearchStatus: '',
  isLoading: false,
  logOutError: {},
  logOutStatus: null,
  loginAuthorizationError: '',
  loginAuthorizationStatus: null,
  loginFieldsDetails: { role: 'user' },
  loginUserDetails: {},
  membersList: [],
  setPasswordError: {},
  setPasswordStatus: null,
  setPasswordValidationDetail: {},
  showLanguageMenu: false,
  signupAuthorizationError: '',
  signupAuthorizationStatus: null,
  signupFieldsDetails: { role: 'user' },
};

const UserAuthentication = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_AUTHORIZATION_REQUESTED:
      return {
        ...state,
        loginAuthorizationError: null,
        loginAuthorizationStatus: null,
      };

    case LOGIN_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        loginAuthorizationError: null,
        loginAuthorizationStatus: 'success',
      };

    case LOGIN_AUTHORIZATION_FAILURE:
      return {
        ...state,
        loginAuthorizationError: payload,
        loginAuthorizationStatus: 'failure',
      };
    case SIGNUP_AUTHORIZATION_REQUESTED:
      return {
        ...state,
        signupAuthorizationError: null,
        signupAuthorizationStatus: null,
      };

    case SIGNUP_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        signupAuthorizationError: null,
        signupAuthorizationStatus: 'success',
      };

    case SIGNUP_AUTHORIZATION_FAILURE:
      return {
        ...state,
        signupAuthorizationError: payload,
        signupAuthorizationStatus: 'failure',
      };

    case FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        forgotPasswordError: null,
        forgotPasswordStatus: null,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordError: null,
        forgotPasswordStatus: 'success',
      };

    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordError: { ...payload },
        forgotPasswordStatus: 'failure',
      };

    case LOG_OUT_REQUESTED:
      return {
        ...state,
        logOutError: null,
        logOutStatus: null,
      };

    case LOG_OUT_SUCCESS:
      return {
        ...state,
        logOutError: null,
        logOutStatus: 'success',
      };

    case LOG_OUT_FAILURE:
      return {
        ...state,
        logOutError: { ...payload },
        logOutStatus: 'failure',
      };

    case SET_PASSWORD_REQUESTED:
      return {
        ...state,
        setPasswordError: null,
        setPasswordStatus: null,
      };

    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        setPasswordError: null,
        setPasswordStatus: 'success',
      };

    case SET_PASSWORD_FAILURE:
      return {
        ...state,
        setPasswordError: { ...payload },
        setPasswordStatus: 'failure',
      };

    case SET_PASSWORD_VALIDATION:
      return {
        ...state,
        setPasswordValidationDetail: payload,
      };

    case LOGIN_FIELDS_STATE:
      return {
        ...state,
        loginAuthorizationError: '',
        loginFieldsDetails: {
          ...payload,
        },
      };
    case SIGNUP_FIELDS_STATE:
      return {
        ...state,
        loginAuthorizationError: '',
        signupFieldsDetails: {
          ...payload,
        },
      };

    case FETCH_LOGIN_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loginUserDetails: payload,
      };

    case SHOW_LANGUAGE_DROPDOWN:
      return {
        ...state,
        showLanguageMenu: payload,
      };

    case GLOBAL_SEARCH_REQUESTED:
      return {
        ...state,
        globalSearchError: {},
        globalSearchStatus: '',
        isLoading: true,
        membersList: [],
      };

    case GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        globalSearchError: {},
        globalSearchStatus: 'success',
        isLoading: false,
        membersList: payload.rows,
      };

    case GLOBAL_SEARCH_FAILURE:
      return {
        ...state,
        globalSearchError: payload,
        globalSearchStatus: 'failure',
        isLoading: false,
      };
    default:
      return state;
  }
};

export default UserAuthentication;
