/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'components';
import leftarrow from 'assets/images/leftarrow.png';
import {
  step3BackButton,
  addCampaignStepThree,
} from 'actions/campaign';

const Loader = require('react-loader');

class RecurringSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datePickerIsOpen: false,
      errors: {},
      formSubmit: false,
      numberOfOccurences: '',
      recurringStartDate: null,
      recurringType: 'daily',
    };

    this.toggleDatePicker = this.toggleDatePicker.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.recurringForm).length) {
      const {
        recurringType,
        recurringStartDate,
        numberOfOccurences,
      } = this.props.recurringForm;

      this.setState({
        numberOfOccurences: numberOfOccurences || '',
        recurringStartDate: recurringStartDate ? moment(recurringStartDate) : null,
        recurringType: recurringType || 'daily',
      });
    }
  }

  toggleDatePicker() {
    this.setState({
      datePickerIsOpen: !this.state.datePickerIsOpen,
    });
  }

  openDatePicker() {
    this.setState({
      datePickerIsOpen: true,
    });
  }

  handleOnChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  previousStep() {
    this.props.step3BackButton();
  }

  handleChangeDate(date) {
    this.setState({
      datePickerIsOpen: false,
      recurringStartDate: date,
    });
  }

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  onValidateHandler = () => {
    const errors = {};

    if (!this.state.recurringStartDate) {
      errors.recurringStartDate = 'emptyFutureDate';
    }

    if (!this.state.numberOfOccurences) {
      errors.numberOfOccurences = 'emptyNumberOfOccurences';
    }

    const isNumber = /^\d+$/.test(this.state.numberOfOccurences);

    if (!isNumber) {
      errors.numberOfOccurences = 'numberOfOccurencesNotNumber';
    }

    this.setState({ errors });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  saveAndContinue = (event) => {
    event.preventDefault();

    this.setState({ formSubmit: true });

    const isValid = this.onValidateHandler();

    if (isValid) {
      const formData = {
        campaignId: this.props.stepOneValues && this.props.stepOneValues.id ? this.props.stepOneValues.id : '',
        goToCampaignList: true,
        numberOfOccurences: this.state.numberOfOccurences,
        recurringStartDate: this.state.recurringStartDate,
        recurringType: this.state.recurringType,
        scheduleType: 'recurring schedule',
      };

      this.props.addCampaignStepThree(formData);
    }
  };

  render() {
    const {
      errors: { recurringStartDate, numberOfOccurences },
      formSubmit,
      recurringType,
    } = this.state;

    const { isLoading, apiErrors } = this.props;

    {
      isLoading && <Loader loaded={!isLoading} />;
    }

    {
      formSubmit
        && apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: 1500,
        });
    }

    return (
      <div className="sm">
        <div className="panel">
          <div className="form-group">
            <div className="btn_col">
              <span className="backarrow" onClick={this.previousStep}>
                <img alt="leftarrow" src={leftarrow} />
              </span>
            </div>
            <h2>Set your recurring date</h2>
            <div className="month_year col">
              <ul>
                <li>
                  <input
                    checked={recurringType === 'daily'}
                    id="Daily"
                    name="recurringType"
                    type="radio"
                    value="daily"
                    onChange={this.handleOnChange}
                  />
                  <label htmlFor="Daily">Daily</label>
                </li>
                <li>
                  <input
                    checked={recurringType === 'weekly'}
                    id="Weekly"
                    name="recurringType"
                    type="radio"
                    value="weekly"
                    onChange={this.handleOnChange}
                  />
                  <label htmlFor="Weekly">Weekly</label>
                </li>
                <li>
                  <input
                    checked={recurringType === 'monthly'}
                    id="Monthly"
                    name="recurringType"
                    type="radio"
                    value="monthly"
                    onChange={this.handleOnChange}
                  />
                  <label htmlFor="Monthly">Monthly</label>
                </li>
                <li>
                  <input
                    checked={recurringType === 'quarterly'}
                    id="Quarterly"
                    name="recurringType"
                    type="radio"
                    value="quarterly"
                    onChange={this.handleOnChange}
                  />
                  <label htmlFor="Quarterly">Quarterly</label>
                </li>
                <li>
                  <input
                    checked={recurringType === 'yearly'}
                    id="Yearly"
                    name="recurringType"
                    type="radio"
                    value="yearly"
                    onChange={this.handleOnChange}
                  />
                  <label htmlFor="Yearly">Yearly</label>
                </li>
              </ul>
            </div>
          </div>

          <div className="form-group cardnumber">
            <DatePicker
              className="form-control"
              dateFormat="MM/DD/YYYY"
              minDate={new Date()}
              open={this.state.datePickerIsOpen}
              placeholderText="Set your campaign start date"
              selected={this.state.recurringStartDate}
              onChange={(event) => this.handleChangeDate(event)}
              onClickOutside={this.toggleDatePicker}
              onFocus={(event) => this.openDatePicker(event)}
            />
            <span className="calendricon">
              <i
                aria-hidden="true"
                className="fa fa-calendar"
                onClick={this.toggleDatePicker}
              ></i>
            </span>
            {recurringStartDate && this.showError(recurringStartDate)}
          </div>

          <div className="form-group">
            <input
              className="form-control"
              name="numberOfOccurences"
              placeholder="Number of occurrence"
              type="text"
              value={this.state.numberOfOccurences}
              onChange={this.handleOnChange}
            />
            {numberOfOccurences && this.showError(numberOfOccurences)}
          </div>

          <button className="btn btn-primary cen" onClick={this.saveAndContinue}>
            Save
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ campaign }) => ({
  apiErrors: campaign.updateCampaignError,
  isLoading: campaign.isLoading,
  recurringForm: campaign.recurringForm,
  stepOneValues: campaign.stepOneValues,
});

export default connect(mapStateToProps, {
  addCampaignStepThree,
  step3BackButton,
})(RecurringSchedule);
