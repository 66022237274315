/* eslint-disable max-len */
import React, { Component, Fragment } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import Popover from 'react-tiny-popover';
import { FormattedMessage, Link } from 'components';
import {
  signupAuthorization,
  signupFieldsState,
  showLanguageDropdown,
} from 'actions/user-authentication-action-types';
import User from 'utils/user';
import { selectLocale } from 'actions/locale-action-types';
import logo from 'assets/images/LogoIcon.png';

const { validateEmail } = new User();
const { validatePassword } = new User();
const Loader = require('react-loader');

class Signup extends Component {
  static propTypes = {
    lang: string.isRequired,
    logOutStatus: string,
    selectLocale: func.isRequired,
    signupAuthorization: func.isRequired,
    signupAuthorizationError: string,
    signupFieldsDetails: shape({
      email: string,
      name: string,
    }),
    signupFieldsState: func.isRequired,
  };

  state = {
    error1: 1,
    errors: {},
    isPopoverOpen: false,
    loaded: true,
    type: 'password',
    type1: 'password',
  };

  componentDidMount() {
    const { signupFieldsDetails } = this.props;

    if (signupFieldsDetails.email || signupFieldsDetails.password) {
      this.props.signupFieldsState(
        update(signupFieldsDetails, {
          $merge: {
            cpassword: '',
            email: '',
            password: '',
          },
        }),
      );
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    const { signupFieldsDetails } = this.props;

    const updatedsignupFieldsDetails = {
      ...signupFieldsDetails,
      [name]: value,
    };

    if (name === 'language') {
      this.props.selectLocale(value);
    }
    this.props.signupFieldsState(updatedsignupFieldsDetails);
  };

  validateFields = () => {
    const {
      signupFieldsDetails: { email, password, cpassword },
    } = this.props;

    const errors = {};

    let isValid = true;

    if (!email) {
      isValid = false;
      errors.userEmail = 'emptyEmailValidation';
    } else if (!validateEmail(email)) {
      isValid = false;
      errors.userEmail = 'emailValidation';
    }
    if (!password) {
      isValid = false;
      errors.userPassword = 'emptyPassword';
    } else if (!validatePassword(password)) {
      console.log('pass true');
      isValid = false;
      errors.userPassword = 'passwordValidation';
    }

    if (!cpassword) {
      isValid = false;
      errors.userCpassword = 'emptyPassword';
    } else if (cpassword !== password) {
      console.log('cpass true');
      isValid = false;
      errors.userCpassword = 'matchPasswordValidation';
    }
    this.setState({ errors });

    return isValid;
  };

  handleKeyPress = (event) => {
    User.spaceNotAllowed(event);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const isValid = this.validateFields();

    if (isValid) {
      this.setState({ error1: 1 });
      this.setState({ loaded: false });
      const { signupFieldsDetails } = this.props;

      this.setState({ errors: '' });
      this.props.signupAuthorization({ ...signupFieldsDetails });
      this.props.signupFieldsState(update(signupFieldsDetails, { $merge: {} }));
    }
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  handleClick = () => this.setState(({ type }) => ({
    type: type === 'text' ? 'password' : 'text',
  }));

  handleClick1 = () => this.setState(({ type1 }) => ({
    type1: type1 === 'text' ? 'password' : 'text',
  }));

  selectedFlag = (data) => {
    const { signupFieldsDetails } = this.props;
    const updatedsignupFieldsDetails = {
      ...signupFieldsDetails,
    };

    this.props.selectLocale(data.value);
    this.props.signupFieldsState(updatedsignupFieldsDetails);
  };

  func() {
    this.setState({ loaded: true });
    this.setState({ error1: 2 });
  }

  render() {
    const {
      signupFieldsDetails: { email, password, cpassword },
      signupAuthorizationError,
    } = this.props;

    const {
      errors: { userEmail, userPassword, userCpassword },
    } = this.state;
    const { isPopoverOpen } = this.state;

    if (signupAuthorizationError && this.state.error1 === 1) {
      this.func();
    }

    return (
      <Fragment>
        <div className="container">
          <div className="panel onboarding-bx">
            <img alt="logo" className="logo-sm" src={logo} />
            <h2>SIGN UP</h2>
            <Loader loaded={this.state.loaded}>
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    className="form-control"
                    name="email"
                    name="email"
                    placeholder="Email"
                    type="text"
                    value={email || ''}
                    onBlur={this.handleBlurChange}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  {!!userEmail && this.showError(userEmail)}
                </div>
                <div className="form-group">
                  <input
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    type={this.state.type}
                    value={password || ''}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <span className="password_show" onClick={this.handleClick}>
                    {this.state.type === 'text' ? 'Hide' : 'Show'}
                  </span>
                  {!!userPassword && this.showError(userPassword)}
                  <Popover
                    content={
                      <div>
                        Please enter a strong password. The password must have
                        atleast six characters long with 1 Lowercase and 1 uppercase,
                        numeric character, special symbol.
                      </div>
                    }
                    isOpen={isPopoverOpen}
                    padding={10}
                    position={['right', 'right']}
                    onClickOutside={() => this.setState({ isPopoverOpen: false })}
                  >
                    <div
                      className="helpicon"
                      onClick={() => this.setState({ isPopoverOpen: !isPopoverOpen })
                      }
                    >
                      <img src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTguMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDM1Ni42MiAzNTYuNjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM1Ni42MiAzNTYuNjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxwYXRoIGQ9Ik0xNzguMzEsMEM3OS45OSwwLDAsNzkuOTksMCwxNzguMzExUzc5Ljk5LDM1Ni42MiwxNzguMzEsMzU2LjYyczE3OC4zMS03OS45ODksMTc4LjMxLTE3OC4zMVMyNzYuNjMsMCwxNzguMzEsMHogICAgTTE3OC4zMSwzMjYuNjJDOTYuNTMyLDMyNi42MiwzMCwyNjAuMDg5LDMwLDE3OC4zMTFTOTYuNTMyLDMwLDE3OC4zMSwzMHMxNDguMzEsNjYuNTMyLDE0OC4zMSwxNDguMzExICAgUzI2MC4wODgsMzI2LjYyLDE3OC4zMSwzMjYuNjJ6IiBmaWxsPSIjMDAwMDAwIi8+Cgk8cGF0aCBkPSJNMTc4LjMxLDY2Ljk0OWMtMzUuNTgsMC02NC41MjcsMjguOTQ3LTY0LjUyNyw2NC41MjdjMCw4LjI4NCw2LjcxNiwxNSwxNSwxNXMxNS02LjcxNiwxNS0xNSAgIGMwLTE5LjAzOCwxNS40ODktMzQuNTI3LDM0LjUyNy0zNC41MjdzMzQuNTI3LDE1LjQ4OSwzNC41MjcsMzQuNTI3cy0xNS40ODksMzQuNTI3LTM0LjUyNywzNC41MjdjLTguMjg0LDAtMTUsNi43MTYtMTUsMTV2MzMuOTE1ICAgYzAsOC4yODQsNi43MTYsMTUsMTUsMTVzMTUtNi43MTYsMTUtMTV2LTIwLjY3NWMyOC4zNzEtNi43NzksNDkuNTI3LTMyLjM1LDQ5LjUyNy02Mi43NjggICBDMjQyLjgzNyw5NS44OTYsMjEzLjg5LDY2Ljk0OSwxNzguMzEsNjYuOTQ5eiIgZmlsbD0iIzAwMDAwMCIvPgoJPGNpcmNsZSBjeD0iMTc4LjMxIiBjeT0iMjY1Ljg5OSIgcj0iMjAuOTk5IiBmaWxsPSIjMDAwMDAwIi8+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==" />
                    </div>
                  </Popover>
                </div>

                <div className="form-group">
                  <input
                    className="form-control"
                    name="cpassword"
                    name="cpassword"
                    placeholder="Confirm Password"
                    type={this.state.type1}
                    value={cpassword || ''}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                  />
                  <span className="password_show" onClick={this.handleClick1}>
                    {this.state.type1 === 'text' ? 'Hide' : 'Show'}
                  </span>
                  {!!userCpassword && this.showError(userCpassword)}
                </div>
                <div className="form-group">
                  <input
                    className="btn btn-primary full"
                    type="submit"
                    value="Sign Up"
                  />
                </div>
                <div className="form-group text-center">
                  <Link className="link" href="/login">
                    Back To Login
                  </Link>
                </div>
              </form>
            </Loader>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  login: { signupFieldsDetails, logOutStatus, signupAuthorizationError },
  locale: { lang },
}) => ({
  lang,
  logOutStatus,
  signupAuthorizationError,
  signupFieldsDetails,
});

export default connect(mapStateToProps, {
  selectLocale,
  showLanguageDropdown,
  signupAuthorization,
  signupFieldsState,
})(Signup);
