/* eslint-disable react/no-unused-state */
/* eslint-disable max-lines */
import React from 'react';
import './ProfileInfo.scss';
import { Collapse } from 'reactstrap';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, CustomFieldType, ImportantPeople } from 'components';
import 'react-datepicker/dist/react-datepicker.css';
import {
  updateMemberInfo,
  headerList,
  saveNotes,
  notesList,
  notesUpdate,
  updateMemberInfoSingle,
} from 'actions/profile-info-action';

import {
  /* workInfo, */
  // addressInfo,
  // addressInfoUpdate,
  customField,
  customFieldList,
} from 'actions/member-view-action';
import User from 'utils/user';
import Moment from 'react-moment';
import moment from 'moment';
import Modal from 'react-responsive-modal';
/* import  {
  geocodeByPlaceId,
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'; */
// import { isThisHour } from 'date-fns';
import country from './country.json';
import states from './state.json';

const { validateName, validateEmail /* getUserOrgType */ } = new User();

let self;

class ProfileInfo extends React.Component {
  static propTypes = {
    logOutStatus: string,
    headerList: func.isRequired,
    updateMemberInfo: func.isRequired,
    saveNotes: func.isRequired,
    // workInfo: func.isRequired,
    // addressInfo: func.isRequired,
    // workInfoUpdate: func.isRequired,
    updateMemberInfoSingle: func.isRequired,
    profileFormError: string,
    profileFormFieldsDetails: shape({
      email: string,
      name: string,
    }),
    // addressInfoUpdate: func.isRequired,
    notesList: func.isRequired,
    notesUpdate: func.isRequired,
    customField: func.isRequired,
    customFieldList: func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      fieldsColumn: [
        'suffix',
        'title',
        'nickName',
        'middleName',
        'createdAt',
        'updatedAt',
        'newsletter',
        'email',
        'altemail',
        'gender',
        'dob',
        'maritalStatus',
        'links',
        'emailLink',
        'firstName',
        'lastName',
        'email',
        'notes',
        'id',
        'addedBy',
        'linkedin',
        'twitter',
        'facebook',
        'instagram',
        // 'emailLink',
        'tags',
      ],
      data: this.props.data,
      customFields: [],
      collapse: false,
      collapse1: false,
      collapse2: false,
      collapseUser: false,
      openDelete: false,
      memberInformation: true,
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      nickName: '',
      suffix: '',
      // newsletter: '',
      email: '',
      alterEmail: '',
      gender: '',
      dob: null,
      marital: '',
      // emailLink: '',
      twitter: '',
      facebook: '',
      linkedin: '',
      instagram: '',
      notes: '',
      startDate: new Date(),
      memberNotes: '',
      companyName: '',
      position: '',
      workAddress1: '',
      workAddress2: '',
      workCity: '',
      workState: '',
      workCountry: 'United States',
      workZip: '',
      businessPhone: '',
      website: '',
      homeAddress1: '',
      homeAddress2: '',
      homeCity: '',
      homeState: '',
      homeCountry: 'United States',
      homeZip: '',
      homePhone: '',
      cellPhone: '',
      /* spouse: '',
      anniversary: null,
      personBirthdat: null,
      child1Birthday: null,
      child2Birthday: null,
      child2name: '',
      child1name: '',
      personname: '', */
      editNote: '',
      editNoteData: '',
      userId: '',
      fieldName: '',
      fieldValue: '',
      fieldType: 'String',
      // isSortable: false,
      availableAsColumn: true,
      // showInFilter: false,
      showResults: false,
      latitude: '',
      longitude: '',
      latitudeWork: '',
      longitudeWork: '',
      deleteNotesData: {},
      numRowsData: '',
      address: '',
      // updatedOfValue: '',
      collapseUserMember: true,
      importantPeopleDiv: true,
      homeDiv: true,
      workDiv: true,
      customFieldDiv: true,
      open1d: true,
      memberView: true,
      openNotes1: false,
      openCutomModal: false,
    };

    const url = new URL(window.location.href);
    const userId = url.pathname.split('/')[2];

    this.currentMemberID = userId;

    // Get the data of actual user who has been using this system.
    const viewingUser = localStorage.getItem('userDetails');

    this.needsUpdateVerification = true;

    if (viewingUser) {
      this.viewingUser = JSON.parse(viewingUser);
      this.needsUpdateVerification = false;
    }
  }

  getInitialState() {
    return { showResults: false };
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const url = new URL(window.location.href);
    const userId = url.pathname.split('/')[2];

    let memberPersonalInfo = {};

    if (JSON.stringify(nextProps.data) !== JSON.stringify(prevState.data)) {
      if (nextProps.data.userProfile.memberView) {
        const tempMemberInfo = nextProps.data.userProfile.memberView?.data;
        const metadata = {};

        if (tempMemberInfo.metadata) {
          tempMemberInfo.metadata.forEach((m) => {
            metadata[m.meta_key] = m.meta_value;
          });
        }

        memberPersonalInfo = {
          title: tempMemberInfo?.title || '',
          userId: tempMemberInfo?.userId || userId,
          firstName: tempMemberInfo?.firstName || '',
          lastName: tempMemberInfo?.lastName || '',
          middleName: tempMemberInfo?.middleName || '',
          nickName: tempMemberInfo?.nickName || '',
          suffix: tempMemberInfo?.suffix || '',
          email: tempMemberInfo?.email || '',
          alterEmail: tempMemberInfo?.altemail || '',
          gender: tempMemberInfo?.gender || '',
          dob: tempMemberInfo?.dob || null,
          marital: tempMemberInfo?.maritalStatus || '',
          parcelnumber: metadata.parcelnumber || '',
          twitter: tempMemberInfo?.twitter || '',
          facebook: tempMemberInfo?.facebook || '',
          linkedin: tempMemberInfo?.linkedin || '',
          instagram: tempMemberInfo?.instagram || '',
          notes: tempMemberInfo?.notes || '',
          homeAddress1: tempMemberInfo?.homeAddress1 || '',
          homeAddress2: tempMemberInfo?.homeAddress2 || '',
          homeCountry: tempMemberInfo?.homeCountry || 'United States',
          homeState: tempMemberInfo?.homeState || '',
          homeCity: tempMemberInfo?.homeCity || '',
          homeZip: tempMemberInfo?.homeZip || '',
          homePhone: tempMemberInfo?.homePhone || '',
          cellPhone: tempMemberInfo?.cellPhone || '',
          workAddress1: tempMemberInfo?.workAddress1 || '',
          workAddress2: tempMemberInfo?.workAddress2 || '',
          companyName: tempMemberInfo?.companyName || '',
          position: tempMemberInfo?.position || '',
          workCountry: tempMemberInfo?.workCountry || '',
          workState: tempMemberInfo?.workState,
          workCity: tempMemberInfo?.workCity || '',
          workZip: tempMemberInfo?.workZip || '',
          businessPhone: tempMemberInfo?.businessPhone || '',
          website: tempMemberInfo?.website || '',
        };
      }

      return ({
        data: nextProps.data,
        ...memberPersonalInfo,
      });
    }

    return null;
  }

  async componentDidMount() {
    self = this;
    // const orgType = getUserOrgType();
    let userInitialStateData = { userId: this.currentMemberID };

    this.props.notesList(this.currentMemberID);
    if (this.props.data) {
      const { data } = this.props;

      if (data && data.userProfile.memberView && data.userProfile.memberView.data) {
        if (data.userProfile.memberView.data.addedBy) {
          this.props.headerList(data.userProfile.memberView.data.addedBy);
          this.props.customFieldList({ userId: data.userProfile.memberView.data.addedBy });
        } else {
          this.props.headerList(this.currentMemberID);
          this.props.customFieldList({ userId: this.currentMemberID });
        }

        if (this.state.memberView || this.state.email !== data.userProfile.memberView.data.email) {
          userInitialStateData.memberView = false;
          userInitialStateData = Object.assign(userInitialStateData, data.userProfile.memberView.data);
        }
      }

      /* if (data && data.memberInfo.addressInfo.data && this.state.open1d) {
        this.setOnclickValue(data.memberInfo.addressInfo.data);
      } */

      if (data && data.userProfile.notesList) {
        const membersToRender1 = data.userProfile.notesList.data.map(
          (item) => item.status === true,
        );

        userInitialStateData.numRowsData = membersToRender1.length;
      }

      if (
        data.userProfile.headerList
        && data.userProfile.headerList.data.response
      ) {
        this.setStateValue(this.props.userData);
        // this.onClickWork(data.memberInfo.workInfo.data);
      }

      if (data.memberInfo.workInfo) {
        userInitialStateData = Object.assign(userInitialStateData, data.memberInfo.workInfo.data);
      }

      self.setState(userInitialStateData);
    }
  }

  getViewingUser = () => {
    let viewingUser = null;

    if (this.viewingUser) {
      viewingUser = this.viewingUser.user.id;
    } else if (this.state.addedBy) {
      viewingUser = this.state.addedBy;
    }

    return viewingUser;
  }

  columnToFieldType(columnName) {
    switch (columnName) {
      case 'Number':
        return 'number';

      case 'Date':
        return 'date';

      case 'Boolean':
      case 'checkbox':
        return 'checkbox';

      default:
        return 'text';
    }
  }

  getFieldId(fieldName) {
    const noSpaceField = fieldName.replace(/[^\w\s]/gi, '').toLowerCase().replace(' ', '-');

    return `${noSpaceField}_${window.btoa(unescape(encodeURIComponent(noSpaceField)))}`;
  }

  validateHome = () => {
    const errors = {};

    const isValid = true;

    this.setState({ errors });

    return isValid;
  };

  onOpenCustomFieldModal = (value, type) => {
    this.setState({
      errors: {
        fieldValueError: '',
        fieldTypeError: '',
      },
      fieldValue: this.getCustomFieldValue(value),
      fieldType: this.columnToFieldType(type),
      fieldName: value,
      open: true,
    });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onCustomModalOpen = () => {
    this.setState({ openCutomModal: false });
  };

  onOpenNotesModal = (data) => {
    this.setState({
      openNotes: true,
      editNoteData: data,
      editNote: data.note,
    });
  };

  deleteNotes = () => {
    this.props.notesUpdate(this.state.deleteNotesData);
    this.cancelNotesModal();
  };

  deleteNotesModal = (data) => {
    this.setState({ openDelete: true });

    const options = {
      note: data.note,
      id: data.id,
      status: false,
      memberId: this.state.userId,
    };

    this.setState({ deleteNotesData: options });
  };

  cancelNotesModal = () => {
    this.setState({ openDelete: false });
  };

  onCloseNotesModal = () => {
    this.setState({ openNotes: false });
  };

  onCloseNotesModal1 = () => {
    this.setState({ openNotes1: false });
  };

  submitNotesValue = (e) => {
    e.preventDefault();
    if (this.state.editNote) {
      const options = {
        note: this.state.editNote,
        id: this.state.editNoteData.id,
        status: true,
        memberId: this.state.userId,
      };

      this.props.notesUpdate(options);
      this.onCloseNotesModal();
    }
  };

  oneditClickHome = (data) => {
    this.setState({ collapse1: !this.state.collapse1 });
    this.setOnclickValue(data);
  }

  /* homeSubmit = (e) => {
    e.preventDefault();
    const valid = true;

    if (valid) {
      const options = {
        memberId: this.state.userId,
        homeAddress1: this.state.homeAddress1.trim() || '',
        homeAddress2: this.state.homeAddress2,
        homeCity: this.state.homeCity,
        homeState: this.state.homeState,
        homeCountry: this.state.homeCountry,
        homeZip: this.state.homeZip,
        homePhone: this.state.homePhone,
        cellPhone: this.state.cellPhone,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
      };

      this.props.addressInfoUpdate(options);
    }
  } */

  homeSubmitValue = (e) => {
    e.preventDefault();
    const valid = self.validateHome();

    if (valid) {
      const addressOptions = {
        memberId: this.state.userId,
        homeAddress1: this.state.homeAddress1?.trim() || null,
        homeAddress2: this.state.homeAddress2?.trim() || null,
        homeCity: this.state.homeCity?.trim() || null,
        homeState: this.state.homeState?.trim() || '',
        homeCountry: this.state.homeCountry?.trim() || '',
        homeZip: this.state.homeZip?.trim() || null,
        homePhone: this.state.homePhone?.trim() || null,
        cellPhone: this.state.cellPhone?.trim() || null,
        homeLatitude: this.state.homeLatitude,
        homeLongitude: this.state.homeLongitude,
        needsUpdateVerification: this.needsUpdateVerification,
      };

      this.props.updateMemberInfo({ options: addressOptions });
    }
  }

  onClickWork = (data, collapse = true) => {
    const stateData = {};

    if (collapse) {
      stateData.collapse2 = !this.state.collapse2;
    }

    if (data) {
      stateData.companyName = data.companyName;
      stateData.position = data.position;
      stateData.workAddress1 = data.workAddress1;
      stateData.workAddress2 = data.workAddress2;
      stateData.workCity = data.workCity;
      stateData.workState = data.workState;
      stateData.workCountry = data.workCountry;
      stateData.workZip = data.workZip;
      stateData.businessPhone = data.businessPhone;
      stateData.website = data.website;
      stateData.workLongitude = data.workLongitude;
      stateData.workLatitude = data.worklatitude;
    }

    this.setState(stateData);
  }

  /* onClickWork1(data) {
    if (data) {
      this.setState({
        companyName: data.companyName,
        position: data.position,
        workAddress1: data.workAddress1,
        workAddress2: data.workAddress2,
        workCity: data.workCity,
        workState: data.workState,
        workCountry: data.workCountry,
        workZip: data.workZip,
        businessPhone: data.businessPhone,
        website: data.website,
        workLongitude: data.workLongitude,
        workLatitude: data.workLatitude,
      });
    }
  } */

  updatedWorkValue = (e) => {
    e.preventDefault();
    const options = {
      memberId: this.state.userId,
      companyName: this.state.companyName ? this.state.companyName.trim() : '',
      position: this.state.position ? this.state.position.trim() : '',
      workAddress1: this.state.workAddress1 ? this.state.workAddress1.trim() : '',
      workAddress2: this.state.workAddress2 ? this.state.workAddress2.trim() : '',
      workCity: this.state.workCity ? this.state.workCity.trim() : '',
      workState: this.state.workState ? this.state.workState.trim() : '',
      workCountry: this.state.workCountry ? this.state.workCountry.trim() : '',
      workZip: this.state.workZip ? this.state.workZip.trim() : '',
      businessPhone: this.state.businessPhone ? this.state.businessPhone.trim() : '',
      website: this.state.website ? this.state.website.trim() : '',
      workLatitude: this.state.workLatitude,
      workLongitude: this.state.workLongitude,
      needsUpdateVerification: this.needsUpdateVerification,
    };

    this.props.updateMemberInfo({ options });
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    let finalValue = value;

    if (event.target.type === 'checkbox') {
      finalValue = event.target.checked;
    }

    this.setState({
      [name]: finalValue,
    });
  }

  /* onChangeSortable = () => {
    this.setState({ isSortable: !this.state.isSortable });
  } */

  /* onChangeFilter = () => {
    this.setState({ showInFilter: !this.state.showInFilter });
  } */

  toggleColumnAvailabiltity = () => this.setState({ availableAsColumn: !this.state.availableAsColumn });

  handleChangeNum = (event) => {
    const { name, value, maxLength } = event.target;

    if (value.length < maxLength + 1) {
      this.setState({
        [name]: value,
      });
    }
  };

  validateCustomFields = () => {
    const errors = {};

    let isValid = true;

    if (this.state.fieldType === 'checkbox' || this.state.fieldType === 'Boolean') {
      isValid = true;
    } else if (!this.state.fieldValue) {
      isValid = false;
      errors.fieldValueError = 'emptyfieldValueValidation';
    }

    if (!this.state.fieldType) {
      isValid = false;
      errors.fieldTypeError = 'emptyfieldTypeValidation';
    }

    this.setState({ errors });

    return isValid;
  };

  validateFields = () => {
    const errors = {};

    let isValid = true;

    if (!this.state.firstName) {
      isValid = false;
      errors.firstNameError = 'emptyNameValidation';
    }

    if (!this.state.lastName) {
      isValid = false;
      errors.lastNameError = 'emptyLastNameValidation';
    }

    if (this.state.email) {
      if (!validateEmail(this.state.email)) {
        isValid = false;
        errors.errorEmail = 'emailValidation';
      }
    }

    if (this.state.alterEmail) {
      if (!validateEmail(this.state.alterEmail)) {
        isValid = false;
        errors.errorAlterEmail = 'emailValidation';
      }
    }

    if (this.state.nickName) {
      if (!validateName(this.state.nickName)) {
        isValid = false;
        errors.NickError = 'invalidNnameValidation';
      }
    }

    this.setState({ errors });

    return isValid;
  };

  setOnclickValue(data) {
    this.setState({
      homeAddress1: data && data.homeAddress1 ? data.homeAddress1 : '',
      homeAddress2: data && data.homeAddress2 ? data.homeAddress2 : '',
      homeCity: data && data.homeCity ? data.homeCity : '',
      homeState: data && data.homeState ? data.homeState : '',
      homeCountry: data && data.homeCountry ? data.homeCountry : '',
      homeZip: data && data.homeZip ? data.homeZip : '',
      homePhone: data && data.homePhone ? data.homePhone : '',
      cellPhone: data && data.cellPhone ? data.cellPhone : '',
      homeLatitude: data && data.homeLatitude ? data.homeLatitude : '',
      homeLongitude: data && data.homeLongitude ? data.homeLongitude : '',
    });
  }

  oneditClick(data) {
    self.setState({ collapse: !self.state.collapse });
    self.setOnclickValue(data);
  }

  /* handleSelectCompany(address, add1) {
    const names = address;

    const nameArr = names.split(',');

    self.setState({
      workAddress1: nameArr[0],
      worklatitude: '',
      workLongitude: '',
      workAddress2: '',
      workCity: '',
      workState: '',
      workCountry: '',
      workZip: '',
    });

    if (add1 !== '') {
      geocodeByAddress(address, add1)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => self.setState({ latitudeWork: lat, longitudeWork: lng }));
      geocodeByPlaceId(add1)
        .then((results) => {
          const bindAddress = results[0].address_components;

          const forLength = bindAddress.length;

          let mystreet = '';

          let myroute = '';

          let mylevel2 = '';

          const addressStateObj = {
            workAddress1: '',
            workCity: '',
            workState: '',
            workCountry: '',
            workZip: '',
            workAddress2: address,
          };

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < forLength; i++) {
            if (
              bindAddress[i].types.indexOf('street_number') !== -1
              || bindAddress[i].types.indexOf('establishment') !== -1
              || bindAddress[i].types.indexOf('route') !== -1
              || bindAddress[i].types.indexOf('sublocality_level_2') !== -1
            ) {
              if (bindAddress[i].types.indexOf('street_number') !== -1) {
                mystreet = `${bindAddress[i].long_name}, `;
              }

              if (bindAddress[i].types.indexOf('establishment') !== -1) {
                mystreet = `${bindAddress[i].long_name}, `;
              }

              if (bindAddress[i].types.indexOf('route') !== -1) {
                myroute = `${bindAddress[i].long_name} `;
              }

              if (bindAddress[i].types.indexOf('sublocality_level_2') !== -1) {
                mylevel2 = bindAddress[i].long_name;
              }

              const firstAddress = mystreet + mylevel2 + myroute;

              addressStateObj.address1 = firstAddress;
            } else if (bindAddress[i].types.indexOf('locality') !== -1) {
              addressStateObj.city = bindAddress[i].long_name;
            } else if (
              bindAddress[i].types.indexOf('administrative_area_level_1') !== -1
            ) {
              addressStateObj.state = bindAddress[i].short_name;
            } else if (bindAddress[i].types.indexOf('country') !== -1) {
              addressStateObj.country = bindAddress[i].long_name;
            } else if (bindAddress[i].types.indexOf('postal_code') !== -1) {
              addressStateObj.zip = bindAddress[i].long_name;
            }
          }

          self.setState(addressStateObj);
        })
        .catch((error) => console.error(error));
    }
  } */

  /* handleChangeCompany = (address1) => {
    this.setState({ address1 });
  }; */

  notesSave = (e) => {
    e.preventDefault();

    if (this.state.memberNotes) {
      const options = {
        note: this.state.memberNotes,
        memberId: this.state.userId,
        status: true,
      };

      this.props.saveNotes(options);
      this.state.memberNotes = '';
    }
  };

  notesSave1 = (e) => {
    e.preventDefault();

    if (this.state.editNote) {
      const options = {
        note: this.state.editNote,
        memberId: this.state.userId,
        status: true,
      };

      self.props.saveNotes(options);

      this.setState({ editNote: '' });
    }

    this.setState({ openNotes1: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.props.userData.id) {
      return false;
    }

    const { userId } = this.state;
    const valid = this.validateFields();

    const addedBy1 = this.getViewingUser();

    this.setState({ memberView: true });
    if (valid) {
      const options = {
        memberId: userId,
        title: this.state.title,
        firstName: this.state.firstName,
        middleName: this.state.middleName,
        lastName: this.state.lastName,
        nickName: this.state.nickName,
        suffix: this.state.suffix,
        email: this.state.email,
        altemail: this.state.alterEmail,
        gender: this.state.gender,
        parcelnumber: this.state.parcelnumber,
        dob: this.state.dob ? new Date(this.state.dob) : null,
        maritalStatus: this.state.marital,
        twitter: this.state.twitter,
        facebook: this.state.facebook,
        linkedin: this.state.linkedin,
        instagram: this.state.instagram,
        notes: this.state.notes,
        addedBy: addedBy1,
        needsUpdateVerification: this.needsUpdateVerification,
      };

      this.props.updateMemberInfo({ options });
    }

    return true;
  };

  /* toggle() {
    self.setState((state) => ({ collapse: !state.collapse }));
  } */

  preventSpacedText = (event) => User.spaceNotAllowed(event);

  validatePrecedingSpace = (event) => User.firstCharSpaceNotAllowed(event);

  /* editMemberInfo = () => {
    this.setState({ memberInformation: false });
  } */

  showError = (error) => <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>;

  setStateValue = (member, collapse = false) => {
    const stateValues = {};

    if (collapse) {
      stateValues.collapseUser = !this.state.collapseUser;
    }

    if (member) {
      stateValues.firstName = member.firstName;
      stateValues.title = member.title;
      stateValues.lastName = member.lastName;
      stateValues.middleName = member.middleName;
      stateValues.nickName = member.nickName;
      stateValues.suffix = member.suffix;
      stateValues.email = member.email;
      stateValues.alterEmail = member.altemail;
      stateValues.gender = member.gender;
      stateValues.parcelnumber = member.parcelnumber;
      stateValues.dob = member.dob ? moment(member.dob).format('MM/DD/YYYY') : null;
      stateValues.marital = member.maritalStatus;
      stateValues.twitter = member.twitter;
      stateValues.facebook = member.facebook;
      stateValues.linkedin = member.linkedin;
      stateValues.instagram = member.instagram;
      stateValues.notes = member.notes;
      stateValues.startDate = member.dob ? moment(member.dob).format('MM/DD/YYYY') : null;
    }

    this.setState(stateValues);
  }

  /* setStateValue1 = (member) => {
    if (member) {
      this.setState({
        firstName: member.firstName,
        title: member.title,
        lastName: member.lastName,
        middleName: member.middleName,
        nickName: member.nickName,
        suffix: member.suffix,
        email: member.email,
        alterEmail: member.altemail,
        gender: member.gender,
        parcelnumber: member.parcelnumber,
        dob: member.dob ? moment(member.dob).format('MM/DD/YYYY') : null,
        marital: member.maritalStatus,
        twitter: member.twitter,
        facebook: member.facebook,
        linkedin: member.linkedin,
        instagram: member.instagram,
        notes: member.notes,
        startDate: member.dob ? moment(member.dob).format('MM/DD/YYYY') : null,
      });
    }
  } */

  addCustomField = (e) => {
    e.preventDefault();

    const valid = this.validateCustomFields();

    if (valid) {
      const options = {
        fieldName: this.state.fieldName,
        fieldValue: this.state.fieldValue.toString(),
        fieldType: this.state.fieldType,
        // isSortable: this.state.isSortable,
        availableAsColumn: this.state.availableAsColumn,
        // showInFilter: this.state.showInFilter,
        memberId: this.state.userId,
        addedBy: this.viewingUser.user.id || this.state.addedBy,
        needsUpdateVerification: this.needsUpdateVerification,
      };

      this.props.updateMemberInfoSingle({ options });

      this.setState({ fieldName: '', fieldValue: '', fieldType: '' });
      this.onCloseModal();
    }
  };

  addCustomFieldTable = (e) => {
    e.preventDefault();

    const valid = this.validateCustomFields();

    if (valid) {
      const options = {
        fieldName: this.state.fieldName,
        fieldValue: this.state.fieldValue,
        fieldType: this.state.fieldType,
        availableAsColumn: this.state.availableAsColumn,
        // isSortable: this.state.isSortable,
        // showInFilter: this.state.showInFilter,
        memberId: this.state.userId,
        addf: 'false',
      };

      this.props.customField(options);
      this.setState({
        fieldName: '',
        fieldValue: '',
        fieldType: '',
        /* isSortable: '',
        showInFilter: '', */
        availableAsColumn: '',
      });
      this.onCustomModalOpen();
    }
  };

  onDeleteCustom = (e) => {
    const options = {
      fieldName: e,
      fieldValue: '',
      fieldType: '',
      /* isSortable: false,
      showInFilter: false, */
      availableAsColumn: false,
      memberId: this.state.userId,
      addedBy: this.viewingUser.user.id || this.state.addedBy,
    };

    this.props.updateMemberInfoSingle({ options });
    this.setState({ fieldName: '', fieldValue: '', fieldType: '' });
  };

  getMetaKeyForCustomColumn(customColumn) {
    return customColumn.toLowerCase().replace(' ', '_');
  }

  getCustomFieldValue = (column) => {
    const member = this.props.userData;

    if (!member || !member.customFields) {
      return 'N/A';
    }

    const col = this.getMetaKeyForCustomColumn(column);

    const selectedKey = member.metadata.find((m) => m.meta_key === col);

    return selectedKey ? selectedKey.meta_value : 'N/A';

    /* if (member.metadata[col] && member.metadata[col] !== null) {
      return member.metadata[col].toString();
    } */

    // return 'N/A';
  }

  canDeleteCustomField = (column) => {
    const member = this.props.userData;

    if (!this.viewingUser || !this.viewingUser.authId) {
      return false;
    }

    if (!member) {
      return false;
    }

    if (!member.customFields) {
      return false;
    }

    const filterCustomField = member.customFields.find((cf) => cf.fieldName === column);

    if (!filterCustomField) {
      return false;
    }

    return true;
  }

  render() {
    const {
      errors: {
        homeCountryError,
        homeAddress1Error,
        homeAddress2Error,
        homeCityError,
        homeStateError,
        titleError,
        errorEmail,
        firstNameError,
        fieldNameError,
        fieldTypeError,
        fieldValueError,
        middleNameError,
        SuffixError,
        NickError,
        lastNameError,
        errorAlterEmail,
        errorParcelNumber,
      },
    } = this.state;

    const { openNotes = false, openNotes1, openDelete, openCutomModal, open = false } = this.state;
    const { data } = this.props;

    const member = this.props.userData;

    if (!member) {
      return null;
    }

    const canHaveParcelNumber = member.organisation.OrganisationType.name === 'Home Owners Association';
    const statesList = states.length > 0
      && states.map((item, i) => (
        <option key={i} value={item.abbreviation}>
          {item.name}
        </option>
      ), this);

    const countriesList = country.length > 0
      && country.map((item, i) => (
        <option key={i} value={item.text}>
          {item.text}
        </option>
      ), this);

    const customHeaderList = this.props.data.userProfile.headerList;

    const customFieldPopulation = (
      <table className="table table-responsive table-Campaign custom-field-table">
        <thead>
          <tr>
            <th>NAME</th>
            <th>VALUE</th>
          </tr>
        </thead>
        <tbody>
          {customHeaderList
            ? customHeaderList.data.response.map((item, i) => (
              (item && item.isCustomColumn && this.state.fieldsColumn.indexOf(item.column_name) === -1)
                ? <tr key={i}>
                  <td>
                    {item.column_name === 'altemail' ? 'Alter Email' : item.column_name}
                  </td>
                  <td>
                    {this.getCustomFieldValue(item.column_name)}
                    {this.viewingUser && this.viewingUser.authId ? (<span onClick={() => this.onOpenCustomFieldModal(item.column_name, item.fieldType)}>Edit</span>) : ''}
                    {this.canDeleteCustomField(item.column_name) ? (
                      <span onClick={() => this.onDeleteCustom(item.column_name)}>Delete</span>
                    ) : null}
                  </td>
                </tr>
                : null
            ))
            : null}
        </tbody>
      </table>
    );

    const checkList = (
      <div>
        <table className="table table-responsive table-information">
          <thead>
            <tr>
              <th>NO</th>
              <th>NOTE</th>
              <th>DATE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.userProfile.notesList
              ? data.userProfile.notesList.data.map((value, i) => (value.status ? (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    <p>{value.note}</p>
                  </td>
                  <td>
                    <Moment format="MM/DD/YYYY">{value.updatedAt}</Moment>
                  </td>
                  <td>
                    <div className="icons_list">
                      <a onClick={() => this.onOpenNotesModal(value)}>
                        <i className="fa fa-pencil"></i>
                      </a>
                      <a onClick={() => this.deleteNotesModal(value)}>
                        <i className="fa fa-trash-o"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              ) : null))
              : null}
          </tbody>
        </table>
      </div>
    );

    return (
      <div>
        <div>
          <div className="panel info">
            <div className="panel-header">
              <h2>Member Information</h2>
              <div className="pull-right">
                {this.viewingUser && this.viewingUser.authId && (this.state.collapseUserMember ? (
                  <a
                    className="btn btn-default"
                    onClick={() => this.setStateValue(member, true)}
                  >
                    Edit
                  </a>
                ) : (
                  ''
                ))}
                <span
                  onClick={() => this.setState({
                    collapseUserMember: !this.state.collapseUserMember,
                  })
                  }

                >
                  {this.state.collapseUserMember ? (
                    <i aria-hidden="true" className="fa fa-caret-up"></i>
                  ) : (
                    <i aria-hidden="true" className="fa fa-caret-down"></i>
                  )}
                </span>
              </div>
            </div>
            <div>
              <Collapse isOpen={this.state.collapseUserMember}>
                <Collapse isOpen={this.state.collapseUser}>
                  <div className="panelbody">
                    <div className="col1 spc">
                      <ul>
                        <li>
                          <span>Title:</span>{' '}
                          {member && member.title ? member.title : ''}
                        </li>
                        <li>
                          <span>First Name:</span>{' '}
                          {member && member.firstName ? member.firstName : ''}
                        </li>
                        <li>
                          <span>Middle Name:</span>{' '}
                          {member && member.middleName ? member.middleName : ''}
                        </li>
                        <li>
                          <span>Last Name:</span>{' '}
                          {member && member.lastName ? member.lastName : ''}
                        </li>
                        <li>
                          <span>Nick Name:</span>{' '}
                          {member && member.nickName ? member.nickName : ''}
                        </li>
                        <li>
                          <span>Suffix:</span>{' '}
                          {member && member.suffix ? member.suffix : ''}
                        </li>
                      </ul>
                    </div>
                    <div className="col1 spc2">
                      <ul>
                        <li>
                          <span>Email:</span>{' '}
                          {member && member.email ? (
                            <a className="linking" href={`mailto:${member.email}`}>
                              {' '}
                              {member.email}{' '}
                            </a>
                          ) : (
                            ''
                          )}
                        </li>
                        <li>
                          <span>Alternate Email:</span>
                          {member && member.altemail ? member.altemail : ''}
                        </li>
                        <li>
                          <span>Gender:</span>
                          {member && member.gender ? member.gender : ''}
                        </li>
                        <li>
                          <span>Date of Birth:</span>
                          {member && member.dob ? (
                            <Moment format="MMMM, D YYYY">{member.dob}</Moment>
                          ) : null}
                        </li>
                        <li>
                          <span>Marital Status:</span>
                          {member && member.maritalStatus
                            ? member.maritalStatus
                            : null}
                        </li>
                      </ul>
                    </div>
                    <div className="col2 spc3">
                      <div className="social">
                        <label>Links:</label>
                        <ul>
                          {member && member.email ? (
                            <li>
                              <a href={`mailto:${member.email}`}>
                                <i className="fa fa-envelope"></i>
                              </a>
                            </li>
                          ) : (
                            ''
                          )}
                          {member && member.twitter ? (
                            <li>
                              <a href={member.twitter}>
                                <i className="fa fa-twitter"></i>
                              </a>
                            </li>
                          ) : (
                            ''
                          )}
                          {member && member.facebook ? (
                            <li>
                              <a href={member.twitter}>
                                <i className="fa fa-facebook"></i>
                              </a>
                            </li>
                          ) : (
                            ''
                          )}
                          {member && member.instagram ? (
                            <li>
                              <a href={member.instagram}>
                                <i className="fa fa-instagram"></i>
                              </a>
                            </li>
                          ) : (
                            ''
                          )}
                          {member && member.linkedin ? (
                            <li>
                              <a href={member.linkedin}>
                                <i className="fa fa-linkedin"></i>
                              </a>
                            </li>
                          ) : (
                            ''
                          )}
                        </ul>
                      </div>
                      <div className="form-group">
                        <label>Notes</label>
                        <textarea
                          className="form-control"
                          name="memberNotes"
                          placeholder="Write something about"
                          value={this.state.memberNotes}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        ></textarea>
                      </div>
                      <a className="btn btn-default" onClick={this.notesSave}>
                        Save note
                      </a>
                    </div>
                  </div>
                </Collapse>
                <Collapse isOpen={!this.state.collapseUser}>
                  <div className="panelbody">
                    <form className="form_opt two_col">
                      <div className="form-group">
                        <label>Nick Name (Preferred Name)</label>
                        <input
                          className="form-control"
                          name="nickName"
                          placeholder="What's your nick name?"
                          type="text"
                          value={this.state.nickName ? this.state.nickName : ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                        {!!NickError && this.showError(NickError)}
                      </div>

                      <div className="form-group">
                        <label>Title</label>
                        <input
                          className="form-control"
                          name="title"
                          placeholder="Title"
                          type="text"
                          value={this.state.title || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                        {!!titleError && this.showError(titleError)}
                      </div>

                      <div className="form-group">
                        <label>First Name</label>
                        <input
                          className="form-control"
                          name="firstName"
                          placeholder="What's your first name?"
                          type="text"
                          value={this.state.firstName}
                          onChange={this.handleChange.bind(this)}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                        {!!firstNameError && this.showError(firstNameError)}
                      </div>
                      <div className="form-group">
                        <label> Middle Name:</label>
                        <input
                          className="form-control"
                          name="middleName"
                          placeholder="What's your middle name?"
                          type="text"
                          value={this.state.middleName ? this.state.middleName : ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                        {!!middleNameError && this.showError(middleNameError)}
                      </div>
                      <div className="form-group">
                        <label>Last Name:</label>
                        <input
                          className="form-control"
                          name="lastName"
                          placeholder="What's your last name?"
                          type="text"
                          value={this.state.lastName || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                        {!!lastNameError && this.showError(lastNameError)}
                      </div>

                      <div className="form-group">
                        <label>Suffix:</label>
                        <input
                          className="form-control"
                          name="suffix"
                          placeholder="What's your suffix?"
                          type="text"
                          value={this.state.suffix || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                        {!!SuffixError && this.showError(SuffixError)}
                      </div>

                      <div className="form-group">
                        <label>Email:</label>
                        <input
                          className="form-control"
                          name="email"
                          placeholder="What's your email?"
                          type="text"
                          value={this.state.email || ''}
                          onChange={this.handleChange}
                        />
                        {!!errorEmail && this.showError(errorEmail)}
                      </div>

                      <div className="form-group">
                        <label>Gender:</label>
                        <select
                          className="form-control"
                          name="gender"
                          placeholder="Select your gender"
                          value={this.state.gender ? this.state.gender : ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        >
                          <option value="">Select your gender?</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {/* <i className="fa fa-caret-down" aria-hidden="true"></i> */}
                      </div>
                      {/* {console.log(this.props)} */}
                      {
                        canHaveParcelNumber
                          ? <div className="form-group">
                              <label>Parcel Number</label>
                              <input
                                className="form-control"
                                name="parcelnumber"
                                placeholder="123456"
                                type="text"
                                value={this.state.parcelnumber ? this.state.parcelnumber : ''}
                                onChange={this.handleChange}
                              />
                              {!!errorParcelNumber && this.showError(errorParcelNumber)}
                            </div>
                          : null
                      }

                      <div className="form-group">
                        <label>Alternate Email</label>
                        <input
                          className="form-control"
                          name="alterEmail"
                          placeholder="What's your alternate email?"
                          type="text"
                          value={this.state.alterEmail ? this.state.alterEmail : ''}
                          onChange={this.handleChange}
                        />
                        {!!errorAlterEmail && this.showError(errorAlterEmail)}
                      </div>

                      <div className="form-group">
                        <label>Marital Status</label>
                        <input
                          className="form-control"
                          name="marital"
                          placeholder="What's your marital status?"
                          type="text"
                          value={this.state.marital ? this.state.marital : ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                      </div>

                      <div className="form-group db">
                        <label>Date of Birth:</label>
                        <input
                          className="form-control"
                          max={moment(new Date()).format('YYYY-MM-DD')}
                          name="dob"
                          placeholder="What's your date of birth?"
                          type="date"
                          value={this.state.dob ? this.state.dob : ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                      </div>

                      <br></br>
                      <h2 className="full_head margin-link">Links:</h2>

                      <div className="form-group">
                        <label>Linkedin</label>
                        <input
                          className="form-control"
                          name="linkedin"
                          placeholder="Your Linkedin link"
                          type="text"
                          value={this.state.linkedin || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.preventSpacedText}
                        />
                      </div>

                      <div className="form-group">
                        <label>Twitter</label>
                        <input
                          className="form-control"
                          name="twitter"
                          placeholder="Your Twitter link"
                          type="text"
                          value={this.state.twitter || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.preventSpacedText}
                        />
                      </div>

                      <div className="form-group">
                        <label>Facebook</label>
                        <input
                          className="form-control"
                          name="facebook"
                          placeholder="Your Facebook link"
                          type="text"
                          value={this.state.facebook || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.preventSpacedText}
                        />
                      </div>

                      <div className="form-group">
                        <label>Instagram</label>
                        <input
                          className="form-control"
                          name="instagram"
                          placeholder="Your Instagram link"
                          type="text"
                          value={this.state.instagram || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.preventSpacedText}
                        />
                      </div>

                      <div className="form_btn_rw">
                        <button
                          className="btn btn-primary save-continue-btn"
                          onClick={this.handleSubmit}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </Collapse>
              </Collapse>
            </div>
          </div>
          <div className="panel info">
            <div className="panel-header">
              <h2>Previous notes:</h2>
              {this.viewingUser && this.viewingUser.authId ? (<div className="pull-right">
                <a
                  className="btn link"
                  onClick={() => this.setState({ openNotes1: true })}
                >
                  Add New
                </a>
              </div>) : ''}

            </div>

            <div className="panelbody">
              {checkList}
              {this.state.numRowsData === 0 ? (
                <div className="no-record">
                  <p>No Record!</p>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <ImportantPeople memberId={this.currentMemberID} />

          <div className="panel info">
            <div className="panel-header">
              <h2>Home</h2>
              <div className="pull-right">
                {this.viewingUser && this.viewingUser.authId && this.state.homeDiv ? (
                  <a
                    className="btn btn-default"
                    onClick={() => this.oneditClickHome(member)
                    }
                  >
                    Edit
                  </a>
                ) : (
                  ''
                )}
                <span
                  onClick={() => this.setState({ homeDiv: !this.state.homeDiv })}
                >
                  {this.state.homeDiv ? (
                    <i aria-hidden="true" className="fa fa-caret-up"></i>
                  ) : (
                    <i aria-hidden="true" className="fa fa-caret-down"></i>
                  )}
                </span>
              </div>
            </div>
            <Collapse isOpen={this.state.homeDiv}>
              <Collapse isOpen={this.state.collapse1}>
                <div className="panelbody" show="false">
                  <div className="col1">
                    <ul>
                      <li>
                        <span>Address Line 1:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.homeAddress1 ? this.state.homeAddress1 : ''}
                        </span>
                      </li>
                      <li>
                        <span>Address Line 2:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.homeAddress2 ? this.state.homeAddress2 : ''}
                        </span>
                      </li>
                      <li>
                        <span>Country:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.homeCountry ? this.state.homeCountry : ''}
                        </span>
                      </li>
                      <li>
                        <span>City:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.homeCity ? this.state.homeCity : ''}
                        </span>
                      </li>

                      <li>
                        <span>State/Province:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.homeState ? this.state.homeState : ''}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col1">
                    <ul>
                      <li>
                        <span>Zip/Postal Code:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.homeZip ? this.state.homeZip : ''}
                        </span>
                      </li>
                      <li>
                        <span>Home Phone:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.homePhone ? this.state.homePhone : ''}
                        </span>
                      </li>
                      <li>
                        <span>Cell:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.cellPhone ? this.state.cellPhone : ''}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Collapse>
              <Collapse isOpen={!this.state.collapse1}>
                <div className="panelbody" show="false">
                <div className="col12 col_wrap">
                  <div className="form-group">
                    <label>Address 1</label>
                    <input
                      className='location-search-input form-control'
                      name="homeAddress1"
                      placeholder='Address 1'
                      value={this.state.homeAddress1 || ''}
                      onChange={this.handleChange}
                      onKeyPress={this.validatePrecedingSpace}
                    />

                    {!!homeAddress1Error && this.showError(homeAddress1Error)}
                  </div>
                  <div className="form-group">
                    <label>Address 2</label>
                    <input
                      className="form-control"
                      name="homeAddress2"
                      placeholder="Address 2"
                      type="text"
                      value={this.state.homeAddress2 || ''}
                      onChange={this.handleChange}
                      onKeyPress={this.validatePrecedingSpace}
                    />
                    {!!homeAddress2Error && this.showError(homeAddress2Error)}
                  </div>
                  <div className="form-group">
                    <label>City</label>
                    <input
                      className="form-control"
                      name="homeCity"
                      placeholder="Your city"
                      type="text"
                      value={this.state.homeCity || ''}
                      onChange={this.handleChange.bind(this)}
                      onKeyPress={this.validatePrecedingSpace}
                    />
                    {!!homeCityError && this.showError(homeCityError)}
                  </div>
                  <div className="form-group">
                    <label>State/Province</label>

                    {this.state.homeCountry === 'United States' ? (
                      <select
                        className="form-control"
                        name="homeState"
                        placeholder="Your state"
                        value={this.state.homeState}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      >
                        <option value="">Your state</option>
                        {statesList}
                      </select>
                    ) : (
                      <input
                        className="form-control"
                        name="homeState"
                        placeholder="Your state"
                        type="text"
                        value={this.state.homeState || ''}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      />
                    )}

                    {!!homeStateError && this.showError(homeStateError)}
                  </div>

                  <div className="form-group">
                    <label>Country</label>

                    <select
                      className="form-control"
                      name="homeCountry"
                      placeholder="Your country"
                      value={
                        this.state.homeCountry
                          ? this.state.homeCountry
                          : 'United States'
                      }

                      onChange={this.handleChange}
                      onKeyPress={this.validatePrecedingSpace}
                    >
                      <option value="">Your country</option>
                      {countriesList}
                    </select>

                    {!!homeCountryError && this.showError(homeCountryError)}
                  </div>
                  <div className="form-group">
                    <label>Home Phone</label>
                    <input
                      className="form-control"
                      name="homePhone"
                      placeholder="Your home phone number"
                      type="tel"
                      value={this.state.homePhone || ''}
                      onChange={this.handleChange}
                      onKeyPress={this.validatePrecedingSpace}
                    />
                  </div>
                  <div className="form-group">
                    <label>Cell Phone</label>
                    <input
                      className="form-control"
                      name="cellPhone"
                      placeholder="Your cell phone number"
                      type="tel"
                      value={this.state.cellPhone || ''}
                      onChange={this.handleChange}
                      onKeyPress={this.validatePrecedingSpace}
                    />
                  </div>
                  </div>
                  <div className="col-sm-12 text-center">
                    <button
                      className="btn btn-primary"
                      onClick={this.homeSubmitValue}
                    >
                      Update Info Home
                    </button>
                  </div>
                </div>
              </Collapse>
            </Collapse>
          </div>

          <div className="panel info">
            <div className="panel-header">
              <h2>Work</h2>
              <div className="pull-right">
                {data.userProfile.memberView && this.viewingUser && this.viewingUser.authId && this.state.workDiv ? (
                  <a
                    className="btn btn-default"
                    onClick={() => this.onClickWork(data.userProfile.memberView.data, true)}
                  >
                    Edit
                  </a>
                ) : (
                  ''
                )}
                <span
                  onClick={() => this.setState({ workDiv: !this.state.workDiv })}
                >
                  {this.state.workDiv ? (
                    <i aria-hidden="true" className="fa fa-caret-up"></i>
                  ) : (
                    <i aria-hidden="true" className="fa fa-caret-down"></i>
                  )}
                </span>
              </div>
            </div>
            <Collapse isOpen={this.state.workDiv}>
              <Collapse isOpen={this.state.collapse2}>
                <div className="panelbody" show="false">
                  <div className="col1">
                    <ul>
                      <li>
                        <span>Company Name:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.companyName
                            ? this.state.companyName
                            : ''}
                        </span>
                      </li>
                      <li>
                        <span>Address Line 1:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.workAddress1
                            ? this.state.workAddress1
                            : ''}
                        </span>
                      </li>
                      <li>
                        <span>Address Line 2:</span>
                        <span className="capitalize-text">
                          {this.state.workAddress2
                            ? this.state.workAddress2
                            : ''}
                        </span>
                      </li>
                      <li>
                        <span>Country:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.workCountry
                            ? this.state.workCountry
                            : ''}
                        </span>
                      </li>
                      <li>
                        <span>City:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.workCity
                            ? this.state.workCity
                            : ''}
                        </span>
                      </li>

                      <li>
                        <span>State/Province:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.workState
                            ? this.state.workState
                            : ''}
                        </span>
                      </li>
                      <li>
                        <span>Zip/Postal Code:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.workZip
                            ? this.state.workZip
                            : ''}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col1">
                    <ul>
                      <li>
                        <span>Position/Title:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.position
                            ? this.state.position
                            : ''}
                        </span>
                      </li>
                      <li>
                        <span>Business Phone:</span>{' '}
                        <span className="capitalize-text">
                          {this.state.businessPhone
                            ? this.state.businessPhone
                            : ''}
                        </span>
                      </li>

                      <li>
                        <span>Webiste URL:</span>
                        <span className="capitalize-text">
                          {this.state.website
                            ? this.state.website
                            : ''}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Collapse>
              <Collapse isOpen={!this.state.collapse2}>
                <div className="panelbody" show="false">
                  <div className="col12 col_wrap">
                    <div className="form-group">
                      <label>Company Name</label>
                      <input
                        className="form-control"
                        name="companyName"
                        placeholder="Your company name"
                        type="text"
                        value={this.state.companyName || ''}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      />
                    </div>

                    <div className="form-group">
                      <label>Position/Title</label>
                      <input
                        className="form-control"
                        name="position"
                        placeholder="Your position"
                        type="text"
                        value={this.state.position || ''}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 1</label>
                      <input
                        className='location-search-input form-control'
                        name="workAddress1"
                        placeholder='Address 1'
                        value={this.state.workAddress1 || ''}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      />
                    </div>
                    <div className="form-group">
                      <label>Address 2</label>
                      <input
                        className="form-control"
                        name="workAddress2"
                        placeholder="Address 2"
                        type="text"
                        value={this.state.workAddress2 || ''}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      />
                    </div>
                    <div className="form-group">
                      <label>City</label>
                      <input
                        className="form-control"
                        name="workCity"
                        placeholder="Your city"
                        type="text"
                        value={this.state.workCity || ''}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      />
                    </div>
                    <div className="form-group">
                      <label>State/Province</label>

                      {this.state.workCountry === 'United States' ? (
                        <select
                          className="form-control"
                          name="workState"
                          placeholder="Your state"
                          value={this.state.workState || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        >
                          <option value="">Your state</option>
                          {statesList}
                        </select>
                      ) : (
                        <input
                          className="form-control"
                          name="workState"
                          placeholder="Your state"
                          type="text"
                          value={this.state.workState || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <label>Zip/Postal Code</label>
                      <input
                        className="form-control"
                        maxLength="6"
                        name="workZip"
                        placeholder="Your zip code"
                        type="number"
                        value={this.state.workZip || ''}
                        onChange={this.handleChangeNum}
                        onKeyPress={this.validatePrecedingSpace}
                      />
                    </div>
                    <div className="form-group">
                      <label>Country</label>
                      <select
                        className="form-control"
                        name="workCountry"
                        placeholder="Your country"
                        value={this.state.workCountry || ''}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      >
                        <option value="">Your country</option>
                        {countriesList}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Business Phone</label>
                      <input
                        className="form-control"
                        maxLength="10"
                        name="businessPhone"
                        placeholder="Your work phone number"
                        type="number"
                        value={this.state.businessPhone || ''}
                        onChange={this.handleChangeNum}
                      />
                    </div>

                    <div className="form-group">
                      <label>Website URL</label>
                      <input
                        className="form-control"
                        name="website"
                        placeholder="Your website URL"
                        type="text"
                        value={this.state.website || ''}
                        onChange={this.handleChange}
                        onKeyPress={this.validatePrecedingSpace}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 text-center">
                    <button
                      className="btn btn-primary"
                      onClick={this.updatedWorkValue}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </Collapse>
            </Collapse>
          </div>
          <div className="panel info">
            <div className="panel-header">
              <h2>Custom Fields</h2>
              <div className="pull-right">
                {this.viewingUser && this.viewingUser.authId ? (<a
                  className="btn link"
                  onClick={() => this.setState({ openCutomModal: true })}
                >
                  Add New
                </a>) : ''}

                <span
                  onClick={() => this.setState({ customFieldDiv: !this.state.customFieldDiv })
                  }

                >
                  {this.state.customFieldDiv ? (
                    <i aria-hidden="true" className="fa fa-caret-up"></i>
                  ) : (
                    <i aria-hidden="true" className="fa fa-caret-down"></i>
                  )}
                </span>
              </div>
            </div>
            <Collapse isOpen={this.state.customFieldDiv}>
              <div className="panelbody">{customFieldPopulation}</div>
            </Collapse>

            <Modal
              center
              open={openDelete}
              onClose={this.cancelNotesModal}
            >
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2> Are you sure you want to delete? </h2>
                </div>
                <div className="model-content eng">
                  <div className="center-btn">
                    <button className="btn btn-primary margin-btn display-none-btn"></button>
                    <button
                      className="btn btn-primary margin-btn"
                      onClick={this.deleteNotes}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-primary margin-btn"
                      onClick={this.cancelNotesModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              center
              open={openNotes}
              onClose={this.onCloseNotesModal}
            >
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2>Edit Notes</h2>
                </div>
                <div className="model-content eng">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="editNote"
                      placeholder="Input the value"
                      rows="4"
                      type="text"
                      value={this.state.editNote}
                      onChange={this.handleChange}
                      onKeyPress={this.validatePrecedingSpace}
                    />
                  </div>
                  <div className="center-btn">
                    <button
                      className="btn btn-primary"
                      onClick={this.submitNotesValue}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal
              center
              open={openNotes1}
              onClose={this.onCloseNotesModal1}
            >
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2>Add Notes</h2>
                </div>
                <div className="model-content eng">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="editNote"
                      placeholder="Input the value"
                      rows="4"
                      type="text"
                      value={this.state.editNote}
                      onChange={this.handleChange}
                      onKeyPress={this.validatePrecedingSpace}
                    />
                  </div>
                  <div className="center-btn">
                    <button className="btn btn-primary" onClick={this.notesSave1}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <Modal center open={open} onClose={this.onCloseModal}>
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2 className="capitalize-text">{this.state.fieldName}</h2>
                </div>
                <div className="model-content eng">
                {
                  this.columnToFieldType(this.state.fieldType) === 'checkbox'
                    ? <div className="form-group">
                          <input
                            checked={this.state.fieldValue === true ? 'checked' : false}
                            className="form-control"
                            id={this.getFieldId(this.state.fieldName)}
                            name="fieldValue"
                            type={this.columnToFieldType(this.state.fieldType)}
                            onChange={this.handleChange}
                          />
                          <label htmlFor={this.getFieldId(this.state.fieldName)}>Value</label>
                        </div>

                    : <div className="form-group">
                        <label htmlFor={this.getFieldId(this.state.fieldName)}>Value</label>
                        <input
                          className="form-control"
                          name="fieldValue"
                          placeholder="Input the value"
                          type={this.state.fieldType}
                          value={this.state.fieldValue || ''}
                          onChange={this.handleChange}
                          onKeyPress={this.validatePrecedingSpace}
                        />
                        {!!fieldValueError && this.showError(fieldValueError)}
                      </div>
                    }
                  <button
                    className="btn btn-primary "
                    onClick={this.addCustomField}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Modal>

            <Modal
              center
              open={openCutomModal}
              onClose={this.onCustomModalOpen}
            >
              <div className="modal_bx member">
                <div className="modal-header head">
                  <h2 className="capitalize-text">Custom Field</h2>
                </div>
                <div className="model-content eng model-content-edit-2">
                  <div className="form-group">
                    <label>Custom Field Name</label>
                    <input
                      className="form-control"
                      name="fieldName"
                      placeholder="Select the custom field"
                      type="text"
                      value={this.state.fieldName}
                      onChange={this.handleChange}
                    />
                    {!!fieldNameError && this.showError(fieldNameError)}
                  </div>
                  <div className="form-group">
                    <label>Type</label>
                    <CustomFieldType
                      className="form-control"
                      name="fieldType"
                      value={this.state.fieldType}
                      onChange={this.handleChange}
                    />
                    {!!fieldTypeError && this.showError(fieldTypeError)}
                  </div>
                  <div>
                    {
                    this.columnToFieldType(this.state.fieldType) === 'checkbox'
                      ? <div className="form-group">
                          <input
                            checked={this.state.fieldValue === true ? 'checked' : false}
                            className="form-control"
                            id={this.getFieldId(this.state.fieldName)}
                            name="fieldValue"
                            type={this.columnToFieldType(this.state.fieldType)}
                            onChange={this.handleChange}
                          />
                          <label htmlFor={this.getFieldId(this.state.fieldName)}>Value</label>
                        </div>

                      : <div className="form-group">
                          <label htmlFor={this.getFieldId(this.state.fieldName)}>Value</label>
                          <input
                            className="form-control"
                            id={this.getFieldId(this.state.fieldName)}
                            name="fieldValue"
                            placeholder="Input the value"
                            type={this.columnToFieldType(this.state.fieldType)}
                            value={this.state.fieldValue}
                            onChange={this.handleChange}
                          />
                          {!!fieldValueError && this.showError(fieldValueError)}
                        </div>
                    }
                  </div>
                  <br />
                  <div className="form-group">
                    <ul>
                      <li>
                        <input
                          id="availableAsColumn"
                          name="availableAsColumn"
                          type="checkbox"
                          value={this.state.availableAsColumn}
                          onChange={this.toggleColumnAvailabiltity}
                        />
                        <label htmlFor="availableAsColumn">Make column available for member list</label>
                      </li>
                      {/* <li>
                        <input
                          id="opted2"
                          name="opted2"
                          type="checkbox"
                          value={this.state.isSortable}
                          onChange={(e) => {
                            this.onChangeSortable(e);
                          }}
                        />
                        <label htmlFor="opted2">Sortable</label>
                      </li> */}
                      {/* <li>
                        <input
                          id="opted3"
                          name="opted3"
                          type="checkbox"
                          value={this.state.showInFilter}
                          onChange={(e) => {
                            this.onChangeFilter(e);
                          }}
                        />
                        <label htmlFor="opted3">Show in Filter</label>
                      </li> */}
                    </ul>
                  </div>

                  <button
                    className="btn btn-primary "
                    onClick={this.addCustomFieldTable}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state,
});

export default connect(mapStateToProps, {
  updateMemberInfo,
  saveNotes,
  notesList,
  // addressInfo,
  // addressInfoUpdate,
  notesUpdate,
  customField,
  customFieldList,
  headerList,
  updateMemberInfoSingle,
})(ProfileInfo);
