/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updatePaypalDetails,
} from 'actions/settings-step-action';
import api from '../SettingStepProfile/Views/Services';

class PayPal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: '',
      clientSecret: '',
      errors: {
        clientIdError: '',
        clientSecretError: '',
      },
    };
  }

  componentDidMount() {
    api.getSecretKey(this.props.organisation.id).then((data) => {
      if (data.response) {
        this.setState({
          clientId: data.response.clientId,
          clientSecret: data.response.clientSecret,
        });
      }
    });
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  };

  /*  validateKeys = () => {
    const errors = {};

    let isValid = true;

    const { clientId, clientSecret } = this.state;

    if (!clientId) {
      isValid = false;
      errors.clientIdError = 'emptyClientIdValidation';
    }
    if (clientId && clientId.length < 2) {
      isValid = false;
      errors.clientIdError = 'invalidClientIdValidation';
    }
    if (!clientSecret) {
      isValid = false;
      errors.clientSecretError = 'emptyClientSecretValidation';
    }
    if (clientSecret && clientSecret.length < 2) {
      isValid = false;
      errors.clientSecretError = 'invalidClientSecretValidation';
    }

    this.setState({ errors });

    return isValid;
  } */

  updatePaypal = (event) => {
    event.preventDefault();
    const { organisation } = this.props;

    const input = {
      clientId: this.state.clientId,
      clientSecret: this.state.clientSecret,
      organisationId: organisation.id,
      paymentMethod: 'paypal',
    };

    this.props.updatePaypalDetails(input);
  }

  render() {
    const {
      clientId,
      clientSecret,
      errors: {
        clientIdError,
        clientSecretError,
      },
    } = this.state;

    return (
      <div className="row paypal-form">
        <h3>PayPal Credentials</h3>
        <div className="setting_topinfo">
          <div className="form-group">
            <label>Client Id</label>
            <input
            autoComplete="false"
            className="form-control"
            name="clientId"
            placeholder="What's your paypal client id?"
            type="text"
            value={clientId}
            onChange={this.handleChange}
            />
            {clientIdError && this.showError(clientIdError)}
          </div>

          <div className="form-group">
            <label>Client Secret</label>
            <input
            autoComplete="false"
            className="form-control"
            name="clientSecret"
            placeholder="What's your paypal client secret?"
            type="text"
            value={clientSecret}
            onChange={this.handleChange}
            />
            {clientSecretError && this.showError(clientSecretError)}
          </div>
          <button className="btn btn-primary" onClick={this.updatePaypal}>Save</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {
  updatePaypalDetails,
})(PayPal);
