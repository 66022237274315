import React from 'react';
import logo from 'assets/images/LogoIcon.png';

class Header extends React.Component {
  render() {
    return (
      <div className="App-header">
        <img alt="logo" src={logo} />
      </div>
    );
  }
}

export default Header;
