import React from 'react';
import './Step3.scss';
import { connect } from 'react-redux';
import { func, shape, string } from 'prop-types';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { logOut } from 'actions/user-authentication-action-types';
import { step3Form } from 'actions/settings-step-action';
import CSVIMG from 'assets/images/csv.png';

let self;

class Step3 extends React.Component {
  static propTypes = {
    logOut: func.isRequired,
    logOutStatus: string,
    step3Form: func.isRequired,
    step3FormError: string,
    step3FormFieldsDetails: shape({
      email: string,
      name: string,
    }),
  };

  state = {
    errorCsv: '',
  };

  componentDidMount() {
    self = this;
  }

  fileUpload(file) {
    const fileExt = file.name;

    if (file) {
      if (
        fileExt.lastIndexOf('.csv') !== -1
        || fileExt.lastIndexOf('.xlsx') !== -1
        || fileExt.lastIndexOf('.xls') !== -1
      ) {
        const uploadcsvPath = document.getElementById('uploadcsv').value;

        localStorage.setItem('uploadcsvPath', uploadcsvPath);
        const formData = new FormData();

        formData.append('uploadcsv', file);
        this.props.step3Form(formData);
      } else {
        self.setState({
          errorCsv: 'File type not supported  only CSV, XLS acceptable.',
        });
      }
    }
  }

  submitCvs = (event) => {
    this.props.beforeSubmit();
    this.setState({ fileName: event.target.files[0] });
    this.importCSV(event.target.files[0]);
    this.props.afterSubmit();
  };

  importCSV = (dataImport) => {
    const fileExt = dataImport.name;

    if (fileExt.lastIndexOf('.csv') !== -1) {
      Papa.parse(dataImport, {
        complete: this.updateData,
        header: false,
      });
    } else if (
      fileExt.lastIndexOf('.xlsx') !== -1
      || fileExt.lastIndexOf('.xls') !== -1
    ) {
      const reader = new FileReader();

      reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws);

        if (data === '') {
          toast.error('Empty xlsx file.');
        } else {
          self.fileUpload(self.state.fileName);
          // sheet_to_csv(ws,{header:0});
          /* Update state */

          const finalData = [];

          // eslint-disable-next-line no-restricted-syntax
          for (const propName in data[0]) {
            // eslint-disable-next-line no-prototype-builtins
            if (data[0].hasOwnProperty(propName)) {
              finalData.push(propName);
            }
          }

          localStorage.setItem('csvFilesHeader', JSON.stringify(finalData));
        }
      };
      reader.readAsBinaryString(dataImport);
    }
  };

  updateData = (result) => {
    const { data } = result;

    if (data[0][0] === '' || data[1][0] === '') {
      toast.error('Empty csv file.');
    } else {
      this.fileUpload(this.state.fileName);
      localStorage.setItem('csvFilesHeader', JSON.stringify(data[0]));
    }
  }

  render() {
    return (
      <div className=" sm">
        <div className="panel">
          <div className="form-group img_bx">
            <img alt="csv" src={CSVIMG} />
            <h3>Import users (CSV)</h3>
          </div>
          <div className="upload-btn-wrapper">
            <button className="btn btn-primary">Upload</button>
            <input
              accept=".csv, .xlsx, .xls"
              id="uploadcsv"
              name="uploadcsv"
              type="file"
              onChange={this.submitCvs}
            />
            <p className="error-msg-csv">{this.state.errorCsv}</p>
          </div>
        </div>
        <a className="link" href="/administration-dashboard">
          Skip this Step
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.settings,
});

export default connect(mapStateToProps, {
  logOut,
  step3Form,
})(Step3);
