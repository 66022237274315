import { createAction } from 'redux-actions';

export const EVENT_LISTING = 'EVENT_LISTING';
export const eventListing = createAction(EVENT_LISTING);

export const EVENT_LISTING_REQUESTED = 'EVENT_LISTING_REQUESTED';
export const eventListingRequested = createAction(EVENT_LISTING_REQUESTED);

export const EVENT_LISTING_SUCCESS = 'EVENT_LISTING_SUCCESS';
export const eventListingSuccess = createAction(EVENT_LISTING_SUCCESS);

export const EVENT_LISTING_FAILURE = 'EVENT_LISTING_FAILURE';
export const eventListingFailure = createAction(EVENT_LISTING_FAILURE);

export const ADD_EVENT = 'ADD_EVENT';
export const addEvent = createAction(ADD_EVENT);

export const ADD_EVENT_REQUESTED = 'ADD_EVENT_REQUESTED';
export const addEventRequested = createAction(ADD_EVENT_REQUESTED);

export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const addEventSuccess = createAction(ADD_EVENT_SUCCESS);

export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';
export const addEventFailure = createAction(ADD_EVENT_FAILURE);

export const EDIT_EVENT = 'EDIT_EVENT';
export const editEvent = createAction(EDIT_EVENT);

export const EDIT_EVENT_REQUESTED = 'EDIT_EVENT_REQUESTED';
export const editEventRequested = createAction(EDIT_EVENT_REQUESTED);

export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const editEventSuccess = createAction(EDIT_EVENT_SUCCESS);

export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';
export const editEventFailure = createAction(EDIT_EVENT_FAILURE);

export const INVITE_MEMBER = 'INVITE_MEMBER';
export const inviteMember = createAction(INVITE_MEMBER);

export const INVITE_MEMBER_REQUESTED = 'INVITE_MEMBER_REQUESTED';
export const inviteMemberRequested = createAction(INVITE_MEMBER_REQUESTED);

export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS';
export const inviteMemberSuccess = createAction(INVITE_MEMBER_SUCCESS);

export const INVITE_MEMBER_FAILURE = 'INVITE_MEMBER_FAILURE';
export const inviteMemberFailure = createAction(INVITE_MEMBER_FAILURE);

export const INVITE_MEMBER_LIST = 'INVITE_MEMBER_LIST';
export const inviteMemberList = createAction(INVITE_MEMBER_LIST);

export const INVITE_MEMBER_LIST_REQUESTED = 'INVITE_MEMBER_LIST_REQUESTED';
export const inviteMemberListRequested = createAction(INVITE_MEMBER_LIST_REQUESTED);

export const INVITE_MEMBER_LIST_SUCCESS = 'INVITE_MEMBER_LIST_SUCCESS';
export const inviteMemberListSuccess = createAction(INVITE_MEMBER_LIST_SUCCESS);

export const INVITE_MEMBER_LIST_FAILURE = 'INVITE_MEMBER_LIST_FAILURE';
export const inviteMemberListFailure = createAction(INVITE_MEMBER_LIST_FAILURE);

export const FILTER_EVENT_LIST = 'FILTER_EVENT_LIST';
export const filterEventList = createAction(FILTER_EVENT_LIST);

export const FILTER_EVENT_LIST_REQUESTED = 'FILTER_EVENT_LIST_REQUESTED';
export const filterEventListRequested = createAction(FILTER_EVENT_LIST_REQUESTED);

export const FILTER_EVENT_LIST_SUCCESS = 'FILTER_EVENT_LIST_SUCCESS';
export const filterEventListSuccess = createAction(FILTER_EVENT_LIST_SUCCESS);

export const FILTER_EVENT_LIST_FAILURE = 'INVITE_MEMBER_LIST_FAILURE';
export const filterEventListFailure = createAction(INVITE_MEMBER_LIST_FAILURE);

export const DELETE_MEMBER_EVENT = 'DELETE_MEMBER_EVENT';
export const deleteMemberEvent = createAction(DELETE_MEMBER_EVENT);

export const DELETE_MEMBER_EVENT_REQUESTED = 'DELETE_MEMBER_EVENT_REQUESTED';
export const deleteMemberEventRequested = createAction(
  DELETE_MEMBER_EVENT_REQUESTED,
);

export const DELETE_MEMBER_EVENT_SUCCESS = 'DELETE_MEMBER_EVENT_SUCCESS';
export const deleteMemberEventSuccess = createAction(DELETE_MEMBER_EVENT_SUCCESS);

export const DELETE_MEMBER_EVENT_FAILURE = 'DELETE_MEMBER_EVENT_FAILURE';
export const deleteMemberEventFailure = createAction(DELETE_MEMBER_EVENT_FAILURE);

export const UPDATE_MEMBER_EVENT = 'UPDATE_MEMBER_EVENT';
export const updateMemberEvent = createAction(UPDATE_MEMBER_EVENT);

export const UPDATE_MEMBER_EVENT_REQUESTED = 'UPDATE_MEMBER_EVENT_REQUESTED';
export const updateMemberEventRequested = createAction(
  UPDATE_MEMBER_EVENT_REQUESTED,
);

export const UPDATE_MEMBER_EVENT_SUCCESS = 'UPDATE_MEMBER_EVENT_SUCCESS';
export const updateMemberEventSuccess = createAction(UPDATE_MEMBER_EVENT_SUCCESS);

export const UPDATE_MEMBER_EVENT_FAILURE = 'UPDATE_MEMBER_EVENT_FAILURE';
export const updateMemberEventFailure = createAction(UPDATE_MEMBER_EVENT_FAILURE);

export const USER_INVITED_EVENT_LIST = 'USER_INVITED_EVENT_LIST';
export const userInvitedEventList = createAction(USER_INVITED_EVENT_LIST);

export const USER_INVITED_EVENT_LIST_REQUESTED = 'USER_INVITED_EVENT_LIST_REQUESTED';
export const userInvitedEventListRequested = createAction(
  USER_INVITED_EVENT_LIST_REQUESTED,
);

export const USER_INVITED_EVENT_LIST_SUCCESS = 'USER_INVITED_EVENT_LIST_SUCCESS';
export const userInvitedEventListSuccess = createAction(
  USER_INVITED_EVENT_LIST_SUCCESS,
);

export const USER_INVITED_EVENT_LIST_FAILURE = 'USER_INVITED_EVENT_LIST_FAILURE';
export const userInvitedEventListFailure = createAction(
  USER_INVITED_EVENT_LIST_FAILURE,
);

export const EVENT_INVITED_USER_LIST = 'EVENT_INVITED_USER_LIST';
export const eventInvitedUserList = createAction(EVENT_INVITED_USER_LIST);

export const EVENT_INVITED_USER_LIST_REQUESTED = 'EVENT_INVITED_USER_LIST_REQUESTED';
export const eventInvitedUserListRequested = createAction(
  EVENT_INVITED_USER_LIST_REQUESTED,
);

export const EVENT_INVITED_USER_LIST_SUCCESS = 'EVENT_INVITED_USER_LIST_SUCCESS';
export const eventInvitedUserListSuccess = createAction(
  EVENT_INVITED_USER_LIST_SUCCESS,
);

export const EVENT_INVITED_USER_LIST_FAILURE = 'EVENT_INVITED_USER_LIST_FAILURE';
export const eventInvitedUserListFailure = createAction(
  EVENT_INVITED_USER_LIST_FAILURE,
);

export const EVENT_INVITED_DELETE_USER = 'EVENT_INVITED_DELETE_USER';
export const eventInvitedDeleteUser = createAction(EVENT_INVITED_DELETE_USER);

export const EVENT_INVITED_DELETE_USER_REQUESTED = 'EVENT_INVITED_DELETE_USER_REQUESTED';
export const eventInvitedDeleteUserRequested = createAction(
  EVENT_INVITED_DELETE_USER_REQUESTED,
);

export const EVENT_INVITED_DELETE_USER_SUCCESS = 'EVENT_INVITED_DELETE_USER_SUCCESS';
export const eventInvitedDeleteUserSuccess = createAction(
  EVENT_INVITED_DELETE_USER_SUCCESS,
);

export const EVENT_INVITED_DELETE_USER_FAILURE = 'EVENT_INVITED_DELETE_USER_FAILURE';
export const eventInvitedDeleteUserFailure = createAction(
  EVENT_INVITED_DELETE_USER_FAILURE,
);
