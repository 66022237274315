import {
  UPDATE_MEMBER_INFO_FAILURE,
  UPDATE_MEMBER_INFO,
  UPDATE_MEMBER_INFO_SUCCESS,
  UPDATE_MEMBER_INFO_REQUESTED,
  MY_PROFILE_SUCCESS,
  FILTER_DATA_LIST_FAILURE,
  SAVE_NOTES_FAILURE,
  NOTES_LIST_FAILURE,
  NOTES_LIST_REQUESTED,
  NOTES_LIST_SUCCESS,
  SAVE_NOTES_SUCCESS,
  SAVE_NOTES_REQUESTED,
  FILTER_DATA_LISTING_FAILURE,
  INDIVISUAL_USER_FAILURE,
  INDIVISUAL_USER_SUCCESS,
  INDIVISUAL_USER_REQUESTED,
  FILTER_DATA_LISTING_SUCCESS,
  FILTER_DATA_LISTING_REQUESTED,
  FILTER_DATA_LIST_SUCCESS,
  FILTER_DATA_LIST_REQUESTED,
  MY_PROFILE_FAILURE,
  MY_PROFILE_REQUESTED,
  ORDER_BY_DATA_LIST_FAILURE,
  ORDER_BY_DATA_LIST_SUCCESS,
  ORDER_BY_DATA_LIST_REQUESTED,
  FILTER_LIST_FAILURE,
  FILTER_LIST_SUCCESS,
  FILTER_LIST_REQUESTED,
  HEADER_LIST_FAILURE,
  HEADER_LIST_SUCCESS,
  HEADER_LIST_REQUESTED,
  MEMBER_LIST_REQUESTED,
  ADD_PERSON_REQUESTED,
  ADD_PERSON_SUCCESS,
  ADD_PERSON_FAILURE,
  MEMBER_LIST_FAILURE,
  MEMBER_LIST_VIEW_FAILURE,
  MEMBER_LIST_VIEW_REQUESTED,
  MEMBER_LIST_VIEW_SUCCESS,
  MEMBER_LIST_SUCCESS,
  TRANSACTION_DETAIL,
  TRANSACTION_DETAIL_FAILURE,
  TRANSACTION_DETAIL_STATE,
  TRANSACTION_DETAIL_SUCCESS,
  TRANSACTION_DETAIL_REQUESTED,
  PROFILE_PAY,
  PROFILE_PAY_FAILURE,
  PROFILE_PAY_FIELDS_STATE,
  PROFILE_PAY_SUCCESS,
  PROFILE_PAY_REQUESTED,
  PROFILE_PAYMENT_FORM,
  PROFILE_PAYMENT_FORM_FAILURE,
  PROFILE_PAYMENT_FORM_FIELDS_STATE,
  PROFILE_PAYMENT_FORM_SUCCESS,
  PROFILE_PAYMENT_FORM_REQUESTED,
  NOTES_UPDATE_FAILURE,
  NOTES_UPDATE_SUCCESS,
  NOTES_UPDATE_REQUESTED,
  ADD_TAGS_FAILURE,
  ADD_TAGS_SUCCESS,
  ADD_TAGS_REQUESTED,
  ADD_TAGS_LIST_FAILURE,
  ADD_TAGS_LIST_SUCCESS,
  TAGS_LIST_REQUESTED,
  UPDATE_MEMBER_INFO_SINGLE_FAILURE,
  UPDATE_MEMBER_INFO_SINGLE_SUCCESS,
  UPDATE_MEMBER_INFO_SINGLE_REQUESTED,
  PAYMENT_LIST_REQUESTED,
  PAYMENT_LIST_SUCCESS,
  PAYMENT_LIST_FAILURE,
  PROFILE_ACTIVITY_LIST_REQUESTED,
  PROFILE_ACTIVITY_LIST_SUCCESS,
  PROFILE_ACTIVITY_LIST_FAILURE,
  PROFILE_EMAIL_LIST_REQUESTED,
  PROFILE_EMAIL_LIST_SUCCESS,
  PROFILE_EMAIL_LIST_FAILURE,
  PROFILE_TRANSACTION_LIST_REQUESTED,
  PROFILE_TRANSACTION_LIST_SUCCESS,
  PROFILE_TRANSACTION_LIST_FAILURE,
  DELETED_MEMBER_REQUESTED,
  DELETED_MEMBER_SUCCESS,
  DELETED_MEMBER_FAILURE,
  UPDATE_USER_META_REQUESTED,
  UPDATE_USER_META_SUCCESS,
  UPDATE_USER_META_FAILED,
  SEARCH_TAGS_REQUESTED,
  SEARCH_TAGS_SUCCESS,
  SEARCH_TAGS_FAILURE,
  UPDATE_PROFILE_BY_FIELD,
  UPDATE_PROFILE_BY_FIELD_SUCCESS,
  CHECK_USER_USAGE_SUCCESS,
  GET_SUBSCRIPTIONS_LIST_SUCCESS,
  GET_SUBSCRIPTIONS_LIST_REQUESTED,
  CHECK_USER_SUBSCRIPTION_SUCCESS,
  UPDATE_USER_SUBSCRIPTION_REQUESTED,
  UPDATE_USER_SUBSCRIPTION_SUCCESS,
  UPDATE_USER_SUBSCRIPTION_FAILED,
  DELETE_USER_SUBSCRIPTION,
  DELETE_USER_SUBSCRIPTION_REQUESTED,
  DELETE_USER_SUBSCRIPTION_SUCCESS,
  DELETE_USER_SUBSCRIPTION_FAILED,
  GET_USER_USAGE_STATS_SUCCESS,
  TAG_REMOVE_FAILURE,
  TAG_REMOVE_REQUESTED,
  TAG_REMOVE_SUCCESS,
} from 'actions/profile-info-action';

const initialState = {
  activityList: [],
  addPerson: '',
  addtag: [],
  cardList: '',
  deletedMember: {},
  emailList: [],
  filterList: '',
  filterListSuccess: '',
  filterListing: '',
  headerList: '',
  indivisualList: [],
  memberList: [],
  memberSingle: '',
  memberView: '',
  notesList: '',
  notesUpdate: '',
  paymentList: [],
  profilePaymentSuccess: {},
  searchTagsList: [],
  tagList: [],
  totalMembers: 0,
  transactionList: [],
  updateMember: '',
  updateNotesValue: '',
  viewPerson: '',
};

const resetMember = {
  memberViewError: null,
  memberViewStatus: null,
};

const resetMemberView = (state) => ({
  ...state,
  ...resetMember,
});

const ProfileInfo = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_MEMBER_INFO:
      return {
        ...state,
        loginAuthorizationError: null,
        loginAuthorizationStatus: null,
      };

    case TRANSACTION_DETAIL:
      return {
        ...state,
        gettingTransactionDetails: null,
        transaction: null,
      };

    case TRANSACTION_DETAIL_FAILURE:
      return {
        ...state,
        gettingTransactionDetails: false,
        transaction: null,
      };

    case TRANSACTION_DETAIL_STATE:
      return {
        ...state,
        gettingTransactionDetails: true,
        transaction: null,
      };

    case TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        gettingTransactionDetails: false,
        transaction: {
          transactionId: payload.transactionId,
          description: payload.description,
          amount: payload.amount,
          currency: payload.currency,
          symbol: payload.symbol,
          recipient_name: payload.recipient_name,
        },
      };

    case TRANSACTION_DETAIL_REQUESTED:
      return {
        ...state,
        gettingTransactionDetails: true,
        transaction: null,
      };

    case PROFILE_PAY:
    case PROFILE_PAY_FIELDS_STATE:
      return {
        ...state,
        profilePaymentError: null,
      };
    case PROFILE_PAY_FAILURE:
      return {
        ...state,
        profilePaymentError: true,
      };

    case PROFILE_PAY_SUCCESS:
      return {
        ...state,
        profilePaymentError: null,
        profilePaymentStatus: null,
        profilePaymentSuccess: payload,
      };

    case PROFILE_PAY_REQUESTED:
      return {
        ...state,
      };
    case PROFILE_PAYMENT_FORM_FAILURE:
    case PROFILE_PAYMENT_FORM:
      return {
        ...state,
        loginAuthorizationError: null,
        loginAuthorizationStatus: null,
      };

    case PROFILE_PAYMENT_FORM_FIELDS_STATE:
      return {
        ...state,
        loginAuthorizationError: null,
        loginAuthorizationStatus: null,
      };
    case PROFILE_PAYMENT_FORM_SUCCESS:
      return {
        ...state,
        profilePaymentError: null,
        profilePaymentStatus: null,
        profilePaymentSuccess: payload,
      };
    case PROFILE_PAYMENT_FORM_REQUESTED:
      return {
        ...state,
        profilePaymentError: null,
        profilePaymentStatus: null,
      };
    case MEMBER_LIST_SUCCESS:
      return {
        ...state,
        memberList:
          payload.offset === 0 ? payload.data : state.memberList.concat(payload.data),
        memberListError: null,
        profilePaymentStatus: null,
        totalMembers: payload.count,
      };
    case MEMBER_LIST_FAILURE:
      return {
        ...state,
        listError: null,
        listStatus: null,
      };
    case MEMBER_LIST_REQUESTED:
      return {
        ...state,
        profilePaymentError: null,
        profilePaymentStatus: null,
      };
    case MEMBER_LIST_VIEW_SUCCESS:
      return {
        ...state,
        memberView: payload,
        ...resetMember,
      };
    case ADD_PERSON_SUCCESS:
      return {
        ...state,
        addPerson: payload,
        ...resetMember,
      };

    case MEMBER_LIST_VIEW_FAILURE:
    case MEMBER_LIST_VIEW_REQUESTED:
    case ADD_PERSON_FAILURE:
    case ADD_PERSON_REQUESTED:
    case MY_PROFILE_FAILURE:
    case MY_PROFILE_REQUESTED:
    case HEADER_LIST_FAILURE:
    case HEADER_LIST_REQUESTED:
    case FILTER_LIST_FAILURE:
    case FILTER_LIST_REQUESTED:
    case INDIVISUAL_USER_FAILURE:
    case INDIVISUAL_USER_REQUESTED:
    case ORDER_BY_DATA_LIST_FAILURE:
    case ORDER_BY_DATA_LIST_REQUESTED:
    case FILTER_DATA_LIST_FAILURE:
    case FILTER_DATA_LIST_REQUESTED:
    case FILTER_DATA_LISTING_FAILURE:
    case FILTER_DATA_LISTING_REQUESTED:
    case UPDATE_MEMBER_INFO_FAILURE:
    case UPDATE_MEMBER_INFO_REQUESTED:
    case UPDATE_MEMBER_INFO_SINGLE_FAILURE:
    case UPDATE_MEMBER_INFO_SINGLE_REQUESTED:
    case SAVE_NOTES_FAILURE:
    case SAVE_NOTES_REQUESTED:
    case NOTES_LIST_FAILURE:
    case NOTES_LIST_REQUESTED:
    case NOTES_UPDATE_FAILURE:
    case NOTES_UPDATE_REQUESTED:
    case ADD_TAGS_FAILURE:
    case ADD_TAGS_REQUESTED:
    case ADD_TAGS_LIST_FAILURE:
    case TAGS_LIST_REQUESTED:
    case DELETED_MEMBER_FAILURE:
    case DELETED_MEMBER_REQUESTED:
    case SEARCH_TAGS_REQUESTED:
    case SEARCH_TAGS_FAILURE:
      return resetMemberView(state);

    case MY_PROFILE_SUCCESS:
      return {
        ...state,
        ...resetMember,
        viewPerson: payload,
      };
    case HEADER_LIST_SUCCESS:
      return {
        ...state,
        headerList: payload,
        ...resetMember,
      };

    case FILTER_LIST_SUCCESS:
      return {
        ...state,
        memberList: payload.data,
        ...resetMember,
        totalMembers: payload.count,
      };
    case INDIVISUAL_USER_SUCCESS:
      return {
        ...state,
        indivisualList: payload.data,
        ...resetMember,
      };

    case ORDER_BY_DATA_LIST_SUCCESS:
      return {
        ...state,
        memberList: payload.data,
        ...resetMember,
      };

    case FILTER_DATA_LIST_SUCCESS:
      return {
        ...state,
        filterListSuccess: payload,
        ...resetMember,
      };

    case FILTER_DATA_LISTING_SUCCESS:
      return {
        ...state,
        filterListing: payload,
        ...resetMember,
      };

    case UPDATE_MEMBER_INFO_SUCCESS:
      return {
        ...state,
        ...resetMember,
        updateMember: payload,
      };
    case UPDATE_MEMBER_INFO_SINGLE_SUCCESS:
      return {
        ...state,
        memberSingle: payload,
        ...resetMember,
      };
    case SAVE_NOTES_SUCCESS:
      return {
        ...state,
        ...resetMember,
        updateNotesValue: payload,
      };
    case NOTES_LIST_SUCCESS:
      return {
        ...state,
        ...resetMember,
        notesList: payload,
      };
    case NOTES_UPDATE_SUCCESS:
      return {
        ...state,
        ...resetMember,
        notesUpdate: payload,
      };
    case ADD_TAGS_SUCCESS:
      return {
        ...state,
        addtag: payload,
        ...resetMember,
      };
    case ADD_TAGS_LIST_SUCCESS:
      return {
        ...state,
        ...resetMember,
        tagList: payload.data,
      };
    case PAYMENT_LIST_REQUESTED:
      return {
        ...state,
        paymentList: [],
      };
    case PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        paymentList: payload,
      };
    case PAYMENT_LIST_FAILURE:
      return {
        ...state,
        paymentList: [],
      };
    case PROFILE_ACTIVITY_LIST_REQUESTED:
      return {
        ...state,
        activityList: [],
      };
    case PROFILE_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        activityList: payload,
      };
    case PROFILE_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        activityList: [],
      };
    case PROFILE_EMAIL_LIST_REQUESTED:
      return {
        ...state,
        emailList: [],
      };
    case PROFILE_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        emailList: payload,
      };
    case PROFILE_EMAIL_LIST_FAILURE:
      return {
        ...state,
        emailList: [],
      };
    case PROFILE_TRANSACTION_LIST_REQUESTED:
      return {
        ...state,
        transactionList: [],
      };
    case PROFILE_TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        transactionList: payload,
      };
    case PROFILE_TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        transactionList: [],
      };
    case DELETED_MEMBER_SUCCESS:
      return {
        ...state,
        deletedMember: payload.data,
        ...resetMember,
      };
    case UPDATE_USER_META_REQUESTED:
      return {
        ...state,
        savingUserMeta: true,
      };

    case UPDATE_USER_META_SUCCESS:
      return {
        ...state,
        savingUserMeta: false,
      };

    case UPDATE_USER_META_FAILED:
      return {
        ...state,
        savingUserMeta: false,
      };
    case SEARCH_TAGS_SUCCESS:
      return {
        ...state,
        ...resetMember,
        searchTagsList: payload,
      };
    case UPDATE_PROFILE_BY_FIELD:
      return {
        ...state,
        updateEmailSuccess: false,
        updateEmailRequested: true,
      };

    case UPDATE_PROFILE_BY_FIELD_SUCCESS:
      return {
        ...state,
        updatedMember: payload,
        updateEmailSuccess: true,
      };

    case CHECK_USER_USAGE_SUCCESS:
      return {
        ...state,
        usage: payload.data,
      };

    case GET_USER_USAGE_STATS_SUCCESS:
      return {
        ...state,
        usageStats: payload.data,
      };

    case GET_SUBSCRIPTIONS_LIST_REQUESTED:
      return {
        ...state,
        subscriptions: null,
      };

    case GET_SUBSCRIPTIONS_LIST_SUCCESS:
      return {
        ...state,
        subscriptions: payload.data,
      };

    case CHECK_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        userSubscription: payload.data,
      };

    case UPDATE_USER_SUBSCRIPTION_REQUESTED:
      return {
        ...state,
        isProcessing: true,
        userSubscription: null,
      };

    case UPDATE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        userSubscription: payload.data,
      };

    case UPDATE_USER_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isProcessing: false,
        userSubscription: {},
      };

    case DELETE_USER_SUBSCRIPTION:
      return {
        ...state,
        isProcessing: true,
      };

    case DELETE_USER_SUBSCRIPTION_REQUESTED:
      return {
        ...state,
        isProcessing: true,
      };

    case DELETE_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
      };

    case DELETE_USER_SUBSCRIPTION_FAILED:
      return {
        ...state,
        isProcessing: false,
      };

    case TAG_REMOVE_FAILURE:
      return {
        ...state,
        errors: {},
        isLoading: false,
      };

    case TAG_REMOVE_SUCCESS:
      return {
        ...state,
        errors: {},
        isLoading: false,
      };

    case TAG_REMOVE_REQUESTED:
      return {
        ...state,
        errors: {},
        isLoading: false,
      };

    default:
      return state;
  }
};

export default ProfileInfo;
