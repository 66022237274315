import React from 'react';
import './Profile.scss';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { func } from 'prop-types';
// import CryptoJS from 'crypto-js';
import { memberListView } from 'actions/profile-info-action';
import api from 'pages/Dashboard/SettingStepProfile/Views/Services';
import Info from 'pages/Dashboard/ProfileInfo';
import ProfileHeader from './ProfileHeader';
import ProfilePayment from '../../Dashboard/ProfilePayment';

class MemberProfile extends React.Component {
  static propTypes = {
    memberListView: func.isRequired,
  };

  constructor(props) {
    super(props);
    const { params } = this.props.match;

    if (params && params.key) {
      const { key } = params;
      // const ciphertext = decodeURIComponent(key);
      // // Decrypt
      // const bytes = CryptoJS.AES.decrypt(ciphertext, 'secret key 123');
      // const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      this.state = {
        memberId: key,
      };
    }
  }

  state = {
    memberId: '',
  };

  componentDidMount() {
    const { memberId } = this.state;

    if (memberId) {
      this.props.memberListView(memberId);
    }
  }

  onChangeFile = (e) => {
    this.fileUpload(e.target.files[0]);
  }

  // On file upload (click the upload button)
  fileUpload = (file) => {
    const { memberId } = this.state;
    const formData = new FormData();

    formData.append('profileImg', file);

    api.memberImageUpload(formData, memberId).then(() => {
      this.props.memberListView(memberId);
    });
  };

  render() {
    const { memberId } = this.state;
    const { stateData, member } = this.props;

    // console.log(')_)_)_', memberId);
    // console.log('member.data', member.data);

    return (
      <div className="container">
        <div className="container-inner">
          <ProfileHeader
            memberId={memberId}
            userData={member.data}
            userDataInfo={stateData}
            onFileChange={this.onChangeFile}
          />
          <div className="tab_bar">
            <Tabs>
              <TabList>
                <Tab>Info</Tab>
                <Tab>Payments</Tab>
              </TabList>
              <TabPanel>
                <Info key={member} memberId={memberId} userData={member.data} />
              </TabPanel>
              <TabPanel>
                <ProfilePayment userData={member.data} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  member: state.userProfile.memberView,
  stateData: state,
});

export default connect(mapStateToProps, {
  memberListView,
})(MemberProfile);
