/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import './CommitteeStable.scss';
import { toast } from 'react-toastify';
import Pagination from 'react-js-pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import leftarrow from 'assets/images/leftarrow.png';
import plusicon from 'assets/images/add.png';
import { TopHeader, UpgradeSubscription } from 'components';
import { fetchCommittees, deleteCommittee } from 'actions/committee';
import { checkUserUsage, getUserUsageStats } from 'actions/profile-info-action';
import User from 'utils/user';
import socketIOClient from 'socket.io-client';

const Loader = require('react-loader');

const socket = socketIOClient(process.env.REACT_APP_WS_URL);

class CommitteesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      limit: 10,
      showAlert: false,
      focusUpgradeNotice: false,
    };

    this.userUtils = new User();
    const currentUserId = this.userUtils.getLoggedInData();

    socket.on(`UsageChangedForUser_${currentUserId}`, () => {
      this.props.checkUserUsage();
      this.props.getUserUsageStats();
    });

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.checkUserUsage();
    this.props.getUserUsageStats();
    this.props.fetchCommittees({
      limit: this.state.limit,
      pageNo: this.state.currentPage,
    });
  }

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });

    this.props.fetchCommittees({
      limit: this.state.limit,
      pageNo: pageNumber,
    });
  };

  capitalize = (string) => string;

  deleteCommittee = (committeeId) => {
    this.setState({
      committeeId,
      showAlert: true,
    });
  };

  redirectToAddInterface = () => {
    const canAdd = this.props.usage && this.props.usage.committee;

    if (!canAdd) {
      this.setState({ focusUpgradeNotice: true });
      setTimeout(() => this.setState({ focusUpgradeNotice: false }), 500);
      toast.error('You are not authorized to add new committee. Please upgrade your account first');
    } else {
      this.props.push('/add-committee');
    }
  }

  onCancel = () => {
    this.setState({
      showAlert: false,
    });
  };

  onConfirmDelete = () => {
    // eslint-disable-next-line prefer-destructuring
    let currentPage = this.state.currentPage;

    if (currentPage > 1 && this.props.committeesList.length === 1) {
      currentPage -= 1;
    }
    if (currentPage === 1 && this.props.committeesList.length === 1) {
      currentPage = 1;
    }

    this.setState({
      currentPage,
      showAlert: false,
    });

    this.props.deleteCommittee({
      committeeId: this.state.committeeId,
      limit: this.state.limit,
      pageNo: currentPage,
    });
  };

  editCommittee = (committeeId) => {
    this.props.history.push(`/edit-committee/${committeeId}`);
  };

  getStatsCount() {
    if (!this.props.usageStats || this.props.usageStats.committee.total === -1) {
      return null;
    }

    return <small>({this.props.usageStats.committee.used}/{this.props.usageStats.committee.total})</small>;
  }

  render() {
    const { committeesList, totalCommittees, isLoading, apiErrors } = this.props;

    const { currentPage, limit, showAlert } = this.state;

    const canAdd = this.props.usage && this.props.usage.committee;

    {
      apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: 1500,
        });
    }

    return (
      <div className="container">
          <TopHeader />
        <div className="csv_row heading-membership">
          <div className="btn_col">
            <Link className="backarrow" to="/administration-dashboard">
              <img alt="leftarrow" src={leftarrow} />
            </Link>
          </div>
          <h2>Committees {this.getStatsCount()}</h2>
          <div className="btn_col last">
            <a className="btn btn-primary" onClick={this.redirectToAddInterface}>
              <img alt="plusicon" src={plusicon} /> Add New
            </a>
          </div>
        </div>
        { !canAdd ? <UpgradeSubscription focusIt={this.state.focusUpgradeNotice} justNotice={true} usage={this.props.usage} /> : null }

        {isLoading && <Loader loaded={!isLoading} />}

        <div className="scrolling">
          <table className="table table-responsive table-committees">
            <thead>
              <tr>
                <th>NAME</th>
                <th>TYPE</th>
                <th>MEMBERS</th>
                <th>ACTIVE</th>
                <th>ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {committeesList.length
                ? committeesList.map((eachCommitte) => (
                      <tr key={eachCommitte.id}>
                        <td className="text-captlise-class">
                          {this.capitalize(eachCommitte.committeeName)}
                        </td>
                        <td className="text-captlise-class">
                          {this.capitalize(eachCommitte.committeeType)}
                        </td>
                        <td>{eachCommitte.CommitteeMembers.length}</td>
                        <td>{eachCommitte.isActive ? 'Yes' : 'No'}</td>
                        <td>
                          <span
                            onClick={this.editCommittee.bind(this, eachCommitte.id)}
                          >
                            <i className="fa fa-pencil"></i>
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span
                            onClick={(e) => this.deleteCommittee(eachCommitte.id, e)}
                          >
                            <i className="fa fa-trash"></i>
                          </span>
                        </td>
                      </tr>
                ))
                : null}
              {!committeesList.length && (
                <tr>
                  <td colSpan="5">No committee added</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {committeesList.length ? (
          <div>
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={limit}
              pageRangeDisplayed={5}
              totalItemsCount={totalCommittees}
              onChange={this.handlePageChange}
            />
          </div>
        ) : null}

        <SweetAlert
          danger
          focusCancelBtn
          showCancel
          confirmBtnBsStyle="danger"
          confirmBtnText="Confirm"
          show={showAlert}
          title="Are you sure you want to delete this committee?"
          onCancel={this.onCancel}
          onConfirm={this.onConfirmDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  committee: { committeesList, totalCommittees, errors, isLoading },
  userProfile: { usage, usageStats },
}) => ({
  apiErrors: errors,
  committeesList,
  isLoading,
  totalCommittees,
  usage,
  usageStats,
});

export default connect(mapStateToProps, {
  deleteCommittee,
  fetchCommittees,
  checkUserUsage,
  getUserUsageStats,
  push,
})(CommitteesTable);
