/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  UPDATE_MEMBER_INFO,
  TRANSACTION_DETAIL,
  getTransactionDetailRequested,
  getTransactionDetailFailure,
  getTransactionDetailSuccess,
  PROFILE_PAY,
  profilePayFailure,
  // profilePayFieldsState,
  profilePaySuccess,
  profilePayRequested,
  PROFILE_PAYMENT_FORM,
  profilePaymentFormRequested,
  profilePaymentFormSuccess,
  profilePaymentFormFailure,
  /* PROFILE_CARD_LIST,
  profileCardListSuccess,
  profileCardListRequested, */
  CHECK_USER_USAGE,
  checkUserUsageRequested,
  checkUserUsageSuccess,
  GET_SUBSCRIPTIONS_LIST,
  getSubscriptionsListRequested,
  getSubscriptionsListSuccess,
  CHECK_USER_SUBSCRIPTION,
  checkUserSubscriptionRequested,
  checkUserSubscriptionSuccess,
  UPDATE_USER_SUBSCRIPTION,
  updateUserSubscriptionRequested,
  // updateUserSubscriptionSuccess,
  updateUserSubscriptionFailed,
  DELETE_USER_SUBSCRIPTION,
  deleteUserSubscriptionRequested,
  deleteUserSubscriptionFailed,
  GET_USER_USAGE_STATS,
  getUserUsageStatsRequested,
  getUserUsageStatsSuccess,
  MEMBER_LIST,
  memberList,
  memberListSuccess,
  memberListRequested,
  MEMBER_LIST_VIEW,
  memberListViewRequested,
  memberListView,
  ADD_PERSON,
  SAVE_NOTES,
  MY_PROFILE,
  HEADER_LIST,
  memberListViewSuccess,
  headerListSuccess,
  headerListRequested,
  myProfileRequested,
  addPersonRequested,
  myProfileSuccess,
  addPersonSuccess,
  FILTER_LIST,
  filterListRequested,
  ORDER_BY_DATA_LIST,
  filterListSuccess,
  orderByDataListSuccess,
  orderByDataListRequested,
  FILTER_DATA_LIST,
  filterDataListRequested,
  filterDataListSuccess,
  filterDataListingRequested,
  filterDataListingSuccess,
  saveNotesRequested,
  FILTER_DATA_LISTING,
  updateMemberInfoRequested,
  NOTES_LIST,
  notesListSuccess,
  notesListRequested,
  notesList,
  saveNotes,
  NOTES_UPDATE,
  notesUpdateRequested,
  INDIVISUAL_USER,
  indivisualUserSuccess,
  indivisualUserRequested,
  ADD_TAGS,
  addTagsRequested,
  TAG_REMOVE,
  removeTagsRequested,
  tagsList,
  ADD_TAGS_LIST,
  tagsListSuccess,
  tagsListRequested,
  UPDATE_MEMBER_INFO_SINGLE,
  updateMemberInfoSingleRequested,
  PAYMENT_LIST,
  paymentListRequested,
  paymentListSuccess,
  paymentListFailure,
  PROFILE_ACTIVITY_LIST,
  activityListFailure,
  activityListSuccess,
  activityListRequested,
  PROFILE_EMAIL_LIST,
  emailListFailure,
  emailListSuccess,
  emailListRequested,
  PROFILE_TRANSACTION_LIST,
  transactionListFailure,
  transactionListSuccess,
  transactionListRequested,
  DELETED_MEMBER,
  deletedMemberRequested,
  deletedMemberFailure,
  SEARCH_TAGS,
  searchTagsRequested,
  searchTagsSuccess,
  UPDATE_PROFILE_BY_FIELD,
  /* updateProfileByField,
  updateProfileByFieldSuccess, */
  UPDATE_USER_META,
  updateUserMetaRequested,
  updateUserMetaSuccess,
  updateUserMetaFailed,
  deletedMemberSuccess,
} from 'actions/profile-info-action';

import { getUserDetails } from 'actions/settings-step-action';

import HttpHelper, { handleResp } from 'utils/http-helper';
import User from 'utils/user';

const { authId } = new User();
const { deleteRequest, getRequest, postRequest, putRequest, prepareReq } = new HttpHelper();

const actions = (sAction, fAction = null, bAction = null) => ({ bAction, fAction, sAction });

export function* getTransactionDetail({ payload }) {
  yield put(getTransactionDetailRequested());
  const queryParams = new URLSearchParams(payload).toString();
  const response = yield call(getRequest, prepareReq(`payment/success?${queryParams}`));

  if (yield* handleResp(response, actions(null, getTransactionDetailFailure))) {
    yield put(
      getTransactionDetailSuccess(response.data.response),
    );
  }
}

export function* profilePay({ payload }) {
  yield put(profilePayRequested());
  const response = yield call(postRequest, prepareReq('payment/processPayment', payload));

  if (yield* handleResp(response, actions(null, profilePayFailure))) {
    yield put(
      profilePaySuccess(response.data.response),
    );
  } else {
    profilePayFailure(response.data);
  }
}

export function* profilePaymentForm({ payload }) {
  const userId = authId();

  yield put(profilePaymentFormRequested());
  const response = yield call(getRequest, prepareReq(`account/add-card/${userId}`, payload));

  if (yield* handleResp(response, actions(null, profilePaymentFormFailure))) {
    yield put(
      profilePaymentFormSuccess({
        data: true,
      }),
    );
  }
}

/* export function* profileCardList(payload = { }) {
  const userId = authId();

  yield put(profileCardListRequested());
  const response = yield call(getRequest, prepareReq(`account/list-cards/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(profileCardListSuccess({ data: response.data.response }));
  }
} */

// payment list start

export function* paymentList() {
  const userId = authId();

  yield put(paymentListRequested());
  const response = yield call(getRequest, prepareReq(`account/payments/${userId}`));

  if (yield* handleResp(response, actions(null, paymentListFailure))) {
    yield put(paymentListSuccess({ data: response.data.response }));
  }
}

// payment list end
export function* memberListValue({ payload }) {
  const limit = 20;
  const offset = payload.offset * limit;
  const userId = authId();

  yield put(memberListRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/members/${payload.offset}/${limit}/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(memberListSuccess({
      count: response.data.totalCount,
      data: response.data.response,
      offset: payload.offset,
    }));
  }
}

export function* memberListViewValue({ payload }) {
  yield put(memberListViewRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/member/${payload}`));

  if (yield* handleResp(response, {})) {
    // console.log('Yeilded??');
    yield put(memberListViewSuccess({ data: response.data.response }));
  }
}

export function* myProfile({ payload }) {
  yield put(myProfileRequested());
  const response = yield call(getRequest, prepareReq(`account/my-profile/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(myProfileSuccess({ data: response.data.response }));
  }
}

export function* headerList({ payload }) {
  let userId = authId();

  /* If user is not logged in get user Id from organisation memebers' addedBy column */
  if (!userId && payload) {
    userId = payload;
  }

  yield put(headerListRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/columns/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(headerListSuccess({ data: response.data.response }));
  }
}

export function* filterList({ payload }) {
  const userId = authId();

  yield put(filterListRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/member/${payload.key}/${payload.type}/${payload.value}/${userId}`));

  if (yield* handleResp(response, {})) {
    // eslint-disable-next-line no-return-assign
    response.data.response.map((value) => (value.filterList = payload.filterList));
    const successData = {
      count: response.data.totalCount,
      data: response.data.response,
    };

    yield put(filterListSuccess(successData));
  }
}

export function* indivisualUser({ payload }) {
  const userId = authId();

  yield put(indivisualUserRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/member/${payload.key}/${payload.type}/${payload.value}/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(indivisualUserSuccess({ data: response.data.response }));
  }
}

export function* orderByDataList({ payload }) {
  const userId = authId();

  yield put(orderByDataListRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/member/${payload.key}/${payload.order}/${payload.value}/${payload.type}/${payload.filterKey}/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(orderByDataListSuccess({ data: response.data.response }));
  }
}

export function* filterDataList({ payload }) {
  const userId = authId();

  yield put(filterDataListRequested());
  const response = yield call(postRequest, prepareReq(`filter/${userId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(filterDataListSuccess({ data: true }));
  }
}

export function* filterDataListing() {
  const userId = authId();

  yield put(filterDataListingRequested());
  const response = yield call(getRequest, prepareReq(`filter/list/${userId}`));

  if (yield* handleResp(response, {})) {
    yield put(filterDataListingSuccess({ data: response.data.response }));
  }
}

export function* addPerson({ payload }) {
  payload.options.addedBy = authId();
  yield put(addPersonRequested());
  const response = yield call(postRequest, prepareReq('myorganisation/member', payload.options));

  if (yield* handleResp(response, {})) {
    yield put(push(`/member/${response.data.response.id}`));
    yield put(addPersonSuccess({ data: response.data.response }));
    if (payload.options.notes) {
      const options = {
        memberId: response.data.response.id,
        note: payload.options.notes,
        status: true,
      };

      yield put(saveNotes(options));
    }
  }
}

export function* updateMemberInfo({ payload }) {
  yield put(updateMemberInfoRequested());
  const response = yield call(putRequest,
    prepareReq(`myorganisation/member/update/${payload.options.memberId}`, payload.options));

  if (yield* handleResp(response, {})) {
    yield put(memberListView(payload.options.memberId));
  }
}

export function* updateMemberInfoSingle({ payload }) {
  yield put(updateMemberInfoSingleRequested());
  const response = yield call(putRequest,
    prepareReq(`myorganisation/member/update/value/${payload.options.memberId}`, payload.options));

  if (yield* handleResp(response, {})) {
    yield put(memberListView(payload.options.memberId));
  }
}

export function* saveNotesValue({ payload }) {
  yield put(saveNotesRequested());
  const response = yield call(postRequest, prepareReq(`notes/${payload.memberId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(notesList(payload.memberId));
  }
}

export function* notesListData({ payload }) {
  yield put(notesListRequested());
  const response = yield call(getRequest, prepareReq(`notes/list/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(notesListSuccess({ data: response.data.response }));
  }
}

export function* notesUpdate({ payload }) {
  yield put(notesUpdateRequested());
  const response = yield call(putRequest, prepareReq(`notes/update/${payload.id}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(notesList(payload.memberId));
  }
}

export function* addTags({ payload }) {
  yield put(addTagsRequested());
  const response = yield call(postRequest, prepareReq(`myorganisation/tags/${payload.memberId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(tagsList(payload.memberId));
  }
}

export function* deleteTags({ payload }) {
  yield put(removeTagsRequested());
  const response = yield call(deleteRequest, prepareReq(`myorganisation/tags/${payload.id}`));

  if (yield* handleResp(response, {})) {
    yield put(tagsList(payload.userId));
  }
}

export function* tagsListData({ payload }) {
  yield put(tagsListRequested());
  const response = yield call(getRequest, prepareReq(`myorganisation/tags/list/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(tagsListSuccess({ data: response.data.response }));
  }
}

export function* activityList() {
  yield put(activityListRequested());
  const userId = localStorage.getItem('selectedMember');
  const response = yield call(getRequest, prepareReq(`campaign/getactivity/${userId}`));

  yield* handleResp(response, actions(activityListSuccess, activityListFailure));
}

export function* emailList() {
  yield put(emailListRequested());
  const userId = localStorage.getItem('selectedMember');
  const response = yield call(getRequest, prepareReq(`campaign/getemailactivity/${userId}`));

  yield* handleResp(response, actions(emailListSuccess, emailListFailure));
}

export function* transactionList({ payload }) {
  yield put(transactionListRequested());
  const response = yield call(getRequest, prepareReq(`payment/transactions/${payload}`));

  yield* handleResp(response, actions(transactionListSuccess, transactionListFailure));
}

export function* deletedMember({ payload }) {
  yield put(deletedMemberRequested());
  const response = yield call(deleteRequest, prepareReq(`member/${payload.memberId}`));

  if (yield* handleResp(response, actions(deletedMemberSuccess, deletedMemberFailure))) {
    payload.offset = 0;
    yield put(memberList(payload));
  }
}

export function* searchTags({ payload }) {
  const url = `myorganisation/searchTags/${payload}`;
  const response = yield call(getRequest, prepareReq(url));

  yield* handleResp(response, actions(searchTagsSuccess, null, searchTagsRequested));
}

export function* updateProfileByFieldSaga({ payload }) {
  const url = 'myorganisation/member/update-by-field';
  const response = yield call(postRequest, prepareReq(url, payload));
  const { isAuthenticated } = payload.options;

  if (yield* handleResp(response, {})) {
    if (isAuthenticated && response.data.response.isPaymentAvailable) {
      yield put(push(`/member/${response.data.response.id}/payments`));
    } else if (isAuthenticated) {
      yield put(push(`/member/${response.data.response.id}`));
    } else {
      yield put(push(`/update-profile/${response.data.response.id}`));
    }
  }
}

function* checkUserUsage(data = null) {
  yield put(checkUserUsageRequested());

  const userId = data.payload ? data.payload : authId();
  const response = yield call(getRequest, prepareReq(`account/${userId}/usage`));

  if (yield* handleResp(response, {})) {
    yield put(checkUserUsageSuccess({ data: response.data.response }));
  }
}

function* checkUserSubscription(data = null) {
  yield put(checkUserSubscriptionRequested());

  const userId = data.payload ? data.payload : authId();
  const response = yield call(getRequest, prepareReq(`account/${userId}/subscription`));

  if (yield* handleResp(response, {})) {
    yield put(checkUserSubscriptionSuccess({ data: response.data.response }));
  }
}

function* getSubscriptionsList() {
  yield put(getSubscriptionsListRequested());

  const response = yield call(getRequest, prepareReq('subscriptions'));

  if (yield* handleResp(response, {})) {
    yield put(getSubscriptionsListSuccess({ data: response.data.response }));
  }
}

function* updateUserSubscription({ payload }) {
  yield put(updateUserSubscriptionRequested());
  const response = yield call(putRequest, prepareReq(`user/subscriptions/${payload.subscriptionId}`, payload));

  if (yield* handleResp(response, {})) {
    yield put(getUserDetails());
  } else {
    yield put(updateUserSubscriptionFailed());
  }
}

function* deleteUserSubscription({ payload }) {
  yield put(deleteUserSubscriptionRequested());
  const response = yield call(deleteRequest, prepareReq(`user/subscription/${payload}`));

  if (yield* handleResp(response, {})) {
    yield put(getUserDetails());
  } else {
    yield put(deleteUserSubscriptionFailed());
  }
}

function* getUserUsageStats(data = null) {
  yield put(getUserUsageStatsRequested());

  const userId = data.payload ? data.payload : authId();
  const response = yield call(getRequest, prepareReq(`account/${userId}/subscription-usage`));

  if (yield* handleResp(response, {})) {
    yield put(getUserUsageStatsSuccess({ data: response.data.response }));
  }
}

function* updateUserMeta({ payload }) {
  yield put(updateUserMetaRequested());

  const response = yield call(postRequest, prepareReq('account/metadata/update', payload));

  if (yield* handleResp(response, {})) {
    yield put(updateUserMetaSuccess({ data: response.data.response }));
  } else {
    yield put(updateUserMetaFailed({ data: {} }));
  }
}

function* UserProfile() {
  yield [
    takeLatest(TRANSACTION_DETAIL, getTransactionDetail),
    takeLatest(PROFILE_PAY, profilePay),
    takeLatest(PROFILE_PAYMENT_FORM, profilePaymentForm),
    // takeLatest(PROFILE_CARD_LIST, profileCardList),
    takeLatest(MEMBER_LIST, memberListValue),
    takeLatest(MEMBER_LIST_VIEW, memberListViewValue),
    takeLatest(ADD_PERSON, addPerson),
    takeLatest(MY_PROFILE, myProfile),
    takeLatest(HEADER_LIST, headerList),
    takeLatest(FILTER_LIST, filterList),
    takeLatest(ORDER_BY_DATA_LIST, orderByDataList),
    takeLatest(FILTER_DATA_LIST, filterDataList),
    takeLatest(FILTER_DATA_LISTING, filterDataListing),
    takeLatest(UPDATE_MEMBER_INFO, updateMemberInfo),
    takeLatest(UPDATE_MEMBER_INFO_SINGLE, updateMemberInfoSingle),
    takeLatest(NOTES_LIST, notesListData),
    takeLatest(NOTES_UPDATE, notesUpdate),
    takeLatest(SAVE_NOTES, saveNotesValue),
    takeLatest(INDIVISUAL_USER, indivisualUser),
    takeLatest(ADD_TAGS, addTags),
    takeLatest(ADD_TAGS_LIST, tagsListData),
    takeLatest(PAYMENT_LIST, paymentList),
    takeLatest(PROFILE_ACTIVITY_LIST, activityList),
    takeLatest(PROFILE_EMAIL_LIST, emailList),
    takeLatest(PROFILE_TRANSACTION_LIST, transactionList),
    takeLatest(DELETED_MEMBER, deletedMember),
    takeLatest(SEARCH_TAGS, searchTags),
    takeLatest(TAG_REMOVE, deleteTags),
    takeLatest(UPDATE_PROFILE_BY_FIELD, updateProfileByFieldSaga),
    takeLatest(CHECK_USER_USAGE, checkUserUsage),
    takeLatest(UPDATE_USER_META, updateUserMeta),
    takeLatest(GET_SUBSCRIPTIONS_LIST, getSubscriptionsList),
    takeLatest(CHECK_USER_SUBSCRIPTION, checkUserSubscription),
    takeLatest(UPDATE_USER_SUBSCRIPTION, updateUserSubscription),
    takeLatest(DELETE_USER_SUBSCRIPTION, deleteUserSubscription),
    takeLatest(GET_USER_USAGE_STATS, getUserUsageStats),
  ];
}

export default UserProfile;
