/* eslint-disable react/no-unused-state */
import React from 'react';
import './EventView.scss';
import { connect } from 'react-redux';
import { TopHeader } from 'components';
import leftarrow from 'assets/images/leftarrow.png';
import plusicon from 'assets/images/add.png';
import Moment from 'react-moment';

import { eventInvitedUserList } from 'actions/event-action';

class ImportCSV extends React.Component {
  state = {
    errors: {},
    csvFile: '',
    eventData: '',
  };

  componentDidMount() {
    if (this.props.location.state) {
      const { eventData } = this.props.location.state;

      this.props.eventInvitedUserList({ eventId: eventData.id });

      this.setState({ eventData });
    }
  }

  getFormattedAddressfromProps = () => {
    if (!this.props.location) {
      return '';
    }

    const { address1, address2, city, state, zipCode, country } = this.props.location.state.eventData;
    const addressLine = [
      address1,
      address2,
      [
        [
          city,
          state,
        ].join(', '),
        zipCode,
      ].join(' '),
      country,
    ];

    const addressComponent = addressLine.map((addr) => <p style={{ marginBottom: 0 }}>{addr}</p>);

    return addressComponent;
  }

  renderInvitationData = () => {
    console.log(this.props.invitedUsers);

    if (!this.props.invitedUsers) {
      return null;
    }

    return this.props.invitedUsers.map((attendee) => (
      <tr>
        <td>{[attendee.firstName, attendee.lastName].join(' ')}</td>
        <td>{attendee.sent ? 'Yes' : 'No'}</td>
        <td>{attendee.rsvp ? 'Yes' : 'No'}</td>
        <td>{attendee.attended ? 'Yes' : 'No'}</td>
      </tr>
    ));
  }

  render() {
    // console.log("this.state.eventData", this.props.location.state);
    // console.log("this.eventData1.eventData", eventData1);

    return (
      <div className="import-csv sm">
        <div className="container">
            <TopHeader />
          <div className="csv_row heading-membership">
            <div className="btn_col">
              <a className="backarrow" href="/event">
                <img alt="leftarrow" src={leftarrow} />
              </a>
            </div>
            <h2>Events</h2>
            <div className="btn_col last">
              <a className="btn btn-primary" href="/addevent">
                <img alt="plusicon" src={plusicon} /> Add new
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-12">
              <div className="panel summerbash-pannel">
                <div className="summerbash-new">
                  <h4>{this.props.location.state.eventData.title}</h4>
                  <p className="description-p">
                    {this.props.location.state.eventData.description}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12">
              <div className="panel summerbash-pannel">
                <div className="summerbash-new">
                  <div className="address-descrip-new">
                  <h3>date and time</h3>
                  <p>
                    {this.props.location.state ? (
                      <Moment format="MMMM, D YYYY">
                        {this.props.location.state.eventData.date}
                      </Moment>
                    ) : (
                      ''
                    )}{' '}
                    {this.props.location.state.eventData.hour}:
                    {this.props.location.state.eventData.minute}
                    {this.props.location.state.eventData.timeFormat}
                  </p>
                  <h3>location</h3>
                  {this.getFormattedAddressfromProps()}
                  <h3>invited people</h3>
                  <p>{this.props.location.state.eventData.peopleInvited}</p>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='panel'>
                <div className='summerbash-new'>
                  <h3>Attendees</h3>
                  <table className='table table-responsive'>
                    <thead>
                      <tr>
                        <th>NAME</th>
                        <th>SENT</th>
                        <th>RSVP</th>
                        <th>ATTENDED</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderInvitationData()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.settings,
  invitedUsers: state.event && state.event.invitedUsers ? state.event.invitedUsers : [],
});

export default connect(mapStateToProps, {
  eventInvitedUserList,
})(ImportCSV);
