/* eslint-disable filenames/match-exported */
import React, { Component } from 'react';

class NoMemberAdded extends Component {
  render() {
    return (
      <ul>
        <li>No member added</li>
      </ul>
    );
  }
}

export default NoMemberAdded;
