/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './EditCommittees.scss';
import { toast } from 'react-toastify';
import leftarrow from 'assets/images/leftarrow.png';
import { FormattedMessage, TopHeader } from 'components';
import {
  searchMembers,
  getCommittee,
  editCommitteeFieldsState,
  editCommittee,
  toggleSearchList,
} from 'actions/committee';
import SearchMembers from '../AddComittee/SearchMembers';
import AddedMembers from '../AddComittee/AddedMembers';
import NoMemberAdded from '../AddComittee/NoMemberAdded';

const Loader = require('react-loader');

class EditCommittee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      formSubmit: false,
      searchKeyword: '',
    };

    this.getMembers = this.getMembers.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    this.props.getCommittee({
      id,
    });
  }

  getMembers = (event) => {
    event.preventDefault();

    let keyword = event.target.value.trim();

    const checkHtmlTags = /(<([^>]+)>)/gi.test(keyword);

    if (keyword && !checkHtmlTags) {
      this.props.searchMembers(keyword);
    }

    this.props.toggleSearchList({
      showSearchList: true,
    });

    if (checkHtmlTags) {
      keyword = '';
    }

    this.setState({
      searchKeyword: keyword,
    });
  };

  showError = (error) => (
    <label className="ez-label ez-error">{<FormattedMessage id={error} />}</label>
  );

  onChangeHandler = (event) => {
    this.setState({ formSubmit: false });

    const {
      target: { name, value },
    } = event;

    const { committee } = this.props;

    const updateCommitteeFieldsState = {
      ...committee,
      [name]: value,
    };

    this.props.editCommitteeFieldsState(updateCommitteeFieldsState);
  };

  onValidateHandler = () => {
    this.setState({ formSubmit: true });

    const errors = {};

    const {
      committee: { committeeName, committeeType },
    } = this.props;

    if (!committeeName.trim()) {
      errors.committeeName = 'emptyCommitteeName';
    }
    if (committeeName.length < 2) {
      errors.committeeName = 'minLengthCommitteeName';
    }

    if (!committeeType.trim()) {
      errors.committeeType = 'emptyCommitteeType';
    }

    if (!this.props.editCommitteeSelectedMembers.length) {
      errors.noMembers = 'noMembersAdded';
    }

    this.setState({ errors });

    if (Object.keys(errors).length) {
      return false;
    }

    return true;
  };

  onSubmitHandler = (event) => {
    event.preventDefault();

    const isValid = this.onValidateHandler();

    const { committee, addedCommitteeMembers, deletedCommitteeMembers } = this.props;

    if (isValid) {
      const formData = {
        ...committee,
        addedMembers: addedCommitteeMembers.length
          ? addedCommitteeMembers.map((eachMember) => eachMember.id)
          : [],
        deletedMembers: deletedCommitteeMembers.length
          ? deletedCommitteeMembers.map((eachMember) => eachMember.id)
          : [],
        isActive: committee.isActive === 'on',
      };

      delete formData.members;

      console.log('added >>>>>', addedCommitteeMembers);
      console.log('deleted >>>>>', deletedCommitteeMembers);

      this.props.editCommittee(formData);
    }
  };

  render() {
    const {
      membersList,
      editCommitteeSelectedMembers: selectedMembersList,
      apiErrors,
      isLoading,
      committee,
      showSearchList,
    } = this.props;

    const {
      searchKeyword,
      errors: { committeeName, committeeType, noMembers },
      formSubmit,
    } = this.state;

    {
      formSubmit
        && apiErrors
        && apiErrors.message
        && toast.error(apiErrors.message, {
          autoClose: 1500,
        });
    }

    return (
      <div className="container">
        <TopHeader />
        <div className="csv_row heading-membership">
          <div className="btn_col">
            <Link className="backarrow" to="/committees">
              <img alt="leftarrow" src={leftarrow} />
            </Link>
          </div>
          <h2>Edit Committees</h2>
        </div>

        {isLoading && <Loader loaded={!isLoading} />}

        <form noValidate onSubmit={this.onSubmitHandler}>
          <div className="panel center">
            <div className="form-group">
              <label>Committee Name</label>
              <input
                className="form-control"
                defaultValue={committee.committeeName}
                name="committeeName"
                placeholder="What is your Committee name?"
                type="text"
                onChange={this.onChangeHandler}
                onKeyUp={this.onChangeHandler}
              />
              {committeeName && this.showError(committeeName)}
            </div>

            <div className="form-group">
              <label>Active</label>
              <div className="month_year">
                <ul>
                  <li>
                    <input
                      checked={committee.isActive === 'on'}
                      id="on"
                      name="isActive"
                      type="radio"
                      value="on"
                      onChange={this.onChangeHandler}
                    />
                    <label htmlFor="on">On</label>
                  </li>
                  <li>
                    <input
                      checked={committee.isActive === 'off'}
                      id="off"
                      name="isActive"
                      type="radio"
                      value="off"
                      onChange={this.onChangeHandler}
                    />
                    <label htmlFor="off">Off</label>
                  </li>
                </ul>
              </div>
            </div>

            <div className="form-group">
              <h3>Add Members</h3>
            </div>
            <div className="form-group">
              <label>Search to add members</label>
              <div className="form-group dropnew-alignment">
                <input
                  className="form-control search-input"
                  placeholder="Search member to invite them"
                  type="text"
                  onFocus={this.getMembers}
                  onKeyUp={this.getMembers}
                />
                {showSearchList && searchKeyword && membersList && (
                  <SearchMembers membersList={membersList} type="edit" />
                )}
              </div>
              {noMembers && this.showError(noMembers)}
            </div>

            <div className="form-group added">
              <h3>Added Members</h3>
              {selectedMembersList && selectedMembersList.length ? (
                <AddedMembers selectedMembers={selectedMembersList} type="edit" />
              ) : (
                <NoMemberAdded />
              )}
            </div>

            <div className="form-group">
              <label>Committee type</label>
              <input
                className="form-control"
                defaultValue={committee.committeeType}
                name="committeeType"
                placeholder="What is your Committee type?"
                type="text"
                onChange={this.onChangeHandler}
                onKeyUp={this.onChangeHandler}
              />
              {committeeType && this.showError(committeeType)}
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
                className="form-control"
                defaultValue={committee.description}
                name="description"
                placeholder="Write something about your committee"
                onChange={this.onChangeHandler}
                onKeyUp={this.onChangeHandler}
              ></textarea>
            </div>
            <button
              className="btn btn-primary btn-cent"
              onClick={this.saveAndContinue}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({
  committee: {
    membersList,
    editCommitteeSelectedMembers,
    committee,
    addedCommitteeMembers,
    deletedCommitteeMembers,
    editCommitteeError,
    isLoading,
    showSearchList,
  },
}) => ({
  addedCommitteeMembers,
  apiErrors: editCommitteeError,
  committee,
  deletedCommitteeMembers,
  editCommitteeSelectedMembers,
  isLoading,
  membersList,
  showSearchList,
});

export default connect(mapStateToProps, {
  editCommittee,
  editCommitteeFieldsState,
  getCommittee,
  searchMembers,
  toggleSearchList,
})(EditCommittee);
