import { createAction } from 'redux-actions';

export const UPDATE_MEMBER_INFO = 'UPDATE_MEMBER_INFO';
export const updateMemberInfo = createAction(UPDATE_MEMBER_INFO);

export const UPDATE_MEMBER_INFO_FAILURE = 'UPDATE_MEMBER_INFO_FAILURE';
export const updateMemberInfoFailure = createAction(UPDATE_MEMBER_INFO_FAILURE);

export const UPDATE_MEMBER_INFO_FIELDS_STATE = 'UPDATE_MEMBER_INFO_FIELDS_STATE';
export const updateMemberInfoFieldsState = createAction(
  UPDATE_MEMBER_INFO_FIELDS_STATE,
);

export const GET_SUBSCRIPTIONS_LIST = 'GET_SUBSCRIPTIONS_LIST';
export const getSubscriptionsList = createAction(GET_SUBSCRIPTIONS_LIST);

export const GET_SUBSCRIPTIONS_LIST_REQUESTED = 'GET_SUBSCRIPTIONS_LIST_REQUESTED';
export const getSubscriptionsListRequested = createAction(GET_SUBSCRIPTIONS_LIST_REQUESTED);

export const GET_SUBSCRIPTIONS_LIST_SUCCESS = 'GET_SUBSCRIPTIONS_LIST_SUCCESS';
export const getSubscriptionsListSuccess = createAction(GET_SUBSCRIPTIONS_LIST_SUCCESS);

export const CHECK_USER_SUBSCRIPTION = 'CHECK_USER_SUBSCRIPTION';
export const checkUserSubscription = createAction(CHECK_USER_SUBSCRIPTION);

export const CHECK_USER_SUBSCRIPTION_REQUESTED = 'CHECK_USER_SUBSCRIPTION_REQUESTED';
export const checkUserSubscriptionRequested = createAction(CHECK_USER_SUBSCRIPTION_REQUESTED);

export const CHECK_USER_SUBSCRIPTION_SUCCESS = 'CHECK_USER_SUBSCRIPTION_SUCCESS';
export const checkUserSubscriptionSuccess = createAction(CHECK_USER_SUBSCRIPTION_SUCCESS);

export const UPDATE_USER_SUBSCRIPTION = 'UPDATE_USER_SUBSCRIPTION';
export const updateUserSubscription = createAction(UPDATE_USER_SUBSCRIPTION);

export const UPDATE_USER_SUBSCRIPTION_REQUESTED = 'UPDATE_USER_SUBSCRIPTION_REQUESTED';
export const updateUserSubscriptionRequested = createAction(UPDATE_USER_SUBSCRIPTION_REQUESTED);

export const UPDATE_USER_SUBSCRIPTION_SUCCESS = 'UPDATE_USER_SUBSCRIPTION_SUCCESS';
export const updateUserSubscriptionSuccess = createAction(UPDATE_USER_SUBSCRIPTION_SUCCESS);

export const UPDATE_USER_SUBSCRIPTION_FAILED = 'UPDATE_USER_SUBSCRIPTION_FAILED';
export const updateUserSubscriptionFailed = createAction(UPDATE_USER_SUBSCRIPTION_FAILED);

export const DELETE_USER_SUBSCRIPTION = 'DELETE_USER_SUBSCRIPTION';
export const deleteUserSubscription = createAction(DELETE_USER_SUBSCRIPTION);

export const DELETE_USER_SUBSCRIPTION_REQUESTED = 'DELETE_USER_SUBSCRIPTION_REQUESTED';
export const deleteUserSubscriptionRequested = createAction(DELETE_USER_SUBSCRIPTION_REQUESTED);

export const DELETE_USER_SUBSCRIPTION_SUCCESS = 'DELETE_USER_SUBSCRIPTION_SUCCESS';
export const deleteUserSubscriptionSuccess = createAction(DELETE_USER_SUBSCRIPTION_SUCCESS);

export const DELETE_USER_SUBSCRIPTION_FAILED = 'DELETE_USER_SUBSCRIPTION_FAILED';
export const deleteUserSubscriptionFailed = createAction(DELETE_USER_SUBSCRIPTION_FAILED);

export const UPDATE_USER_META = 'UPDATE_USER_META';
export const updateUserMeta = createAction(UPDATE_USER_META);

export const UPDATE_USER_META_REQUESTED = 'UPDATE_USER_META_REQUESTED';
export const updateUserMetaRequested = createAction(UPDATE_USER_META_REQUESTED);

export const UPDATE_USER_META_SUCCESS = 'UPDATE_USER_META_SUCCESS';
export const updateUserMetaSuccess = createAction(UPDATE_USER_META_SUCCESS);

export const UPDATE_USER_META_FAILED = 'UPDATE_USER_META_FAILED';
export const updateUserMetaFailed = createAction(UPDATE_USER_META_FAILED);

export const CHECK_USER_USAGE = 'CHECK_USER_USAGE';
export const checkUserUsage = createAction(CHECK_USER_USAGE);

export const CHECK_USER_USAGE_REQUESTED = 'CHECK_USER_USAGE_REQUESTED';
export const checkUserUsageRequested = createAction(CHECK_USER_USAGE_REQUESTED);

export const CHECK_USER_USAGE_SUCCESS = 'CHECK_USER_USAGE_SUCCESS';
export const checkUserUsageSuccess = createAction(CHECK_USER_USAGE_SUCCESS);

export const GET_USER_USAGE_STATS = 'GET_USER_USAGE_STATS';
export const getUserUsageStats = createAction(GET_USER_USAGE_STATS);

export const GET_USER_USAGE_STATS_REQUESTED = 'GET_USER_USAGE_STATS_REQUESTED';
export const getUserUsageStatsRequested = createAction(GET_USER_USAGE_STATS_REQUESTED);

export const GET_USER_USAGE_STATS_SUCCESS = 'GET_USER_USAGE_STATS_SUCCESS';
export const getUserUsageStatsSuccess = createAction(GET_USER_USAGE_STATS_SUCCESS);

export const UPDATE_MEMBER_INFO_SUCCESS = 'UPDATE_MEMBER_INFO_SUCCESS';
export const updateMemberInfoFormSuccess = createAction(UPDATE_MEMBER_INFO_SUCCESS);

export const UPDATE_MEMBER_INFO_REQUESTED = 'UPDATE_MEMBER_INFO_REQUESTED';
export const updateMemberInfoRequested = createAction(UPDATE_MEMBER_INFO_REQUESTED);

export const UPDATE_MEMBER_INFO_SINGLE = 'UPDATE_MEMBER_INFO_SINGLE';
export const updateMemberInfoSingle = createAction(UPDATE_MEMBER_INFO_SINGLE);

export const UPDATE_MEMBER_INFO_SINGLE_FAILURE = 'UPDATE_MEMBER_INFO_SINGLE_FAILURE';
export const updateMemberInfoSingleFailure = createAction(
  UPDATE_MEMBER_INFO_SINGLE_FAILURE,
);

export const UPDATE_MEMBER_INFO_SINGLE_SUCCESS = 'UPDATE_MEMBER_INFO_SINGLE_SUCCESS';
export const updateMemberInfoSingleSuccess = createAction(
  UPDATE_MEMBER_INFO_SINGLE_SUCCESS,
);

export const UPDATE_MEMBER_INFO_SINGLE_REQUESTED = 'UPDATE_MEMBER_INFO_SINGLE_REQUESTED';
export const updateMemberInfoSingleRequested = createAction(
  UPDATE_MEMBER_INFO_SINGLE_REQUESTED,
);

export const TRANSACTION_DETAIL = 'TRANSACTION_DETAIL';
export const getTransactionDetail = createAction(TRANSACTION_DETAIL);

export const TRANSACTION_DETAIL_FAILURE = 'TRANSACTION_DETAIL_FAILURE';
export const getTransactionDetailFailure = createAction(TRANSACTION_DETAIL_FAILURE);

export const TRANSACTION_DETAIL_STATE = 'TRANSACTION_DETAIL_STATE';
export const getTransactionDetailState = createAction(TRANSACTION_DETAIL_STATE);

export const TRANSACTION_DETAIL_SUCCESS = 'TRANSACTION_DETAIL_SUCCESS';
export const getTransactionDetailSuccess = createAction(TRANSACTION_DETAIL_SUCCESS);

export const TRANSACTION_DETAIL_REQUESTED = 'TRANSACTION_DETAIL_REQUESTED';
export const getTransactionDetailRequested = createAction(TRANSACTION_DETAIL_REQUESTED);

export const PROFILE_PAY = 'PROFILE_PAY';
export const profilePay = createAction(PROFILE_PAY);

export const PROFILE_PAY_FAILURE = 'PROFILE_PAY_FAILURE';
export const profilePayFailure = createAction(PROFILE_PAY_FAILURE);

export const PROFILE_PAY_FIELDS_STATE = 'PROFILE_PAY_FIELDS_STATE';
export const profilePayFieldsState = createAction(PROFILE_PAY_FIELDS_STATE);

export const PROFILE_PAY_SUCCESS = 'PROFILE_PAY_SUCCESS';
export const profilePaySuccess = createAction(PROFILE_PAY_SUCCESS);

export const PROFILE_PAY_REQUESTED = 'PROFILE_PAY_REQUESTED';
export const profilePayRequested = createAction(PROFILE_PAY_REQUESTED);

export const PROFILE_PAYMENT_FORM = 'PROFILE_PAYMENT_FORM';
export const profilePaymentForm = createAction(PROFILE_PAYMENT_FORM);

export const PROFILE_PAYMENT_FORM_FAILURE = 'PROFILE_PAYMENT_FORM_FAILURE';
export const profilePaymentFormFailure = createAction(UPDATE_MEMBER_INFO_FAILURE);

export const PROFILE_PAYMENT_FORM_FIELDS_STATE = 'PROFILE_PAYMENT_FORM_FIELDS_STATE';
export const profilePaymentFormFieldsState = createAction(
  PROFILE_PAYMENT_FORM_FIELDS_STATE,
);

export const PROFILE_PAYMENT_FORM_SUCCESS = 'PROFILE_PAYMENT_FORM_SUCCESS';
export const profilePaymentFormSuccess = createAction(PROFILE_PAYMENT_FORM_SUCCESS);

export const PROFILE_PAYMENT_FORM_REQUESTED = 'PROFILE_PAYMENT_FORM_REQUESTED';
export const profilePaymentFormRequested = createAction(
  PROFILE_PAYMENT_FORM_REQUESTED,
);

/* export const PROFILE_CARD_LIST = 'PROFILE_CARD_LIST';
export const profileCardList = createAction(PROFILE_CARD_LIST);

export const PROFILE_CARD_LIST_FAILURE = 'PROFILE_CARD_LIST_FAILURE';
export const profileCardListFailure = createAction(PROFILE_CARD_LIST_FAILURE);

export const PROFILE_CARD_LIST_SUCCESS = 'PROFILE_CARD_LIST_SUCCESS';
export const profileCardListSuccess = createAction(PROFILE_CARD_LIST_SUCCESS);

export const PROFILE_CARD_LIST_REQUESTED = 'PROFILE_CARD_LIST_REQUESTED';
export const profileCardListRequested = createAction(PROFILE_CARD_LIST_REQUESTED); */

export const MEMBER_LIST = 'MEMBER_LIST';
export const memberList = createAction(MEMBER_LIST);

export const MEMBER_LIST_FAILURE = 'MEMBER_LIST_FAILURE';
export const memberListFailure = createAction(MEMBER_LIST_FAILURE);

export const MEMBER_LIST_SUCCESS = 'MEMBER_LIST_SUCCESS';
export const memberListSuccess = createAction(MEMBER_LIST_SUCCESS);

export const MEMBER_LIST_REQUESTED = 'MEMBER_LIST_REQUESTED';
export const memberListRequested = createAction(MEMBER_LIST_REQUESTED);

export const MEMBER_LIST_VIEW = 'MEMBER_LIST_VIEW';
export const memberListView = createAction(MEMBER_LIST_VIEW);

export const MEMBER_LIST_VIEW_FAILURE = 'MEMBER_LIST_VIEW_FAILURE';
export const memberListViewFailure = createAction(MEMBER_LIST_VIEW_FAILURE);

export const MEMBER_LIST_VIEW_SUCCESS = 'MEMBER_LIST_VIEW_SUCCESS';
export const memberListViewSuccess = createAction(MEMBER_LIST_VIEW_SUCCESS);

export const MEMBER_LIST_VIEW_REQUESTED = 'MEMBER_LIST_VIEW_REQUESTED';
export const memberListViewRequested = createAction(MEMBER_LIST_VIEW_REQUESTED);

export const ADD_PERSON = 'ADD_PERSON';
export const addPerson = createAction(ADD_PERSON);

export const ADD_PERSON_FAILURE = 'ADD_PERSON_FAILURE';
export const addPersonFailure = createAction(ADD_PERSON_FAILURE);

export const ADD_PERSON_SUCCESS = 'ADD_PERSON_SUCCESS';
export const addPersonSuccess = createAction(ADD_PERSON_SUCCESS);

export const ADD_PERSON_REQUESTED = 'ADD_PERSON_REQUESTED';
export const addPersonRequested = createAction(ADD_PERSON_REQUESTED);

export const MY_PROFILE = 'MY_PROFILE';
export const myProfile = createAction(MY_PROFILE);

export const MY_PROFILE_FAILURE = 'MY_PROFILE_FAILURE';
export const myProfileFailure = createAction(MY_PROFILE_FAILURE);

export const MY_PROFILE_SUCCESS = 'MY_PROFILE_SUCCESS';
export const myProfileSuccess = createAction(MY_PROFILE_SUCCESS);

export const MY_PROFILE_REQUESTED = 'MY_PROFILE_REQUESTED';
export const myProfileRequested = createAction(MY_PROFILE_REQUESTED);

export const HEADER_LIST = 'HEADER_LIST';
export const headerList = createAction(HEADER_LIST);

export const HEADER_LIST_FAILURE = 'HEADER_LIST_FAILURE';
export const headerListFailure = createAction(HEADER_LIST_FAILURE);

export const HEADER_LIST_SUCCESS = 'HEADER_LIST_SUCCESS';
export const headerListSuccess = createAction(HEADER_LIST_SUCCESS);

export const HEADER_LIST_REQUESTED = 'HEADER_LIST_REQUESTED';
export const headerListRequested = createAction(HEADER_LIST_REQUESTED);

export const FILTER_LIST = 'FILTER_LIST';
export const filterList = createAction(FILTER_LIST);

export const FILTER_LIST_FAILURE = 'FILTER_LIST_FAILURE';
export const filterListFailure = createAction(FILTER_LIST_FAILURE);

export const FILTER_LIST_SUCCESS = 'FILTER_LIST_SUCCESS';
export const filterListSuccess = createAction(FILTER_LIST_SUCCESS);

export const FILTER_LIST_REQUESTED = 'FILTER_LIST_REQUESTED';
export const filterListRequested = createAction(FILTER_LIST_REQUESTED);

export const ORDER_BY_DATA_LIST = 'ORDER_BY_DATA_LIST';
export const orderByDataList = createAction(ORDER_BY_DATA_LIST);

export const ORDER_BY_DATA_LIST_FAILURE = 'ORDER_BY_DATA_LIST_FAILURE';
export const orderByDataListFailure = createAction(ORDER_BY_DATA_LIST_FAILURE);

export const ORDER_BY_DATA_LIST_SUCCESS = 'ORDER_BY_DATA_LIST_SUCCESS';
export const orderByDataListSuccess = createAction(ORDER_BY_DATA_LIST_SUCCESS);

export const ORDER_BY_DATA_LIST_REQUESTED = 'ORDER_BY_DATA_LIST_REQUESTED';
export const orderByDataListRequested = createAction(ORDER_BY_DATA_LIST_REQUESTED);

export const FILTER_DATA_LIST = 'FILTER_DATA_LIST';
export const filterDataList = createAction(FILTER_DATA_LIST);

export const FILTER_DATA_LIST_FAILURE = 'FILTER_DATA_LIST_FAILURE';
export const filterDataListFailure = createAction(FILTER_DATA_LIST_FAILURE);

export const FILTER_DATA_LIST_SUCCESS = 'FILTER_DATA_LIST_SUCCESS';
export const filterDataListSuccess = createAction(FILTER_DATA_LIST_SUCCESS);

export const FILTER_DATA_LIST_REQUESTED = 'FILTER_DATA_LIST_REQUESTED';
export const filterDataListRequested = createAction(FILTER_DATA_LIST_REQUESTED);

export const FILTER_DATA_LISTING = 'FILTER_DATA_LISTING';
export const filterDataListing = createAction(FILTER_DATA_LISTING);

export const FILTER_DATA_LISTING_FAILURE = 'FILTER_DATA_LISTING_FAILURE';
export const filterDataListingFailure = createAction(FILTER_DATA_LISTING_FAILURE);

export const FILTER_DATA_LISTING_SUCCESS = 'FILTER_DATA_LISTING_SUCCESS';
export const filterDataListingSuccess = createAction(FILTER_DATA_LISTING_SUCCESS);

export const FILTER_DATA_LISTING_REQUESTED = 'FILTER_DATA_LISTING_REQUESTED';
export const filterDataListingRequested = createAction(
  FILTER_DATA_LISTING_REQUESTED,
);

export const SAVE_NOTES = 'SAVE_NOTES';
export const saveNotes = createAction(SAVE_NOTES);

export const SAVE_NOTES_FAILURE = 'SAVE_NOTES_FAILURE';
export const saveNotesFailure = createAction(SAVE_NOTES_FAILURE);

export const SAVE_NOTES_SUCCESS = 'SAVE_NOTES_SUCCESS';
export const saveNotesSuccess = createAction(SAVE_NOTES_SUCCESS);

export const SAVE_NOTES_REQUESTED = 'SAVE_NOTES_REQUESTED';
export const saveNotesRequested = createAction(SAVE_NOTES_REQUESTED);

export const NOTES_LIST = 'NOTES_LIST';
export const notesList = createAction(NOTES_LIST);

export const NOTES_LIST_FAILURE = 'NOTES_LIST_FAILURE';
export const notesListFailure = createAction(NOTES_LIST_FAILURE);

export const NOTES_LIST_SUCCESS = 'NOTES_LIST_SUCCESS';
export const notesListSuccess = createAction(NOTES_LIST_SUCCESS);

export const NOTES_LIST_REQUESTED = 'NOTES_LIST_REQUESTED';
export const notesListRequested = createAction(NOTES_LIST_REQUESTED);

export const NOTES_UPDATE = 'NOTES_UPDATE';
export const notesUpdate = createAction(NOTES_UPDATE);

export const NOTES_UPDATE_FAILURE = 'NOTES_UPDATE_FAILURE';
export const notesUpdateFailure = createAction(NOTES_UPDATE_FAILURE);

export const NOTES_UPDATE_SUCCESS = 'NOTES_UPDATE_SUCCESS';
export const notesUpdateSuccess = createAction(NOTES_UPDATE_SUCCESS);

export const NOTES_UPDATE_REQUESTED = 'NOTES_UPDATE_REQUESTED';
export const notesUpdateRequested = createAction(NOTES_UPDATE_REQUESTED);

export const INDIVISUAL_USER = 'INDIVISUAL_USER';
export const indivisualUser = createAction(INDIVISUAL_USER);

export const INDIVISUAL_USER_FAILURE = 'INDIVISUAL_USER_FAILURE';
export const indivisualUserFailure = createAction(INDIVISUAL_USER_FAILURE);

export const INDIVISUAL_USER_SUCCESS = 'INDIVISUAL_USER_SUCCESS';
export const indivisualUserSuccess = createAction(INDIVISUAL_USER_SUCCESS);

export const INDIVISUAL_USER_REQUESTED = 'INDIVISUAL_USER_REQUESTED';
export const indivisualUserRequested = createAction(INDIVISUAL_USER_REQUESTED);

export const ADD_TAGS = 'ADD_TAGS';
export const addTags = createAction(ADD_TAGS);

export const ADD_TAGS_FAILURE = 'ADD_TAGS_FAILURE';
export const addTagsFailure = createAction(ADD_TAGS_FAILURE);

export const ADD_TAGS_SUCCESS = 'ADD_TAGS_SUCCESS';
export const addTagsSuccess = createAction(ADD_TAGS_SUCCESS);

export const ADD_TAGS_REQUESTED = 'ADD_TAGS_REQUESTED';
export const addTagsRequested = createAction(ADD_TAGS_REQUESTED);

export const ADD_TAGS_LIST = 'ADD_TAGS_LIST';
export const tagsList = createAction(ADD_TAGS_LIST);

export const ADD_TAGS_LIST_FAILURE = 'ADD_TAGS_LIST_FAILURE';
export const tagsListFailure = createAction(ADD_TAGS_LIST_FAILURE);

export const ADD_TAGS_LIST_SUCCESS = 'ADD_TAGS_LIST_SUCCESS';
export const tagsListSuccess = createAction(ADD_TAGS_LIST_SUCCESS);

export const TAGS_LIST_REQUESTED = 'TAGS_LIST_REQUESTED';
export const tagsListRequested = createAction(TAGS_LIST_REQUESTED);

export const TAG_REMOVE = 'TAG_REMOVE';
export const removeTags = createAction(TAG_REMOVE);

export const TAG_REMOVE_FAILURE = 'TAG_REMOVE_FAILURE';
export const removeTagsFailure = createAction(TAG_REMOVE_FAILURE);

export const TAG_REMOVE_SUCCESS = 'TAG_REMOVE_SUCCESS';
export const removeTagsSuccess = createAction(TAG_REMOVE_SUCCESS);

export const TAG_REMOVE_REQUESTED = 'TAG_REMOVE_REQUESTED';
export const removeTagsRequested = createAction(TAG_REMOVE_REQUESTED);

// payment actions
export const PAYMENT_LIST = 'PAYMENT_LIST';
export const paymentList = createAction(PAYMENT_LIST);

export const PAYMENT_LIST_FAILURE = 'PAYMENT_LIST_FAILURE';
export const paymentListFailure = createAction(PAYMENT_LIST_FAILURE);

export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS';
export const paymentListSuccess = createAction(PAYMENT_LIST_SUCCESS);

export const PAYMENT_LIST_REQUESTED = 'PAYMENT_LIST_REQUESTED';
export const paymentListRequested = createAction(PAYMENT_LIST_REQUESTED);

// profile activity list
export const PROFILE_ACTIVITY_LIST = 'PROFILE_ACTIVITY_LIST';
export const activityList = createAction(PROFILE_ACTIVITY_LIST);

export const PROFILE_ACTIVITY_LIST_FAILURE = 'PROFILE_ACTIVITY_LIST_FAILURE';
export const activityListFailure = createAction(PROFILE_ACTIVITY_LIST_FAILURE);

export const PROFILE_ACTIVITY_LIST_SUCCESS = 'PROFILE_ACTIVITY_LIST_SUCCESS';
export const activityListSuccess = createAction(PROFILE_ACTIVITY_LIST_SUCCESS);

export const PROFILE_ACTIVITY_LIST_REQUESTED = 'PROFILE_ACTIVITY_LIST_REQUESTED';
export const activityListRequested = createAction(PROFILE_ACTIVITY_LIST_REQUESTED);

// profile email activity list
export const PROFILE_EMAIL_LIST = 'PROFILE_EMAIL_LIST';
export const emailList = createAction(PROFILE_EMAIL_LIST);

export const PROFILE_EMAIL_LIST_FAILURE = 'PROFILE_EMAIL_LIST_FAILURE';
export const emailListFailure = createAction(PROFILE_EMAIL_LIST_FAILURE);

export const PROFILE_EMAIL_LIST_SUCCESS = 'PROFILE_EMAIL_LIST_SUCCESS';
export const emailListSuccess = createAction(PROFILE_EMAIL_LIST_SUCCESS);

export const PROFILE_EMAIL_LIST_REQUESTED = 'PROFILE_EMAIL_LIST_REQUESTED';
export const emailListRequested = createAction(PROFILE_EMAIL_LIST_REQUESTED);

// member profile payment list
export const PROFILE_TRANSACTION_LIST = 'PROFILE_TRANSACTION_LIST';
export const transactionList = createAction(PROFILE_TRANSACTION_LIST);

export const PROFILE_TRANSACTION_LIST_FAILURE = 'PROFILE_TRANSACTION_LIST_FAILURE';
export const transactionListFailure = createAction(PROFILE_TRANSACTION_LIST_FAILURE);

export const PROFILE_TRANSACTION_LIST_SUCCESS = 'PROFILE_TRANSACTION_LIST_SUCCESS';
export const transactionListSuccess = createAction(PROFILE_TRANSACTION_LIST_SUCCESS);

export const PROFILE_TRANSACTION_LIST_REQUESTED = 'PROFILE_TRANSACTION_LIST_REQUESTED';
export const transactionListRequested = createAction(
  PROFILE_TRANSACTION_LIST_REQUESTED,
);

// action to delete member by Id from member list
export const DELETED_MEMBER = 'DELETED_MEMBER';
export const deletedMember = createAction(DELETED_MEMBER);

export const DELETED_MEMBER_REQUESTED = 'DELETED_MEMBER_REQUESTED';
export const deletedMemberRequested = createAction(DELETED_MEMBER_REQUESTED);

export const DELETED_MEMBER_SUCCESS = 'DELETED_MEMBER_SUCCESS';
export const deletedMemberSuccess = createAction(DELETED_MEMBER_SUCCESS);

export const DELETED_MEMBER_FAILURE = 'DELETED_MEMBER_FAILURE';
export const deletedMemberFailure = createAction(DELETED_MEMBER_FAILURE);

// search tags from tag list
export const SEARCH_TAGS = 'SEARCH_TAGS';
export const searchTags = createAction(SEARCH_TAGS);

export const SEARCH_TAGS_REQUESTED = 'SEARCH_TAGS_REQUESTED';
export const searchTagsRequested = createAction(SEARCH_TAGS_REQUESTED);

export const SEARCH_TAGS_SUCCESS = 'SEARCH_TAGS_SUCCESS';
export const searchTagsSuccess = createAction(SEARCH_TAGS_SUCCESS);

export const SEARCH_TAGS_FAILURE = 'SEARCH_TAGS_FAILURE';
export const searchTagsFailure = createAction(SEARCH_TAGS_FAILURE);

export const UPDATE_PROFILE_BY_FIELD = 'UPDATE_PROFILE_BY_FIELD';
export const updateProfileByField = createAction(UPDATE_PROFILE_BY_FIELD);

export const UPDATE_PROFILE_BY_FIELD_SUCCESS = 'UPDATE_PROFILE_BY_FIELD_SUCCESS';
export const updateProfileByFieldSuccess = createAction(UPDATE_PROFILE_BY_FIELD_SUCCESS);
